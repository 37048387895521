import { useState, useEffect } from 'react';

import TsKundeTypeahead from 'components/TsKundeTypeahead';

import AccessToSelect from 'components/AccessToSelect';
import RoleSelect from 'components/RoleSelect';

import TsKunderApi from 'services/AdminportalenApi/TsKunderApi';
import { TsKundeSearchDto } from 'services/AdminportalenApi/TsKunderApi/types';
import { BrukerTilgangCommand } from 'services/AdminportalenApi/BrukereApi/types';
import { IconButton } from '@tradesolution/iceberg-ui-react';

interface Props {
  tilgang?: BrukerTilgangCommand;
  onUpdateTilgang: (prop: string, value: string) => void;
  onDeleteRow: () => void;
  tsKundeId: string;
}

const TilgangRow = (props: Props) => {

  const [selectedTsKunde, setSelectedTsKunde] = useState<TsKundeSearchDto>();

  useEffect(() => {
    if (selectedTsKunde) {
      props.onUpdateTilgang('tsKundeId', selectedTsKunde.tsKundeId);
    } else {
      props.onUpdateTilgang('tsKundeId', null);
    }
  }, [selectedTsKunde]);

  useEffect(() => {
    if (props.tsKundeId && !selectedTsKunde) {
      TsKunderApi.getById(props.tsKundeId).then(response => setSelectedTsKunde(response));
    }
  }, [props.tsKundeId]);

  return (
    <tr>
      <td style={{ minWidth: "250px" }}>
        <TsKundeTypeahead
          size='sm'
          selectedTsKundeId={selectedTsKunde?.tsKundeId}
          onHandleChange={e => setSelectedTsKunde(e)} />
      </td>
      <td style={{ maxWidth: "250px" }}>
        <AccessToSelect
          size='sm'
          selected={props.tilgang.accessTo}
          onChange={e => props.onUpdateTilgang('accessTo', e)} />
      </td>
      <td style={{ maxWidth: "250px" }}>
        <RoleSelect
          size='sm'
          selected={props.tilgang.role}
          onChange={e => props.onUpdateTilgang('role', e)} />
      </td>
      <td className='fitContent'>
        <IconButton size='sm' icon='trash' variant='outline-danger' onClick={props.onDeleteRow} />
      </td>
    </tr>
  );
};

export default TilgangRow;