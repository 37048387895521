import { IcebergAlert, IconButton } from "@tradesolution/iceberg-ui-react";
import { Modal, Form } from "react-bootstrap";
import AdApplicationsApi from "services/AdminportalenApi/AdApplicationsApi";
import { AdApplication} from "services/AdminportalenApi/AdApplicationsApi/types";

interface Props {
    application?: AdApplication;
    show: boolean;
    onClose: () => void;
}

const StatusModal = (props: Props) => {

    const toggleStatus = async (app: AdApplication) => {        
        await AdApplicationsApi.toogleStatus(app.applicationId);
        props.onClose();        
    };

    return (
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                {props.application?.deactivatedDate ? 'Aktiver' : 'Deaktiver'} {props.application?.name}?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>  
                    <IcebergAlert variant="info">
                        {props.application?.deactivatedDate ?
                        'Husk å legg til tilganger som så brutalt ble revet vekk fra applikasjonen ved deaktivering' :
                         'Alle tilganger vil fjernes ved deaktivering og må manuelt legges til ved aktivinger'}
                    </IcebergAlert>                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <IconButton variant="outline-primary" icon="close" className="col" onClick={props.onClose}>
                    Lukk
                </IconButton>
                <IconButton variant="primary" size="sm" icon="send" className="col" onClick={() => toggleStatus(props.application)}>
                {props.application?.deactivatedDate ? 'Aktiver' : 'Deaktiver'}
                </IconButton>
            </Modal.Footer>
        </Modal >
    );
};

export default StatusModal;