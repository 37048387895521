import { useState, useEffect } from 'react';
import { Button, Popover, OverlayTrigger, Form, Spinner, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import cx from 'classnames';
import { debounce } from 'lodash';

import BrregTypeAhead from 'components/BrregTypeAhead';

import { EnhetDto } from 'services/AdminportalenApi/BrregApi/types';
import TsKunderApi from 'services/AdminportalenApi/TsKunderApi';
import { OpprettTsKundeCommand } from 'services/AdminportalenApi/TsKunderApi/types';

import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

const NyTsKunde = () => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [brregResult, setBrregResult] = useState<EnhetDto | undefined>();
  const [brregError, setBrregError] = useState<string>('');
  const [brregQuery, setBrregQuery] = useState<string>('');
  const [foretaksNavn, setForetaksNavn] = useState<string>('');
  const [tsKundeNavn, setTsKundeNavn] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [kundeExists, setKundeExists] = useState<boolean>(false);

  const toaster = useToaster();
  const navigate = useNavigate();

  const searchTsKundeByName = debounce(async () => {
    if (!tsKundeNavn) return;
    setIsSearching(prev => true);
    let searchResults = await TsKunderApi.search(tsKundeNavn, 'Alle', 100);
    if (searchResults.findIndex(x => x.tsKundeNavn.toLowerCase() === tsKundeNavn.toLowerCase()) > -1) {
      setKundeExists(true);
    } else {
      setKundeExists(false);
    }
    setIsSearching(prev => false);
  }, 400);

  const handleOnSubmit = async () => {
    setIsCreating(true);
    try {
      let command: OpprettTsKundeCommand =
      {
        tsKundeNavn: tsKundeNavn,
        organisasjonsnummer: brregResult?.orgNr ?? brregQuery,
        foretaksnavn: brregResult?.name ?? foretaksNavn
      };
      let tsKunde = await TsKunderApi.opprettTsKunde(command);
      toaster.success('Ny kunde opprettet', tsKunde.tsKundeNavn + ' er kundens fortryllende navn');
      resetForm();
      openTsKundeDetaljer(tsKunde.tsKundeId);
      setIsCreating(false);
    } catch (err: any) {
      toaster.error('Feil! Kunne ikke opprette TsKunde', HttpErrorHelper.handleErrorMessage(err));
      setIsCreating(false);
    }
    setIsCreating(false);
  };

  const resetForm = () => {
    setTsKundeNavn('');
    setForetaksNavn('');
    setBrregResult(undefined);
  }

  const openTsKundeDetaljer = (tsKundeId: string) => {
    let url = `/TsKunder/${tsKundeId}/kundeKort`;
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
    navigate(`/TsKunder/${tsKundeId}/kundeKort`)
  };

  useEffect(() => {
    if (brregResult && brregResult.name) {
      if (!tsKundeNavn) {
        setTsKundeNavn(brregResult.name);
      }
      if (!foretaksNavn) {
        setForetaksNavn(brregResult.name);
      }
    }
    if (brregResult) {
      if (brregResult.slettetDato)
        setBrregError('Orgnr er slettet i brreg');
      else
        setBrregError('');
    } else {
      setBrregError('');
    }
  }, [brregResult]);

  useEffect(() => {
    if (tsKundeNavn.length > 2) {
      searchTsKundeByName();
    }
  }, [tsKundeNavn]);

  const popoverMenu = (
    <Popover className={Style.popover} id="popover-menu">
      <Popover.Header>Ny TsKunde</Popover.Header>
      <Popover.Body>
        <Form>
          <Form.Group className={Style.formGroup}>
            <Form.Label>
              TsKundenavn
            </Form.Label>
            <InputGroup>
              <Form.Control
                className={Style.kundenavnFormControl}
                value={tsKundeNavn}
                onChange={e => {
                  setKundeExists(prev => prev ? !prev : prev);
                  setTsKundeNavn(e.target.value.toUpperCase());
                }}
                isInvalid={kundeExists && !isSearching}
                required />
              <InputGroup.Text className={cx(Style.spinnerContainer, { [Style.invalid]: (tsKundeNavn && kundeExists && !isSearching) })}>
                {
                  isSearching &&
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                }
              </InputGroup.Text>
              <Form.Control.Feedback type='invalid'>
                {kundeExists && 'Dette kundenavnet er allerede i bruk.'}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className={Style.formGroup}>
            <Form.Label>Organisasjonsnummer</Form.Label>
            <BrregTypeAhead
              onBrregEnhetSelected={e => setBrregResult(e)}
              isInvalid={brregError.length > 0}
              setQueryUsed={setBrregQuery} />
            {brregError.length > 0 && <div className={Style.invalidFeedback}>{brregError}</div>}
          </Form.Group>
          <Form.Group className={Style.formGroup}>
            <Form.Label>Foretaksnavn</Form.Label>
            <Form.Control
              value={foretaksNavn} 
              readOnly={brregResult && !brregResult.finnesIkke && !brregResult.slettetDato}
              onChange={e => {
                setForetaksNavn(e.target.value.toUpperCase());
              }}
              required />
          </Form.Group>
          <div className={Style.btnContainer} >
            <Button type="button" variant="primary" size="sm"
              disabled={
                (!brregResult && !brregQuery) || isCreating ||
                !tsKundeNavn || kundeExists || isSearching || !foretaksNavn
              }
              onClick={e => handleOnSubmit()}>
              {
                isCreating &&
                <Spinner style={{ padding: '0', marginRight: '0.3em' }} as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              }
              <span>Opprett</span>
            </Button>
          </div>
        </Form>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose trigger="click" placement="auto" overlay={popoverMenu}>
      <Button variant="primary">
        <span
          style={{
            paddingLeft: '.3em',
          }}
        >
          <IcebergIcon icon='plus' />TsKunde
        </span>
      </Button>
    </OverlayTrigger>
  );
};

export default NyTsKunde;
