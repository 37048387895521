import axios from "axios";
import { CreateAbonnementCommand, UpdateAbonnementCommand } from "./types";
import { getBaseUrl } from "..";

const baseUrl = getBaseUrl() + '/Abonnement';

const AbonnementApi = {
  createAbonnement: (command: CreateAbonnementCommand) =>
    axios.post(baseUrl, command).then(response => response.data),

  updateAbonnement: (command: UpdateAbonnementCommand) => 
    axios.put(baseUrl, command).then(response => response.data),

  deleteAbonnement: (abonnementId: string) => 
    axios.delete(`${baseUrl}/${abonnementId}`),
}

export default AbonnementApi;