import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Spinner, Alert } from 'react-bootstrap';

import TjenestegruppePicker from 'components/TjenestegruppePicker';

import FakturaAdresserApi from 'services/AdminportalenApi/FakturaAdresserApi';
import { UpdateFakturaAddresseCommand } from 'services/AdminportalenApi/FakturaAdresserApi/types';
import BrregApi from 'services/AdminportalenApi/BrregApi';
import { EnhetDto } from 'services/AdminportalenApi/BrregApi/types';
import { FakturaAdresse } from 'services/AdminportalenApi/KundeKortApi/types';

import useToaster from 'utils/hooks/useToaster';
import useForm from 'utils/hooks/useForm';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  fakturaadresse: FakturaAdresse;
  onUpdated: () => void;
  onlyView: boolean;
  orgNr: string;
}

const EditFaktureringsinfo: React.FC<Props> = props => {
  const toaster = useToaster();

  const [brregResult, setBrregResult] = useState<EnhetDto>();

  const initialValues: UpdateFakturaAddresseCommand = {
    fakturaAdresseId: props.fakturaadresse.fakturaAdresseId,
    fakturaAdresseNavn: props.fakturaadresse.fakturaAdresseNavn,
    fakturaEmail: props.fakturaadresse.fakturaEmail,
    adressat: props.fakturaadresse.adressat ?? '',
    postadresse: props.fakturaadresse.postadresse,
    postnummer: props.fakturaadresse.postnummer,
    poststed: props.fakturaadresse.poststed,
    land: props.fakturaadresse.land,
    tjenestegruppeIds: props.fakturaadresse.tjenesteGruppeConnections.filter(x => x.selected).map(x => x.tjenesteGruppeId)
  }

  const validate = (values: UpdateFakturaAddresseCommand) => {
    let errors: any = {};
    if (values.fakturaAdresseNavn.length === 0)
      errors.fakturaAdresseNavn = 'Dette feltet kan ikke være tomt.';

    if (values.fakturaEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.fakturaEmail))
      errors.fakturaEmail = 'Ugyldig epost';

    if (values.postnummer?.length === 0) {
      errors.postnummer = 'Dette feltet kan ikke være tomt.';
    }
    return errors;
  }

  const onSubmit = async (command: UpdateFakturaAddresseCommand) => {
    try {
      await FakturaAdresserApi.updateFaktureringsinfo(command)
      toaster.success('Suksess', 'Fakturaadresse oppdatert!');
      props.setShow(false)
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit, updateValues } = useForm(initialValues, validate, onSubmit);

  const handleBrregSearch = async () => {
    if (errors.organisasjonsnummer) return;
    var result = await BrregApi.getByOrganisasjonsnummer(props.orgNr);
    if (result?.length > 0) {
      setBrregResult(result[0]);
    }
  }

  const handleCopyFromBrreg = () => {
    if (!brregResult?.postAddress) return;

    const copy = { ...values };

    if (brregResult.postAddress.address && brregResult.postAddress.address.toUpperCase() !== values.postadresse?.toUpperCase()) {
      copy.postadresse = brregResult.postAddress.address.toUpperCase();
    }
    if (brregResult.postAddress.co && brregResult.postAddress.co.toUpperCase() !== values.adressat?.toUpperCase()) {
      copy.adressat = brregResult.postAddress.co.toUpperCase();
    }
    if (brregResult.postAddress.postalCode && brregResult.postAddress.postalCode !== values.postnummer) {
      copy.postnummer = brregResult.postAddress.postalCode;
    }
    if (brregResult.postAddress.postalPlace && brregResult.postAddress.postalPlace !== values.poststed) {
      copy.poststed = brregResult.postAddress.postalPlace;
    }
    if (brregResult.postAddress.country && brregResult.postAddress.country.toUpperCase() !== values.land?.toUpperCase()) {
      copy.land = brregResult.postAddress.country?.toUpperCase();
    }

    updateValues(copy);
  };

  useEffect(() => {
    if (values.fakturaAdresseNavn === 'Brønnøysundregisterets adresse') {
      if (!brregResult) {
        handleBrregSearch();
      }
    }
  }, [values.fakturaAdresseNavn, brregResult, props.show])

  useEffect(() => {
    if (values.fakturaAdresseNavn !== 'Brønnøysundregisterets adresse' && values.fakturaAdresseNavn !== 'Avvikende fra brreg') {
      handleChange('fakturaAdresseNavn', 'Avvikende fra brreg');
    }
  }, [values.fakturaAdresseNavn]);

  const isBrregAddressEqualToCurrentAddress = (brregResult: EnhetDto, current: UpdateFakturaAddresseCommand) => {
    const brPostAddress = brregResult.postAddress;

    if (!brPostAddress)
      return true;

    return (!brPostAddress.address || brPostAddress.address?.toUpperCase() === current.postadresse?.toUpperCase())
      && brPostAddress.postalCode === values.postnummer
      && brPostAddress.postalPlace === values.poststed
      && brPostAddress.country?.toUpperCase() === values.land.toUpperCase();
  };

  // const notEqualToBrreg = brregResult && !brregResult.finnesIkke && brregResult.postAddress && (brregResult.postAddress.address.toUpperCase() !== values.postadresse.toUpperCase() ||
  //   brregResult.postAddress.postalCode !== values.postnummer || brregResult.postAddress.postalPlace !== values.poststed ||
  //   brregResult.postAddress.country?.toUpperCase() !== values.land.toUpperCase());

  return (
    <Modal centered show={props.show} onHide={() => props.setShow(false)} dialogClassName="modal-10w" aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">Fakturainfo</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Beskrivende navn</Form.Label>
            {
              props.onlyView ?
                <Form.Control value={values.fakturaAdresseNavn} readOnly /> :
                <Form.Select
                  value={values.fakturaAdresseNavn === 'Brønnøysundregisterets adresse' ? '0' : '1'}
                  onChange={e => handleChange('fakturaAdresseNavn', e.target.value === '0' ? 'Brønnøysundregisterets adresse' : 'Avvikende fra brreg')}
                  isInvalid={errors?.fakturaAdresseNavn}>
                  <option value={0}>Brønnøysundregisterets adresse</option>
                  <option value={1}>Avvikende fra brreg</option>
                </Form.Select>
            }
            <Form.Control.Feedback type="invalid">
              {errors?.fakturaAdresseNavn}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Pogo kundenummer: {props.fakturaadresse.kundenummer}</Form.Label>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Tjenestegrupper</Form.Label>
            <TjenestegruppePicker onSelectionChanged={selected => handleChange('tjenestegruppeIds', selected.map(x => x.tjenesteGruppeId))} mode='multiSelect' selected={values.tjenestegruppeIds} disabled={props.onlyView} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Faktura e-post</Form.Label>
            <Form.Control
              value={values.fakturaEmail}
              onChange={e => handleChange('fakturaEmail', e.target.value)}
              isInvalid={errors?.fakturaEmail && !props.onlyView}
              readOnly={props.onlyView} />
            <Form.Control.Feedback type="invalid">
              {errors?.fakturaEmail}
            </Form.Control.Feedback>
          </Form.Group>

          <br />

          <Form.Group className="mb-3">
            <Form.Label>Fakturaadresse</Form.Label>
            <Form.Control
              value={values.postadresse}
              onChange={e => handleChange('postadresse', e.target.value)}
              readOnly={props.onlyView} />
          </Form.Group>

          {
            values.fakturaAdresseNavn === 'Brønnøysundregisterets adresse'
            && brregResult
            && !brregResult.finnesIkke
            && !isBrregAddressEqualToCurrentAddress(brregResult, values)
            &&
            <Alert variant='warning' className={Style.brregWarning}>
              <Row>
                <Col sm={4}>
                  <IcebergIcon icon='alert' size='lg' />
                  Adresse i Brreg:
                </Col>
                <Col>
                  <span className={Style.brregAddressLine}>{brregResult.postAddress.address?.toUpperCase()}</span>
                  <span className={Style.brregAddressLine}>{brregResult.postAddress.co?.toUpperCase()}</span>
                  <span className={Style.brregAddressLine}>{brregResult.postAddress.postalCode} {brregResult.postAddress.postalPlace}</span>
                  <span className={Style.brregAddressLine}>{brregResult.postAddress.country?.toUpperCase()}</span>
                  <a target="_blank" href="#" onClick={e => { e.preventDefault(); handleCopyFromBrreg(); }}>Klikk for å bytte</a>
                </Col>
              </Row>
            </Alert>
          }

          <Form.Group className="mb-3">
            <Form.Label>Adressat (valgfritt)</Form.Label>
            <Form.Control
              value={values.adressat}
              onChange={e => handleChange('adressat', e.target.value)}
              readOnly={props.onlyView} />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Postnummer</Form.Label>
                <Form.Control
                  value={values.postnummer}
                  onChange={e => handleChange('postnummer', e.target.value)}
                  isInvalid={errors?.postnummer}
                  readOnly={props.onlyView} />
                <Form.Control.Feedback type='invalid'>
                  {errors?.postnummer}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Poststed</Form.Label>
                <Form.Control
                  value={values.poststed}
                  onChange={e => handleChange('poststed', e.target.value.toUpperCase())}
                  readOnly={props.onlyView} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Land</Form.Label>
                <Form.Control
                  value={values.land}
                  onChange={e => handleChange('land', e.target.value.toUpperCase())}
                  readOnly={props.onlyView} />
              </Form.Group>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            props.onlyView ?
              <Button variant="primary" onClick={() => props.setShow(false)}>
                Lukk
              </Button> :
              <>
                <Button className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
                  <IcebergIcon size='lg' icon='close' /> Avbryt
                </Button>
                <Button
                  variant="primary"
                  className="col"
                  disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
                  type="submit">
                  <span>
                    <IcebergIcon icon='disk' size='lg' /> Lagre
                    {
                      loading &&
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    }
                  </span>
                </Button>
              </>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EditFaktureringsinfo;