import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { getTjenestegruppeIcon } from "components/IcebergIcon/utils";
import { TjenesteGruppeConnection } from "services/AdminportalenApi/KundeKortApi/types";
import Style from './index.module.css';
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";

interface Props {
  connections: TjenesteGruppeConnection[];
}

const TjenestegruppeConnectionsView: React.FC<Props> = props => {

  const TgPopover = (warning: string) => {
    return (
      <Popover id="popover-menu" className={Style.popover}>
        <Popover.Body>
          <p>{warning}</p>
        </Popover.Body>
      </Popover>
    )
  }

  return (
    <div className={Style.container}>
      {
        props.connections && props.connections.map((tjenes) => (
          <div key={tjenes.tjenesteGruppeId}>
            <OverlayTrigger
              rootClose
              placement="bottom"
              trigger={["hover", "focus"]}
              overlay={TgPopover(tjenes.tjenesteGruppeNavn)}>

              <span>
                <IcebergIcon icon={getTjenestegruppeIcon(tjenes.tjenesteGruppeNavn)} rounded />
              </span>

            </OverlayTrigger>
          </div>
        ))
      }
    </div>
  )
}

export default TjenestegruppeConnectionsView;