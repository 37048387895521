import { useState, useEffect } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { debounce } from "lodash";
import TsKunderApi from "services/AdminportalenApi/TsKunderApi";
import { TsKundeSearchDto } from "services/AdminportalenApi/TsKunderApi/types";

interface Props {
  selectedTsKundeId?: string;
  onHandleChange: (selectedKunde: TsKundeSearchDto) => void;
  disabled?: boolean;
  placeholderText?: string;
  size?: "sm" | "lg";
}

const TsKundeTypeahead = (props: Props) => {
  const [query, setQuery] = useState('');
  const [tsKunder, setTsKunder] = useState<TsKundeSearchDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTsKunder, setSelectedTsKunder] = useState<TsKundeSearchDto[]>([]);

  const searchTsKunder = async () => {
    setIsLoading(true);
    const result = await TsKunderApi.search(query);
    setTsKunder(result);
    setIsLoading(false);
  };

  useEffect(() => {
    if (query) {
      searchTsKunder();
    }
  }, [query]);

  const onChangeHandler = (e: TsKundeSearchDto[]) => {
    if (!e || e.length === 0) {
      props.onHandleChange(undefined);
      setSelectedTsKunder([]);
    } else {
      props.onHandleChange(e[0]);
      setSelectedTsKunder(e);
    }
  };

  useEffect(() => {
    if (props.selectedTsKundeId) {
      TsKunderApi.getById(props.selectedTsKundeId).then(response => setSelectedTsKunder([response]));
    } else {
      setSelectedTsKunder([]);
    }
  }, [props.selectedTsKundeId])

  return (
    <AsyncTypeahead
      id="tsKundeTypeahead"
      labelKey="tsKundeNavn"
      minLength={3}
      delay={100}
      isLoading={isLoading}
      options={tsKunder}
      searchText="Søker"
      placeholder={props.placeholderText || "Søk etter kunder"}
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      useCache={false}
      maxResults={8}
      clearButton
      size={props.size}
      onChange={(e: any) => onChangeHandler(e)}
      onSearch={debounce(q => setQuery(q), 300)}
      selected={selectedTsKunder}
      disabled={props.disabled}
      filterBy={() => true} />
  )
};

export default TsKundeTypeahead;