import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import ExternalAdSourcesApi from "services/AdminportalenApi/ExternalAzureAdSourcesApi";
import { TenantDto } from "services/AdminportalenApi/ExternalAzureAdSourcesApi/types";

interface Props {
  selected: TenantDto;
  onChange: (tenant: TenantDto) => void;
}

const TenantSelect: React.FC<Props> = props => {
  const [tenants, setTenants] = useState<TenantDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadTenants = async () => {
    setLoading(true);
    let res = await ExternalAdSourcesApi.getTenants();
    setTenants(res);
    setLoading(false);
  };

  const handleChange = (value: string) => {
    if(value){
      props.onChange(tenants.find(x => x.tenantId === value));
    } else {
      props.onChange(null);
    }
  }

  useEffect(() => {
    loadTenants();
  }, []);
  
  {loading && <Spinner animation="border" />}

  return (
    <Form.Select aria-label="TenantSelect" onChange={e => handleChange(e.target.value)} value={props.selected?.tenantId ?? ''}>                  
      <option value="">Velg tenant</option>
      {
        tenants.map(x => 
          <option key={x.tenantId} value={x.tenantId}>{x.tenantName}</option>
        )
      }
    </Form.Select>
  );
};

export default TenantSelect;