import { VerifyResultDto } from "./types";

import { getBaseUrl } from "..";
import axios from "axios";

const baseUrl = getBaseUrl() + '/Emails';

const EmailsApi = {
    verify: async (email: string): Promise<VerifyResultDto> => {
        const response = await axios.get(`${baseUrl}/${email}/Verify`);
        return response.data;
    }    
};

export default EmailsApi;