import { useState, useEffect } from 'react'
import { Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import cx from 'classnames';
import CreateFakturafritak from './CreateFakturafritak';

import FakturafritakApi from 'services/AdminportalenApi/FakturafritakApi';
import { FakturafritakDto, FakturafritakSearchFilter } from 'services/AdminportalenApi/FakturafritakApi/types';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import Style from './index.module.css';
import { IcebergIcon} from '@tradesolution/iceberg-ui-react';
import ConfirmButton from 'components/ConfirmButton';
import CommonLoader from 'components/CommonLoader';

const Fakturafritak = () => {

  const toaster = useToaster();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fakturafritak, setFakturafritak] = useState<FakturafritakDto[]>([]);
  const [showCreateFakturafritak, setShowCreateFakturafritak] = useState<boolean>(false);
  const [filter, setFilter] = useState<FakturafritakSearchFilter>({
    query: '',
  });

  const loadFakturafritak = async () => {
    setIsLoading(true);
    const result = await FakturafritakApi.getAll();
    setFakturafritak(result);
    setIsLoading(false);
  }

  const deleteFakturafritak = async (tjenesteId: string, tsKundeId: string) => {
      try {
        await FakturafritakApi.deleteFakturafritak(tjenesteId, tsKundeId);
        toaster.success('Suksess', 'Fakturafritak slettet');
        loadFakturafritak();

      } catch (err) {
        toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
      }
  };

  useEffect(() => {
    loadFakturafritak();
  }, []);



  const filteredFakturafritak: FakturafritakDto[] = fakturafritak.filter(x =>
    !filter.query || filter.query === '' ?
      x :
      x.tjenesteNavn.toLowerCase().includes(filter.query.toLowerCase()) || x.tskundeNavn.toLowerCase().includes(filter.query.toLowerCase()));


  return (
    <div className={Style.container}>
      <Row style={{ marginTop: '0.5em' }}>
        {/* title of the page */}
        <Col xs='8'>
          <h2 className={Style.mainHeader}>Fakturafritak</h2>
        </Col>

        <Col>
          <div className='float-end'>
            <CreateFakturafritak
              show={showCreateFakturafritak}
              setShow={setShowCreateFakturafritak}
              onUpdated={() => loadFakturafritak()}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <InputGroup className='mb-3'>
            <Form.Control placeholder='Filtrer etter tjeneste eller kunde'
              type='text'
              value={filter.query}
              style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
              onChange={e => setFilter(prev => ({ ...prev, query: e.target.value }))} />

            <InputGroup.Text style={{ background: 'white', borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
              {filter.query.length > 0 ?
                <IcebergIcon icon='close' cursor='pointer'
                  onClick={() => setFilter(prev => ({ ...prev, query: '' }))} />
                : <IcebergIcon icon='search' />}
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>

      {/* layout for table */}
      <Row>
        <Col>
          {isLoading && (fakturafritak.length <= 0) &&
            <Row>
              <Col>
                <CommonLoader text="Henter fakturafritak..." color="#003b6e" />
              </Col>
            </Row>
          }
          {!isLoading && (fakturafritak.length <= 0) && (
            <div>
              <h3>Ingen treff</h3>
            </div>
          )}
          {(fakturafritak.length > 0) && (
            <Row className={Style.wrapper}>
              <Table responsive className={cx([Style.withHelpText])} hover>
                <thead className={Style.thead}>
                  <tr>
                    <th>TsKunde</th>
                    <th>Tjeneste</th>
                    <th>Kommentar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className={Style.tbody} style={{ borderTop: 'none', border: '1px solid #DCDCDC' }} >
                  {filteredFakturafritak.map((f, i) => (
                    <tr key={i}>
                      <td>{f.tskundeNavn} </td>
                      <td>{f.tjenesteNavn}</td>
                      <td>{f.begrunnelse}</td>
                      <td width={320}>
                        <ConfirmButton variant='outline-danger' onClick={() => deleteFakturafritak(f.tjenesteId, f.tsKundeId)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Fakturafritak;

