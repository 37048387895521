import React from 'react';
import { Modal, Form, Row, Col, Button, Spinner } from 'react-bootstrap';

import BrukereApi from 'services/AdminportalenApi/BrukereApi';
import { BrukerWithTilgangerDto, ResetPasswordCommand} from 'services/AdminportalenApi/BrukereApi/types';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  bruker: BrukerWithTilgangerDto;
  show: boolean;
  setShow: (value: boolean) => void;
}

const ResetPasswordModal: React.FC<Props> = props => {
  const toaster = useToaster();

  const initialValues: ResetPasswordCommand = {
    brukerId: props.bruker.brukerId,
    sendEmail: false,
    sendEmailTo: props.bruker.otherMail,
    newPassword: 'Trades4242'
  }

  const validate = (values: ResetPasswordCommand) => {
    let errors: any = {};
    if (!values.newPassword) {
      errors.newPassword = 'Nytt passord kan ikke være tomt';
    }
    if (values.newPassword && values.newPassword.length < 7) {
      errors.newPassword = 'Nytt passord må inneholde minst 8 tegn';
    } 
    if (values.sendEmailTo && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.sendEmailTo)) {
      errors.sendEmailTo = 'E-post har ugyldig format';
    }   
    return errors;
  }

  const onSubmit = async (command: ResetPasswordCommand) => {
    try {
      await BrukereApi.resetPassword(command);
      toaster.success('Suksess', 'Passord resatt!');
      props.setShow(false);
      resetForm(undefined);
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);
  return (
    <Modal centered show={props.show} onHide={() => props.setShow(false)} dialogClassName={Style.modalContainer}>
      <Modal.Header closeButton>
        <Modal.Title>Reset passord for {props.bruker.givenName} {props.bruker.surname}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Nytt passord</Form.Label>
                <Form.Control type="text" value={values.newPassword} onChange={e => handleChange('newPassword', e.target.value)} isInvalid={(errors?.newPassword) && touched?.newPassword}/>
                <Form.Control.Feedback type="invalid">{errors?.newPassword}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Check aria-label="option 1" label='Skal nytt passord sendes på e-post?'
                  checked={values.sendEmail}
                  onChange={e => handleChange('sendEmail', e.target.checked)}
                />
              </Form.Group>
            </Col>
          </Row>
          {
            values.sendEmail && 
            (
              <Row>
                <Col>
                <Form.Group className="mb-3">
                  <Form.Label>E-post</Form.Label>                  
                    <Form.Control type="text" value={values.sendEmailTo} onChange={e => handleChange('sendEmailTo', e.target.value)} isInvalid={(errors?.sendEmailTo) && touched?.sendEmailTo} />                    
                    <Form.Control.Feedback type="invalid">{errors?.sendEmailTo}</Form.Control.Feedback>
                </Form.Group>
                </Col>
              </Row>
            )            
          }
          
        </Modal.Body>
        <Modal.Footer>          
          <Button className='col' variant="outline-primary" onClick={() => props.setShow(false)}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button
            variant="primary"
            className='col'
            disabled={loading || Object.keys(errors).length > 0}
            type="submit">
            <IcebergIcon icon='disk' size='lg' /> Lagre
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ResetPasswordModal;