import { useEffect } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import { CreateAvtaleCommand } from 'services/AdminportalenApi/AvtalerApi/types';
import AvtaleApi from 'services/AdminportalenApi/AvtalerApi';
import TjenesegruppeSelect from 'components/TjenestegruppeSelect';
import * as validator from 'validator';

interface Props {
  tsKundeId: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onUpdated: () => void;
}

const CreateAvtale = (props: Props) => {
  const toaster = useToaster();

  const initialValues: CreateAvtaleCommand = {
    tsKundeId: props.tsKundeId,
    fraOgMed: new Date(),
    navn: '',
  }

  const validate = (values: CreateAvtaleCommand) => {
    let errors: any = {};
    if (values.navn === '') {
      errors.navn = 'Vennligst gi avtalen et navn';
    }
    if (values.url && !validator.isURL(values.url)) {
      errors.url = 'URL må være gyldig';
    }
    return errors;
  }

  useEffect(() => {
    handleChange('tsKundeId', props.tsKundeId);
  }, [props.tsKundeId]);

  const onSubmit = async (command: CreateAvtaleCommand) => {
    try {
      await AvtaleApi.createAvtale(command);
      toaster.success('Suksess', 'Avtale opprettet!');
      handleClose();
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => {
    resetForm(initialValues);
    props.setShow(false);
  }

  return (
    <Modal centered show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Avtale</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Navn</Form.Label>
            <Form.Control
              placeholder='Skriv inn navn på avtalen'
              value={values.navn}
              onChange={e => handleChange('navn', e.target.value)}
              isInvalid={touched?.navn && errors?.navn} />
            <Form.Control.Feedback type="invalid">
              {errors?.navn}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Gyldig fra</Form.Label>
            <DatePicker
              onChange={date => handleChange('fraOgMed', date)}
              selected={values.fraOgMed} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Url (valgfritt)</Form.Label>
            <Form.Control
              placeholder='Skriv inn url til avtalen'
              value={values.url}
              onChange={e => handleChange('url', e.target.value)}
              isInvalid={touched?.url && errors?.url} />
            <Form.Control.Feedback type="invalid">
              {errors?.url}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tjenestegruppe (valgfritt)</Form.Label>
            <TjenesegruppeSelect selected={values.tjenesteGruppeId ?? ''} onChange={e => handleChange('tjenesteGruppeId', e)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="col" variant="outline-primary" onClick={handleClose}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button
            variant="primary"
            className="col"
            type="submit"
            disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}>
            <span>
              <IcebergIcon icon='disk' size='lg' /> Lagre
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              }
            </span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateAvtale;