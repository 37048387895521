import React from 'react';
import { Modal, Button, Form, Spinner, Row, Col } from 'react-bootstrap';

import AccessToSelect from 'components/AccessToSelect';
import RoleSelect from 'components/RoleSelect';

import ExternalAdSourcesApi from 'services/AdminportalenApi/ExternalAzureAdSourcesApi';
import { ExternalAzureAdSourceDto, UpdateExternalGroupAccessesCommand } from "services/AdminportalenApi/ExternalAzureAdSourcesApi/types";

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  externalGroup: ExternalAzureAdSourceDto;
  onUpdated: () => void;
}

const EditExternalGroupAccessesModal: React.FC<Props> = props => {

  const toaster = useToaster();

  const initialVals: UpdateExternalGroupAccessesCommand = {
    id: props.externalGroup.id,
    permissions: props.externalGroup.permissions.map(x => ({
      tsKundeId: x.tsKundeId,
      accessTo: x.accessTo,
      accessToValue: x.accessToValue,
      role: x.role
    }))
  }

  const validate = (values: UpdateExternalGroupAccessesCommand) => {
    let errors: any = {};

    if (values.permissions?.length > 0) {
      if (values.permissions.findIndex(x => !x.accessTo) > -1) {
        errors.permissions = 'En eller flere rader mangler tilgang';
      }
    }

    return errors;
  }

  const onSubmit = async (command: UpdateExternalGroupAccessesCommand) => {
    try {
      await ExternalAdSourcesApi.updateExternalSourceAccesses(command)
      toaster.success('Suksess', 'Ekstern Ad gruppe oppdatert!');
      props.setShow(false);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit } = useForm(initialVals, validate, onSubmit);

  const handleDeleteRow = (accessTo: string) => {
    const copy = values.permissions.filter(x => x.accessTo !== accessTo);
    handleChange('permissions', copy);
  };

  const addRow = () => {
    const permissions = [...values.permissions, { tsKundeId: props.externalGroup.tsKundeId, role: '', accessTo: '' }];
    handleChange('permissions', permissions);
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      dialogClassName={Style.modalContainer}
      centered
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title>Endre tilganger for {props.externalGroup.name}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {
            values.permissions?.length > 0 &&
            values.permissions.map((permission, index) =>
              <Row key={index}>
                <Col md={5}>
                  <Form.Group>
                    <Form.Label>Tilgang til</Form.Label>
                    <AccessToSelect
                      selected={permission.accessToValue ?? permission.accessTo ?? ''}
                      onChange={e => handleChange('permissions', values.permissions.map(x => x === permission ? { ...x, accessTo: e, accessToValue: e } : x))} />
                  </Form.Group>
                </Col>
                <Col md={5}>
                  <Form.Group>
                    <Form.Label>Rolle</Form.Label>
                    <RoleSelect
                      selected={permission.role ?? ''}
                      onChange={e => handleChange('permissions', values.permissions.map(x => x === permission ? { ...x, role: e } : x))} />
                  </Form.Group>
                </Col>
                <Col className='text-end'>
                  <IconButton icon='trash' variant='outline-danger' style={{ marginTop: '28px' }} onClick={() => handleDeleteRow(permission.accessTo)} />
                </Col>
              </Row>
            )
          }
          {
            errors?.permissions && touched?.permissions &&
            <div className={Style.invalidFeedback}>{errors?.permissions}</div>
          }
          <br />
          <IconButton icon="plus" variant='outline-primary' onClick={addRow} />
        </Modal.Body>
        <Modal.Footer>

          <Button className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button
            variant="primary"
            className="col"
            disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
            type="submit">
            <IcebergIcon icon='disk' size='lg' /> Lagre
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

export default EditExternalGroupAccessesModal;