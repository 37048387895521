import axios from 'axios';
import { EnhetDto } from './types';
import { getBaseUrl } from '..';

const baseUrl = getBaseUrl() + "/Brreg";

const BrregApi = {
  search: (q: string) : Promise<EnhetDto[]> =>
    axios
      .get(
        `${baseUrl}/Search?juridiskNavn=${q}`
      )
      .then((response) => response.data),

  getByOrganisasjonsnummer: (organisasjonsnummer: string) : Promise<EnhetDto[]> =>
    axios
      .get(
        `${baseUrl}/${organisasjonsnummer}`
      )
      .then((response) => response.data)
}


export default BrregApi;