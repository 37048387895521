import { IconButton } from "@tradesolution/iceberg-ui-react";
import { useEffect, useState } from "react";
import { Modal, Form, FormGroup } from "react-bootstrap";
import AdApplicationsApi from "services/AdminportalenApi/AdApplicationsApi";
import { AdApplication, TokenReponse, TokenReponseStatus } from "services/AdminportalenApi/AdApplicationsApi/types";

interface Props {
    application?: AdApplication;
    show: boolean;
    onClose: () => void;
}

const TestModal = (props: Props) => {

    const tenant = "trades.no";

    const getExampleRequest = (app: AdApplication) => {
        return `POST /${tenant}/oauth2/v2.0/token
HOST https://login.microsoftonline.com
Content-Type: application/x-www-form-urlencoded

client_id=${app?.applicationId}
&client_secret=##########
&grant_type=client_credentials
&scope=https://trades.no/TradesolutionApi/.default
        `;
    }

    const [tokenResponse, setTokenResponse] = useState<TokenReponse | undefined>();

    useEffect(() => {
        setTokenResponse(undefined);
    }, [props.application]);

    const fetchAccessToken = async () => {
        const tokenResponse = await AdApplicationsApi.getAccessToken(props.application?.applicationId || '');
        setTokenResponse(tokenResponse);
    };

    return (
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Test API tilgang login
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Navn på API tilgangen</Form.Label>
                        <Form.Control type="text" value={props.application?.name || ''} onChange={e => { }} disabled />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Hvordan hente access token (HTTP Request)</Form.Label>
                        <Form.Control as="textarea" rows={8} value={getExampleRequest(props.application)} onChange={e => { }} disabled />
                        <Form.Text>
                            <a style={{ textDecoration: "none" }} href="https://github.com/Tradesolution/Authentication/blob/master/README.md" target="_blank">Dokumentasjon som du kan dele med kunden finnes her</a>
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Access token</Form.Label>
                        <Form.Control as="textarea" rows={7} value={tokenResponse?.accessToken} onChange={e => { }} disabled />
                    </Form.Group>
                    <Form.Text className="error-text">
                        {tokenResponse?.responseStatus === TokenReponseStatus.MissingInKeyVault && 'Client secret mangler i KeyVault'}
                        {tokenResponse?.responseStatus === TokenReponseStatus.UnknownApplication && 'Ukjent applikasjon'}
                        {tokenResponse?.responseStatus === TokenReponseStatus.NoAccess && 'Mangler rettigheter til å hente access token'}
                    </Form.Text>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <IconButton variant="outline-primary" icon="close" className="col" onClick={props.onClose}>
                    Lukk
                </IconButton>
                <IconButton variant="primary" size="sm" icon="send" className="col" onClick={fetchAccessToken} disabled={!!props.application?.deactivatedDate}>
                    Generer
                </IconButton>
            </Modal.Footer>
        </Modal >
    );
};

export default TestModal;