import axios from "axios";
import { getBaseUrl } from '..';
import { CreateProdukteierGlnCommand, UpdateProdukteierGlnDescriptionCommand } from "./types";

const baseUrl = getBaseUrl() + '/ProdukteierGln';

const ProdukteierGlnApi = {
    deleteProdukteierGln: (produkteierGlnId: string) => 
        axios.delete(`${baseUrl}/${produkteierGlnId}`).then(response => response.data),

    createProdukteierGln: (command: CreateProdukteierGlnCommand) => 
        axios.post(baseUrl, command).then(response => response.data),

    updateProdukteierGlnDescription: (command: UpdateProdukteierGlnDescriptionCommand) =>
        axios.put(baseUrl, command).then(response => response.data)
    
}

export default ProdukteierGlnApi;