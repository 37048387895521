import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Style from './index.module.css';
import cx from 'classnames';
import { setHours } from 'date-fns';

interface Props {
  selected?: Date;
  placeholderText?: string;
  onChange: (date: Date) => void;
  disabled?: boolean;
  isClearable?: boolean;
  minDate?: Date;
  required?: boolean;
  id?: string;
  isInvalid?: boolean;
  isSmall?: boolean;
}

const DatePicker: React.FC<Props> = props => {
  const onDateSelected = (date: Date) => {
    if (date) {
      const adjustedDate = setHours(date, 3);
      props.onChange(adjustedDate);
    } else {
      props.onChange(undefined); // onClear
    }
  };

  const onFocus = (event: any) => {
    if (event.target.autocomplete) {
      event.target.setAttribute('autocomplete', 'off');
    }
  };

  return (
    <ReactDatePicker
      id={props.id}
      locale="nb"
      className="form-control"
      selected={props.selected}
      onChange={(date: Date) => onDateSelected(date)}
      dateFormat="dd.MM.yyyy"
      placeholderText={props.placeholderText}
      disabled={props.disabled}
      autoComplete="new-password"
      onFocus={onFocus}
      isClearable={props.isClearable}
      minDate={props.minDate}
      required={props.required}
      wrapperClassName={cx(Style.datePicker, {
        [Style.invalidDatePicker]: props.isInvalid,
        [Style.small]: props.isSmall,
      })}
    />
  );
};

export default DatePicker;
