import React, { useState } from 'react';
import { Modal, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';

import TsKundeTypeahead from 'components/TsKundeTypeahead';
import AccessToSelect from 'components/AccessToSelect';
import RoleSelect from 'components/RoleSelect';

import BrukereApi from 'services/AdminportalenApi/BrukereApi';
import { GiveAccessToBrukereCommand, RemoveAccessFromBrukereCommand } from 'services/AdminportalenApi/BrukereApi/types';
import { TsKundeSearchDto } from 'services/AdminportalenApi/TsKunderApi/types';

import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  brukerIds: string[];
}

const BulkEndringerModal: React.FC<Props> = props => {

  const [show, setShow] = useState<boolean>(false);
  const [isLoadingBulkJob, setIsLoadingBulkJob] = useState<boolean>(false);
  const [bulkJobStarted, setBulkJobStarted] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<number>(0);
  const [selectedTsKunde, setSelectedTsKunde] = useState<TsKundeSearchDto>();
  const [selectedAccessTo, setSelectedAccessTo] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<string>("");


  const handleCloseModal = () => {
    setSelectedTsKunde(undefined);
    setSelectedAccessTo("");
    setSelectedRole("");
    setIsLoadingBulkJob(false);
    setBulkJobStarted(false);
    setShow(false);
  }

  const handleBulkJob = async () => {
    switch (selectedJob) {
      case 0:
        const giveAccessToBrukereCommand: GiveAccessToBrukereCommand = {
          brukerIds: props.brukerIds,
          tsKundeId: selectedTsKunde.tsKundeId,
          accessTo: selectedAccessTo,
          role: selectedRole
        }
        setIsLoadingBulkJob(true);
        await BrukereApi.giveAccessToBrukere(giveAccessToBrukereCommand);
        break;
      case 1:
        const removeAccessFromBrukereCommand: RemoveAccessFromBrukereCommand = {
          brukerIds: props.brukerIds,
          tsKundeId: selectedTsKunde.tsKundeId,
          accessTo: selectedAccessTo,
          role: selectedRole
        }
        setIsLoadingBulkJob(true);
        await BrukereApi.removeAccessFromBrukere(removeAccessFromBrukereCommand);
        break;
    }
    setIsLoadingBulkJob(false);
    setBulkJobStarted(true);
  };

  return (
    <>
      <Button variant="outline-primary" onClick={() => setShow(true)}>
        <span style={{ paddingLeft: '.3em', }}>
          Bulk endringer
        </span>
      </Button>

      <Modal show={show} onHide={handleCloseModal} dialogClassName={Style.modalContainer}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk endringer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className={Style.formGroup}>
            <Form.Label>Velg jobb</Form.Label>
            <Form.Select value={selectedJob} onChange={e => setSelectedJob(Number(e.target.value))}>
              <option value={0}>Gi tilgang</option>
              <option value={1}>Fjern tilgang</option>
            </Form.Select>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>TsKunde</Form.Label>
                <TsKundeTypeahead selectedTsKundeId={selectedTsKunde?.tsKundeId} onHandleChange={e => setSelectedTsKunde(e)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Tilgang til</Form.Label>
                <AccessToSelect selected={selectedAccessTo} onChange={e => setSelectedAccessTo(e)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Rolle</Form.Label>
                <RoleSelect selected={selectedRole} onChange={e => setSelectedRole(e)} />
              </Form.Group>
            </Col>
          </Row>
          <span>
            {props.brukerIds.length + ' brukere valgt for bulk endring.'}
          </span>
          {
            bulkJobStarted &&
            <Alert variant='info' style={{ marginTop: "0.5rem" }}>
              Jobben er startet. {props.brukerIds.length > 100 ? 'Over 100 brukere er valgt for oppdatering; dette kan ta lengre tid.' : ''}
            </Alert>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button className="col" variant="outline-primary" onClick={handleCloseModal}>
            <IcebergIcon icon="close" size="lg" /> Avbryt
          </Button>
          <Button
            variant="primary"
            className="col"
            disabled={isLoadingBulkJob || bulkJobStarted || !selectedTsKunde || !selectedAccessTo}
            onClick={() => handleBulkJob()}>
            <IcebergIcon icon="arrow-right" size="lg" /> Utfør
            {isLoadingBulkJob && (
              <span style={{ paddingLeft: '.3em' }}>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BulkEndringerModal;