import { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { CreateKontaktpersonCommand } from 'services/AdminportalenApi/KontaktpersonerApi/types';
import KontaktpersonerApi from 'services/AdminportalenApi/KontaktpersonerApi';
import useToaster from 'utils/hooks/useToaster';
import useForm from 'utils/hooks/useForm';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import EmailsApi from 'services/AdminportalenApi/EmailsApi';
import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import nb from 'react-phone-number-input/locale/nb.json'
import { E164Number } from 'libphonenumber-js/types.cjs';
import KontaktpersonTypeAhead from 'components/KontaktpersonTypeAhead';

interface Props {
  tsKundeId: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onUpdated: () => void;
}

const CreateKontaktperson = (props: Props) => {
  const toaster = useToaster();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  useEffect(() => {
    handleChange('tsKundeId', props.tsKundeId);
  }, [props.tsKundeId]);

  const initialValues: CreateKontaktpersonCommand = {
    tsKundeId: props.tsKundeId,
    navn: '',
    email: '',
    typeKontaktperson: '',
    mobile: null,
    comment: '',
  }

  const validate = (values: CreateKontaktpersonCommand) => {
    let errors: any = {};
    if (!values.navn) errors.navn = 'Navn må fylles ut';
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = 'Ugyldig email';
    if (values.mobile && values.mobile.length > 12) errors.mobile = 'Mobilnummeret må bestå av max 12 tegn'
    return errors;
  }

  const onSubmit = async (values: CreateKontaktpersonCommand) => {
    await KontaktpersonerApi.createKontaktperson(values);
    toaster.success('Success', 'Kontaktpersonen er opprettet');
    handleClose();
    resetForm(undefined);
    props.onUpdated();
  }

  const verifyEmail = async (email: string) => {
    const verifyResult = await EmailsApi.verify(email);
    setIsEmailValid(verifyResult.canBeUsed);
  }

  const { values, errors, loading, touched, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  useEffect(() => {
    console.log(values.mobile)
  }, [values.mobile])

  const handleClose = () => props.setShow(false);

  return (
    <Modal
      centered
      show={props.show}
      onHide={handleClose}
      dialogClassName="modal-10w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">Kontaktperson</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Navn</Form.Label>
            <Form.Control
              placeholder='Skriv inn navn'
              value={values.navn}
              onChange={e => handleChange('navn', e.target.value)}
              isInvalid={touched?.navn && errors?.navn} />
            <Form.Control.Feedback type="invalid">
              {errors?.navn}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>E-post</Form.Label>
            <Form.Control
              placeholder='E-post'
              value={values.email}
              onChange={e => handleChange('email', e.target.value)}
              onBlur={e => verifyEmail(e.target.value)}
              isInvalid={(touched?.email && errors?.email) || !isEmailValid} />
            <Form.Control.Feedback type="invalid">
              {errors?.email}
            </Form.Control.Feedback>
            {!isEmailValid && (
              <div className='invalid-feedback'>
                <span>Det finnes ingen aktiv e-post konto med denne adressen. Har det sneket seg inn en skrivefeil? Kanskje noen har sluttet i jobben?</span>
              </div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Tittel</Form.Label>
            <KontaktpersonTypeAhead
              onSelected={title => handleChange('typeKontaktperson', title)}
              isInvalid={touched?.typeKontaktperson && errors?.typeKontaktperson}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.typeKontaktperson}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Mobil</Form.Label>
            <PhoneInputWithCountrySelect
              labels={nb}
              countryOptionsOrder={['NO', 'SE', 'DK']}
              defaultCountry='NO'
              className='form-control'
              value={values.mobile}
              onChange={(num: E164Number) => handleChange('mobile', num)} />
            <Form.Control.Feedback type="invalid">
              {errors?.mobile}
            </Form.Control.Feedback>
          </Form.Group>


          <Form.Group className="mb-3">
            <Form.Label>Kommentarer</Form.Label>
            <Form.Control as="textarea" value={values.comment} onChange={e => handleChange('comment', e.target.value)} />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>

          <Button className="col" variant="outline-primary" onClick={handleClose}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button variant="primary" className="col" type='submit' disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}>
            <span>
              <IcebergIcon icon='disk' size='lg' /> Lagre
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              }
            </span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateKontaktperson;