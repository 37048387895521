import YearPeriodPicker from "./YearPeriodPicker";
import QuarterPeriodPicker, { Quarter } from "./QuarterPeriodPicker";
import MonthPeriodPicker from "./MonthPeriodPicker";
import { DatePeriod } from "./DatePeriod";

/*
Rendrer et passende GUI etter hvor ofte en tjenestegruppe faktureres og om det er etterskuddsvis, forskuddsvis eller begge
*/
interface Props {
    year: number;
    faktureringsInterval: number;
    quarter?: Quarter;
    month?: number;
    onPeriodeChanged: (period: DatePeriod) => void;
}

const PeriodeVelger = (props: Props) => {

    const handlePeriodChanged = (period: DatePeriod) => {
        props.onPeriodeChanged(period)
    }

    // velger for månentlig fakturering
    if (props.faktureringsInterval === 1) {
        return <MonthPeriodPicker
            month={props.month}
            year={props.year}
            onChange={handlePeriodChanged}
        />;
    }

    // velger for kvartalsvis fakturering
    if (props.faktureringsInterval === 3) {
        return <QuarterPeriodPicker
            year={props.year}
            quarter={props.quarter}
            onChange={handlePeriodChanged} />
    }

    // velger for årlig fakturering
    if (props.faktureringsInterval === 12) {
        return <YearPeriodPicker
            year={props.year}
            onChange={handlePeriodChanged}
        />;
    }
}

export default PeriodeVelger;