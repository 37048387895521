import React, { useEffect } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import TjenestegruppePicker from 'components/TjenestegruppePicker';
import PONummerApi from 'services/AdminportalenApi/PONummerApi';
import { CreatePONummerCommand } from 'services/AdminportalenApi/PONummerApi/types';
import useToaster from 'utils/hooks/useToaster';
import useForm from 'utils/hooks/useForm';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  tsKundeId: string;
  onUpdated: () => void;
  show: boolean;
  setShow: (show: boolean) => void;
}

const CreatePONummer = (props: Props) => {
  const toaster = useToaster();

  useEffect(() => {
    handleChange('tsKundeId', props.tsKundeId);
  }, [props.tsKundeId]);

  const initialValues: CreatePONummerCommand = {
    tsKundeId: props.tsKundeId,
    ponummerValue: '',
    tjenesteGruppeIds: [],
    validFromDate: new Date(),
    validToDate: null
  }

  const validate = (values: CreatePONummerCommand) => {
    let errors: any = {};
    if (values.ponummerValue.length === 0) {
      errors.ponummerValue = 'PO-nummerets må ha en verdi';
    }
    return errors;
  }

  const onSubmit = async (values: CreatePONummerCommand) => {
    try {
      await PONummerApi.createPONummer(values);
      toaster.success('Suksess', 'PONummer opprettet!');
      handleClose();
      resetForm(undefined);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, loading, touched, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => props.setShow(false);

  return (
    <Modal
      centered
      show={props.show}
      onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>PO-nummer</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder='Skriv inn PO-nummer'
              value={values.ponummerValue}
              onChange={e => handleChange('ponummerValue', e.target.value)}
              isInvalid={touched?.ponummerValue && errors?.ponummerValue} />
            <Form.Control.Feedback type="invalid">
              {errors?.ponummerValue}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tjenestegruppe</Form.Label>
            <TjenestegruppePicker onSelectionChanged={selected => handleChange('tjenesteGruppeIds', selected.map(x => x.tjenesteGruppeId) || [])} mode='multiSelect' selected={values.tjenesteGruppeIds} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Gyldig fra dato</Form.Label>
            <DatePicker selected={values.validFromDate} onChange={date => handleChange('validFromDate', date)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Gyldig til dato</Form.Label>
            <DatePicker selected={values.validToDate} onChange={date => handleChange('validToDate', date)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="col" variant="outline-primary" onClick={handleClose}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button variant="primary" className="col" type="submit" disabled={loading || errors?.ponummerValue || Object.keys(touched).length === 0}>
            <IcebergIcon icon='disk' size='lg' /> Lagre
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreatePONummer;