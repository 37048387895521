import axios from "axios";
import { CreateAvtaleCommand, UpdateAvtaleCommand } from "./types";
import { getBaseUrl } from "..";

const baseUrl = getBaseUrl() + '/Avtaler';

const AvtaleApi = {
  createAvtale: (command: CreateAvtaleCommand) =>
    axios.post(baseUrl, command).then(response => response.data),

  updateAvtale: (command: UpdateAvtaleCommand) => 
    axios.put(baseUrl, command).then(response => response.data),

  deleteAvtale: (id: string) => 
    axios.delete(`${baseUrl}/${id}`),
}

export default AvtaleApi;