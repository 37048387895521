import { useState } from 'react'
import { Form, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import TjenesterApi from "services/AdminportalenApi/TjenesterApi";
import { TjenesteMedPriserDto, UpdateTjenesteCommand } from "services/AdminportalenApi/TjenesterApi/types";
import TjenesegruppeSelect from "components/TjenestegruppeSelect";
import useForm from "utils/hooks/useForm";
import useToaster from "utils/hooks/useToaster";
import HttpErrorHelper from "utils/HttpErrorHelper";
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import ConfirmButton from 'components/ConfirmButton';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  tjeneste: TjenesteMedPriserDto;
  onlyView: boolean;
  onUpdated: () => void;
  onDeleted: () => void;
}

const EditTjeneste = (props: Props) => {
  const toaster = useToaster();

  const initialValues: UpdateTjenesteCommand = {
    tjenesteId: props.tjeneste.tjenesteId,
    tjenesteGruppeId: props.tjeneste.tjenesteGruppeId,
    tjenesteNavn: props.tjeneste.tjenesteNavn,
    produktnummer: props.tjeneste.produktnummer,
    faktureresForskuddsvis: props.tjeneste.faktureresForskuddsvis,
    avdelingsnummer: props.tjeneste.avdelingsnummer,
    description: props.tjeneste.description,
    faktureringsIntervall: props.tjeneste.faktureringsIntervall,
    deletedDate: props.tjeneste.deletedDate,
    salgskonto: props.tjeneste.salgskonto,
    momsFritattSalgskonto: props.tjeneste.momsFritattSalgskonto,
  }

  const validate = (values: UpdateTjenesteCommand) => {
    let errors: any = {};
    if (values.tjenesteNavn.length === 0)
      errors.tjenesteNavn = 'Tjenestenavn kan ikke være tomt';

    if (values.tjenesteGruppeId === "")
      errors.tjenesteGruppeId = 'Tjenestegruppe må velges';

    return errors;
  }

  const onSubmit = async (command: UpdateTjenesteCommand) => {
    try {
      await TjenesterApi.updateTjeneste(command);
      toaster.success('Suksess', 'Tjenesten er oppdatert!');
      props.setShow(false);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, loading, errors, touched, handleChange, handleSubmit } = useForm(initialValues, validate, onSubmit);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (tjenesteId: string) => {
    setIsDeleting(true);
    try {
      await TjenesterApi.deleteTjeneste(tjenesteId);
      toaster.success('Suksess', 'Tjenesten er slettet!');
      props.onDeleted();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
    setIsDeleting(false);
  }

  return (
    <>
      <IconButton
        icon='edit'
        variant='primary'
        className='col'
        onClick={() => props.setShow(true)}
      >
        Endre
      </IconButton>


      <Modal
        centered show={props.show} onHide={() => props.setShow(false)} dialogClassName="modal-10w">

        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">Rediger tjeneste</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>
                Tjenestenavn
                <OverlayTrigger
                  key='tooltip-top'
                  placement="top"
                  overlay={<Tooltip id="tooltip">Navnet som skrives inn her vil vises som en linje på fakturaen</Tooltip>}>
                  <span>
                    <IcebergIcon icon='info' />
                  </span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                value={values.tjenesteNavn}
                onChange={e => handleChange('tjenesteNavn', e.target.value)}
                isInvalid={errors.tjenesteNavn}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tjenesteNavn}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Velg en tjenestegruppe</Form.Label>
              <TjenesegruppeSelect selected={values.tjenesteGruppeId} onChange={e => handleChange('tjenesteGruppeId', e)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Produktnummer</Form.Label>
              <Form.Control
                value={values.produktnummer ?? ''}
                readOnly />
            </Form.Group>


            <Form.Group className="mb-3">
              <Form.Label>Avdelingsnummer</Form.Label>
              <Form.Control
                value={values.avdelingsnummer ?? ''}
                onChange={e => handleChange('avdelingsnummer', e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check aria-label="option 2" label='Faktureres Forskuddsvis?'
                checked={values.faktureresForskuddsvis}
                onChange={e => handleChange('faktureresForskuddsvis', e.target.checked)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Salgskonto</Form.Label>
              <Form.Control
                value={values.salgskonto ?? ''}
                onChange={e => handleChange('salgskonto', e.target.value)}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Momsfritatt salgskonto - Hvis denne ikke fylles ut vil tjenesten
                regnes som momspliktig</Form.Label>
              <Form.Control
                value={values.momsFritattSalgskonto ?? ''}
                onChange={e => handleChange('momsFritattSalgskonto', e.target.value)}
              />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Faktureringsintervall (1 = månedlig, 3 = kvartalsvis, 12 = årlig)
              </Form.Label>
              <Form.Control
                value={values.faktureringsIntervall}
                onChange={e => handleChange('faktureringsIntervall', e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Kommentar
                <OverlayTrigger
                  key='tooltip-top'
                  placement="top"
                  overlay={<Tooltip id="tooltip">Skriv inn detaljer som vil vises som en ekstra linje på fakturaen</Tooltip>}>
                  <span>
                    <IcebergIcon icon='info' />
                  </span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                value={values.description ?? ''}
                onChange={e => handleChange('description', e.target.value)}
              />
            </Form.Group>

          </Modal.Body>

          {/* footer section */}
          <Modal.Footer>

            <ConfirmButton disabled={isDeleting} className="col" variant="outline-danger" onClick={() => handleDelete(props.tjeneste.tjenesteId)}>
              Slett
            </ConfirmButton>

            <IconButton
              icon='close'
              variant='outline-primary'
              onClick={() => props.setShow(false)}>
              Avbryt
            </IconButton>

            <IconButton
              variant='primary'
              className="col"
              type='submit'
              icon='disk'
              disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}>
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              } Lagre
            </IconButton>

          </Modal.Footer>

        </Form>

      </Modal>

    </>
  );
};

export default EditTjeneste;