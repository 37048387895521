import { useState, useEffect } from "react";
import { Offcanvas, Form, FormGroup } from "react-bootstrap";
import TsKundeTypeahead from "components/TsKundeTypeahead";
import AccessToSelect from "components/AccessToSelect";
import RoleSelect from "components/RoleSelect";
import { BrukerSearchFilter } from "services/AdminportalenApi/BrukereApi/types";
import TsKunderApi from "services/AdminportalenApi/TsKunderApi";
import { TsKundeSearchDto } from "services/AdminportalenApi/TsKunderApi/types";
import Style from './index.module.css';
import { IconButton } from "@tradesolution/iceberg-ui-react";
import { OffcanvasPlacement } from "react-bootstrap/esm/Offcanvas";

interface Props {
    selectedFilter: BrukerSearchFilter;
    onFilterStatusChanged: (value: 'Alle' | 'Aktive' | 'Deaktiverte') => void;
    onFilterStringValuesChanged: (prop: string, value: string, name?: string) => void;
    onFilterBooleanValuesChanged: (prop: string, value: boolean) => void;
    onClose: () => void;
    show: boolean;
}

const Filters = (props: Props) => {

    const [placement, setPlacement] = useState<OffcanvasPlacement>(window.innerWidth <= 768 ? 'bottom' : 'end');
    const [height, setHeight] = useState(506);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedTsKunde, setSelectedTsKunde] = useState<TsKundeSearchDto>();
    const [selectedOnBehalfOfTsKunde, setSelectedOnBehalfOfTsKunde] = useState<TsKundeSearchDto>();

    useEffect(() => {
        const handleResize = () => {
            setPlacement(window.innerWidth <= 768 ? 'bottom' : 'end');
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClearAll = () => {
        props.onFilterStatusChanged('Alle');
        props.onFilterStringValuesChanged('tsKundeId', null);
        props.onFilterStringValuesChanged('onBehalfOfTsKundeId', null);
        props.onFilterStringValuesChanged('role', null);
        props.onFilterStringValuesChanged('accessTo', null);
        props.onFilterBooleanValuesChanged('hasNoAccesses', null);
        props.onFilterBooleanValuesChanged('passiveOnly', null);
    }

    //To ensure that child componen tag is removed when nullstill button within this component(child) is clicked
    useEffect(() => {
        if (props.selectedFilter.tsKundeId) {
            TsKunderApi.getById(props.selectedFilter.tsKundeId).then(response => setSelectedTsKunde(response));
        } else {
            setSelectedTsKunde(prev => null);
        }
    }, [props.selectedFilter.tsKundeId])

    // To ensure that child componen tag is removed when nullstill button within this component(child) is clicked
    useEffect(() => {
        if (props.selectedFilter.onBehalfOfTsKundeId) {
            TsKunderApi.getById(props.selectedFilter.onBehalfOfTsKundeId).then(response => setSelectedOnBehalfOfTsKunde(response));
        } else {
            setSelectedOnBehalfOfTsKunde(prev => null);
        }
    }, [props.selectedFilter.onBehalfOfTsKundeId])

    return (
        <Offcanvas
            show={props.show}
            onHide={props.onClose}
            placement={placement}
            className={`${Style.offcanvasStyle} container-fluid`}
            backdropClassName={Style.offcanvasBackdrop}
            style={{ height: window.innerWidth <= 768 ? `${height}px` : '100%' }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className={Style.bigLabel}>Filter</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    <div className={Style.formContainer}>
                        <FormGroup className={windowWidth > 768 ? 'mb-3' : 'mt-3'} style={{ order: windowWidth <= 768 ? 99 : 0 }}>
                            <IconButton
                                icon='synchronize'
                                size='sm'
                                variant='outline-primary'
                                onClick={handleClearAll}
                            >
                                Nullstill filter
                            </IconButton>
                        </FormGroup>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Status</Form.Label>
                            <Form.Group>
                                <Form.Check type="radio" value={'Alle'} checked={props.selectedFilter.statusFilter === 'Alle'}
                                    onChange={e => props.onFilterStatusChanged('Alle')} label={'Alle'} />
                                <Form.Check type="radio" value={'Aktive'} checked={props.selectedFilter.statusFilter === 'Aktive'}
                                    onChange={e => props.onFilterStatusChanged('Aktive')} label={'Aktive'} />
                                <Form.Check type="radio" value={'Deaktiverte'} checked={props.selectedFilter.statusFilter === 'Deaktiverte'}
                                    onChange={e => props.onFilterStatusChanged('Deaktiverte')} label={'Deaktiverte'} />
                            </Form.Group>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Ansatt hos</Form.Label>
                            <TsKundeTypeahead
                                selectedTsKundeId={selectedTsKunde?.tsKundeId}
                                onHandleChange={e => props.onFilterStringValuesChanged('tsKundeId', e ? e.tsKundeId : null, e ? e.tsKundeNavn : null)}
                            />

                        </Form.Group>


                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>På vegne av</Form.Label>
                            <TsKundeTypeahead
                                selectedTsKundeId={selectedOnBehalfOfTsKunde?.tsKundeId}
                                onHandleChange={e => props.onFilterStringValuesChanged('onBehalfOfTsKundeId', e ? e.tsKundeId : null, e ? e.tsKundeNavn : null)}
                            />

                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Tilgang til</Form.Label>
                            <AccessToSelect style={{width: '323.81px'}} selected={props.selectedFilter.accessTo} onChange={e => props.onFilterStringValuesChanged('accessTo', e)} />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Rolle</Form.Label>
                            <RoleSelect style={{ width: '323.81px'}} selected={props.selectedFilter.role} onChange={e => props.onFilterStringValuesChanged('role', e)} />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Andre filtere</Form.Label>
                            <Form.Check type="checkbox" name="hasNoAccesses" id="hasNoAccesses"
                                checked={props.selectedFilter.hasNoAccesses}
                                onChange={e => props.onFilterBooleanValuesChanged('hasNoAccesses', e.target.checked)}
                                label="Uten tilganger" />
                            <Form.Check type="checkbox" name="passiveOnly" id="passiveOnly"
                                checked={props.selectedFilter.passiveOnly}
                                onChange={e => props.onFilterBooleanValuesChanged('passiveOnly', e.target.checked)}
                                label="Lenge siden siste innlogging (9 mnd++)" />
                        </Form.Group>

                    </div>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>

    )
};

export default Filters;