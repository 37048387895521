import { IconButton } from "@tradesolution/iceberg-ui-react";
import { Button, Badge, Col, Row } from "react-bootstrap";

const DesignCheck = () => {
    return (
        <div className="container">
            <Row>
                <Col>
                    <h1>Buttons</h1>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                            <Button variant="primary">Primary</Button>
                            <Button variant="secondary">Secondary</Button>
                            <Button variant="success">Success</Button>
                            <Button variant="warning">Warning</Button>
                            <Button variant="danger">Danger</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                            <Button variant="outline-primary">Primary</Button>
                            <Button variant="outline-secondary">Secondary</Button>
                            <Button variant="outline-success">Success</Button>
                            <Button variant="outline-warning">Warning</Button>
                            <Button variant="outline-danger">Danger</Button>
                        </Col>
                    </Row>
                    <h2>With icon</h2>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                            <IconButton icon="export" variant="primary">Primary</IconButton>
                            <IconButton icon="export" variant="secondary">Secondary</IconButton>
                            <IconButton icon="export" variant="success">Success</IconButton>
                            <IconButton icon="export" variant="warning">Warning</IconButton>
                            <IconButton icon="export" variant="danger">Danger</IconButton>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                            <IconButton icon="export" variant="outline-primary">Primary</IconButton>
                            <IconButton icon="export" variant="outline-secondary">Secondary</IconButton>
                            <IconButton icon="export" variant="outline-success">Success</IconButton>
                            <IconButton icon="export" variant="outline-warning">Warning</IconButton>
                            <IconButton icon="globe" variant="outline-danger">Danger</IconButton>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                            <IconButton icon="export" variant="primary" size="lg">Primary</IconButton>
                            <IconButton icon="export" variant="secondary" size="sm">Secondary</IconButton>
                            <IconButton icon="export" variant="success" size="sm">Success</IconButton>
                            <IconButton icon="export" variant="warning" size="sm">Warning</IconButton>
                            <IconButton icon="export" variant="danger" size="sm">Danger</IconButton>
                        </Col>
                    </Row>
                    <h2>Only icon</h2>
                    <Row style={{ marginBottom: "1rem" }}>
                        <Col>
                            <IconButton icon="export" variant="primary" size="lg" />
                            <IconButton icon="export" variant="secondary" size="sm" />
                            <IconButton icon="export" variant="success" size="sm" />
                            <IconButton icon="export" variant="warning" size="sm" />
                            <IconButton icon="export" variant="danger" size="sm" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Badges</h1>
                    <Badge bg="primary">Primary</Badge>
                    <Badge bg="primary dark">Primary dark</Badge>
                    <Badge bg="secondary">Secondary</Badge>
                    <Badge bg="success">Success</Badge>
                    <Badge bg="warning">Warning</Badge>
                    <Badge bg="danger">Danger</Badge>
                    <Badge bg="danger dark">Danger bold</Badge>
                </Col>
            </Row>
        </div>
    )
}

export default DesignCheck;