import { useEffect, useState } from "react";
import { IconButton } from "@tradesolution/iceberg-ui-react";
import TjenestegruppeSelect from "components/TjenestegruppeSelect";
import { Alert, Card, Form, Modal, Spinner, Table } from "react-bootstrap";
import FakturagrunnlagApi from "services/AdminportalenApi/FakturagrunnlagApi";
import { FakturerBarTjeneste, GenererFakturagrunnlagForAbonnementOldCommand, GenererFakturagrunnlagForAbonnementResponse } from "services/AdminportalenApi/FakturagrunnlagApi/types";
import useLocalStorage from "utils/hooks/useLocalStorage";
import IcebergAlert from "components/IcebergAlert";

interface Props {
    show: boolean;
    onModalClose: () => void;
}

const GenerateAbonnementFakturaModal = (props: Props) => {

    const [selectedTjenesteGruppeId, setSelectedTjenesteGruppeId] = useLocalStorage<string | undefined>("fakturagrunnlag_selectedTjenesteGruppeId", undefined);

    const currentYear = new Date().getFullYear();
    const years = [currentYear + 1, currentYear, currentYear - 1, currentYear - 2];

    const [genererFakturagrunnlagCommand, setGenererFakturagrunnlagCommand] = useState<GenererFakturagrunnlagForAbonnementOldCommand>({
        tjenesteGruppeId: selectedTjenesteGruppeId,
        year: new Date().getFullYear(),
    });

    const handleTjenesteGruppeSelected = async (tjenesteGruppeId: string) => {
        setSelectedTjenesteGruppeId(tjenesteGruppeId);
        setGenererFakturagrunnlagCommand({ ...genererFakturagrunnlagCommand, tjenesteGruppeId });
    };

    const handleYearChanged = (year: number) => {
        setGenererFakturagrunnlagCommand({ ...genererFakturagrunnlagCommand, year });
    };

    const handleQuarterChanged = (quarter?: number) => {
        setGenererFakturagrunnlagCommand({ ...genererFakturagrunnlagCommand, quarter });
    };

    const handleMonthChanged = (month?: number) => {
        setGenererFakturagrunnlagCommand({ ...genererFakturagrunnlagCommand, month });
    };

    const [tjenesterToBeInvoiced, setTjenesterToBeInvoiced] = useState<FakturerBarTjeneste[]>([]);

    useEffect(() => {
        FakturagrunnlagApi.getTjenesterThatIsToBeInvoiced(genererFakturagrunnlagCommand.tjenesteGruppeId, genererFakturagrunnlagCommand.year, genererFakturagrunnlagCommand.quarter, genererFakturagrunnlagCommand.month).then(res => {
            setTjenesterToBeInvoiced(res);
        });
    }, [genererFakturagrunnlagCommand]);

    const [isGenerating, setIsGenerating] = useState(false);
    const [genererFakturaResponse, setGenererFakturaResponse] = useState<GenererFakturagrunnlagForAbonnementResponse | undefined>();

    const genererFakturagrunnlag = async () => {
        setIsGenerating(true);
        const response = await FakturagrunnlagApi.genererFakturagrunnlagForAbonnementOld(genererFakturagrunnlagCommand);
        setGenererFakturaResponse(response);
        setIsGenerating(false);
    };

    const genererFakturaSubmitEnabled = genererFakturagrunnlagCommand?.tjenesteGruppeId && genererFakturagrunnlagCommand.year && !isGenerating;

    const toReadableIntervall = (intervall: number) => {
        switch (intervall) {
            case 1:
                return "Månedlig";
            case 3:
                return "Kvartalsvis";
            case 12:
                return "Årlig";
            default:
                return "Ukjent";
        }
    };

    const formatPeriod = (periode?: string) => {
        if (!periode) {
            return "";
        }

        const year = periode.substring(0, 4);
        const month = periode.substring(4, 6);

        return `${getMonthName(month)} ${year}`;
    };

    const getMonthName = (month: string) => {
        switch (month) {
            case "01":
                return "JAN";
            case "02":
                return "FEB";
            case "03":
                return "MAR";
            case "04":
                return "APR";
            case "05":
                return "MAI";
            case "06":
                return "JUN";
            case "07":
                return "JUL";
            case "08":
                return "AUG";
            case "09":
                return "SEP";
            case "10":
                return "OKT";
            case "11":
                return "NOV";
            case "12":
                return "DES";
            default:
                return "Ukjent";
        }
    }

    return (<Modal size="lg" show={props.show} onHide={props.onModalClose}>
        <Modal.Header closeButton>
            <Modal.Title>Opprett fakturagrunnlag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group>
                    <Form.Label>Tjenestegruppe</Form.Label>
                    <TjenestegruppeSelect selected={selectedTjenesteGruppeId} onChange={handleTjenesteGruppeSelected} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>År</Form.Label>
                    <Form.Select value={genererFakturagrunnlagCommand.year} onChange={e => handleYearChanged(parseInt(e.target.value))}>
                        {years.map(y => <option key={y} selected={y === currentYear}>{y}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Kvartal</Form.Label>
                    <Form.Select value={genererFakturagrunnlagCommand.quarter} onChange={e => handleQuarterChanged(e.target.value ? parseInt(e.target.value) : null)}>
                        <option value="">Velg</option>
                        <option value={1}>Q1</option>
                        <option value={2}>Q2</option>
                        <option value={3}>Q3</option>
                        <option value={4}>Q4</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Måned</Form.Label>
                    <Form.Select value={genererFakturagrunnlagCommand.month} onChange={e => handleMonthChanged(e.target.value ? parseInt(e.target.value) : null)}>
                        <option value="">Velg</option>
                        <option value={1}>Januar</option>
                        <option value={2}>Februar</option>
                        <option value={3}>Mars</option>
                        <option value={4}>April</option>
                        <option value={5}>Mai</option>
                        <option value={6}>Juni</option>
                        <option value={7}>Juli</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>Oktober</option>
                        <option value={11}>November</option>
                        <option value={12}>Desember</option>
                    </Form.Select>
                </Form.Group>
            </Form>
            {isGenerating &&
                <div style={{ marginTop: "1rem" }}>
                    <IcebergAlert variant="info" icon="info">
                        <Spinner animation="border" size="sm" style={{ marginRight: "0.5rem" }} />
                        Genererer fakturagrunnlag. Dette kan ta litt tid...
                    </IcebergAlert>
                </div>
            }
            {genererFakturaResponse && <Alert style={{ marginTop: "1rem" }} variant="info">
                {genererFakturaResponse.antallUnikeKunder} kunde(r) fikk generert fakturagrunnlag for
                <ul>
                    {genererFakturaResponse.tjenesteNavn.map(tjeneste => (
                        <li key={tjeneste}>{tjeneste}</li>
                    ))}
                </ul>
            </Alert>}
            {tjenesterToBeInvoiced.length === 0 &&
                <Alert style={{ marginTop: "2rem" }} variant="info">
                    Finner ingen aktive abonnement for angitt periode
                </Alert>
            }
            {tjenesterToBeInvoiced.length > 0 && <Card style={{ marginTop: "2rem" }}>
                <Card.Body>
                    <Card.Title>Tjenester som blir inkludert for angitt periode</Card.Title>
                    <div className="table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Tjeneste</th>
                                    <th>Forskuddsvis</th>
                                    <th>Intervall</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tjenesterToBeInvoiced.map(t => <tr key={t.tjenesteId}>
                                    <td>{t.tjenesteNavn}</td>
                                    <td>{t.forskuddsvis ? "Ja" : "Nei"}</td>
                                    <td>{toReadableIntervall(t.faktureringsIntervall)}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>}
        </Modal.Body>
        <Modal.Footer>
            <IconButton icon="close" className="col" variant="secondary" onClick={props.onModalClose}>Lukk</IconButton>
            <IconButton disabled={!genererFakturaSubmitEnabled} icon="chevron-right" className="col" variant="primary" onClick={genererFakturagrunnlag}>Start</IconButton>
        </Modal.Footer>
    </Modal>);
};

export default GenerateAbonnementFakturaModal;