import axios from "axios";
import { CreateFaktureringsInfoCommand, UpdateFakturaAddresseCommand } from "./types";
import { getBaseUrl } from "..";

const baseUrl = getBaseUrl() + '/FakturaAdresser';

const FakturaAdresserApi = {
  createFaktureringsinfo: (command: CreateFaktureringsInfoCommand) =>
    axios.post(baseUrl, command).then(response => response.data),

  updateFaktureringsinfo: (command: UpdateFakturaAddresseCommand) =>
    axios.put(baseUrl, command),

  deleteFaktureringsinfo: (fakturaAdresseId: string) => 
    axios.delete(`${baseUrl}/${fakturaAdresseId}`),
}

export default FakturaAdresserApi;