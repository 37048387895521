import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { AdApplication, App } from "services/AdminportalenApi/AdApplicationsApi/types";
import { getAppIcon } from "components/IcebergIcon/utils";

interface Props {
    application: AdApplication;
}

const TilgangerIcons = (props: Props) => {

    const getApps = () => {
        if (props.application.tilganger.length > 0) {
            return props.application.tilganger[0].apps;
        }
        return [];
    }

    const getTitle = (app: App) => {
        const roles = app.accesses.map(o => o.role).filter(o => !!o);
        if (roles.length > 0) {
            return `${roles.join(", ")} i ${app.appName}`;
        }
        return app.appName;
    }

    return (
        <ul>
            {getApps().map(a => <IcebergIcon key={getTitle(a)} title={getTitle(a)} icon={getAppIcon(a.appName)} />)}
        </ul>
    );
};

export default TilgangerIcons;