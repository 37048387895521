import axios from "axios";
import { TjenesteDto, TjenesteOverviewDto, TjenesteMedPriserDto, CreateTjenesteCommand, UpdateTjenesteCommand } from "./types";
import { getBaseUrl } from "..";

const baseUrl = getBaseUrl() + '/Tjenester';

const TjenesterApi = {
  getById: async (id: string): Promise<TjenesteDto> => {
    const response = await axios.get(`${baseUrl}/${id}`);
    return response.data;
  },
  search: async (query: string): Promise<TjenesteDto[]> => {
    const response = await axios.get(`${baseUrl}/search?searchValue=${query}`);
    return response.data;
  },

  getOverview: async (): Promise<TjenesteOverviewDto[]> => {
    const response = await axios.get(`${baseUrl}/overview`);
    return response.data;
  },

  getTjenesteMedPris: async (tjenesteId: string): Promise<TjenesteMedPriserDto> => {
    const response = await axios.get(`${baseUrl}/${tjenesteId}/medPriser`);
    return response.data;
  },

  createTjeneste: async (command: CreateTjenesteCommand): Promise<TjenesteDto> => {
    const response = await axios.post(`${baseUrl}`, command);
    return response.data;
  },

  updateTjeneste: async (command: UpdateTjenesteCommand): Promise<TjenesteDto> => {
    const response = await axios.put(`${baseUrl}`, command);
    return response.data;
  },

  deleteTjeneste: async (tjenesteId: string) =>
    await axios.delete(`${baseUrl}/${tjenesteId}`)

}

export default TjenesterApi;