import { useState } from 'react';
import { KundeKortOverviewDto } from 'services/AdminportalenApi/KundeKortApi/types';
import { Row, Col, Spinner, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import Rediger from './components/Rediger';
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import IcebergBadge from 'components/IcebergBadge';

interface Props {
  kundeOverview: KundeKortOverviewDto;
  onUpdate: () => void;
  onUpdateOmsetning: () => void;
  loading: boolean;
}

const Overview = (props: Props) => {

  const [showRedigerModal, setShowRedigerModal] = useState<boolean>(false);
  const [showFullTidligereNavn, setShowFullTidligereNavn] = useState<boolean>(false);
  const [showFullOrganisasjonsform, setShowFullOrganisasjonsform] = useState<boolean>(false);

  const handleFullOrganisasjonsform = () => {
    setShowFullOrganisasjonsform(!showFullOrganisasjonsform);
  }

  const handleFullTidligereNavn = () => {
    setShowFullTidligereNavn(!showFullTidligereNavn);
  }

  const OmsetningsKlassePopover = (omsetningsklasse: string) => {
    let msg = 'Omsetningsklasse ' + omsetningsklasse;
    switch (omsetningsklasse) {
      case "E":
        msg = msg + `: mindre enn ${"100000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
        break;
      case "D":
        msg = msg + `: mellom ${"100000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")} og ${"250000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
        break;
      case "C":
        msg = msg + `: mellom ${"250000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")} og ${"500000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
        break;
      case "B":
        msg = msg + `: mellom ${"500000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")} og ${"1000000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
        break;
      case "A":
        msg = msg + `: mellom ${"1000000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")} og ${"1500000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
        break;
      case "XL":
        msg = msg + `: over ${"1500000000".replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
        break;
    }
    return (
      <Popover id="popover-menu" className={Style.popover}>
        <Popover.Body>
          <p>{msg}</p>
        </Popover.Body>
      </Popover>
    )
  }

  const fullTidligereNavn = showFullTidligereNavn ? props.kundeOverview.tidligereNavn.join(', ') : props.kundeOverview.tidligereNavn[0] + '...';

  return (
    <div className={Style.OverviewContainer}>
      <Row>
        <Col>
          <div className={Style.companyName}><b>{props.kundeOverview.tsKundeNavn}</b></div>
          <div className={Style.activeCustomer}>
            {
              props.kundeOverview.nedlagtDato ?
                <IcebergBadge variant="warning">Konkurs</IcebergBadge> :
                props.kundeOverview.inaktivDato ?
                  <IcebergBadge variant="dark">Inaktiv</IcebergBadge> :
                  <IcebergBadge variant="primary">Aktiv</IcebergBadge>
            }
          </div>
        </Col>

        <Col>
          <p className={Style.category}>Organisasjonsnummer</p>
          <p className={Style.categoryContent}>
            {props.kundeOverview.organisasjonsnummer}
            {props.kundeOverview.foretaksnavn && (` (${props.kundeOverview.foretaksnavn})`)} </p>

          <p className={Style.category}>Organisasjonsform</p>
          <p className={Style.categoryContent}>
            {
              props.kundeOverview.organisasjonsform?.length > 22 && !showFullOrganisasjonsform ?
                <>
                  {props.kundeOverview.organisasjonsform?.slice(0, 22) + '...'}
                  <span onClick={handleFullOrganisasjonsform} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#007bff' }}>mer</span>
                </> :
                <>
                  {props.kundeOverview.organisasjonsform}
                  {props.kundeOverview.organisasjonsform?.length > 22 && <span onClick={handleFullOrganisasjonsform}>...mindre</span>}
                </>
            }
          </p>


        </Col>

        <Col>
          <p className={Style.category}>Tidligerenavn</p>
          <p className={Style.categoryContent}>
            {
              props.kundeOverview.tidligereNavn.length > 1 ?
                <>
                  <p className={Style.categoryContent}>
                    {fullTidligereNavn}
                    <span onClick={handleFullTidligereNavn}>{showFullTidligereNavn ? '...mindre' : 'mer'}</span>
                  </p>
                </> :
                props.kundeOverview.tidligereNavn.length === 1 ?
                  <>
                    {props.kundeOverview.tidligereNavn}
                  </> :
                  null
            }
          </p>

          <p className={Style.category}>Omsetning (NOK)</p>
          <p className={Style.categoryContent}>
            {
              props.kundeOverview.omsetning ?
                props.kundeOverview.omsetning?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :
                "0"
            }
            {' '}
            {
              props.kundeOverview.omsetning > 0 && props.kundeOverview.omsetningsklasse &&
              <OverlayTrigger rootClose placement="bottom" trigger={["hover", "focus"]}
                overlay={OmsetningsKlassePopover(props.kundeOverview.omsetningsklasse)}>
                <IcebergBadge variant="primary">{props.kundeOverview.omsetningsklasse}</IcebergBadge>
              </OverlayTrigger>
            }
          </p>
        </Col>

        <Col>
        
          <p className={Style.category}>Utenlandsk kunde?</p>
          <p className={Style.categoryContent}>{props.kundeOverview.erUtenlandskSelskap ? 'Ja' : 'Nei'}</p>

          <p className={Style.category}>Firma e-post</p>
          <p className={Style.categoryContent}>{props.kundeOverview.firmaEpost}</p>
        </Col>

        <Col>
          <p className={Style.category}>Antall dager til fakturaforfall</p>
          {
            props.kundeOverview.betalingsbetingelser > 0 &&
            <>
              <p className={Style.categoryContent}>{props.kundeOverview.betalingsbetingelser}</p>
            </>
          }

          <p className={Style.category}>Antall aktive brukere</p>
          <p className={Style.categoryContent}>
            <a target='_blank' href={`/Brukere?selectedOrderBy=TsKundeNavnAsc&tsKundeId=${props.kundeOverview.tsKundeId}`}>
              {props.kundeOverview.antallAktiveBrukere}
            </a>
          </p>
        </Col>

        <Col>
          <p className={Style.category}>Rapport</p>
          <p className={Style.categoryContent}>
            <a target='_self' href={`/TsKunder/${props.kundeOverview.tsKundeId}/kundeRapport`}>
              Omsetning i år mot i fjor
            </a>
          </p>
        </Col>

        <Col>
          <Row style={{ marginBottom: "0.5rem" }}>
            <Col>
              <div className='float-end'>
                <Rediger
                  show={showRedigerModal}
                  setShow={setShowRedigerModal}
                  kunde={props.kundeOverview}
                  onUpdate={props.onUpdate} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='float-end'>
                <OverlayTrigger trigger="click" key={'left'} placement={'left'} rootClose
                  overlay={
                    <Popover id={`popover-positioned-left`}>
                      <Popover.Body>
                        {props.kundeOverview.overrideOmsetning ? <button className={Style.syncButton} disabled><IcebergIcon icon='synchronize' /></button> : <button className={Style.syncButton} onClick={props.onUpdateOmsetning}>
                          {
                            props.loading ? <Spinner animation="border" role="status" size='sm'>
                              <span className="visually-hidden">Loading...</span>
                            </Spinner> : <IcebergIcon icon='synchronize' />
                          } Oppdater omsetning
                        </button>
                        }
                      </Popover.Body>
                    </Popover>
                  }>
                  <Button variant='outline-primary' className='col'>
                    <IcebergIcon icon='ellipsis' />
                  </Button>
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
        </Col>

      </Row>

    </div>
  )
}

export default Overview