import { Col, Form } from "react-bootstrap";
import { DatePeriod } from "./DatePeriod";

interface YearPickerProps {
    year: number;
    onChange: (period: DatePeriod) => void;
}

const YearPicker = (props: YearPickerProps) => {

    const handleYearChanged = (value: number) => {
        props.onChange({
            year: value
        });
    };

    return (
        <Form.Group as={Col}>
            <Form.Label>År</Form.Label>
            <Form.Select value={props.year} onChange={e => handleYearChanged(parseInt(e.target.value))}>
                <option>{new Date().getFullYear() - 1}</option>
                <option>{new Date().getFullYear()}</option>
                <option>{new Date().getFullYear() + 1}</option>
            </Form.Select>
        </Form.Group>
    );
}

export default YearPicker;