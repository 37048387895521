import axios from "axios";
import { getBaseUrl } from '..';
import { CreateKontaktpersonCommand, UpdateKontaktpersonCommand, UpdateKontaktpersonConnectionsCommand } from "./types";

const baseUrl = getBaseUrl() + '/Kontaktperson';

const KontaktpersonerApi = {
    createKontaktperson: (payload: CreateKontaktpersonCommand) =>
        axios.post(`${baseUrl}`, payload).then(response => response.data),

    deleteKontaktperson: async (kontaktpersonId: string) => {
        await axios.delete(`${baseUrl}/${kontaktpersonId}`);
    },

    updateKontaktperson: (command: UpdateKontaktpersonCommand) =>
        axios.put(baseUrl, command).then(response => response.data),

    updateKontaktpersonKontakter: (command: UpdateKontaktpersonConnectionsCommand) =>
        axios.put(`${baseUrl}/Tjenestegrupper`, command)
}

export default KontaktpersonerApi;