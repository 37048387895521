import { useState } from 'react'
import { Table, OverlayTrigger } from 'react-bootstrap';
import { IcebergAvatar, IcebergIcon } from '@tradesolution/iceberg-ui-react';
import PopoverMenu from 'components/PopoverMenu';
import WarningPopover from 'components/WarningPopover';
import EditKontaktperson from './components/EditKontaktperson';
import EditKontaktpersonConnections from './components/EditKontaktpersonConnections';
import CreateKontaktperson from './components/CreateKontaktperson';
import TjenestegruppeConnectionsViewer from './components/TjenestegruppeConnectionsViewer';
import { Kontaktperson } from 'services/AdminportalenApi/KundeKortApi/types';
import KontaktpersonerApi from 'services/AdminportalenApi/KontaktpersonerApi';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import Style from './index.module.css';

interface Props {
  tsKundeId: string;
  kontaktpersoner: Kontaktperson[];
  isNedlagt: boolean;
  onUpdated: () => void;
  filteredTjenestegrupper: string[];
  varsler?: string;
}

const KontaktPersoner = (props: Props) => {
  const toaster = useToaster();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showEditConnectionsModal, setShowEditConnectionsModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [selectedKontaktperson, setSelectedKontaktperson] = useState<Kontaktperson>(null);

  const handleDelete = async (id: string) => {
    try {
      await KontaktpersonerApi.deleteKontaktperson(id);
      toaster.success('Suksess', 'Kontaktperson slettet!');
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const handleEdit = (kontaktperson: Kontaktperson) => {
    setShowEditModal(true);
    setSelectedKontaktperson(kontaktperson);
  }

  const handleEditKontakter = (kontaktperson: Kontaktperson) => {
    setShowEditConnectionsModal(true);
    setSelectedKontaktperson(kontaktperson);
  }

  const filteredKontaktpersoner = props.kontaktpersoner.filter(x => {
    if (props.filteredTjenestegrupper.length === 0)
      return x;
    const connections = x.tjenesteGruppeConnections.filter(x => x.isPrimaryContact || x.isFakturaContact || x.isOtherContact);
    const notFound = connections.findIndex(val => props.filteredTjenestegrupper.includes(val.tjenesteGruppeId)) === -1;
    return connections.length === 0 || !notFound;
  });

  return (
    <>
      <div className={Style.wrapper}>
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                {
                  props.varsler && !props.isNedlagt &&
                  <OverlayTrigger rootClose placement="bottom" trigger={["hover", "focus"]} overlay={WarningPopover(props.varsler)}>
                    <span>
                      <IcebergIcon icon='alert' backgroundColor='#fcd7ad' rounded bold />
                    </span>
                  </OverlayTrigger>
                }
                {' Kontaktpersoner'}
              </th>
              <th></th>
              <th></th>
              <th>
                Ansvar
              </th>
              <th style={{ cursor: props.isNedlagt ? 'default' : 'pointer' }}>
                {!props.isNedlagt && <IcebergIcon icon='plus' title='Ny' bold onClick={() => setShowCreateModal(true)} />}
              </th>
            </tr>
          </thead>
          <tbody>
            {
              filteredKontaktpersoner.map(person => (
                <tr key={person.kontaktpersonId}>
                  <td>
                    <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                      <IcebergAvatar firstName={person.navn} />
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: 5 }}>
                      <p className={Style.kontaktpersonNavn}>{person.navn}</p>
                      {person.typeKontaktperson && <p className={Style.kontaktpersonTittel}>{person.typeKontaktperson}</p>}
                    </div>
                  </td>
                  <td>{person.mobile ?
                    person.mobile.replace(/\s+/g, '') : null
                  }</td>
                  <td>{person.email}</td>
                  <td>
                    <TjenestegruppeConnectionsViewer connections={person.tjenesteGruppeConnections} />
                  </td>
                  <td className='fitContent'>
                    <PopoverMenu
                      onDelete={() => handleDelete(person.kontaktpersonId)}
                      onEdit={() => handleEdit(person)}
                      customOptions={[{ title: 'Endre tilknytninger', type: 'edit', action: () => handleEditKontakter(person) }]}
                      confirmDelete={true} />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {props.filteredTjenestegrupper.length > 0 && <span className={Style.helpText}>Viser {filteredKontaktpersoner.length} av {props.kontaktpersoner.length} kontaktpersoner</span>}
      </div>
      {showEditModal && <EditKontaktperson show={showEditModal} setShow={setShowEditModal} kontaktperson={selectedKontaktperson} onUpdated={props.onUpdated} tsKundeId={props.tsKundeId} onlyView={props.isNedlagt} />}
      {showEditConnectionsModal && <EditKontaktpersonConnections show={showEditConnectionsModal} setShow={setShowEditConnectionsModal} tsKundeId={props.tsKundeId} kontaktperson={selectedKontaktperson} onUpdated={props.onUpdated} />}
      {showCreateModal && <CreateKontaktperson show={showCreateModal} setShow={setShowCreateModal} onUpdated={props.onUpdated} tsKundeId={props.tsKundeId} />}
    </>
  )
}

export default KontaktPersoner;