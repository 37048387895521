import axios from "axios";
import { cp } from "fs";

export const getBaseUrl = () => {
    let baseUrl = 'https://adminportalenapi.tradesolution.no/api';
    if (window.location.host.indexOf('dev') > -1)
        baseUrl = 'https://adminportalenapi-dev.tradesolution.no/api';

    if (window.location.host.indexOf('localhost') > -1)
        //this.baseUrl = 'https://adminportalenapi-dev.tradesolution.no/api';
        baseUrl = 'https://localhost:7216/api';

    return baseUrl;
}
const AiApi = {
    summarize: async (prompt: string): Promise<string> => {
        const payload = {
            prompt: prompt
        }
        const response = await axios.post(getBaseUrl() + '/ai', payload);
        return response.data;

       
    }
}

export default AiApi;