import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Spinner } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import TjenestegruppePicker from 'components/TjenestegruppePicker';
import PONummerApi from 'services/AdminportalenApi/PONummerApi';
import { UpdatePONummerCommand, UpdatePONummerCommandTjenestegruppe, PONummer } from 'services/AdminportalenApi/PONummerApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  poNummerId: string;
  onUpdated: () => void;
  onlyView: boolean;
}

const EditPONummer: React.FC<Props> = props => {
  const toaster = useToaster();

  const [poNummer, setPONummer] = useState<PONummer>();
  const [newSelectedTjenestegrupper, setNewSelectedTjenestegrupper] = useState<string[]>([]);
  const [newValidFromDate, setNewValidFromDate] = useState<Date>(new Date());
  const [newValidToDate, setNewValidToDate] = useState<Date>();

  const initialValues: UpdatePONummerCommand = {
    poNummerId: '',
    tsKundeId: '',
    tjenestegrupper: []
  }

  const validate = (values: UpdatePONummerCommand) => {
    let errors: any = {};
    return errors;
  }

  const onSubmit = async (values: UpdatePONummerCommand) => {
    try {
      await PONummerApi.updatePONummer(values);
      toaster.success('Suksess', 'PONummer oppdatert!');
      props.setShow(false);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, touched, loading, updateValues, handleSubmit } = useForm(initialValues, validate, onSubmit);


  const loadPONummer = async () => {
    const poNummer = await PONummerApi.getPONummer(props.poNummerId);
    setPONummer(poNummer);
    const copy = { ...values };
    copy.poNummerId = poNummer.poNummerId;
    copy.tsKundeId = poNummer.tsKundeId;
    copy.tjenestegrupper = poNummer.tjenestegruppePONummer.map(x => {
      const tg: UpdatePONummerCommandTjenestegruppe = {
        tjenestegruppeId: x.tjenestegruppeId,
        tjenestegruppeNavn: x.tjenestegruppeNavn,
        validFromDate: x.validFromDate,
        validToDate: x.validToDate
      }
      return tg;
    })
    updateValues(copy);
  }

  const handleDateChange = (date: Date, validFromDate: boolean, id: string) => {
    const copy = { ...values };
    copy.tjenestegrupper = copy.tjenestegrupper.map(x => {
      if (validFromDate) {
        return x.tjenestegruppeId === id ? { ...x, validFromDate: date } : x
      } else {
        return x.tjenestegruppeId === id ? ({ ...x, validToDate: date }) : x
      }
    });
    updateValues(copy);
  }

  useEffect(() => {
    loadPONummer();
  }, [props.poNummerId])

  useEffect(() => {
    if (poNummer && poNummer.tjenestegruppePONummer?.length === 0) {
      const copy = { ...values };
      copy.tjenestegrupper = newSelectedTjenestegrupper.map(x => ({
        tjenestegruppeId: x,
        validFromDate: newValidFromDate,
        validToDate: newValidToDate
      }));
      updateValues(copy);
    }
  }, [poNummer, newSelectedTjenestegrupper, newValidFromDate, newValidToDate])

  return (
    <Modal centered show={props.show} onHide={() => props.setShow(false)} dialogClassName="modal-10w" aria-labelledby="example-custom-modal-styling-title">
      {
        poNummer ?
          <>
            <Modal.Header>
              <h6>Oppdater PO-nummer {poNummer.poNummerValue}</h6>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                {
                  poNummer.tjenestegruppePONummer?.length > 0 ?
                    values.tjenestegrupper.map(x =>
                      <Row key={x.tjenestegruppeId}>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>Tjenestegruppe</Form.Label>
                            <Form.Control value={x.tjenestegruppeNavn} readOnly />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>Gyldig fra</Form.Label>
                            <DatePicker onChange={date => handleDateChange(date, true, x.tjenestegruppeId)} selected={x.validFromDate} disabled={props.onlyView} />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className='mb-3'>
                            <Form.Label>Gyldig til</Form.Label>
                            <DatePicker onChange={date => handleDateChange(date, false, x.tjenestegruppeId)} selected={x.validToDate} disabled={props.onlyView} />
                          </Form.Group>
                        </Col>
                      </Row>
                    ) :
                    <>
                      <Row>
                        <TjenestegruppePicker onSelectionChanged={selected => setNewSelectedTjenestegrupper(selected.map(x => x.tjenesteGruppeId) || [])} mode='multiSelect' selected={newSelectedTjenestegrupper} disabled={props.onlyView} />
                      </Row>
                      <Row>
                        <Col>
                          <Form.Label>Gyldig fra</Form.Label>
                          <DatePicker onChange={date => setNewValidFromDate(date)} selected={newValidFromDate} disabled={props.onlyView} />
                        </Col>
                        <Col>
                          <Form.Label>Gyldig til</Form.Label>
                          <DatePicker onChange={date => setNewValidToDate(date)} selected={newValidToDate} disabled={props.onlyView} />
                        </Col>
                      </Row>
                    </>
                }
              </Modal.Body>
              <Modal.Footer>
                {
                  props.onlyView ?
                    <Button variant="primary" onClick={() => props.setShow(false)}>
                      Lukk
                    </Button> :
                    <>
                      <Button className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
                        <IcebergIcon size='lg' icon='close' /> Avbryt
                      </Button>
                      <Button variant="primary" className="col" type="submit" disabled={loading || Object.keys(touched).length === 0}>
                        <IcebergIcon icon='disk' size='lg' /> Lagre
                        {
                          loading &&
                          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                        }
                      </Button>
                    </>
                }
              </Modal.Footer>
            </Form>
          </> :
          <></>
      }
    </Modal>
  );
}

export default EditPONummer;