import axios from "axios";

import { ExternalAzureAdSourceDto, TenantDto, GroupDto, CreateExternalAdSourceCommand, UpdateExternalGroupCommand, UpdateExternalGroupAccessesCommand } from "./types";

import { getBaseUrl } from "..";

const baseUrl  = getBaseUrl() + '/ExternalAzureAdSources';

const ExternalAdSourcesApi = {
  getExternalAdSources: async() : Promise<ExternalAzureAdSourceDto[]> =>
    axios.get(baseUrl).then(response => response.data),

  createExternalSource: async(command: CreateExternalAdSourceCommand) : Promise<ExternalAzureAdSourceDto> =>
    axios.post(baseUrl, command).then(response => response.data),

  updateExternalSource: async(command: UpdateExternalGroupCommand) : Promise<ExternalAzureAdSourceDto> =>
    axios.put(baseUrl, command).then(response => response.data),

  updateExternalSourceAccesses: async(command: UpdateExternalGroupAccessesCommand) : Promise<ExternalAzureAdSourceDto> =>
    axios.put(`${baseUrl}/Accesses`, command).then(response => response.data),

  deleteExternalSource: async(id: string) =>
    axios.delete(`${baseUrl}/${id}`),
  
  getTenants: async() : Promise<TenantDto[]> =>
    axios.get(`${baseUrl}/Tenants`).then(response => response.data),

  searchExternalGroups: async(searchValue: string, tenantId: string) : Promise<GroupDto[]> => 
    axios.get(`${baseUrl}/SearchExternalGroups?searchValue=${searchValue}&externalTenantId=${tenantId}`).then(response => response.data) 
};

export default ExternalAdSourcesApi;