import { useState, useEffect } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { IProductMenuItem } from 'services/KundeportalenApi/types.interface';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { IcebergProductMenu, IcebergIcon, IcebergNotifications, IcebergCustomerPicker } from '@tradesolution/iceberg-ui-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from 'authConfig';
import KundeportalenApi from 'services/KundeportalenApi';
import LogRocket from 'logrocket';


const MainMenu = () => {

    const [services, setServices] = useState<IProductMenuItem[]>();
    const currentSelectedServiceName = 'Adminportalen';



    const appInsights = useAppInsightsContext();

    const loadServices = async () => {
        const kundeportalInfo = await KundeportalenApi.fetchMenu(false);
        setServices(kundeportalInfo?.tjenester);
    };

    useEffect(() => {
        loadServices();
    }, []);

    const { instance, accounts } = useMsal();

    const name = accounts[0] && accounts[0].name;

    // for logrocket
    useEffect(() => {
        LogRocket.identify(name);
    }, [name]);

    const getFirstName = (fullname: string | undefined): string => {
        if (!fullname) {
            return "";
        }
        return fullname.split(' ')[0];
    };

    const handleLogin = (i: IPublicClientApplication) => {
        i.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    const handleLogout = () => {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }


    return (
        <Navbar collapseOnSelect expand="lg" bg='info-dark' sticky='top' style={{ padding: "0.2rem 0" }} >
            <Container fluid>
                <Navbar.Brand className='text-decoration-none' href="/">
                    <IcebergIcon icon='adminportal' size="lg" />
                    <span className='ms-3'>Adminportalen</span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbarScroll" />

                <Navbar.Collapse role="navigation">
                    <Nav className='me-auto'>
                        <NavDropdown
                            title={<IcebergIcon icon='meatballs' />}
                        >
                            <IcebergProductMenu />
                        </NavDropdown>
                    </Nav>

                    <Nav style={{ marginLeft: "auto" }}>
                        <AuthenticatedTemplate>
                            <>
                                <NavDropdown
                                    id="dropdownUserMenu"
                                    align="end"
                                    title={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ marginLeft: '0.5em' }}>{getFirstName(name)}</span>
                                        </div>
                                    }
                                >
                                    <NavDropdown.Item onClick={handleLogout}>
                                        <span>
                                            <strong>Logg av</strong>
                                        </span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <IcebergCustomerPicker />
                                <IcebergNotifications />
                            </>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <Nav.Link onClick={() => handleLogin(instance)}>
                                Logg inn
                            </Nav.Link>
                        </UnauthenticatedTemplate>
                    </Nav>
                </Navbar.Collapse >
            </Container>
        </Navbar >
    );
}

export default MainMenu;