import { useEffect, useState } from 'react';
import { Offcanvas, Form, FormGroup } from 'react-bootstrap';
import TjenestegruppeSelect from 'components/TjenestegruppeSelect';
import { TsKundeSearchFilter } from "services/AdminportalenApi/TsKunderApi/types";
import Style from './index.module.css';
import { IconButton } from '@tradesolution/iceberg-ui-react';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';
import TjenesteTypeahead from 'components/TjenesteTypeahead';
import { TjenesteDto } from 'services/AdminportalenApi/TjenesterApi/types';

interface Props {
    selectedFilter: TsKundeSearchFilter;
    onFilterBooleanValuesChanged: (prop: string, value: boolean) => void;
    onFilterStringValuesChanged: (prop: string, value: string) => void;
    onFilterStatusChanged: (value: 'Alle' | 'Aktive' | 'Inaktive' | 'Nedlagte') => void;
    tjenesteNavnSelected: (tjenesteNavn: string) => void;
    selectedAktivAvtaleTjenestegruppeNavn: string;
    onClose: () => void;
    show: boolean;
}

const Filters = (props: Props) => {

    const [placement, setPlacement] = useState<OffcanvasPlacement>(window.innerWidth <= 768 ? 'bottom' : 'end');
    const [height, setHeight] = useState(506);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setPlacement(window.innerWidth <= 768 ? 'bottom' : 'end');
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const handleClearAll = () => {
        props.onFilterStatusChanged('Aktive');
        props.onFilterStringValuesChanged('aktivtAbonnementTjenestegruppeId', null);
        props.onFilterStringValuesChanged('aktivtAbonnementTjenesteId', null);
        props.onFilterStringValuesChanged('aktivAvtaleTjenesteGruppeId', null);
        props.onFilterBooleanValuesChanged('overriddenOmsetningOnly', false);
        props.onFilterBooleanValuesChanged('withoutAbonnement', false);
        props.onFilterBooleanValuesChanged('withoutAvtaler', false);
        props.onFilterBooleanValuesChanged('withoutFakturaAdresser', false);
        props.onFilterBooleanValuesChanged('withoutKontaktpersoner', false);
        props.onFilterBooleanValuesChanged('harDagligLederUtenEpost', false);
        props.onFilterBooleanValuesChanged('manglerDagligLeder', false);

    };

    const tjenesteSelected = (tjeneste: TjenesteDto) => {
        props.onFilterStringValuesChanged('aktivtAbonnementTjenesteId', tjeneste?.tjenesteId);
        props.tjenesteNavnSelected(tjeneste?.tjenesteNavn);
    };

    return (
        <Offcanvas
            show={props.show}
            onHide={props.onClose}
            placement={placement}
            className={`${Style.offcanvasStyle} container-fluid`}
            backdropClassName={Style.offcanvasBackdrop}
            style={{ height: window.innerWidth <= 768 ? `${height}px` : '100%' }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className={Style.bigLabel}>Filter</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Form>
                    <div className={Style.formContainer}>
                        <FormGroup className={windowWidth > 768 ? 'mb-3' : 'mt-3'} style={{ order: windowWidth <= 768 ? 99 : 0 }}>
                            <IconButton
                                icon='synchronize'
                                size='sm'
                                variant='outline-primary'
                                onClick={handleClearAll}
                            >
                                Nullstill filter
                            </IconButton>
                        </FormGroup>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Status</Form.Label>
                            <Form.Check type="radio" value={'Alle'} checked={props.selectedFilter.statusOption === 'Alle'}
                                onChange={e => props.onFilterStatusChanged('Alle')} label={'Alle'} />
                            <Form.Check type="radio" value={'Aktive'} checked={props.selectedFilter.statusOption === 'Aktive'}
                                onChange={e => props.onFilterStatusChanged('Aktive')} label={'Aktive'} />
                            <Form.Check type="radio" value={'Inaktive'} checked={props.selectedFilter.statusOption === 'Inaktive'}
                                onChange={e => props.onFilterStatusChanged('Inaktive')} label={'Inaktive'} />
                            <Form.Check type="radio" value={'Nedlagte'} checked={props.selectedFilter.statusOption === 'Nedlagte'}
                                onChange={e => props.onFilterStatusChanged('Nedlagte')} label={'Konkurs'} />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Aktiv abonnent i</Form.Label>
                            <TjenestegruppeSelect selected={props.selectedFilter.aktivtAbonnementTjenestegruppeId ?? ''} onChange={e => props.onFilterStringValuesChanged('aktivtAbonnementTjenestegruppeId', e)} />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Aktiv abonnent i tjeneste</Form.Label>
                            <TjenesteTypeahead selectedId={props.selectedFilter.aktivtAbonnementTjenesteId ?? ''} onHandleChange={e => tjenesteSelected(e)} />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Aktiv avtale i</Form.Label>
                            <TjenestegruppeSelect selected={props.selectedFilter.aktivAvtaleTjenesteGruppeId ?? ''} onChange={e => props.onFilterStringValuesChanged('aktivAvtaleTjenesteGruppeId', e)} />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className={Style.bigLabel}>Andre filter</Form.Label>
                            <Form.Check type="checkbox" name="OverriddenOmsetningOnly" id="OverriddenOmsetningOnly"
                                checked={props.selectedFilter.overriddenOmsetningOnly}
                                onChange={e => props.onFilterBooleanValuesChanged('overriddenOmsetningOnly', e.target.checked)}
                                label="Med overstyrt omsetning"
                            />
                            <Form.Check type="checkbox" name="WithoutAbonnement" id="WithoutAbonnement"
                                checked={props.selectedFilter.withoutAbonnement}
                                onChange={e => props.onFilterBooleanValuesChanged('withoutAbonnement', e.target.checked)}
                                label="Uten abonnement"
                            />
                            <Form.Check type="checkbox" name="WithoutAvtaler" id="WithoutAvtaler"
                                checked={props.selectedFilter.withoutAvtaler}
                                onChange={e => props.onFilterBooleanValuesChanged('withoutAvtaler', e.target.checked)}
                                label="Uten avtaler"
                            />
                            <Form.Check type="checkbox" name="WithoutFakturaAdresser" id="WithoutFakturaAdresser"
                                checked={props.selectedFilter.withoutFakturaAdresser}
                                onChange={e => props.onFilterBooleanValuesChanged('withoutFakturaAdresser', e.target.checked)}
                                label="Uten fakturaadresser"
                            />
                            <Form.Check type="checkbox" name="WithoutKontaktpersoner" id="WithoutKontaktpersoner"
                                checked={props.selectedFilter.withoutKontaktpersoner}
                                onChange={e => props.onFilterBooleanValuesChanged('withoutKontaktpersoner', e.target.checked)}
                                label="Uten kontaktpersoner"
                            />
                            <Form.Check type="checkbox" name="HarDagligLederUtenEpost" id="HarDagligLederUtenEpost"
                                checked={props.selectedFilter.harDagligLederUtenEpost}
                                onChange={e => props.onFilterBooleanValuesChanged('harDagligLederUtenEpost', e.target.checked)}
                                label="Har daglig leder uten epost"
                            />

                            <Form.Check type="checkbox" name="ManglerDagligLeder" id="ManglerDagligLeder"
                                checked={props.selectedFilter.manglerDagligLeder}
                                onChange={e => props.onFilterBooleanValuesChanged('manglerDagligLeder', e.target.checked)}
                                label="Mangler daglig leder"
                            />
                        </Form.Group>
                    </div>
                </Form>
            </Offcanvas.Body >
        </Offcanvas >
    );
}


export default Filters;