import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import DropdownTreeSelect, { Mode, TreeNode } from 'react-dropdown-tree-select';

import cx from 'classnames';

import TjenestegruppeApi from 'services/AdminportalenApi/TjenestegruppeApi';
import { Tjenestegruppe } from 'services/AdminportalenApi/TjenestegruppeApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import './index.css';

interface Props {
  onSelectionChanged: (selected: Tjenestegruppe[]) => void;
  mode?: Mode;
  selected?: string[];
  disabled?: boolean;
  className?: string;
}

const TjenestegruppePicker: React.FC<Props> = props  => {
  const isMountedRef = useIsMountedRef();

  const [data, setData] = useState<Tjenestegruppe[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mapTjenestegruppeNode = (node: TreeNode) => {
    const item: Tjenestegruppe = {
      id: node.id,
      label: node.label,
      tjenesteGruppeId: node.id,
      tjenesteGruppeNavn: node.label,
      checked: true
    };
    return item;
  };

  const mapTreeItem = (node: Tjenestegruppe) => {
    const isChecked = (props.selected && props.selected.includes(node.tjenesteGruppeId)) ? true : false;
    const item: Tjenestegruppe = {
      id: node.tjenesteGruppeId,
      label: node.tjenesteGruppeNavn,
      tjenesteGruppeId: node.tjenesteGruppeId,
      tjenesteGruppeNavn: node.tjenesteGruppeNavn,
      checked: isChecked
    };
    return item;
  };

  const loadTjenestegrupper = async () => {
    setIsLoading(true);
    let res = await TjenestegruppeApi.getTjenestegrupper();
    if (res.length > 0 && isMountedRef.current) {
      const resData: Tjenestegruppe[] = res.map((x: Tjenestegruppe) => mapTreeItem(x))
      setData(resData);
    }
    setIsLoading(false);
  };

  const handleChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    if (Array.isArray(selectedNodes)) {
      props.onSelectionChanged(selectedNodes.map(x => mapTjenestegruppeNode(x)));
    }
  };

  useEffect(() => {
    if(!data){
      loadTjenestegrupper();
    }
  }, [data])

  useEffect(() => {
    if(data){
      setData(prev => prev.map((x: Tjenestegruppe) => mapTreeItem(x)))
    }
  }, [props.selected])

  return (
    <>
      <DropdownTreeSelect 
        className={cx('reactDropdownMultiPicker', props.className)}
        data={data || []}
        mode={props.mode || 'radioSelect'}
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        texts={{placeholder: 'vis: velg'}} 
        onChange={(currentNode: TreeNode, selectedNodes: TreeNode[]) => handleChange(currentNode, selectedNodes)}
        disabled={props.disabled} />
      {isLoading && (
        <div>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          <span> Henter data... </span>
        </div>
      )}
    </>
  )
}

export default TjenestegruppePicker;