import { useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import useIsMountedRef from "utils/hooks/useIsMountedRef";
import Style from './index.module.css';
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { getTjenestegruppeIcon } from 'components/IcebergIcon/utils';

const Prismodeller = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [containerWidth, setContainerWidth] = useState<boolean>(true);  
  const isMountedRef = useIsMountedRef();

  return (
    <div className={containerWidth ? Style.container : Style.containerCollapse}>
      <Row style={{ marginTop: '0.5em' }}>
        <Col>
          <h2 className={Style.mainHeader}>Prismodeller</h2>
        </Col>        
      </Row>      
      <Row>
        <Col>
          <Row>
              <Col>                
                  <Table responsive style={{ borderCollapse: 'collapse', borderTop: 'none' }} hover>
                    <thead>
                      <tr>                        
                        <th>Tjenestegruppe</th>
                        <th>Fil</th>                        
                      </tr>
                    </thead>
                    <tbody className={Style.tableBody} style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
                    <tr>
                        <td><IcebergIcon icon={getTjenestegruppeIcon("Kjederegisteret")} /> Kjederegisteret</td>
                        <td><a href="https://eanreg.sharepoint.com/:b:/s/CRMInsight/EWJOHjnXeMNGhOVZXDYWlSMBqu5_cMb1w9PFpMro5vckTg?e=zfiRf2">KR Priser 2023</a></td>                        
                      </tr>
                      <tr>
                        <td><IcebergIcon icon={getTjenestegruppeIcon("salgsdata")} /> Salgsdata</td>
                        <td><a href="https://eanreg.sharepoint.com/:b:/s/CRMInsight/ESv7g5ZkxF9Pn0DmnPEr6RoBP4IjGLU2qgYIlxvC8mdTMA?e=LWOXNy">SD Priser 2023</a></td>                        
                      </tr>
                      <tr>
                        <td><IcebergIcon icon={getTjenestegruppeIcon("epd")} /> EPD</td>
                        <td><a href="https://eanreg.sharepoint.com/:b:/s/MDMDigitalContent/EUmNQ0pFsj5HoxlkRPAFOnYB_a2gkrISCUzmSS8VZ7OILw?e=oqMxhN">EPD og MS priser 2023</a></td>                        
                      </tr>                      
                    </tbody>
                  </Table>
              </Col>
            </Row>
        </Col>
      </Row>
    </div >
  );
}

export default Prismodeller;
