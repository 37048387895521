import { IconButton } from '@tradesolution/iceberg-ui-react';
import { useState, useEffect } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap';
import { MottakerViewModel } from 'services/AdminportalenApi/FakturaSpesifiseringApi/types';
import FakturaSpesifiseringApi from 'services/AdminportalenApi/FakturaSpesifiseringApi';
import { SendableFakturagrunnlagViewModel, SendSpesifiseringToTsKundeCommand } from 'services/AdminportalenApi/FakturaSpesifiseringApi/types';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';


interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  mottaker: MottakerViewModel;
}


const SendeSpesifikkKunde = (props: Props) => {
  const toaster = useToaster();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPeriode, setSelectedPeriode] = useState<SendableFakturagrunnlagViewModel | undefined>();
  const [periodeInfo, setPeriodeInfo] = useState<SendableFakturagrunnlagViewModel[] | undefined>();
  const loadFaktureringsperiode = async () => {
    setIsLoading(true);
    const response = await FakturaSpesifiseringApi.getSendableFakturagrunnlagByTsKunde(props.mottaker.tjenesteGruppeId, props.mottaker.tsKundeId);
    setPeriodeInfo(response);
    setIsLoading(false);
  };

  useEffect(() => {
    if (props.mottaker.tjenesteGruppeId && props.mottaker.tsKundeId && props.show) {
      loadFaktureringsperiode();
    }
  }, [props.mottaker.tjenesteGruppeId && props.mottaker.tsKundeId])

  const handleSubmit = async () => {
    const command: SendSpesifiseringToTsKundeCommand = {
      periode: selectedPeriode.periode,
      tjenesteGruppeId: props.mottaker.tjenesteGruppeId,
      eksportHodeId: selectedPeriode.eksportHodeId,
      tsKundeId: props.mottaker.tsKundeId,
      emails: props.mottaker.emails
    }


    try {
      await FakturaSpesifiseringApi.sendSpesifiseringToTsKunde(command);
      toaster.success('Suksess', 'Fakturaspesifisering er sendt');;
    }
    catch (err) {
      toaster.error('Noe gikk galt', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const handlePeriodeSelected = (periode: string) => {
    const selected = periodeInfo?.find(p => p.periode === periode);
    setSelectedPeriode(selected);
  };

  const handleClose = () => {
    props.setShow(false);
  }

  return (
    <>
      <Modal centered show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>{`Send ${props.mottaker.tjenesteGruppeNavn} fakturadetaljer til ${props.mottaker.tsKundeNavn}`}</Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Periode</Form.Label>
            <Form.Select value={selectedPeriode?.periode} onChange={e => handlePeriodeSelected(e.target.value)}>
              <option value=''>Velg periode</option>
              {periodeInfo?.map((periode, index) =>
                <option key={index} value={periode.periode}>{periode.displayPeriode}</option>)}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon='close'
            variant='secondary'
            className='col'
            onClick={handleClose}
          >
            Avbryt
          </IconButton>
          <IconButton
            variant='primary'
            disabled={!selectedPeriode}
            className='col'
            icon='send'
            onClick={handleSubmit} >
            {
              isLoading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            } Send
          </IconButton>

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendeSpesifikkKunde;