import { IconButton } from "@tradesolution/iceberg-ui-react";
import IcebergAlert from "components/IcebergAlert";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import FakturagrunnlagApi from "services/AdminportalenApi/FakturagrunnlagApi";
import SpinnerPlaceholder from 'images/spinner_placeholder.svg';
import { format } from "date-fns";
import CommonLoader from "components/CommonLoader";

export interface DeletePeriodeParams {
    periode: string;
    tjenesteGruppeId: string;
    invoiceCount: number;
    exportedDate?: Date;
}

interface Props {
    show: boolean;
    onModalClose: () => void;
    deletePeriodeParams: DeletePeriodeParams;
}

const DeletePeriodeModal = (props: Props) => {

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();
    const [force, setForce] = useState<boolean>(false);

    const deletePeriode = async () => {
        setIsDeleting(true);
        try {
            await FakturagrunnlagApi.deletePeriod(props.deletePeriodeParams.tjenesteGruppeId, props.deletePeriodeParams.periode, force);
            setSuccessMessage("Sletting av periode fullført!");
        } catch (e) {
            setErrorMessage(e);
        }

        setIsDeleting(false);
    };

    const deleteDisabled = isDeleting || (!!props.deletePeriodeParams?.exportedDate && !force);

    const spinnerPlaceholder = (<img style={{ height: "100%", width: "100%", margin: "auto", marginTop: "1rem", marginBottom: "1rem" }} src={SpinnerPlaceholder} />);

    const handleClose = () => {
        setErrorMessage(undefined);
        setSuccessMessage(undefined);
        setForce(false);
        props.onModalClose();
    };

    // no point in trying to render this modal if we don't have any data to show
    if (!props.deletePeriodeParams) return null;

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Slett periode</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Slett periode med {props.deletePeriodeParams.invoiceCount} fakturagrunnlag
                {isDeleting ? <div style={{ padding: "2rem" }}><CommonLoader color="#003b6e" /></div> : spinnerPlaceholder}
                {!!props.deletePeriodeParams.exportedDate && (
                    <Form.Group>
                        <Form.Check label={`Tidligere eksportert ${format(props.deletePeriodeParams.exportedDate, "dd.MM.yy")}. Marker for å slette`} checked={force} onChange={(e) => setForce(e.target.checked)} />
                    </Form.Group>)}
                {errorMessage && <IcebergAlert style={{ marginTop: "1rem" }} variant="danger" icon="alert">{errorMessage}</IcebergAlert>}
                {successMessage && <IcebergAlert style={{ marginTop: "1rem" }} variant="success" icon="info">{successMessage}</IcebergAlert>}
            </Modal.Body>
            <Modal.Footer>
                {!!successMessage && <IconButton icon="close" className="col" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>}
                {!successMessage && <>
                    <IconButton icon="close" className="col" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>
                    <IconButton disabled={deleteDisabled} icon="trash" className="col" variant="danger" onClick={deletePeriode}>Slett</IconButton>
                </>}
            </Modal.Footer>
        </Modal>
    );
};

export default DeletePeriodeModal;