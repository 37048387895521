import { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import TjenesteTypeahead from 'components/TjenesteTypeahead';
import DatePicker from 'components/DatePicker';
import AbonnementApi from 'services/AdminportalenApi/AbonnementApi';
import { CreateAbonnementCommand } from 'services/AdminportalenApi/AbonnementApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import Style from '../index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react'
import { FakturaAdresse } from 'services/AdminportalenApi/KundeKortApi/types';
import { TjenesteDto } from 'services/AdminportalenApi/TjenesterApi/types';

interface Props {
  tsKundeId: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onUpdated: () => void;
  faktureringsinformasjon: FakturaAdresse[];  
}

const CreateAbonnement = (props: Props) => {
  // const [priceAdjustmentType, setPriceAdjustmentType] = useState<string>();
  const [showFakturaInfoSelector, setShowFakturaInfoSelector] = useState<boolean>(false);

  const toaster = useToaster();

  const initialValues: CreateAbonnementCommand = {
    tsKundeId: props.tsKundeId,
    tjenesteId: '',
    validFromDate: new Date()
  }

  const validate = (values: CreateAbonnementCommand) => {
    let errors: any = {};
    if (values.tjenesteId === '') {
      errors.tjenesteId = 'Vennligst velg tjeneste';
    }
    return errors;
  }

  const onTjenesteSelected = (tjeneste: TjenesteDto) => {
    if(tjeneste){
      let needToConnectTjenesteGruppe = props.faktureringsinformasjon
    .every(x => x.tjenesteGruppeConnections.filter(y => y.selected === true).every(y => y.tjenesteGruppeId !== tjeneste.tjenesteGruppeId))
      setShowFakturaInfoSelector(needToConnectTjenesteGruppe);
    }
    else{
      setShowFakturaInfoSelector(false);
    }    
    handleChange('tjenesteId', tjeneste ? tjeneste.tjenesteId : '');
  }

  useEffect(() => {
    handleChange('tsKundeId', props.tsKundeId);
    // Sjekker om det bare er ett alternativ tilgjengelig
    if (props.faktureringsinformasjon.length === 1) {
      handleChange('fakturaAdresseIdToUse', props.faktureringsinformasjon[0].fakturaAdresseId);
    } else {
      handleChange('fakturaAdresseIdToUse', undefined); // Nullstill valgt hvis det ikke er bare ett alternativ tilgjengelig
    }
  }, [props.tsKundeId]);

  const onSubmit = async (command: CreateAbonnementCommand) => {
    if (!isNaN(parseFloat(command.overriddenPrice?.toString() || ""))) {
      command.overriddenPrice = Number(command.overriddenPrice);
    }
    else {
      command.overriddenPrice = null;
    }

    try {
      await AbonnementApi.createAbonnement(command);
      toaster.success('Suksess', 'Abonnement opprettet!');
      handleClose();
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => props.setShow(false);

  return (
    <Modal centered show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Abonnement</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Tjeneste</Form.Label>
            <TjenesteTypeahead
              onHandleChange={e => onTjenesteSelected(e)}
              selectedId={values.tjenesteId}
              isInvalid={errors?.tjenesteId && touched?.tjenesteId}
            />
            {errors?.tjenesteId && touched?.tjenesteId && <div className={Style.invalidFeedback}>{errors?.tjenesteId}</div>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Gyldig fra</Form.Label>
            <DatePicker
              onChange={date => handleChange('validFromDate', date)}
              selected={values.validFromDate} />
          </Form.Group>
          {showFakturaInfoSelector && 
            <Form.Group className="mb-3">
              <Form.Label>Knytt tjenestegruppe til fakturainfo (ellers så blir det ikke bonus)</Form.Label>
              <Form.Select 
                aria-label="Fakturainfo select" 
                onChange={e => handleChange('fakturaAdresseIdToUse', e.target.value)}                 
                style={{borderRadius: "24px"}}
                value={values.fakturaAdresseIdToUse} // Sett verdien til det valgte fakturaadresse-ID-et
                >                  
                <option value="">Velg fakturainfo å knytte til</option>
                {
                  props.faktureringsinformasjon.map(x => 
                    <option key={x.fakturaAdresseId} value={x.fakturaAdresseId}>{x.fakturaAdresseNavn} ({x.kundenummer ?? 'Mangler kundenummer'})</option>
                  )
                }
              </Form.Select>         
          </Form.Group>
          }          
        </Modal.Body>
        <Modal.Footer>
          <Button className="col" variant="outline-primary" onClick={handleClose}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button
            variant="primary"
            className="col"
            type="submit"
            disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}>
            <span>
              <IcebergIcon icon='disk' size='lg' /> Lagre
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              }
            </span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateAbonnement;