import React from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { ProdukteierGln } from 'services/AdminportalenApi/KundeKortApi/types';
import ProdukteierGlnApi from 'services/AdminportalenApi/ProdukteierGlnApi';
import { UpdateProdukteierGlnDescriptionCommand } from 'services/AdminportalenApi/ProdukteierGlnApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  gln: ProdukteierGln;
  onUpdated: () => void;
  onlyView: boolean;
}

const EditProdukteierGLN: React.FC<Props> = props => {
  const toaster = useToaster();

  const initialValue: UpdateProdukteierGlnDescriptionCommand = {
    produkteierGlnId: props.gln.produkteierGlnId,
    description: props.gln.glnNavn
  }

  const validate = (values: UpdateProdukteierGlnDescriptionCommand) => {
    let errors: any = {};
    return errors;
  }

  const onSubmit = async (command: UpdateProdukteierGlnDescriptionCommand) => {
    try {
      await ProdukteierGlnApi.updateProdukteierGlnDescription(command);
      toaster.success('Suksess', 'ProdukteierGLN oppdatert!');
      props.setShow(false)
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, touched, loading, handleChange, handleSubmit } = useForm(initialValue, validate, onSubmit)

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.setShow(false)}
      dialogClassName="modal-10w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">Produkteier GLN</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>Beskrivelse</Form.Label>
          <Form.Control placeholder='Legg inn en beskrivelse' value={values.description} onChange={e => handleChange('description', e.target.value)} readOnly={props.onlyView} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {
          props.onlyView ?
            <Button variant="primary" onClick={() => props.setShow(false)}>
              Lukk
            </Button> :
            <>
              <Button className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
                <IcebergIcon size='lg' icon='close' /> Avbryt
              </Button>
              <Button variant="primary" className="col" onClick={handleSubmit} disabled={loading || !touched.description}>
                <IcebergIcon icon='disk' size='lg' /> Lagre
                {
                  loading &&
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                }
              </Button>
            </>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default EditProdukteierGLN;