import React, { useState } from 'react';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';

import TsKundeTypeahead from 'components/TsKundeTypeahead';
import TenantSelect from './components/TenantSelect';
import ExternalAdGroupTypeahead from './components/ExternalAdGroupTypeahead';

import ExternalAdSourcesApi from 'services/AdminportalenApi/ExternalAzureAdSourcesApi';
import { CreateExternalAdSourceCommand } from 'services/AdminportalenApi/ExternalAzureAdSourcesApi/types';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  onUpdated: () => void;
}

const CreateExternalAdGroup: React.FC<Props> = props => {

  const toaster = useToaster();

  const [show, setShow] = useState<boolean>(false);

  const initialVals: CreateExternalAdSourceCommand = {
    name: '',
    description: '',
    tsKunde: null,
    externalGroup: null,
    tenant: null,
  };

  const validate = (values: CreateExternalAdSourceCommand) => {
    let errors: any = {};

    if (!values.name) {
      errors.name = 'Navn kan ikke være tomt';
    }

    if (!values.tsKunde) {
      errors.tsKunde = 'Velg en TsKunde';
    }

    if (!values.tenant) {
      errors.tenant = 'Velg en tenant';
    }

    if (!values.externalGroup) {
      errors.externalGroup = 'Velg en ekstern gruppe';
    }

    return errors;
  }

  const onSubmit = async (command: CreateExternalAdSourceCommand) => {
    try {
      await ExternalAdSourcesApi.createExternalSource(command)
      toaster.success('Suksess', 'Ekstern Ad gruppe oppdatert!');
      setShow(false);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit } = useForm(initialVals, validate, onSubmit);

  const handleClose = () => {
    setShow(false);
  }

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        <span style={{ paddingLeft: '.3em', }}>
          <IcebergIcon icon='plus' /> Ny gruppe
        </span>
      </Button>

      <Modal centered show={show} onHide={handleClose} dialogClassName={Style.modalContainer}>
        <Modal.Header closeButton>
          <Modal.Title>Opprett ekstern Ad gruppe</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>TsKunde</Form.Label>
                  <TsKundeTypeahead selectedTsKundeId={values.tsKunde?.tsKundeId} onHandleChange={e => handleChange('tsKunde', e)} />
                  {errors?.tsKunde && touched?.tsKunde && <div className={Style.invalidFeedback}>{errors?.tsKunde}</div>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Navn</Form.Label>
                  <Form.Control
                    value={values.name}
                    onChange={e => handleChange('name', e.target.value)}
                    isInvalid={touched?.name && errors?.name} />
                  <Form.Control.Feedback type='invalid'>
                    {errors?.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Tenant</Form.Label>
                  <TenantSelect selected={values.tenant} onChange={e => handleChange('tenant', e)} />
                  {errors?.tenant && touched?.tenant && <div className={Style.invalidFeedback}>{errors?.tenant}</div>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Gruppe</Form.Label>
                  <ExternalAdGroupTypeahead selectedId={values.externalGroup?.id ?? ''} tenantId={values.tenant?.tenantId ?? ''} onHandleChange={e => handleChange('externalGroup', e)} />
                  {errors?.externalGroup && touched?.externalGroup && <div className={Style.invalidFeedback}>{errors?.externalGroup}</div>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Beskrivelse</Form.Label>
                  <Form.Control value={values.description} onChange={e => handleChange('description', e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>

            <Button className="col" variant="outline-primary" onClick={() => setShow(false)}>
              <IcebergIcon size='lg' icon='close' /> Avbryt
            </Button>
            <Button
              variant="primary"
              className="col"
              disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
              type="submit">
              <IcebergIcon icon='disk' size='lg' /> Lagre
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              }
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default CreateExternalAdGroup;