
import './layout.scss';
import { createRoot } from 'react-dom/client';
import FontFaceObserver from 'fontfaceobserver';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import setLocale from 'utils/setLocale';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import HomePage from 'pages/Home';
import KundeKort from 'pages/TsKunder/KundeKort';
import KundeRapport from 'pages/TsKunder/KundeRapport';
import KundeSok from 'pages/TsKunder/KundeSok';
import TjenesteDetails from 'pages/Tjenester/TjenesteDetails';
import TjenesteSok from 'pages/Tjenester/TjenesteSok';
import Brukere from 'pages/Brukere';
import DesignCheck from 'pages/DesignCheck';
import Fakturafritak from 'pages/Fakturafritak';
import FakturaSpesifisering from 'pages/FakturaSpesifisering';
import MottakereTable from 'pages/FakturaSpesifisering/components/MottakereTable';
import Fakturagrunnlag from 'pages/Fakturagrunnlag';
import EksterneAdGrupper from 'pages/Tilgangsstyring/components/EksterneAdGrupper';
import TilgangerOgRoller from 'pages/Tilgangsstyring/components/TilgangerOgRoller';
import Rapporter from 'pages/Rapporter';
import NotFound from 'pages/NotFound';
import ToastProvider from 'components/Toaster/ToastProvider';
import Layout from 'Layout';
import Applikasjoner from 'pages/Applikasjoner';
import Prismodeller from 'pages/Prismodeller';
import Tilgangssøknader from 'pages/Tilgangsstyring/components/Tilgangssøknader';

LogRocket.init('msbcwv/adminportalen');

// after calling LogRocket.init()
setupLogRocketReact(LogRocket);

const openSansObserver = new FontFaceObserver('Open Sans', {});

setLocale();

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <MsalProvider instance={msalInstance}>
    <ToastProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<HomePage />} />

            <Route path="/ApiTilganger" element={<Applikasjoner />} />

            <Route path="/TsKunder/:tsKundeId/KundeKort" element={<KundeKort />} />
            <Route path="/TsKunder/:tsKundeId/KundeRapport" element={<KundeRapport />} />
            <Route path="/TsKunder" element={<KundeSok />} />

            <Route path="/Tjenester/:tjenesteId/Details" element={<TjenesteDetails />} />
            <Route path="/Tjenester" element={<TjenesteSok />} />

            <Route path="/Brukere" element={<Brukere />} />

            <Route path="/DesignCheck" element={<DesignCheck />} />

            <Route path="/Fakturafritak" element={<Fakturafritak />} />

            <Route path="/FakturaSpesifisering" element={<FakturaSpesifisering />} />
            <Route path="/FakturaSpesifisering/Mottakere" element={<MottakereTable />} />
            <Route path="/Prismodeller" element={<Prismodeller />} />

            <Route path="/Fakturagrunnlag" element={<Fakturagrunnlag />} />

            <Route path="/Tilgangsstyring/EksterneAdGrupper" element={<EksterneAdGrupper />} />
            <Route path="/Tilgangsstyring/TilgangerOgRoller" element={<TilgangerOgRoller />} />
            <Route path="/Tilgangsstyring/Tilgangssoknader" element={<Tilgangssøknader />} />

            <Route path="/Rapporter/:id" element={<Rapporter />} />

            <Route path="/NotFound" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ToastProvider>
  </MsalProvider>
);

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded');
  },
  () => {
    document.body.classList.remove('fontLoaded');
  },
);

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}