import axios from "axios";
import { getBaseUrl } from "..";
import { CreateFakturafritakCommand, FakturafritakDto } from "./types";



const baseUrl = getBaseUrl() + '/Fakturafritak';

const FakturafritakApi = {
  getAll: async (): Promise<FakturafritakDto[]> => {
    const response = await axios.get(`${baseUrl}/all`);
    return response.data;
  },


  createFakturafritak: async (command: CreateFakturafritakCommand): Promise<FakturafritakDto> => {
    const response = await axios.post(`${baseUrl}`, command);
    return response.data;
  },


  deleteFakturafritak: async (tjenesteId: string, tskundeId: string) => 
    await axios.delete(`${baseUrl}/${tjenesteId}/${tskundeId}`)   

}


export default FakturafritakApi;