import { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import ConfirmButton from 'components/ConfirmButton';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import { BrukerWithTilgangerDto } from 'services/AdminportalenApi/BrukereApi/types';

interface Props {
  onDelete?: () => Promise<any>;
  onEdit?: () => void;
  onCopyTilganger?: () => void;
  onToggle?: () => void;
  bruker?: BrukerWithTilgangerDto;
  confirmDelete?: boolean;
  customOptions?: CustomOption[];
  onlyView?: boolean;
  useIcons?: boolean;
}

interface CustomOption {
  title: string;
  type: 'view' | 'edit' | 'delete'
  action: () => void;
}

const PopoverMenu = (props: Props) => {
  const viewOptions: CustomOption[] = props.customOptions ? props.customOptions.filter(x => x.type === 'view') : []
  const editOptions: CustomOption[] = props.customOptions ? props.customOptions.filter(x => x.type === 'edit') : [];
  const deleteOptions: CustomOption[] = props.customOptions ? props.customOptions.filter(x => x.type === 'delete') : [];
  const [show, setShow] = useState(false);

  const handleShowDelete = () => {
    setShow(!show);
    props.onDelete();
  };

  return (
    <div >
      <OverlayTrigger trigger="click" key={'left'} placement={'left'} rootClose show={show} onToggle={setShow}
        overlay={
          <Popover id={`popover-positioned-${'left'}`}>
            {
              viewOptions.length > 0 &&
              viewOptions.map(viewOpt =>
                <Popover.Body key={viewOpt.title}>
                  <Button variant="outline-primary" style={{ width: '100%', border: '0px' }} onClick={viewOpt.action}>
                    {viewOpt.title}
                  </Button>
                </Popover.Body>
              )
            }
            {
              !props.onlyView ?
                <>
                  
                  {
                    props.onEdit ?
                      <Popover.Body onClick={() => setShow(!show)}>
                        <Button variant="outline-primary" style={{ width: '100%', border: '0px' }} onClick={props.onEdit}>
                          Endre
                        </Button>
                      </Popover.Body> :
                      null
                  }
                  {
                    editOptions.length > 0 &&
                    editOptions.map(editOpt =>
                      <Popover.Body key={editOpt.title} onClick={() => setShow(!show)}>
                        <Button variant="outline-primary" style={{ width: '100%', border: '0px' }} onClick={editOpt.action}>
                          {editOpt.title}
                        </Button>
                      </Popover.Body>
                    )
                  }
                  {
                    props.onCopyTilganger &&
                    <Popover.Body onClick={() => setShow(!show)}>
                      <Button variant="outline-primary" style={{ width: '100%', border: '0px' }} onClick={props.onCopyTilganger}>
                        Kopier tilganger
                      </Button>
                    </Popover.Body>
                  }

                </> :
                <Popover.Body>
                  <Button variant="outline-primary" style={{ width: '100%', border: '0px' }} onClick={props.onEdit}>
                    Vis
                  </Button>
                </Popover.Body>
            }
            {
              !props.onlyView &&
              <>
                {props.onToggle && (
                  <Popover.Body onClick={() => setShow(!show)}>
                    <IconButton
                      icon={props.bruker?.isEnabled ? 'ban' : 'redo'}
                      variant='outline-primary'
                      onClick={props.onToggle}
                      style={{ border: '0px' }}
                    >
                      {props.bruker?.isEnabled ? 'Deaktiver' : 'Reaktiver'}
                    </IconButton>
                  </Popover.Body>
                )}
                {
                  props.onDelete &&
                  <Popover.Body>
                    {
                      props.confirmDelete ?
                        (<ConfirmButton style={{ width: '100%', border: '0px' }} onClick={props.onDelete} variant="outline-primary">Fjern</ConfirmButton>) :
                        <Button variant="outline-primary" style={{ width: '100%', border: '0px' }} onClick={handleShowDelete}>
                          Fjern
                        </Button>
                    }
                  </Popover.Body>
                }
                {
                  deleteOptions.length > 0 &&
                  deleteOptions.map(deleteOpt =>
                    <Popover.Body key={deleteOpt.title}>
                      <Button variant="outline-primary" style={{ width: '100%', border: '0px' }} onClick={deleteOpt.action}>
                        {deleteOpt.title}
                      </Button>
                    </Popover.Body>
                  )
                }
              </>
            }
          </Popover>
        }>
        <button style={{ border: 'none', background: 'transparent', padding: 0 }}>
          <IcebergIcon icon="ellipsis" color='#68778D' />
        </button>
      </OverlayTrigger >
    </div >
  );
}
export default PopoverMenu;