import { IconButton } from "@tradesolution/iceberg-ui-react";
import IcebergAlert from "components/IcebergAlert";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import FakturagrunnlagApi from "services/AdminportalenApi/FakturagrunnlagApi";
import { SendTilPogoCommand } from "services/AdminportalenApi/FakturagrunnlagApi/types";
import SpinnerPlaceholder from 'images/spinner_placeholder.svg';
import { format } from "date-fns";
import CommonLoader from "components/CommonLoader";

export interface SendToPogoParams {
    periode: string;
    tjenesteGruppeId: string;
    invoiceCount: number;
    exportedDate?: Date;
}

interface Props {
    show: boolean;
    onModalClose: () => void;
    sendToPogoParams: SendToPogoParams;
}

const SendTilPogoModal = (props: Props) => {

    const [forceReSend, setForceReSend] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    const sendToPogo = async () => {
        setIsExporting(true);
        const command: SendTilPogoCommand = {
            periode: props.sendToPogoParams.periode,
            tjenesteGruppeId: props.sendToPogoParams.tjenesteGruppeId,
            forceReSend: forceReSend
        };
        try {
            const result = await FakturagrunnlagApi.sendTilPogo(command);
            if (!result.success) {
                setErrorMessage(result.message);
            } else {
                setSuccessMessage("Eksport til POGO fullført");
            }
        } catch (e) {
            setErrorMessage(e);
        }

        setIsExporting(false);
    };

    const sendDisabled = isExporting || (!!props.sendToPogoParams?.exportedDate && !forceReSend);

    const spinnerPlaceholder = (<img style={{ height: "100%", width: "100%", margin: "auto", marginTop: "1rem", marginBottom: "1rem" }} src={SpinnerPlaceholder} />);

    const handleClose = () => {
        setSuccessMessage(undefined);
        setErrorMessage(undefined);
        props.onModalClose();
    }

    const formatErrorMessage = (message: string) => {
        return (<ul>
            {message.split(";").map((m, i) => <li key={i}>{m}</li>)}
        </ul>);
    }

    // no point in trying to render this modal if we don't have any data to show
    if (!props.sendToPogoParams) return null;

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eksport til POGO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Eksporter {props.sendToPogoParams.invoiceCount} fakturagrunnlag til POGO
                {
                    isExporting ?
                        <div style={{ padding: "2rem" }}> <CommonLoader color="#003b6e" />Ta deg en kaffe. Dette kan ta litt tid. Last siden på nytt hvis ingenting har skjedd etter 90 sekunder</div>
                        :
                        spinnerPlaceholder
                }

                {!!props.sendToPogoParams.exportedDate && (
                    <Form.Group>
                        <Form.Check label={`Tidligere eksportert ${format(props.sendToPogoParams.exportedDate, "dd.MM.yy")}. Marker for å likevel sende på nytt`} checked={forceReSend} onChange={(e) => setForceReSend(e.target.checked)} />
                    </Form.Group>)}
                {errorMessage && <IcebergAlert style={{ marginTop: "1rem" }} variant="danger" icon="alert">{formatErrorMessage(errorMessage)}</IcebergAlert>}
                {successMessage && <IcebergAlert style={{ marginTop: "1rem" }} variant="success" icon="info">{successMessage}</IcebergAlert>}
            </Modal.Body>
            <Modal.Footer>
                {!!successMessage && <IconButton icon="close" className="col" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>}
                {!successMessage &&
                    <>
                        <IconButton icon="close" className="col" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>
                        <IconButton disabled={sendDisabled} icon="chevron-right" className="col" variant="primary" onClick={sendToPogo}>Send</IconButton>
                    </>}
            </Modal.Footer>
        </Modal>
    );
};

export default SendTilPogoModal;