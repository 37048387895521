
import { getBaseUrl } from "..";
import axios from "axios";
import { PermissionsChangeLogDto } from "./types";

const baseUrl = getBaseUrl() + '/PermissionsChangeLog';

const PermissionsChangeLogApi = {
    getPermissionsChangeLog: async (objectId: string): Promise<PermissionsChangeLogDto[]> => {
        const response = await axios.get(`${baseUrl}?objectId=${objectId}`);
        return response.data;
    }
};

export default PermissionsChangeLogApi;