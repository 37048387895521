import axios from 'axios';
import { KundeKortOverviewDto } from './types';
import { getBaseUrl } from '..';

const baseUrl = getBaseUrl();

const KundeKortApi = {

    getTsKundeById: async (tsKundeId: string) : Promise<KundeKortOverviewDto> => {
        const response = await axios.get(
            `${baseUrl}/TsKunder/${tsKundeId}/KundeKort`
        )
        return response.data;
    },

    postOmsetning: async (tsKundeId: string, omsetning: number) : Promise<boolean> => {
        const response = await axios.post(
            `${baseUrl}/TsKunder/${tsKundeId}/UpdateOmsetning`,
            {
                omsetning: omsetning
            }
        )
        return response.data;
    },

}

export default KundeKortApi;