import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

import BrukereApi from 'services/AdminportalenApi/BrukereApi';
import { RoleTypeDto } from 'services/AdminportalenApi/BrukereApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

const RolleTable: React.FC = () => {
  const isMountedRef = useIsMountedRef();

  const [roleTypes, setRoleTypes] = useState<RoleTypeDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadRoleTypes = async () => {
    setIsLoading(true);
    if(isMountedRef.current){
      const result = await BrukereApi.getRoleTypes();   
      setRoleTypes(prev => result);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    loadRoleTypes();
  }, [])

  if(roleTypes.length <= 0){
    if(isLoading){
      return (
        <div>
          <CommonLoader text="Henter roller..." color="#003b6e" />
        </div>
      )
    } else {
      <div>
        <h3>Ingen treff</h3>
      </div>
    }
  }

  return (
    <Table responsive style={{borderCollapse: 'collapse', borderTop: 'none'}} hover>
      <thead style={{color: '#68778D', fontWeight: 'normal'}}>
        <tr>
          <th>Navn</th>
          <th>Visningsnavn</th>
        </tr>
      </thead>
      <tbody className={Style.tableBody} style={{borderTop: 'none', border: '1px solid #DCDCDC'}}>
        {
          roleTypes.sort((a, b) => a.name.localeCompare(b.name)).map(roleType =>
            <tr key={roleType.name}>
              <td>{roleType.name}</td>
              <td>{roleType.displayName}</td>
            </tr>
          )
        }
      </tbody>
    </Table>
  )
}

export default RolleTable;