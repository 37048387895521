import { useState } from 'react';
import { Modal, Form, Row, Col, Button, Spinner, InputGroup, Alert } from 'react-bootstrap';

import BrukereApi from 'services/AdminportalenApi/BrukereApi';
import { BrukerWithTilgangerDto, ReinviteBrukerCommand } from 'services/AdminportalenApi/BrukereApi/types';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import cx from 'classnames';
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import EmailsApi from 'services/AdminportalenApi/EmailsApi';

interface Props {
  bruker: BrukerWithTilgangerDto;
  show: boolean;
  setShow: (value: boolean) => void;
  onUpdated: () => void;
}

const ReinviteBrukerModal = (props: Props) => {
  const toaster = useToaster();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [emailExists, setEmailExists] = useState<string>(null);
  const [onlyInAzure, setOnlyInAzure] = useState<boolean>(false);

  const initialValues: ReinviteBrukerCommand = {
    sendInvite: false
  }

  const validate = (values: ReinviteBrukerCommand) => {
    let errors: any = {};
    if (values.newEmail && values.newEmail.endsWith('@trades.no')) {
      errors.newEmail = 'Ny e-post kan ikke være en trades adresse';
    }
    if (values.newEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.newEmail)) {
      errors.newEmail = 'E-post har ugyldig format';
    }
    return errors;
  }
  const handleSearchEmail = async () => {
    if (!values.newEmail || errors.newEmail) return;
    setIsSearching(prev => true);
    setOnlyInAzure(false);
    const verifyResult = await EmailsApi.verify(values.newEmail);
    if (!verifyResult.canBeUsed && !values.newEmail.includes("@trades.no")) {
      setEmailExists('Det finnes ingen aktiv e-post konto med denne adressen. Har det sneket seg inn en skrivefeil? Kanskje noen har sluttet i jobben?');
      setIsSearching(prev => false);
      return;
    }
    const searchResults = await BrukereApi.checkStorageLocationStatus(values.newEmail);
    const indexForUserWithEmailInProxyAdresses = searchResults.findIndex(x =>
      x.proxyAdresses.some(function (item) { return item.includes(values.newEmail) }) &&
      x.email !== values.newEmail);
    if (searchResults.findIndex(x => x.email.toLowerCase() === values.newEmail.toLowerCase() && x.existsInDb === true) > -1) {
      setEmailExists('Bruker med denne e-posten finnes fra før');
    }
    else if (indexForUserWithEmailInProxyAdresses > -1) {
      setEmailExists('E-posten er allerede brukt på bruker med e-post ' + searchResults[indexForUserWithEmailInProxyAdresses]?.email);
    }
    else {
      setEmailExists(null);
    }

    const indexForAzureOnlyUser = searchResults.findIndex(x => x.email.toLowerCase() === values.newEmail.toLowerCase() && x.existsInDb === false);
    if (indexForAzureOnlyUser > -1) {
      setOnlyInAzure(true);
      values.newEmail = searchResults[indexForAzureOnlyUser]?.email;
    }
    setIsSearching(prev => false);
  };

  const onSubmit = async (command: ReinviteBrukerCommand) => {
    try {
      await BrukereApi.reinviteBruker(props.bruker.objectId, command);
      toaster.success('Suksess', 'Bruker reinvitert!');
      props.setShow(false);
      resetForm(undefined);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);
  return (
    <Modal centered show={props.show} onHide={() => props.setShow(false)} dialogClassName={Style.modalContainer}>
      <Modal.Header closeButton>
        <Modal.Title>Reinviter {props.bruker.givenName} {props.bruker.surname}? Id og tilganger bevares</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Klarer ikke bruker å logge inn lenger? Reinvitasjon gjennoppretter kobling mellom gjestebruker og eksterne brukere</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Skal det sendes ut ny invitasjon?</Form.Label>
                <Form.Check aria-label="option 1" label='Ja takk'
                  checked={values.sendInvite}
                  onChange={e => handleChange('sendInvite', e.target.checked)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Bytte e-post samtidig? </Form.Label>
                <InputGroup>
                  <Form.Control type="text" value={values.newEmail} onChange={e => handleChange('newEmail', e.target.value)} onBlur={handleSearchEmail} isInvalid={(errors?.newEmail || emailExists?.length>0) && touched?.newEmail} />
                  {isSearching
                    ? <InputGroup.Text className={cx(Style.spinnerContainer, { [Style.invalid]: (errors?.newEmail || emailExists?.length > 0) && touched?.newEmail })}>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </InputGroup.Text>
                    : <InputGroup.Text className={cx(Style.spinnerContainer, { [Style.invalid]: (errors?.newEmail || emailExists?.length > 0) && touched?.newEmail })}>
                      <IcebergIcon icon='email' />
                    </InputGroup.Text>}
                  <Form.Control.Feedback type="invalid">{emailExists?.length > 0 ? emailExists: errors?.newEmail}</Form.Control.Feedback>
                  {
                    onlyInAzure && 
                    <Row>
                      <Alert variant='info'>Utfylt e-post allerede finnes i Azure</Alert>
                    </Row>
                  }
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className='col' variant="outline-primary" onClick={() => props.setShow(false)}>
            <IcebergIcon icon='close' /> Avbryt
          </Button>
          <Button
            variant="primary"
            className='col'
            disabled={loading || Object.keys(errors).length > 0}
            type="submit">
            Reinviter
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ReinviteBrukerModal;