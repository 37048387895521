import { useEffect, useRef, useState } from "react";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import BrregApi from "services/AdminportalenApi/BrregApi";
import { EnhetDto } from "services/AdminportalenApi/BrregApi/types";
import { debounce } from "lodash";
import useIsMountedRef from "utils/hooks/useIsMountedRef";

interface Props {
  onBrregEnhetSelected: (item: EnhetDto) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  required?: boolean;
  clearTypeaheadInitiator?: string | number;
  selected?: { name: string; orgNr: string }[];
  size?: "sm" | "lg";
  setQueryUsed?: (q: string) => void;
}

const BrregTypeAhead = (props: Props) => {
  const [searchResult, setSearchResult] = useState<EnhetDto[]>();
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useIsMountedRef();
  const typeaheadRef = useRef(null);
  const formSize = props.size || null;

  const searchBrregByOrgnr = async (orgnr: string) => {
    setIsLoading(true);
    const result = await BrregApi.getByOrganisasjonsnummer(orgnr);
    if (result && result.length > 0) {
      const filtered = result.find(
        (x: EnhetDto) =>
          x.orgNr === props.selected[0]?.orgNr &&
          x.name === props.selected[0]?.name
      );
      if (filtered) {
        props.onBrregEnhetSelected(filtered);
      }
      setSearchResult(result);
      setIsLoading(false);
    }
  };

  const isNumeric = (value: string) => {
    return /^\d+$/.test(value);
  };

  const removeNullAndSpace = (value: string) => {
      return value ? value?.replace(/^null\s+/, "") : "";
  };

  const lookupQueryInBrreg = debounce(async (q: string) => {
    setIsLoading(true);
    if (q) {
      if (!isNumeric(q)) {
        const result = await BrregApi.search(q);
        if (result.length > 0) {
          setSearchResult(result);
          setIsLoading(false);
          const filtered = result.find(
            (x: any) => `${x.name.toUpperCase()} ${x.orgNr}` === q
          );
          if (filtered) {
            props.onBrregEnhetSelected(filtered);
          }
          return result;
        }
      } else {
        const result = await BrregApi.getByOrganisasjonsnummer(q);
        if (result && result.length > 0) {
          setSearchResult(result);
          setIsLoading(false);
          return result;
        }
      }
    }
    setSearchResult([]);
    setIsLoading(false);
    if (props.setQueryUsed) {
      props.setQueryUsed(q);
    }
  }, 300);

  const renderMenuItem = (option: any, props: any, index: number) => {
    
   const itemDesc = `${removeNullAndSpace(option?.name)} ${option.orgNr}`;
   
    return (
      <div key={index}>
        <Highlighter search={props.text}>{itemDesc}</Highlighter>
      </div>
    );
  };

  const clearTypeahead = () => {
    if (isMountedRef.current && typeaheadRef.current) {
      typeaheadRef.current.clear();
      typeaheadRef.current.setState({ text: "" });
    }
  };

  useEffect(() => {
    clearTypeahead();
  }, [props.clearTypeaheadInitiator]);

  useEffect(() => {
    if (
      isMountedRef.current &&
      props.selected &&
      props.selected[0]?.name &&
      props.selected[0]?.orgNr
    ) {
      searchBrregByOrgnr(props.selected[0].orgNr);
    }
  }, [props.selected]);

  return (
    <AsyncTypeahead
      inputProps={{ required: props.required || undefined }}
      id="brregTypeAhead"
      filterBy={() => true}
      labelKey={(option: any) => `${option.name ? option.name + " " : ""}${option.orgNr}`}
      renderMenuItemChildren={(option: any, props: any, index: number) =>
        renderMenuItem(option, props, index)
      }
      size={formSize}
      useCache={false}
      minLength={3}
      clearButton
      isLoading={isLoading}
      options={searchResult}
      searchText="Søker"
      placeholder="Søk i brønnøysundregistrene..."
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      maxResults={10}
      onChange={(e: EnhetDto[]) => props.onBrregEnhetSelected(e[0])}
      onSearch={q => lookupQueryInBrreg(q)}
      isInvalid={(!isLoading && props.isInvalid) || undefined}
      isValid={(!isLoading && props.isValid) || undefined}
      ref={typeaheadRef}
      selected={props.selected}
    />
  );
};

export default BrregTypeAhead;
