import React, { useState, useEffect } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { debounce } from 'lodash';

import ExternalAdSourcesApi from "services/AdminportalenApi/ExternalAzureAdSourcesApi";
import { GroupDto } from "services/AdminportalenApi/ExternalAzureAdSourcesApi/types";

interface Props {
  selectedId: string;
  tenantId: string;
  onHandleChange: (selected: GroupDto) => void;
}

const ExternalAdGroupTypeahead: React.FC<Props> = props => {
  const [query, setQuery] = useState<string>('');
  const [grupper, setGrupper] = useState<GroupDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedGrupper, setSelectedGrupper] = useState<GroupDto[]>([]);

  const onChangeHandler = (e: GroupDto[]) => {
    if (e.length === 0) {
      props.onHandleChange(undefined);
      setSelectedGrupper(undefined);
    } else {
      props.onHandleChange(e[0]);
      setSelectedGrupper(e);
    }
  };

  const searchGrupperer = async () => {
    setIsLoading(true);
    const result = await ExternalAdSourcesApi.searchExternalGroups(query, props.tenantId);
    setGrupper(result);
    setIsLoading(false);
  };

  useEffect(() => {
    if (query && query.length > 2) {
      searchGrupperer();
    }
  }, [query]);

  useEffect(() => {
    if (props.selectedId && grupper) {
      setSelectedGrupper(grupper.filter(x => x.id === props.selectedId));
    }
  }, [props.selectedId]);

  return (
    <AsyncTypeahead
      id="externalAdGroupTypeahead"
      clearButton
      labelKey="name"
      minLength={0}
      delay={100}
      isLoading={isLoading}
      options={grupper}
      searchText="Søker"
      placeholder="Søk eksterne grupper"
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      useCache={false}
      maxResults={8}
      onInputChange={debounce(e => setQuery(e), 300)}
      onChange={(e: any) => onChangeHandler(e)}
      onSearch={debounce(q => setQuery(q), 300)}
      selected={selectedGrupper}
      disabled={!props.tenantId} />
  )
}

export default ExternalAdGroupTypeahead;