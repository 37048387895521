import React, { useState } from "react"
import { Modal, Button, Spinner } from "react-bootstrap";
import ConfirmButton from "components/ConfirmButton";
import { KundekortPoNummer } from "services/AdminportalenApi/KundeKortApi/types";
import PONummerApi from 'services/AdminportalenApi/PONummerApi';
import useToaster from "utils/hooks/useToaster";
import Style from './index.module.css';
import HttpErrorHelper from "utils/HttpErrorHelper";
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  poNummer: KundekortPoNummer;
  onUpdated: () => void;
}

const DeletePoNummer: React.FC<Props> = props => {
  const toaster = useToaster();

  const [isRemovingRow, setIsRemovingRow] = useState<boolean>(false);

  const deletePONummer = async () => {
    try {
      await PONummerApi.deletePONummer(props.poNummer.ponummerId);
      toaster.success('Suksess', 'PONummer fjernet!')
      props.setShow(false);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err))
    }
  }

  const deletePONummerRow = async () => {
    try {
      setIsRemovingRow(true);
      await PONummerApi.deletePONummerRow(props.poNummer.ponummerId, props.poNummer.tjenesteGruppeId);
      toaster.success('Suksess', 'Kobling fjernet!')
      props.setShow(false);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err))
    }
    setIsRemovingRow(false);
  }

  return (
    <Modal centered show={props.show} onHide={() => props.setShow(false)} dialogClassName={Style.deleteModal} rootClose>
      <Modal.Body className={Style.deleteModalBody}>
        <h6>Vil du fjerne dette PO-nummeret?</h6>
        <p>Om du velger å fjerne, vil samtlige koblinger til tjenester også forsvinne.</p>
        <div style={{ marginTop: '1rem' }}>
          <Button variant="outline-primary" onClick={() => props.setShow(false)}>
            <IcebergIcon size='lg' icon='close' />  Avbryt
          </Button>
          <ConfirmButton onClick={deletePONummer}>
            Fjern PO-nummer
          </ConfirmButton>
          {props.poNummer.tjenesteGruppeId &&
            <ConfirmButton onClick={deletePONummerRow} disabled={isRemovingRow}>
              {`Fjern kobling til ${props.poNummer.tjenesteGruppeNavn}`}
            </ConfirmButton>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeletePoNummer;