import React, { useEffect } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { CreateProdukteierGlnCommand } from 'services/AdminportalenApi/ProdukteierGlnApi/types';
import ProdukteierGlnApi from 'services/AdminportalenApi/ProdukteierGlnApi';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import useForm from 'utils/hooks/useForm';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  tsKundeId: string;
  kundeNavn: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onUpdated: () => void;
}

const CreateProdukteierGLN = (props: Props) => {
  const toaster = useToaster();

  useEffect(() => {
    handleChange('tsKundeId', props.tsKundeId);
  }, [props.tsKundeId]);

  const initialValues: CreateProdukteierGlnCommand = {
    tsKundeId: props.tsKundeId,
    gln: null,
    glnNavn: '',
  }

  const validate = (values: CreateProdukteierGlnCommand) => {
    let errors: any = {};
    const glnString = values.gln ? values.gln.toString() : '';
    if (glnString.length < 13) errors.gln = 'For få siffer (13)';
    else if (glnString.length > 13) errors.gln = 'For mange siffer. (13)';    
    else if (glnString !== '' && !isNumeric(glnString)) errors.gln = 'Kun siffer i GLN (0..9).';
    return errors;
  }

  const isNumeric = (value: string) => {
    return /^\d+$/.test(value);
  }

  const onSubmit = async (values: CreateProdukteierGlnCommand) => {
    try {
      await ProdukteierGlnApi.createProdukteierGln(values);
      toaster.success('Suksess', 'ProdukteierGLN opprettet!');
      handleClose();
      resetForm(undefined);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, loading, touched, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => props.setShow(false);

  return (
    <Modal
      centered
      show={props.show}
      onHide={handleClose}
      dialogClassName="modal-10w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">Produkteier GLN</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>GLN</Form.Label>
            <Form.Control
              placeholder='Skriv inn GLN'
              value={values.gln}
              onChange={e => handleChange('gln', e.target.value)}
              isInvalid={touched?.gln && errors?.gln} />
           <Form.Control.Feedback type="invalid">
              {errors.gln} 
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Navn</Form.Label>
            <Form.Control placeholder='Skriv inn Navn' value={values.glnNavn} onChange={e => handleChange('glnNavn', e.target.value)} isInvalid={errors?.glnNavn} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="col" variant="outline-primary" onClick={handleClose}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button variant="primary" className="col" type="submit" disabled={loading || errors?.gln || Object.keys(touched).length === 0}>
            <IcebergIcon icon='disk' size='lg' /> Lagre
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateProdukteierGLN;