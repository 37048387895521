import { useEffect, useState } from 'react';
import { Modal, Form, Spinner, Table } from 'react-bootstrap';

import TilgangRow from './components/TilgangRow';

import BrukereApi from 'services/AdminportalenApi/BrukereApi';
import { BrukerWithTilgangerDto, UpdateBrukerTilgangerCommand } from 'services/AdminportalenApi/BrukereApi/types';

import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import PermissionsApi from 'services/AdminportalenApi/PermissionsApi';
import { Permission } from 'services/AdminportalenApi/PermissionsApi/types';
import IcebergAlert from 'components/IcebergAlert';

import { getAppIcon } from "components/IcebergIcon/utils";

interface Props {
  bruker: BrukerWithTilgangerDto;
  show: boolean;
  setShow: (value: boolean) => void;
  onUpdated: () => void;
}

interface NewPermission {
  role: string;
  accessTo: string;
  tsKundeId: string;
  objectId: string;
}

const EditBrukerTilganger = (props: Props) => {

  const [touched, setTouched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const command: UpdateBrukerTilgangerCommand = {
        brukerId: props.bruker.brukerId,
        tsKundeId: props.bruker.tsKundeId,
        tilgangCommands: [...addedPermissions, ...permissions]
      };
      setLoading(true);
      await BrukereApi.updateBrukerTilganger(command);
      setLoading(false);
      setSuccessMessage(`Tilganger oppdatert for ${props.bruker.givenName} ${props.bruker.surname}!`);
      loadTilganger(props.bruker.objectId);
      setAddedPermissions([]);
      setTouched(false);
      props.onUpdated();
    } catch (err) {
      setErrorMessage(err);
    }
  }

  const [addedPermissions, setAddedPermissions] = useState<NewPermission[]>([]);

  const handleDeleteExistingPermission = (index) => {
    setPermissions(permissions.filter((_, i) => i !== index));
    setTouched(true);
  }

  const handleDeleteNewPermission = (index) => {
    setAddedPermissions(addedPermissions.filter((_, i) => i !== index));
    setTouched(true);
  }

  const addPermission = () => {
    const emptyPermission: NewPermission = { role: '', accessTo: '', tsKundeId: props.bruker.tsKundeId, objectId: props.bruker.objectId };
    setAddedPermissions([...addedPermissions, emptyPermission]);
    setTouched(true);
  }

  const [permissions, setPermissions] = useState<Permission[]>([]);

  const loadTilganger = async (objectId: string) => {
    setLoading(true);
    const p = await PermissionsApi.getByObjectId(objectId);
    setLoading(false);
    setPermissions(p);
  };

  useEffect(() => {
    loadTilganger(props.bruker.objectId);
    setAddedPermissions([]);
    setTouched(false);
    setErrorMessage('');
    setSuccessMessage('');
  }, [props.bruker.objectId]);

  const handleUpdateAccessTo = (index: number, value: string) => {
    const newPermissions = [...addedPermissions];
    newPermissions[index].accessTo = value;
    setAddedPermissions(newPermissions);
  };

  const handleUpdateRole = (index: number, value: string) => {
    const newPermissions = [...addedPermissions];
    newPermissions[index].role = value;
    setAddedPermissions(newPermissions);
  };

  const handleUpdateTsKundeId = (index: number, value: string) => {
    const newPermissions = [...addedPermissions];
    newPermissions[index].tsKundeId = value;
    setAddedPermissions(newPermissions);
  };

  return (
    <Modal centered show={props.show} onHide={() => props.setShow(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Endre tilganger for {props.bruker.givenName} {props.bruker.surname}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={(e: any) => handleSubmit(e)}>
        <Modal.Body>

          <IconButton  style={{ float: 'right' }} icon='plus' variant='outline-primary' onClick={addPermission} />

          <Table borderless hover>
            <thead>
              <tr>
                <th>På vegne av TsKunde</th>
                <th>Tilgang til</th>
                <th>Rolle</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {permissions.map((permission, index) => {
                return <tr key={permission.id}>
                  <td>{permission.tsKundeNavn}</td>
                  <td><IcebergIcon icon={getAppIcon(permission.accessTo.toLowerCase())} />
                    <span className='ms-1'>{permission.accessToDisplayName}</span> </td>
                  <td>{permission.roleDisplayName}</td>
                  <td className='text-end p-0'>
                    <IconButton icon='trash' variant='outline-danger' onClick={() => handleDeleteExistingPermission(index)} />
                  </td>
                </tr>
              })}
              {addedPermissions.map((permission, index) => {
                return <TilgangRow
                  key={index}
                  permission={permission}
                  onUpdateAccessTo={(value) => handleUpdateAccessTo(index, value)}
                  onUpdateRole={(value) => handleUpdateRole(index, value)}
                  onUpdateTsKundeId={(value) => handleUpdateTsKundeId(index, value)}
                  onDeleteRow={() => handleDeleteNewPermission(index)}
                  selectedTsKundeId={permission.tsKundeId} />
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4}>
                  <IconButton icon='plus' variant='outline-primary' onClick={addPermission} />
                </td>
              </tr>
            </tfoot>
          </Table>
          {errorMessage && <IcebergAlert variant='danger'>{errorMessage}</IcebergAlert>}
          {successMessage && <IcebergAlert variant='success'>{successMessage}</IcebergAlert>}
        </Modal.Body>
        <Modal.Footer>

          <IconButton className='col' icon='close' variant="outline-primary" onClick={() => props.setShow(false)}>
            Avbryt
          </IconButton>

          <IconButton
            variant="primary"
            icon='disk'
            className='col'
            disabled={loading || !touched}
            type="submit">
            Lagre
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </IconButton>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

export default EditBrukerTilganger;