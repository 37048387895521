import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner, Badge } from 'react-bootstrap';
import TjenesegruppeSelect from 'components/TjenestegruppeSelect';
import KundeLoggApi from 'services/AdminportalenApi/KundeLoggApi';
import { CreateKundeLoggCommand } from 'services/AdminportalenApi/KundeLoggApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import IcebergBadge from 'components/IcebergBadge';
import AiApi from './SmartSummary/Api';

interface Props {
  tsKundeId: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onUpdated: () => void;
}

const getAIResponse = async (prompt: string) => {
  const response = await AiApi.summarize(prompt);
  return response;
}

const CreateLogg = (props: Props) => {
  const toaster = useToaster();

  //Smart summary 
  const [smartsummaryLoading, setsmartsummaryLoading] = useState<boolean>(false);

  const createAIPrompt = (text: string) => {
    return `
        Summarize the following text:

        Input Text: "${text}"
        
        Provide the summary in plain text, written in Norwegian.

        Focus on key details such as customer inquiries, important questions, or issues mentioned in the email.

        First, identify and list the main category or theme of the email, such as "Problemer med kontotilgang", "Spørsmål om fakturering", etc.

        Format the response as follows:

        Category: [Identified Category] [line break]

        Summary: [Provide the summary here, with a clear separation from the category.]

        if text contains contact information that has to be included in the summary
    `;
  };
  const handleSmartSummary = async () => {
    try {
      setsmartsummaryLoading(true); // Start loading

      const text = await navigator.clipboard.readText();
      console.log("Clipboard text: ", text); // Log clipboard text
      // Create AI prompt
      const aiPrompt = createAIPrompt(text);
      console.log("AI Prompt: ", aiPrompt);

      // Get AI response 
      const aiResponse = await getAIResponse(aiPrompt);
      console.log("AI Response: ", aiResponse);

      // Update state with summary
      if (typeof (aiResponse) === "string") {
        handleChange('message', aiResponse);
      } else {
        handleChange('message', "Failed to generate summary.")
      }
    } catch (err) {
      console.error('Failed to generate summary: ', err);
    } finally {
      setsmartsummaryLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    handleChange('tsKundeId', props.tsKundeId);
  }, [props.tsKundeId]);

  const initialValues: CreateKundeLoggCommand = {
    tsKundeId: props.tsKundeId,
    subject: '',
    message: '',
    incoming: false,
    tjenestegruppeId: null
  }

  const validate = (values: CreateKundeLoggCommand) => {
    let errors: any = {};
    if (values.tsKundeId === '') {
      errors.tsKundeId = 'Vennligst velg tjeneste';
    }
    return errors;
  }

  const onSubmit = async (command: CreateKundeLoggCommand) => {
    try {
      await KundeLoggApi.createKundeLogg(command);
      toaster.success('Suksess', 'Logg opprettet!');
      handleClose();
      resetForm(undefined);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => props.setShow(false);

  return (
    <Modal centered show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Logg</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {['radio'].map((type) => (
            <div key={`inline-${type}`} className="mb-3">
              <Form.Check
                inline
                label={<IcebergBadge variant='secondary'>Fra oss</IcebergBadge>}
                checked={values.incoming === true}
                onChange={() => handleChange('incoming', true)}
              />
              <Form.Check
                inline
                label={<IcebergBadge variant='primary'>Mottatt</IcebergBadge>}
                checked={values.incoming === false}
                onChange={() => handleChange('incoming', false)}
              />
            </div>
          ))}

          <Form.Group className="mb-3">
            <Form.Label>Emne</Form.Label>
            <Form.Control type="text" placeholder="Skriv inn emne" value={values.subject} onChange={e => handleChange('subject', e.target.value)} isInvalid={errors?.subject && touched?.subject} />
            <Form.Control.Feedback type="invalid">{errors?.subject}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Tjenestegruppe (valgfritt)</Form.Label>
            <TjenesegruppeSelect selected={values.tjenestegruppeId ?? ''} onChange={e => handleChange('tjenestegruppeId', e)} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Melding</Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Skriv inn beskrivelse" value={values.message} onChange={e => handleChange('message', e.target.value)} isInvalid={errors?.message && touched?.message} />
            <Form.Control.Feedback type="invalid">{errors?.message}</Form.Control.Feedback>
          </Form.Group>

          <Button variant='outline-primary' className='col' onClick={handleSmartSummary} disabled={smartsummaryLoading} title='Smart summary er en ny funksjonalitet som genererer et sammendrag av teksten du har i utklippstavlen.' 
          >  Smart summary</Button>

        </Modal.Body>
        <Modal.Footer>
          <Button className="col" variant="outline-primary" onClick={handleClose}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button variant="primary" className="col" type="submit" disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}>
            <IcebergIcon icon='disk' size='lg' /> Lagre
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateLogg;