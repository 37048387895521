import axios from "axios";
import { getBaseUrl } from "..";
import { CreateKundeLoggCommand, KundeLoggDto } from "./types";

const baseUrl = getBaseUrl() + "/KundeLogg";

const KundeLoggApi = {
   getKundeLogg: async (tsKundeId: string, top?: number): Promise<KundeLoggDto[]> => {
   const response = await axios.get(`${baseUrl}/${tsKundeId}`);
    return response.data;
}, 

  createKundeLogg: async (command: CreateKundeLoggCommand): Promise<KundeLoggDto> => {
    const response = await axios.post(baseUrl, command);
    return response.data;
  },

  deleteKundeLogg: (id: string) => 
    axios.delete(`${baseUrl}/${id}`),
}

export default KundeLoggApi;