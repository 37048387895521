import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

import BrukereApi from 'services/AdminportalenApi/BrukereApi';
import { AccessToTypeDto } from 'services/AdminportalenApi/BrukereApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

const TilgangerTable: React.FC = () => {
  const isMountedRef = useIsMountedRef();

  const [accessToTypes, setAccessToTypes] = useState<AccessToTypeDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadAccessToTypes = async () => {
    setIsLoading(true);
    if(isMountedRef.current){
      const result = await BrukereApi.getAccessToTypes();   
      setAccessToTypes(prev => result);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    loadAccessToTypes();
  }, [])

  if(accessToTypes.length <= 0){
    if(isLoading){
      return (
        <div>
          <CommonLoader text="Henter tilganger..." color="#003b6e" />
        </div>
      )
    } else {
      <div>
        <h3>Ingen treff</h3>
      </div>
    }
  }

  return (
    <Table responsive style={{borderCollapse: 'collapse', borderTop: 'none'}} hover>
      <thead style={{color: '#68778D', fontWeight: 'normal'}}>
        <tr>
          <th>Navn</th>
          <th>Visningsnavn</th>
          <th>Applikasjonsnavn</th>
        </tr>
      </thead>
      <tbody className={Style.tableBody} style={{borderTop: 'none', border: '1px solid #DCDCDC'}}>
        {
          accessToTypes.sort((a, b) => a.name.localeCompare(b.name)).map(accessToType =>
            <tr key={accessToType.name}>
              <td>{accessToType.name}</td>
              <td>{accessToType.displayName}</td>
              <td>{accessToType.applicationName}</td>
            </tr>
          )
        }
      </tbody>
    </Table>
  )
}

export default TilgangerTable;