export const getBaseUrl = () => {
  let baseUrl = 'https://adminportalenapi.tradesolution.no/api';
  if (window.location.host.indexOf('dev') > -1) 
    baseUrl = 'https://adminportalenapi-dev.tradesolution.no/api';

  if (window.location.host.indexOf('localhost') > -1) 
    //this.baseUrl = 'https://adminportalenapi-dev.tradesolution.no/api';
    baseUrl = 'https://localhost:7216/api';

  return baseUrl;
}