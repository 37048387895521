import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Modal } from 'react-bootstrap';
import TjenestegruppePicker from 'components/TjenestegruppePicker';
import TsKundeTypeahead from 'components/TsKundeTypeahead';

import Overview from './Overview';
import Faktureringsinformasjon from './Faktureringsinformasjon';
import KontaktPersoner from './Kontaktpersoner';
import ProdukteierGLN from './ProdukteierGLN';
import Abonnement from './Abonnement';
import POnummere from './POnummere';
import Logg from './Logg';
import CreateFaktureringsinfo from './Faktureringsinformasjon/components/CreateFaktureringsinfo';
import CreateKontaktperson from './Kontaktpersoner/components/CreateKontaktperson';
import CreateAbonnement from './Abonnement/components/CreateAbonnement';
import CreateProdukteierGLN from './ProdukteierGLN/components/CreateProdukteierGLN';
import CreatePONummer from './POnummere/components/CreatePONummer';
import CreateLogg from './Logg/components/CreateLogg';

import KundeKortApi from 'services/AdminportalenApi/KundeKortApi';
import { KundeKortOverviewDto } from 'services/AdminportalenApi/KundeKortApi/types';
import { TsKundeSearchDto } from "services/AdminportalenApi/TsKunderApi/types";

import useLocalStorage from 'utils/hooks/useLocalStorage';

import Style from './index.module.scss';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import Avtaler from './Avtaler';
import CreateAvtale from './Avtaler/components/CreateAvtale';
import SearchWord from './SearchWords';
import CreateSearchWord from './SearchWords/components/CreateSearchWord';
import CommonLoader from 'components/CommonLoader';

const KundeKort = () => {
  const params = useParams<{ tsKundeId: string }>();

  const [kunde, setKunde] = useState<KundeKortOverviewDto | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCreateAvtale, setShowCreateAvtale] = useState<boolean>(false);
  const [showCreateFakturainfo, setShowCreateFakturainfo] = useState<boolean>(false);
  const [showCreateKontaktperson, setShowCreateKontaktperson] = useState<boolean>(false);
  const [showCreateProdGLN, setShowCreateProdGLN] = useState<boolean>(false);
  const [showCreateAbonnement, setShowCreateAbonnment] = useState<boolean>(false);
  const [showCreatePO, setShowCreatePO] = useState<boolean>(false);
  const [showCreateLogg, setShowCreateLogg] = useState<boolean>(false);
  const [showSearchWord, setShowSearchWord] = useState<boolean>(false);

  const navigate = useNavigate();

  const [filteredTjenestegrupper, setFilteredTjenestegrupper] = useLocalStorage<string[]>('filteredTjenestegrupper', []);
  const [showTsKundeSwitcherModal, setShowTsKundeSwitcherModal] = useState(false);

  const loadKunde = async () => {
    setIsLoading(true);
    const e = await KundeKortApi.getTsKundeById(params.tsKundeId);
    if (!e) {
      navigate("/NotFound");
    }
    setKunde(e);
    setIsLoading(false);
  };

  const updateOmsetning = async () => {
    setIsLoading(true);
    await KundeKortApi.postOmsetning(kunde.tsKundeId, kunde.omsetning);
    await loadKunde();
    setIsLoading(false);
  }

  useEffect(() => {
    loadKunde();
  }, [params.tsKundeId]);

  const handleTsKundeChanged = (e: TsKundeSearchDto) => {
    navigate(`/TsKunder/${e.tsKundeId}/kundeKort`)
    setShowTsKundeSwitcherModal(false)
  }


  if (!kunde) {
    return <div>
      <CommonLoader color="#003b6e" />
    </div>;
  }

  const isNedlagt = kunde.nedlagtDato !== null;

  return (
    <>
      <div className={Style.kundeKort}>
        {
          <>
            <Overview kundeOverview={kunde} onUpdate={loadKunde} onUpdateOmsetning={updateOmsetning} loading={isLoading} />
            <br></br>
            <div className={Style.createButtons}>
              <Button variant="outline-primary" onClick={() => setShowCreateFakturainfo(true)} disabled={isNedlagt}>
                <IcebergIcon icon='plus' /> Fakturainfo
              </Button>
              <Button variant="outline-primary" onClick={() => setShowCreateAbonnment(true)} disabled={isNedlagt}>
                <IcebergIcon icon='plus' />  Abonnement
              </Button>
              <Button variant="outline-primary" onClick={() => setShowCreateKontaktperson(true)} disabled={isNedlagt}>
                <IcebergIcon icon='plus' />  Kontaktperson
              </Button>
              <Button variant="outline-primary" onClick={() => setShowCreateAvtale(true)} disabled={isNedlagt}>
                <IcebergIcon icon='plus' /> Avtale
              </Button>
              <Button variant="outline-primary" onClick={() => setShowCreateProdGLN(true)} disabled={isNedlagt}>
                <IcebergIcon icon='plus' />  Produkteier GLN
              </Button>
              <Button variant="outline-primary" onClick={() => setShowCreatePO(true)} disabled={isNedlagt}>
                <IcebergIcon icon='plus' />  PO-nummer
              </Button>
              <Button variant="outline-primary" onClick={() => setShowCreateLogg(true)} disabled={isNedlagt}>
                <IcebergIcon icon='plus' />  Logg
              </Button>
              {/* <div className={Style.filterContainer}>
                <span style={{ marginTop: '10px', marginLeft: '10px' }} onClick={() => setShowTsKundeSwitcherModal(true)}>
                  <IcebergIcon icon='search' cursor='pointer' />
                </span>
                <Modal
                  centered
                  show={showTsKundeSwitcherModal}
                  onHide={() => setShowTsKundeSwitcherModal(false)}>
                  <Modal.Header closeButton>
                    <TsKundeTypeahead onHandleChange={handleTsKundeChanged} />
                  </Modal.Header>
                </Modal>
                <TjenestegruppePicker className={Style.picker} selected={filteredTjenestegrupper} onSelectionChanged={e => setFilteredTjenestegrupper(e.map(x => x.tjenesteGruppeId))} mode='multiSelect' />
              </div> */}
            </div>
            <br></br>
            {kunde.fakturaAdresser.length > 0 ?
              <Faktureringsinformasjon faktureringsinformasjon={kunde.fakturaAdresser} onUpdated={() => loadKunde()} tsKundeId={kunde.tsKundeId} isNedlagt={isNedlagt} filteredTjenestegrupper={filteredTjenestegrupper} orgNr={kunde.organisasjonsnummer} /> :
              <Card className={Style.missingDataCard}>
                <Card.Body className={Style.cardBody}>
                  <Button variant='link' onClick={() => setShowCreateFakturainfo(true)}>Opprett fakturainfo</Button>
                </Card.Body>
              </Card>}

            {kunde.abonnementer.length > 0 ?
              <Abonnement abonnementer={kunde.abonnementer} tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} isNedlagt={isNedlagt} filteredTjenestegrupper={filteredTjenestegrupper} faktureringsinformasjon={kunde.fakturaAdresser} /> :
              <Card className={Style.missingDataCard}>
                <Card.Body className={Style.cardBody}>
                  <Button variant='link' onClick={() => setShowCreateAbonnment(true)}>Opprett abonnement</Button>
                </Card.Body>
              </Card>}

            {kunde.kontaktpersoner.length > 0 ?
              <KontaktPersoner kontaktpersoner={kunde.kontaktpersoner} onUpdated={() => loadKunde()} tsKundeId={kunde.tsKundeId} varsler={kunde.varsler?.kontaktperson} isNedlagt={isNedlagt} filteredTjenestegrupper={filteredTjenestegrupper} /> :
              <Card className={Style.missingDataCard}>
                <Card.Body className={Style.cardBody}>
                  <Button variant='link' onClick={() => setShowCreateKontaktperson(true)}>Opprett kontaktperson</Button>
                </Card.Body>
              </Card>}

            {kunde.avtaler.length > 0 ?
              <Avtaler avtaler={kunde.avtaler} onUpdated={() => loadKunde()} tsKundeId={kunde.tsKundeId} isNedlagt={isNedlagt} filteredTjenestegrupper={filteredTjenestegrupper} /> :
              <Card className={Style.missingDataCard}>
                <Card.Body className={Style.cardBody}>
                  <Button variant='link' onClick={() => setShowCreateAvtale(true)}>Opprett avtale</Button>
                </Card.Body>
              </Card>}

            {kunde.produkteierGln.length > 0 ?
              <ProdukteierGLN produkteiergln={kunde.produkteierGln} tsKundeId={kunde.tsKundeId} kundeNavn={kunde.tsKundeNavn} onUpdated={() => loadKunde()} isNedlagt={isNedlagt} /> :
              <Card className={Style.missingDataCard}>
                <Card.Body className={Style.cardBody}>
                  <Button variant='link' onClick={() => setShowCreateProdGLN(true)}>Opprett produkteier GLN</Button>
                </Card.Body>
              </Card>}

            {kunde.poNummer.length > 0 ?
              <POnummere tsKundeId={kunde.tsKundeId} poNummer={kunde.poNummer} onUpdated={() => loadKunde()} isNedlagt={isNedlagt} filteredTjenestegrupper={filteredTjenestegrupper} /> :
              <Card className={Style.missingDataCard}>
                <Card.Body className={Style.cardBody}>
                  <Button variant='link' onClick={() => setShowCreatePO(true)}>Opprett PO-nummer</Button>
                </Card.Body>
              </Card>}

            {kunde.kundeLogger.length > 0 ?
              <Logg kundeLogger={kunde.kundeLogger} tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} isNedlagt={isNedlagt} filteredTjenestegrupper={filteredTjenestegrupper} /> :
              <Card className={Style.missingDataCard}>
                <Card.Body className={Style.cardBody}>
                  <Button variant='link' onClick={() => setShowCreateLogg(true)}>Opprett logg</Button>
                </Card.Body>
              </Card>}
            {kunde.searchWords.length > 0 ?
              <SearchWord searchWords={kunde.searchWords} tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} isNedlagt={isNedlagt} /> :
              <Card className={Style.missingDataCard}>
                <Card.Body className={Style.cardBody}>
                  <Button variant='link' onClick={() => setShowSearchWord(true)}>Opprett søkeord</Button>
                </Card.Body>
              </Card>}
          </>
        }
      </div>
      <CreateAvtale tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} show={showCreateAvtale} setShow={setShowCreateAvtale} />
      <CreateFaktureringsinfo tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} show={showCreateFakturainfo} setShow={setShowCreateFakturainfo} orgNr={kunde.organisasjonsnummer} />
      <CreateProdukteierGLN tsKundeId={kunde.tsKundeId} kundeNavn={kunde.tsKundeNavn} onUpdated={() => loadKunde()} show={showCreateProdGLN} setShow={setShowCreateProdGLN} />
      <CreatePONummer tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} show={showCreatePO} setShow={setShowCreatePO} />
      <CreateAbonnement tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} show={showCreateAbonnement} setShow={setShowCreateAbonnment} faktureringsinformasjon={kunde.fakturaAdresser} />
      <CreateKontaktperson tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} show={showCreateKontaktperson} setShow={setShowCreateKontaktperson} />
      <CreateLogg tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} show={showCreateLogg} setShow={setShowCreateLogg} />
      <CreateSearchWord tsKundeId={kunde.tsKundeId} onUpdated={() => loadKunde()} show={showSearchWord} setShow={setShowSearchWord} />
    </>
  );
};

export default KundeKort;