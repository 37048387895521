import React from "react";
import { Modal, Tab, Nav, Row, Col, Table } from "react-bootstrap";

import { ExternalAzureAdSourceDto } from "services/AdminportalenApi/ExternalAzureAdSourcesApi/types";

import Style from './index.module.css';

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  externalGroup: ExternalAzureAdSourceDto;
}

const DetailsModal: React.FC<Props> = props => {
  return (
    <Modal 
      show={props.show}
      onHide={() => props.setShow(false)} 
      dialogClassName={Style.modalContainer}
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">{props.externalGroup.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={Style.modalBody}>
        <Row>
          <Col>
            <span className={Style.headerSpan}>Ekstern gruppe</span>
            <span className={Style.valueSpan}>{props.externalGroup.groupName}</span>
          </Col>
          <Col>
            <span className={Style.headerSpan}>Tenant</span>
            <span className={Style.valueSpan}>{props.externalGroup.tenantName}</span>
          </Col>
        </Row>
        {
          props.externalGroup.description &&
          <Row>
            <Col>
              <span className={Style.headerSpan}>Beskrivelse</span>
              <span className={Style.valueSpan}>{props.externalGroup.description}</span>
            </Col>
          </Row>
        }
        <br />
        <Tab.Container
          defaultActiveKey="tilganger">
          <Row>
            <Col>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="tilganger">Tilganger</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="medlemmer">Medlemmer</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="tilganger">
                  <div className={Style.tabPane}>
                    <h6>Medlemmer får følgende tilganger på vegne av {props.externalGroup.tsKundeName}:</h6>
                    <Table responsive>
                      <thead>
                        <th>Tilgang til</th>
                        <th>Rolle</th>
                      </thead>
                      <tbody>
                        {
                          props.externalGroup.permissions?.length > 0 &&
                          props.externalGroup.permissions.map(permission => 
                            <tr key={permission.accessTo}>
                              <td>{permission.accessTo}</td>
                              <td>{permission.role}</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="medlemmer">
                <div className={Style.tabPane}>
                  <Table responsive>
                    <tbody>
                      {
                        props.externalGroup.membersOnLastCheck?.length > 0 &&
                        props.externalGroup.membersOnLastCheck.map(member =>
                          <tr key={member.objectId}>
                            <td>{member.email}</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </Table>
                </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  )
}

export default DetailsModal;