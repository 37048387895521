import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import React from "react";
import { Popover, Row, Col } from "react-bootstrap";

import Style from './index.module.css';

const WarningPopover = (warning: string) => {

  return (
    <Popover id="popover-menu" className={Style.popover}>
      <Popover.Body>
        <Row>
          <Col md={3} className={Style.iconCol}>
            <IcebergIcon icon='alert' size='50px' />
          </Col>
          <Col className={Style.warningCol}>
            <p>{warning}</p>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  )
}

export default WarningPopover;