import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { TjenesteGruppeConnection2 } from "services/AdminportalenApi/KundeKortApi/types";
import { getTjenestegruppeIcon } from "components/IcebergIcon/utils";
import Style from './index.module.css';
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import IcebergBadge from "components/IcebergBadge";

interface Props {
  connections: TjenesteGruppeConnection2[];
}

const TjenestegruppeConnectionsViewer = (props: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const primaryContacts = props.connections.filter(x => x.isPrimaryContact);
  const fakturaContacts = props.connections.filter(x => x.isFakturaContact);
  const otherContacts = props.connections.filter(x => x.isOtherContact);
  const totalContacts = props.connections.filter(x => x.isPrimaryContact || x.isFakturaContact || x.isOtherContact);

  return (
    <div>
      {
        expanded ?
          totalContacts.map((contact, index) => {
            if (contact.isPrimaryContact && contact.isFakturaContact) {
              return <React.Fragment key={contact.tjenesteGruppeId + '-both'}>
                <Row>
                  <Col>
                    <IcebergBadge variant="success"><IcebergIcon icon="phone" /> {' '}Hovedkontakt</IcebergBadge>
                  </Col>
                  <Col className={Style.tdTgNavn}><IcebergIcon icon={getTjenestegruppeIcon(contact.tjenesteGruppeNavn)} /> {contact.tjenesteGruppeNavn}</Col>
                  <Col className={Style.tdButton}>
                    {
                      index === 0 &&
                      <button className={Style.expandButton} onClick={() => setExpanded(false)}>
                        <IcebergIcon icon='chevron-up' />
                      </button>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <IcebergBadge>
                      <IcebergIcon icon="bill" /> {' '}Fakturakontakt
                    </IcebergBadge>
                  </Col>
                  <Col className={Style.tdTgNavn}>
                    <IcebergIcon icon={getTjenestegruppeIcon(contact.tjenesteGruppeNavn)} /> {contact.tjenesteGruppeNavn}
                  </Col>
                  <Col className={Style.tdButton}></Col>
                </Row>
              </React.Fragment>
            } else {
              return <Row key={contact.tjenesteGruppeId + '-primary'}>
                <Col>
                  {contact.isPrimaryContact ?
                    <IcebergBadge variant="success"><IcebergIcon icon="phone" />Hovedkontakt</IcebergBadge> :
                    (contact.isFakturaContact ? <IcebergBadge><IcebergIcon icon="bill" /> {' '}Fakturakontakt</IcebergBadge> :
                      <IcebergBadge variant='light'><IcebergIcon icon="profile" />{' '}Annen kontakt</IcebergBadge>)}
                </Col>
                <Col className={Style.tdTgNavn}>
                  <IcebergIcon icon={getTjenestegruppeIcon(contact.tjenesteGruppeNavn)} /> {contact.tjenesteGruppeNavn}
                </Col>
                <Col className={Style.tdButton}>
                  {
                    index === 0 &&
                    <button className={Style.expandButton} onClick={() => setExpanded(false)}>
                      <IcebergIcon icon='chevron-up' />
                    </button>
                  }
                </Col>
              </Row>
            }
          }
          ) :
          <Row>
            <Col>
              {primaryContacts.length > 0 && <>{primaryContacts.length} <IcebergIcon icon="phone" /></>} {' '}
              {fakturaContacts.length > 0 && <>{fakturaContacts.length} <IcebergIcon icon="bill" /></>} {' '}
              {otherContacts.length > 0 && <>{otherContacts.length} <IcebergIcon icon="profile" /></>}
            </Col>
            <Col className={Style.tdTotalContacts}>
              {
                totalContacts.length > 0 ?
                  <span className={Style.totalContacts}>
                    {totalContacts.map(x => <IcebergIcon key={x.tjenesteGruppeId} icon={getTjenestegruppeIcon(x.tjenesteGruppeNavn)} />)}
                  </span> :
                  <></>
              }
            </Col>
            {
              totalContacts.length > 0 &&
              <Col className={Style.tdButton}>
                <button className={Style.expandButton} onClick={() => setExpanded(true)}>
                  <IcebergIcon icon='chevron-down' />
                </button>
              </Col>
            }
          </Row>
      }

    </div>
  )
}

export default TjenestegruppeConnectionsViewer;