import React from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap';
import TjenesterApi from 'services/AdminportalenApi/TjenesterApi';
import { CreateTjenesteCommand } from 'services/AdminportalenApi/TjenesterApi/types';
import TjenesegruppeSelect from 'components/TjenestegruppeSelect';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import { IconButton } from '@tradesolution/iceberg-ui-react';

interface Props {

  onUpdated: (id: string) => void;
  show: boolean;
  setShow: (show: boolean) => void;
}

const CreateTjeneste = (props: Props) => {
  const toaster = useToaster();
  const initialValues: CreateTjenesteCommand = {
    tjenesteGruppeId: '',
    tjenesteNavn: '',
    salgskonto: 0,
    avdelingsnummer: null,
  };

  const validate = (values: CreateTjenesteCommand) => {
    let errors: any = {};
    if (values.tjenesteNavn === '' || values.tjenesteNavn === null)
      errors.tjenesteNavn = 'Tjenestenavn kan ikke være tomt';

    if (values.tjenesteGruppeId === '' || values.tjenesteGruppeId === null) {
      errors.tjenesteGruppeId = 'Vennligst velg en tjenestegruppe';
    }
    if (values.salgskonto === 0 || values.salgskonto === null) {
      errors.salgskonto = 'salgskonto kan ikke være 0. en salgskonto må være 4 siffer';
    }
    return errors;
  };

  const onSubmit = async (commmand: CreateTjenesteCommand) => {
    try {
      var tjeneste = await TjenesterApi.createTjeneste(commmand);
      toaster.success('Suksess', 'Tjeneste opprettet!');
      handleClose();
      resetForm(undefined);
      props.onUpdated(tjeneste.tjenesteId);
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => {
    props.setShow(false);
  };

  return (
    <>
      <IconButton
        icon="plus"
        variant="primary"
        onClick={() => props.setShow(true)}
      >
        Tjeneste
      </IconButton>

      <Modal centered show={props.show} onHide={handleClose}>
        <Modal.Header closeButton> <Modal.Title>Opprett ny tjeneste</Modal.Title> </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>

            <Form.Group className='mb-3'>
              <Form.Label>Tjeneste navn</Form.Label>
              <Form.Control
                value={values.tjenesteNavn}
                onChange={e => handleChange('tjenesteNavn', e.target.value)}
                isInvalid={touched?.tjenesteNavn && errors?.tjenesteNavn}
                type='text'
              />
              <Form.Control.Feedback type="invalid">{errors?.tjenesteNavn}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Velg en tjenestegruppe</Form.Label>
              <TjenesegruppeSelect
                selected={values.tjenesteGruppeId} onChange={e => handleChange('tjenesteGruppeId', e)} />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Salgskonto</Form.Label>
              <Form.Control
                value={values.salgskonto}
                onChange={e => handleChange('salgskonto', e.target.value)}
                isInvalid={touched?.salgskonto && errors?.salgskonto}
                type='number'
                placeholder="Salgskonto må være et 4 siffer"
              />
              <Form.Control.Feedback type='invalid'>{errors?.salgskonto} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Avdelingsnummer</Form.Label>
              <Form.Control
                value={values.avdelingsnummer}
                onChange={e => handleChange('avdelingsnummer', e.target.value)}
                type='number'
              />
            </Form.Group>


          </Modal.Body>

          <Modal.Footer>
            <IconButton
              icon="close"
              variant="outline-primary"
              className='col'
              onClick={handleClose}
            >
              Avbryt
            </IconButton>

            <IconButton
              variant='primary'
              className='col'
              type='submit'
              icon='disk'
              disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
            >
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              } Lagre
            </IconButton>

          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTjeneste;