import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import TsKundeTypeahead from 'components/TsKundeTypeahead';
import { useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';
import AdApplicationsApi from 'services/AdminportalenApi/AdApplicationsApi';
import { AdApplication } from 'services/AdminportalenApi/AdApplicationsApi/types';
import { TsKundeSearchDto } from 'services/AdminportalenApi/TsKunderApi/types';
import CreateModal from './CreateModal';
import EditModal from './EditModal';
import TestModal from './TestModal';
import TilgangerIcons from './TilgangerIcons';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import useDebounce from 'utils/hooks/useDebounce';
import IcebergBadge from 'components/IcebergBadge';
import StatusModal from './StatusModal';
import CommonLoader from 'components/CommonLoader';

const AdApplications = () => {

    const [applications, setApplications] = useState<AdApplication[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    const [selectedApp, setSelectedApp] = useState<AdApplication | undefined>();
    const [selectedTsKunde, setSelectedTsKunde] = useState<TsKundeSearchDto | undefined>();

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showTestModal, setShowTestModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(false);
    const isMountedRef = useIsMountedRef();
    const [show, setShow] = useState(undefined);

    const [filter, setFilter] = useState({} as any);

    const loadApplications = async (p: number) => {
        setIsLoading(true);
        var tc = await AdApplicationsApi.getApplicationsCount(filter);
        const result = await AdApplicationsApi.get(filter, p);
        setIsLoading(false);
        setTotalCount(tc);
        if (p > 1) {
            setApplications(prev => [...prev, ...result]);
        } else {
            setApplications(result);
        }
    };

    useEffect(() => {
        if (page > 1) {
            loadApplications(page);
        }
    }, [page]);

    const handleLoadMore = () => {
        if (!isLoading && applications.length < totalCount && isMountedRef.current) {
            setIsLoading(true);
            setPage(prev => prev + 1);
        }
    };

    useEffect(() => {
        loadApplications(1);
        setPage(1);
    }, [filter]);


    useEffect(() => {
        setFilter({ tsKundeId: selectedTsKunde?.tsKundeId || '' });
    }, [selectedTsKunde]);

    const [queryText, setQueryText] = useState<string>('');

    // send a query to the api max every 300ms
    useDebounce(() => setFilter({ ...filter, query: queryText }), 300, [queryText]);

    const handleTestAppClicked = (app: AdApplication) => {
        setShow(undefined);
        setShowTestModal(true);
        setSelectedApp(app);
    };

    const handleEditAppClicked = (app: AdApplication) => {
        setShowEditModal(true);
        setSelectedApp(app);
    };

    const handleStatusAppClicked = (app: AdApplication) => {
        setShow(undefined);
        setShowStatusModal(true);
        setSelectedApp(app);
    };

    const handleCloseStatusModal = () => {
        setShow(undefined);
        setSelectedApp(undefined);
        setShowStatusModal(false);
        loadApplications(1);
    }

    const handleCloseTestModal = () => {
        setSelectedApp(undefined);
        setShowTestModal(false);
    }

    const handleCloseEditModal = () => {
        setSelectedApp(undefined);
        setShowEditModal(false);
        loadApplications(1);
    }

    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
        loadApplications(1);
    };

    const toogleOverlayMenu = (app?: AdApplication, nextShow?: boolean) => {
        if (nextShow === true)
            setShow(app.applicationId);
        if (nextShow === false && show === app.applicationId)
            setShow(undefined);
    };

    return (
        <div className='container-fluid'>
            <Row style={{ marginTop: "0.5rem" }}>
                <Col className="mb-3 mt-3">
                    <h2 className='fw-bold' style={{ fontSize: '20px', textAlign: 'left' }}> API tilganger</h2>
                </Col>
                <Col className='mb-3 mt-3'>
                    <IconButton className='float-end' variant='primary' icon="plus" onClick={() => setShowCreateModal(true)}>
                        Registrer
                    </IconButton>
                </Col>
            </Row>
            <Row style={{ margin: "1rem 0" }}>
                <Col>
                    <Form.Control value={queryText} type="text" placeholder="Søk på navn, applicationId eller objectId" onChange={e => setQueryText(e.target.value)} />
                </Col>
                <Col>
                    <TsKundeTypeahead onHandleChange={(kunde) => setSelectedTsKunde(kunde)} placeholderText="Filtrer på kunde" />
                </Col>
                <Col>
                    <span style={{ marginTop: '5px', color: '#68778D', float: 'right' }}>Viser {applications.length} av {totalCount} API tilganger</span>
                </Col>
            </Row>


            {(applications.length > 0) && (
                <Row>
                    <Col>
                        <InfiniteScroll
                            datalength={applications.length}
                            pageStart={1}
                            initialLoad={false}
                            loadMore={handleLoadMore}
                            hasMore={totalCount > applications.length}
                            threshold={50}
                            loader={
                                <div key={0}>
                                    <CommonLoader text="Henter flere..." color="#003b6e" />
                                </div>
                            }
                        >
                            <Table responsive style={{ borderCollapse: 'collapse', borderTop: 'none' }} hover>
                                <thead>
                                    <tr>
                                        <th>TsKunde</th>
                                        <th>Navn</th>
                                        <th>Tilganger</th>
                                        <th>Status</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
                                    {applications.map((a, i) => <tr key={a.objectId}>
                                        <td>{a.tsKundeNavn} {a.tilgangerOnBehalfOfTsKunde ? `(på vegne av ${a.tilgangerOnBehalfOfTsKunde})` : ''}</td>
                                        <td>{a.name}</td>
                                        <td>
                                            <TilgangerIcons application={a} />
                                        </td>
                                        <td>{a.deactivatedDate ? <IcebergBadge variant="dark">Deaktivert</IcebergBadge> : <IcebergBadge variant="primary">Aktiv</IcebergBadge>}</td>
                                        <td className="fitContent">
                                            <IconButton variant='outline-primary' icon="edit" onClick={() => handleEditAppClicked(a)}>
                                                Endre
                                            </IconButton>
                                        </td>
                                        <td className="fitContent">
                                            <OverlayTrigger trigger="click" key={'left'} placement={'left'} rootClose show={show === a.applicationId} onToggle={(nextShow) => toogleOverlayMenu(a, nextShow)}
                                                overlay={
                                                    <Popover id={`popover-positioned-${'left'}`}>
                                                        <Popover.Body>
                                                            <IconButton variant='outline-primary' icon="send" style={{ width: '100%', border: '0px' }} onClick={() => handleTestAppClicked(a)}>
                                                                Test
                                                            </IconButton>
                                                            <Button variant='outline-primary' style={{ width: '100%', border: '0px' }} onClick={() => handleStatusAppClicked(a)}>
                                                                {a.deactivatedDate ? 'Aktiver' : 'Deaktiver'}
                                                            </Button>
                                                        </Popover.Body>
                                                    </Popover>
                                                }>
                                                <button style={{ border: 'none', background: 'transparent', padding: '0.5rem' }}>
                                                    <IcebergIcon icon="ellipsis" color='#68778D' />
                                                </button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </InfiniteScroll>
                    </Col>
                </Row>
            )}
            <CreateModal show={showCreateModal} onClose={handleCloseCreateModal} />
            <EditModal show={showEditModal} application={selectedApp} onClose={handleCloseEditModal} />
            <TestModal show={showTestModal} application={selectedApp} onClose={handleCloseTestModal} />
            <StatusModal show={showStatusModal} application={selectedApp} onClose={handleCloseStatusModal} />
        </div>

    )
};

export default AdApplications;