import { IconButton } from "@tradesolution/iceberg-ui-react";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import BrukereApi from "services/AdminportalenApi/BrukereApi";
import { PendingBrukerInviteDto } from "services/AdminportalenApi/BrukereApi/types";
import HttpErrorHelper from "utils/HttpErrorHelper";
import useToaster from "utils/hooks/useToaster";
import Style from "./index.module.css";
import CommonLoader from "components/CommonLoader";
import { format } from 'date-fns';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Tilgangssøknader = () => {
    const [pendingBrukerInvites, setPendingBrukerInvites] = useState<PendingBrukerInviteDto[]>([]);
    const [pendingInviteAcceptances, setPendingInviteAcceptances] = useState<PendingBrukerInviteDto[]>([]);
    const [pendingInviteRejections, setPendingInviteRejections] = useState<PendingBrukerInviteDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const toaster = useToaster();

    /* Load Functions */
    const loadPendingBrukerInvites = async () => {
        setIsLoading(true);
        try {
            const response = await BrukereApi.pendingInvites();
            setPendingBrukerInvites(response);
        } catch (error) {
            console.error('Error loading pending invites:', error);
        }
        setIsLoading(false);
    }


    const loadPendingInviteAcceptance = async () => {
        setIsLoading(true);
        try {
            const response = await BrukereApi.acceptedInvites();
            setPendingInviteAcceptances(response);
        } catch (error) {
            console.error('Noe gikk galt:', error);
        }
        setIsLoading(false);
    }

    const loadPendingInviteRejection = async () => {
        setIsLoading(true);
        try {
            const response = await BrukereApi.rejectedInvites();
            setPendingInviteRejections(response);
        } catch (error) {
            console.error('Noe gikk galt:', error);
        }
        setIsLoading(false);
    }

    /* Handle Functions */
    const handleAccept = async (id: string) => {
        try {
            await BrukereApi.acceptPendingInvite(id);
            toaster.success('Suksess', 'Invitasjon til brukeren er godkjent');
            loadPendingBrukerInvites();
        } catch (err) {
            toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
        }
    };

    const handleReject = async (id: string) => {
        try {
            await BrukereApi.rejectPendingInvite(id);
            toaster.success('Suksess', 'Invitasjon til brukeren er avslått');
            loadPendingBrukerInvites();

        } catch (err) {
            toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
        }
    }


    /* Render Functions */
    const renderPendingInvites = () => {
        return (
            <div>
                {isLoading ? (
                    <CommonLoader />
                ) : (
                    pendingBrukerInvites.length > 0 && (
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>Opprettetdato</th>
                                    <th>Navn</th>
                                    <th>Kontaktinfo</th>
                                    <th>TsKunde</th>
                                    <th>Tilganger</th>
                                    <th>Opprettet Av</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingBrukerInvites.map((invite) => (
                                    <tr key={invite.id}>
                                        <td>{format(invite.createdAt, "dd.MM.yy HH:mm")}</td>
                                        <td>{invite.brukerFornavn + " " + invite.brukerEtternavn}</td>
                                        <td>
                                            <p className={Style.email}>{invite.brukerEpost}</p>
                                            {invite.brukerTelefon && <p className={Style.telefon}>{invite.brukerTelefon}</p>}
                                        </td>
                                        <td>{invite.onBehalfOfTsKunde}</td>
                                        <td>
                                            {invite.permissions.map((permission, index) => (
                                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                                                    <span>{permission.accessToDisplayName}</span>
                                                    <span>{permission.roleDisplayName}</span>
                                                </div>
                                            ))}
                                        </td>
                                        <td>{invite.createdBy}</td>
                                        <td>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                gap: "10px"
                                            }}>
                                                <IconButton size="sm" variant='outline-primary' icon='close' onClick={() => handleReject(invite.id)}>Avslå</IconButton>
                                                <IconButton size="sm" variant='outline-primary' icon='check' onClick={() => handleAccept(invite.id)}>Godkjenn</IconButton>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                )}
            </div>
        );
    };

    const renderAcceptedInvites = () => {
        return (
            <div>
                {isLoading ? (
                    <CommonLoader />
                ) : (
                    pendingInviteAcceptances.length > 0 && (
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>Akseptertdato</th>
                                    <th>Navn</th>
                                    <th>Kontaktinfo</th>
                                    <th>TsKunde</th>
                                    <th>Tilganger</th>
                                    <th>Akseptert Av</th>
                                    <th>Kommentar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingInviteAcceptances.map((acceptance) => (
                                    <tr key={acceptance.id}>
                                        <td>{format(acceptance.pendingInviteAcceptedAt, "dd.MM.yy HH:mm")}</td>
                                        <td>{acceptance.brukerFornavn + " " + acceptance.brukerEtternavn}</td>
                                        <td>
                                            <p className={Style.email}>{acceptance.brukerEpost}</p>
                                            {acceptance.brukerTelefon && <p className={Style.telefon}>{acceptance.brukerTelefon}</p>}
                                        </td>
                                        <td>{acceptance.onBehalfOfTsKunde}</td>
                                        <td>
                                            {acceptance.permissions.map((permission, index) => (
                                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                                                    <span>{permission.accessToDisplayName}</span>
                                                    <span>{permission.roleDisplayName}</span>
                                                </div>
                                            ))}
                                        </td>
                                        <td>{acceptance.pendingInviteAcceptedBy}</td>
                                        <td>{acceptance.comment}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                )}
            </div>
        );
    };

    const renderRejectedInvites = () => {
        return (
            <div>
                {isLoading ? (
                    <CommonLoader />
                ) : (
                    pendingInviteRejections.length > 0 && (
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>Avvistdato</th>
                                    <th>Navn</th>
                                    <th>Kontaktinfo</th>
                                    <th>TsKunde</th>
                                    <th>Tilganger</th>
                                    <th>Avvist Av</th>
                                    <th>Kommentar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingInviteRejections.map((rejection) => (
                                    <tr key={rejection.id}>
                                        <td>{format(rejection.pendingInviteRejectedAt, "dd.MM.yy HH:mm")}</td>
                                        <td>{rejection.brukerFornavn + " " + rejection.brukerEtternavn}</td>
                                        <td>
                                            <p className={Style.email}>{rejection.brukerEpost}</p>
                                            {rejection.brukerTelefon && <p className={Style.telefon}>{rejection.brukerTelefon}</p>}
                                        </td>
                                        <td>{rejection.onBehalfOfTsKunde}</td>
                                        <td>
                                            {rejection.permissions.map((permission, index) => (
                                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                                                    <span>{permission.accessToDisplayName}</span>
                                                    <span>{permission.roleDisplayName}</span>
                                                </div>
                                            ))}
                                        </td>
                                        <td>{rejection.pendingInviteRejectedBy}</td>
                                        <td>{rejection.comment}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                )}
            </div>
        );
    };

    /* useEffects */

    useEffect(() => {
        loadPendingBrukerInvites();
    }, []);

    useEffect(() => {
        loadPendingInviteAcceptance();
    }, []);

    useEffect(() => {
        loadPendingInviteRejection();
    }, []);

    return (
        <div className="container-fluid" style={{ marginTop: "20px", marginLeft: "5px", marginRight: "5px" }}>
            <Row className="mb-2">
                <Col style={{ marginTop: "0.5em" }}>
                    <h2>Tilgangssøknader</h2>
                </Col>
            </Row>

            <Tabs defaultActiveKey="pending" className="mb-3"  >
                <Tab eventKey="pending" title="Til behandling">
                    {renderPendingInvites()}
                </Tab>
                <Tab eventKey="accepted" title="Aksepterte">
                    {renderAcceptedInvites()}
                </Tab>
                <Tab eventKey="rejected" title="Avviste">
                    {renderRejectedInvites()}
                </Tab>
            </Tabs>

        </div>
    );

};

export default Tilgangssøknader;