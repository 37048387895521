import React, { useState } from 'react'
import { Table, Badge } from 'react-bootstrap';
import { format } from 'date-fns'
import cx from 'classnames';
import PopoverMenu from 'components/PopoverMenu';
import { getTjenestegruppeIcon } from 'components/IcebergIcon/utils';
import CreatePONummer from './components/CreatePONummer';
import EditPONummer from './components/EditPONummer';
import DeletePoNummer from './components/DeletePoNummer';
import { KundekortPoNummer } from 'services/AdminportalenApi/KundeKortApi/types';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import IcebergBadge from 'components/IcebergBadge';

interface Props {
  tsKundeId: string;
  poNummer: KundekortPoNummer[];
  isNedlagt: boolean;
  onUpdated: () => void;
  filteredTjenestegrupper: string[];
}

const POnummere: React.FC<Props> = props => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [selectedPoNummer, setSelectedPoNummer] = useState<KundekortPoNummer>(null);
  const [showInactive, setShowInactive] = useLocalStorage<boolean>('showInactivePO', false);

  const selectionHandler = (poNummer: KundekortPoNummer, edit: boolean) => {
    setSelectedPoNummer(poNummer);
    if (edit) {
      setShowEditModal(true);
    } else {
      setShowDeleteModal(true);
    }
  }

  const totalPONummere = props.poNummer.filter(x => showInactive ? x : (x.isActive || x.isFuture));
  const filteredPONummere = totalPONummere.filter(x => (props.filteredTjenestegrupper.length > 0 ? props.filteredTjenestegrupper.includes(x.tjenesteGruppeId) : x));
  const inactivePONummere = props.poNummer.filter(x => !x.isActive && !x.isFuture && (props.filteredTjenestegrupper.length > 0 ? props.filteredTjenestegrupper.includes(x.tjenesteGruppeId) : x));

  return (
    <>
      <div className={Style.wrapper}>
        <Table responsive hover>
          <thead>
            <tr>
              <th>PO-nummere</th>
              <th>Sist oppdatert av</th>
              {
                inactivePONummere?.length > 0 ? (
                  showInactive ?
                    <th onClick={() => setShowInactive(prev => !prev)} style={{ cursor: 'pointer' }}>Status (Skjul inaktive)<IcebergIcon icon='arrow-up' /></th> :
                    <th onClick={() => setShowInactive(prev => !prev)} style={{ cursor: 'pointer' }}>Status {'(' + inactivePONummere.length + ' inaktive' + ', Vis' + ')'}<IcebergIcon icon='arrow-down' /></th>
                ) : <th>Status</th>
              }
              <th>Tjenestegrupper</th>
              <th style={{ cursor: props.isNedlagt ? 'default' : 'pointer' }}>
                {!props.isNedlagt && <IcebergIcon icon='plus' title='Ny' bold onClick={() => setShowCreateModal(true)} />}
              </th>
            </tr>
          </thead>
          <tbody>
            {
              filteredPONummere.map(po => (
                <tr key={po.ponummerId + '-' + po.tjenesteGruppeId}>
                  <td>{po.ponummerValue}</td>
                  <td>{po.updatedBy}</td>
                  <td>
                    {
                      po.isActive ?
                        <IcebergBadge variant='primary'>AKTIV</IcebergBadge> : (po.isFuture ?
                          <IcebergBadge variant='warning'>FREMTIDIG</IcebergBadge> :
                          <IcebergBadge variant='dark'>INAKTIV</IcebergBadge>)
                    }
                    {' '}
                    ({po.validFromDate ? format(po.validFromDate, 'dd.MM.yyyy') : ''} - {po.validToDate ? format(po.validToDate, 'dd.MM.yyyy') : ''})
                  </td>
                  <td className="tjenesteGruppeCol">
                    {
                      po.tjenesteGruppeId &&
                      <>
                        <IcebergIcon icon={getTjenestegruppeIcon(po.tjenesteGruppeNavn)} />
                        {' ' + po.tjenesteGruppeNavn}
                      </>
                    }
                  </td>
                  <td className="fitContent">
                    <PopoverMenu
                      onDelete={async () => selectionHandler(po, false)}
                      onEdit={() => selectionHandler(po, true)}
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        {props.filteredTjenestegrupper.length > 0 && <span className={Style.helpText}>Viser {filteredPONummere.length} av {totalPONummere.length} PO-nummere</span>}
      </div>
      {showDeleteModal && <DeletePoNummer show={showDeleteModal} setShow={setShowDeleteModal} poNummer={selectedPoNummer} onUpdated={props.onUpdated} />}
      {showEditModal && <EditPONummer show={showEditModal} setShow={setShowEditModal} poNummerId={selectedPoNummer.ponummerId} onUpdated={props.onUpdated} onlyView={props.isNedlagt} />}
      <CreatePONummer tsKundeId={props.tsKundeId} onUpdated={props.onUpdated} show={showCreateModal} setShow={setShowCreateModal} />
    </>
  )
}

export default POnummere