import axios from 'axios';
import { Client, ApiError } from './types';

const getBaseUrl = () => {
  if (window.location.host.indexOf('dev') > -1 || window.location.host.indexOf('localhost') > -1) {
    return 'https://kundeportalenapi-dev.tradesolution.no/api';
  }
  return 'https://kundeportalenapi.tradesolution.no/api';
}

const KundeportalenApi = {
  fetchMenu: (isTileDisplay: any) =>
    axios
      .get(`${getBaseUrl()}/menu/me?isTileDisplay=${isTileDisplay}`)
      .then((response) => response.data),

  fetchTsKundeMenu: (tsKundeId: any) => {
    if (tsKundeId) {
      return axios
        .get(`${getBaseUrl()}/menu/me/tskunder?tsKundeId=${tsKundeId}`)
        .then((response) => response.data);
    }
    return axios
      .get(`${getBaseUrl}/menu/me/tskunder`)
      .then((response) => response.data);
  },

  getClients: async (token: string): Promise<Client[] | ApiError> => {
    const response = await fetch(
      `${getBaseUrl()}/clients`,
    );
    if (response.ok) {
      const data = await response.json();
      return data.map((d: any) => Client.parse(d));
    }
    return new ApiError(response.status, response.statusText);
  }
}

export default KundeportalenApi;