import React, { useState, useEffect } from "react";
import { Modal, Table, Form, Button, Spinner } from "react-bootstrap";
import TjenestegruppeApi from 'services/AdminportalenApi/TjenestegruppeApi';
import { Tjenestegruppe } from 'services/AdminportalenApi/TjenestegruppeApi/types';
import { Kontaktperson } from "services/AdminportalenApi/KundeKortApi/types";
import KontaktpersonerApi from "services/AdminportalenApi/KontaktpersonerApi";
import { UpdateKontaktpersonConnectionsCommand, TjenestegruppeKontakt } from "services/AdminportalenApi/KontaktpersonerApi/types";
import HttpErrorHelper from "utils/HttpErrorHelper";
import useForm from "utils/hooks/useForm";
import useToaster from "utils/hooks/useToaster";
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import Style from './index.module.css';
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  tsKundeId: string;
  kontaktperson: Kontaktperson;
  onUpdated: () => void;
}

const EditKontaktpersonConnections: React.FC<Props> = props => {
  const isMountedRef = useIsMountedRef();
  const toaster = useToaster();

  const [tjenestegrupper, setTjenestegrupper] = useState<Tjenestegruppe[]>([]);

  const initialValues: UpdateKontaktpersonConnectionsCommand = {
    tsKundeId: props.tsKundeId,
    kontaktpersonId: props.kontaktperson.kontaktpersonId,
    kontakter: props.kontaktperson.tjenesteGruppeConnections.filter(x =>
      (x.isPrimaryContact || x.isFakturaContact || x.isOtherContact)).map(x => (
        {
          tjenestegruppeId: x.tjenesteGruppeId,
          isPrimaryContact: x.isPrimaryContact,
          isFakturaContact: x.isFakturaContact,
          isOtherContact: x.isOtherContact
        }
      ))
  };

  const validate = (values: UpdateKontaktpersonConnectionsCommand) => {
    let errors: any = {};
    return errors;
  }

  const onSubmit = async (command: UpdateKontaktpersonConnectionsCommand) => {
    try {
      await KontaktpersonerApi.updateKontaktpersonKontakter(command);
      toaster.success('Suksess', 'Kontaktperson opprettet!');
      props.setShow(false)
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err))
    }
  }

  const { values, touched, loading, updateValues, handleSubmit } = useForm(initialValues, validate, onSubmit);

  const loadTjenestegrupper = async () => {
    let res = await TjenestegruppeApi.getTjenestegrupper();
    if (res.length > 0 && isMountedRef.current) {
      setTjenestegrupper(res);
    }
  }

  const handleChange = (id: string, type: 'isPrimaryContact' | 'isFakturaContact' | 'isOtherContact', checked: boolean) => {
    const copy = { ...values };
    let kontakt = copy.kontakter.find(x => x.tjenestegruppeId == id);
    if (kontakt) {
      kontakt[type] = checked;
      if(type === 'isPrimaryContact' || type === 'isFakturaContact'){
        kontakt['isOtherContact'] = false;
      }
      if (!kontakt.isFakturaContact && !kontakt.isPrimaryContact && !kontakt.isOtherContact) {
        const index = copy.kontakter.indexOf(kontakt);
        copy.kontakter.splice(index, 1);
      }
    } else if (checked) {
      var newKontakt: TjenestegruppeKontakt = {
        tjenestegruppeId: id,
        isFakturaContact: type === 'isFakturaContact' ? checked : false,
        isPrimaryContact: type === 'isPrimaryContact' ? checked : false,
      }
      copy.kontakter.push(newKontakt);
    }

    updateValues(copy, 'kontakter');
  }

  useEffect(() => {
    loadTjenestegrupper();
  }, [])

  return (
    <Modal centered show={props.show} onHide={() => props.setShow(false)} dialogClassName={Style.container}
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">Endre tilknytninger for {props.kontaktperson.navn}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Hovedkontakt</th>
                <th>Fakturakontakt</th>
                <th>Annen kontakt</th>
              </tr>
            </thead>

            <tbody>
              {
                tjenestegrupper.map(tg => {
                  var contact = values.kontakter.find(x => x.tjenestegruppeId === tg.tjenesteGruppeId);
                  var disableOtherContact = contact?.isPrimaryContact || contact?.isFakturaContact;
                  return (
                    <tr key={tg.tjenesteGruppeId}>
                      <td>{tg.tjenesteGruppeNavn}</td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={contact ? contact.isPrimaryContact : false}
                          onChange={e => handleChange(tg.tjenesteGruppeId, 'isPrimaryContact', e.target.checked)} />
                      </td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={contact ? contact.isFakturaContact : false}
                          onChange={e => handleChange(tg.tjenesteGruppeId, 'isFakturaContact', e.target.checked)} />
                      </td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={contact ? contact.isOtherContact : false}
                          disabled={disableOtherContact}
                          onChange={e => handleChange(tg.tjenesteGruppeId, 'isOtherContact', e.target.checked)} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button variant="primary" className="col" type='submit' disabled={loading}>
            <span>
              <IcebergIcon icon='disk' size='lg' /> Lagre
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              }
            </span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditKontaktpersonConnections;