import React from 'react';
import Style from './index.module.css';
import { Alert, Button } from 'react-bootstrap';
import { Toast } from './types';

interface Props {
  toasts: Toast[];
  handleCloseToast: any;
  onCancelRequest?: () => void;
}

const Toaster: React.FC<Props> = props => {

  const handleCancelRequest = () => {
    if (props.onCancelRequest)
      props.onCancelRequest();
  }

  return (
    <div className={Style.toastContainer}>
      {props.toasts &&
        props.toasts.map((t, index) => {
          return (
            <Alert
              key={`alert-${Math.random()}`}
              show={true}
              variant={t.type}
              onClose={() => props.handleCloseToast(index)}
              dismissible={t.dismissible}
            >
              <Alert.Heading>{t.title || ''}</Alert.Heading>
              {t.type === 'danger' && Array.isArray(t.message) ? (
                <div>
                  {t.message.map((x: { key: string; errors: string[] }) => (
                    <div key={x.key + '-' + Math.random()}>
                      <span>
                        <strong>{x.key}</strong>
                      </span>
                      <ul>
                        {x.errors.map(y => (
                          <li key={y}>{y}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <p>{t.message || ''}</p>
                  
                </div>               
              )}
            </Alert>
          );
        })}
    </div>
  )
};
export default Toaster;