import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import { Avtale } from 'services/AdminportalenApi/KundeKortApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import { UpdateAvtaleCommand } from 'services/AdminportalenApi/AvtalerApi/types';
import * as validator from 'validator';
import AvtaleApi from 'services/AdminportalenApi/AvtalerApi';
import TjenesegruppeSelect from 'components/TjenestegruppeSelect';
import { useEffect, useState } from 'react';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  avtale: Avtale;
  onUpdated: () => void;
  onlyView: boolean;
}

const EditAvtale = (props: Props) => { 
  const toaster = useToaster();

  const initialValues: UpdateAvtaleCommand = {
    avtaleId: props.avtale.avtaleId,
    navn: props.avtale.navn,
    fraOgMed: props.avtale.fraOgMed,
    tilOgMed: props.avtale.tilOgMed,
    tjenesteGruppeId: props.avtale.tjenesteGruppeId,
    url: props.avtale.url,
    assistWithCleanUp: false,
  }

  const validate = (values: UpdateAvtaleCommand) => {
    let errors: any = {};
    if (values.navn === '') {
      errors.navn = 'Vennligst gi avtalen et navn';
    }
    if (values.url && !validator.isURL(values.url)) {
      errors.url = 'URL må være gyldig';
    }
    return errors;
  }

  const onSubmit = async (command: UpdateAvtaleCommand) => {
    try {
      await AvtaleApi.updateAvtale(command);
      toaster.success('Suksess', 'Avtale oppdatert!');
      props.setShow(false)
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }
  
  const { values, errors, touched, loading, handleChange, handleSubmit } = useForm(initialValues, validate, onSubmit);

  useEffect(() => {
    if(values.tilOgMed)
      values.assistWithCleanUp = true;
      else
      values.assistWithCleanUp = false; 
  }, [values.tilOgMed]);

  return (
    <Modal centered show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Avtale</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Navn</Form.Label>
            <Form.Control
              placeholder='Skriv inn navn på avtalen'
              value={values.navn}
              onChange={e => handleChange('navn', e.target.value)}
              isInvalid={touched?.navn && errors?.navn} />
            <Form.Control.Feedback type="invalid">
              {errors?.navn}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Gyldig fra</Form.Label>
            <DatePicker
              onChange={date => handleChange('fraOgMed', date)}
              selected={values.fraOgMed} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Gyldig til</Form.Label>
            <DatePicker
              onChange={date => handleChange('tilOgMed', date)}
              selected={values.tilOgMed} />
          </Form.Group>
          {values.tilOgMed && (
            <Form.Group className="mb-3">
              <Form.Check aria-label="option 1" label='Legg ned tilhørende abonnment og fjern tjenestegruppe fra fakturainfo?'
                checked={values.assistWithCleanUp}
                onChange={e => handleChange('assistWithCleanUp', e.target.checked)}
              />
            </Form.Group>
          )}          
          <Form.Group className="mb-3">
            <Form.Label>Url (valgfritt)</Form.Label>
            <Form.Control
              placeholder='Skriv inn url til avtalen'
              value={values.url}
              onChange={e => handleChange('url', e.target.value)}
              isInvalid={touched?.url && errors?.url} />
            <Form.Control.Feedback type="invalid">
              {errors?.url}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tjenestegruppe (valgfritt)</Form.Label>
            <TjenesegruppeSelect selected={values.tjenesteGruppeId ?? ''} onChange={e => handleChange('tjenesteGruppeId', e)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {
            props.onlyView ?
              <Button
                variant="primary"
                onClick={() => props.setShow(false)}>
                Lukk
              </Button> :
              <>
                <Button className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
                  <IcebergIcon size='lg' icon='close' /> Avbryt
                </Button>
                <Button
                  variant="primary"
                  className="col"
                  type="submit"
                  disabled={loading || Object.keys(errors).length > 0}>
                  <IcebergIcon icon='disk' size='lg' /> Lagre
                  {
                    loading &&
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  }
                </Button>
              </>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EditAvtale;