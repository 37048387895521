import { IcebergAvatar, IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Card, Col, Collapse, Form, Row, Table } from "react-bootstrap";
import FakturagrunnlagApi from "services/AdminportalenApi/FakturagrunnlagApi";
import { Fakturagrunnlag, FakturagrunnlagOverviewDto, FakturagrunnlagPeriodeOverviewDto } from "services/AdminportalenApi/FakturagrunnlagApi/types";
import TjenestegruppeSelect from "components/TjenestegruppeSelect";
import { toMonthName } from "utils/dateHelpers";
import formatAsCurrency from "utils/formatAsCurrency";
import ActionMenu from "./Components/ActionMenu";
import FakturaPreview from "./Components/FakturaPreview";
import Style from './index.module.scss';
import useLocalStorage from "utils/hooks/useLocalStorage";
import GenerateAbonnementFakturaModal from "./Components/GenerateAbonnementFakturaModal";
import SendToPogoModal, { SendToPogoParams } from "./Components/SendToPogoModal";
import DeletePeriodeModal, { DeletePeriodeParams } from "./Components/DeletePeriodeModal";
import IcebergBadge from "components/IcebergBadge";
import UtestaaendeModal from "./Components/UtestaaendeModal";
import CommonLoader from "components/CommonLoader";
import { useSearchParams } from "react-router-dom";

const FakturagrunnlagOversikt = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTjenesteGruppeId, setSelectedTjenesteGruppeId] = useLocalStorage<string | undefined>("fakturagrunnlag_selectedTjenesteGruppeId", undefined);
    const [groupedFakturagrunnlag, setGroupedFakturagrunnlag] = useState<FakturagrunnlagOverviewDto[]>([]);
    const [selectedGruppering, setSelectedGruppering] = useState<FakturagrunnlagOverviewDto | undefined>();
    const [selectedPeriode, setSelectedPeriode] = useState<FakturagrunnlagPeriodeOverviewDto[] | undefined>()
    const [selectedGrunnlag, setSelectedGrunnlag] = useState<Fakturagrunnlag | undefined>();
    const [year, setYear] = useState<number>(new Date().getFullYear());

    const [fakturagrunnlagLoading, setFakturagrunnlagLoading] = useState<boolean>(false);

    const loadFakturagrunnlag = async () => {
        setFakturagrunnlagLoading(true);
        const result = await FakturagrunnlagApi.getYearOversikt(selectedTjenesteGruppeId, year);
        setGroupedFakturagrunnlag(result);
        setFakturagrunnlagLoading(false);
    };

    const getPeriodeOversikt = async (periode: string): Promise<FakturagrunnlagPeriodeOverviewDto[]> => {
        const result = await FakturagrunnlagApi.getPeriodeOversikt(selectedTjenesteGruppeId, periode);
        return result;
    }

    const getOneFakturagrunnlag = async (id: string): Promise<Fakturagrunnlag> => {
        const result = await FakturagrunnlagApi.getGrunnlag(id);
        return result;
    }

    const handleTjenesteGruppeSelected = (tjenesteGruppeId: string) => {
        setSelectedTjenesteGruppeId(tjenesteGruppeId);
        setSelectedGruppering(undefined);
        setSelectedPeriode(undefined);
        setSelectedGrunnlag(undefined);
    };

    useEffect(() => {
        if (selectedTjenesteGruppeId) {
            loadFakturagrunnlag();
        }
    }, [selectedTjenesteGruppeId, year]);

    const toggleFakturagrunnlagDetaljer = async (grunnlag: FakturagrunnlagOverviewDto, periode: string) => {
        if (selectedGruppering?.title === grunnlag.title) {
            setSelectedPeriode(undefined);
            setSelectedGruppering(undefined)
        } else {
            setSelectedGruppering(grunnlag);
            const items = await getPeriodeOversikt(periode);
            setSelectedPeriode(items);

        }
    };

    const toggleSelectedGrunnlag = async (periode: string, id: string) => {
        if (selectedGrunnlag?.id === id) {
            setSelectedGrunnlag(undefined);
            toggleExpanded(undefined);
        } else {
            const grunnlag = await getOneFakturagrunnlag(id);
            setSelectedGrunnlag(grunnlag);
            toggleExpanded(`${periode}-${grunnlag.tsKundeId}`)
        }
    };

    const [showGenererModalOld, setShowGenererModalOld] = useState(false);

    const [showGenererModal, setShowGenererModal] = useState(false);
    const toggleGenererModal = () => {
        setShowGenererModal(!showGenererModal);
    };

    const handleGenererFakturaModalClose = () => {
        setShowGenererModal(false);
        loadFakturagrunnlag();
    }

    const [expanded, setExpanded] = useState<string | undefined>();

    const toggleExpanded = (id: string) => {
        if (expanded === id) {
            setExpanded(undefined);
        } else {
            setExpanded(id);
        }
    };

    const getPeriodStatusIcons = (group: FakturagrunnlagOverviewDto) => {
        let helpText = "Klar for innsending til POGO";
        let variant: any = "success";

        if (group.exportDate) {
            variant = "primary";
            helpText = `Sendt til POGO`;
        } else if (group.kunderMedMangler > 0) {
            variant = "warning";
            helpText = `${group.kunderMedMangler} kunder med mangler`;
        }

        return <IcebergBadge variant={variant}>{helpText}</IcebergBadge>;
    };

    const getOverviewStatusIcons = (group: FakturagrunnlagOverviewDto) => {
        let helpText = "Utestående";
        let variant: any = "primary";

        if (group.exportDate) {
            helpText = `${format(group.exportDate, "dd.MM.yy")} - Eksportert `;
        }
        return <IcebergBadge variant={variant}>{helpText}</IcebergBadge>;
    };

    const getTsKundeStatusBadge = (fakturagrunnlag: FakturagrunnlagPeriodeOverviewDto) => {
        if (fakturagrunnlag.manglerKundenummer) {
            return <IcebergBadge variant="warning">Mangler kundenummer</IcebergBadge>;
        }
        if (fakturagrunnlag.manglerOmsetning) {
            return <IcebergBadge variant="warning">Mangler omsetning</IcebergBadge>;
        }
        if (fakturagrunnlag.tsKundeInactive) {
            return <IcebergBadge variant="warning">Kunden er inaktiv</IcebergBadge>;
        }
        return null;
    };

    const getLast5Years = (): number[] => {
        const currentYear = new Date().getFullYear() + 1;
        return Array.from({ length: 9 }, (_, i) => i + currentYear - 8).reverse();
    };

    const handleDeaktiverFakturaLinje = async (fakturagrunnlagId: string, linjeId: string) => {
        await FakturagrunnlagApi.deaktiverFakturaLinje(fakturagrunnlagId, linjeId);
        const grunnlag = await getOneFakturagrunnlag(fakturagrunnlagId);
        setSelectedGrunnlag(grunnlag);
    };

    const handleGenererFakturaModalCloseOld = () => {
        setShowGenererModalOld(false);
        loadFakturagrunnlag();
    }

    const [showSendToPogoModal, setShowSendToPogoModal] = useState(false);
    const [sendToPogoParams, setSendToPogoParams] = useState<SendToPogoParams | undefined>();

    const handleSendToPogo = async (periode: string, invoiceCount: number, exportDate?: Date) => {
        setSendToPogoParams({
            periode,
            invoiceCount: invoiceCount,
            exportedDate: exportDate,
            tjenesteGruppeId: selectedTjenesteGruppeId
        });
        setShowSendToPogoModal(true);
    };

    const handlePogoModalClose = () => {
        setShowSendToPogoModal(false);
        loadFakturagrunnlag();
    };

    const [showDeletePeriodModal, setShowDeletePeriodModal] = useState(false);
    const [deletePeriodeParams, setDeletePeriodeParams] = useState<DeletePeriodeParams | undefined>();

    const handleDeletePeriodClicked = (periode: string, invoiceCount: number, exportedDate?: Date) => {
        setShowDeletePeriodModal(true);
        setDeletePeriodeParams({
            periode: periode,
            tjenesteGruppeId: selectedTjenesteGruppeId,
            invoiceCount: invoiceCount,
            exportedDate: exportedDate
        });
    };

    const handleDeletePeriodModalClose = () => {
        setShowDeletePeriodModal(false);
        loadFakturagrunnlag();
    };

    return (
        <div className="container-fluid">
            <Row style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                <Col>
                    <h1>Fakturagrunnlag</h1>
                </Col>
                <Col>
                    <div className="float-end">
                        <IconButton icon="plus" onClick={toggleGenererModal}>
                            Fakturagrunnlag
                        </IconButton>
                        {/* <IconButton variant="outline-primary" style={{ marginLeft: "0.5rem" }} icon="plus" onClick={toggleGenererModalOld}>
                            Fakturagrunnlag (gammel)
                        </IconButton> */}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Tjenestegruppe</Form.Label>
                                    <TjenestegruppeSelect selected={selectedTjenesteGruppeId} onChange={handleTjenesteGruppeSelected} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>År</Form.Label>
                                    <Form.Select as="select" value={year} onChange={e => setYear(parseInt(e.target.value))} aria-label="YearSelect">
                                        {getLast5Years().map(year => <option key={year} value={year}>{year}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    {fakturagrunnlagLoading && <div style={{ padding: "10rem" }}><CommonLoader color="#003b6e" /></div>}
                    {!fakturagrunnlagLoading && groupedFakturagrunnlag.length === 0 && <Card className={Style.missingDataCard}>
                        <Card.Body>
                            Ingen fakturagrunnlag funnet for valgt Tjenestegruppe
                        </Card.Body>
                    </Card>}
                    {groupedFakturagrunnlag.length > 0 && !fakturagrunnlagLoading &&
                        <div className="table-responsive">
                            <Table className={Style.tsTable} borderless hover style={{ marginTop: '20px' }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Antall kunder</th>
                                        <th className={Style.rightAlign}>Totalsum (NOK)</th>
                                        <th className={Style.rightAlign}>Status</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupedFakturagrunnlag.map(o =>
                                        <React.Fragment key={o.periode}>
                                            <tr>
                                                <td>
                                                    {o.gjelderTeksterFraGrunnlagene && (
                                                        <span className={Style.periodBox}>
                                                        {o.gjelderTeksterFraGrunnlagene.join(', ')}
                                                    </span>
                                                    )}
                                                    {o.exportDate && !o.gjelderTeksterFraGrunnlagene && (
                                                        <span className={Style.periodBox}>
                                                            {toMonthName(o.exportDate.getMonth())}
                                                        </span>
                                                    )}                                                    
                                                </td>
                                                <td><a style={{ cursor: 'pointer' }} onClick={() => toggleFakturagrunnlagDetaljer(o, o.periode)}>{getOverviewStatusIcons(o)}</a></td>
                                                <td>{o.antallKunder}</td>
                                                <td className={Style.rightAlign}>{formatAsCurrency(o.total)}</td>
                                                <td className={Style.rightAlign}>{getPeriodStatusIcons(o)}</td>
                                                <td className={Style.fitContent}>
                                                    <IconButton variant="outline-primary" icon="send" onClick={() => handleSendToPogo(o.periode, o.antallKunder, o.exportDate)}>
                                                        Send til Pogo
                                                    </IconButton>
                                                </td>
                                                <td className={Style.fitContent}>
                                                    <IcebergIcon icon={selectedGruppering?.periode === o.periode ? "chevron-up" : "chevron-down"} cursor="pointer" onClick={() => toggleFakturagrunnlagDetaljer(o, o.periode)} />
                                                </td>
                                                <td className={Style.fitContent}>
                                                    <ActionMenu
                                                        tjenesteGruppeId={selectedTjenesteGruppeId}
                                                        periode={o.periode}
                                                        onDeletePeriod={() => handleDeletePeriodClicked(o.periode, o.antallKunder, o.exportDate)}
                                                    />
                                                </td>
                                            </tr>
                                            {selectedPeriode && selectedGruppering?.periode === o.periode && (
                                                <Collapse in={selectedGruppering?.periode === o.periode}>
                                                    <>
                                                        {selectedPeriode.map(i =>
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <IcebergAvatar firstName={i.tsKundeNavn} />
                                                                        <a style={{ cursor: 'pointer', marginLeft: "0.5rem" }} onClick={() => toggleSelectedGrunnlag(o.title, i.id)}>{i.tsKundeNavn}</a>
                                                                    </td>
                                                                    <td className={Style.rightAlign}></td>
                                                                    <td className={Style.rightAlign}>{formatAsCurrency(i.nettoBelop)}</td>
                                                                    <td>{getTsKundeStatusBadge(i)}</td>
                                                                    <td>
                                                                        <IcebergIcon icon={expanded === `${o.title}-${i.tsKundeId}` ? "chevron-up" : "chevron-down"} cursor="pointer" onClick={() => toggleSelectedGrunnlag(o.title, i.id)} />
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                                {selectedGrunnlag && selectedGrunnlag.id === i.id && (
                                                                    <Collapse in={`${o.title}-${i.tsKundeId}` === expanded}>
                                                                        <tr>
                                                                            <td colSpan={7}>
                                                                                <FakturaPreview grunnlag={selectedGrunnlag} onDeaktiverLinje={(linjeId: string) => handleDeaktiverFakturaLinje(i.id, linjeId)} />
                                                                            </td>
                                                                        </tr>
                                                                    </Collapse>
                                                                )}
                                                            </>)
                                                        }
                                                    </>
                                                </Collapse>
                                            )}
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </Table>
                        </div>}
                </Col>
            </Row>
            <GenerateAbonnementFakturaModal
                show={showGenererModalOld}
                onModalClose={handleGenererFakturaModalCloseOld}
            />
            <UtestaaendeModal
                show={showGenererModal}
                onModalClose={handleGenererFakturaModalClose}
                showDebugInfo={!!searchParams.get("debug")}
            />
            <SendToPogoModal
                show={showSendToPogoModal}
                onModalClose={handlePogoModalClose}
                sendToPogoParams={sendToPogoParams}
            />
            <DeletePeriodeModal
                show={showDeletePeriodModal}
                onModalClose={handleDeletePeriodModalClose}
                deletePeriodeParams={deletePeriodeParams}
            />
        </div >
    );
};

export default FakturagrunnlagOversikt;