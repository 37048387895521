import { Col, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DatePeriod } from "./DatePeriod";

export enum Quarter {
    Q1 = 1, Q2 = 2, Q3 = 3, Q4 = 4
}

interface Props {
    year: number;
    quarter: Quarter;
    onChange: (period: DatePeriod) => void;
}

const QuarterPicker = (props: Props) => {

    const handleQuarterChanged = (value: string) => {
        switch (value) {
            case "1":
                props.onChange({
                    year: props.year,
                    quarter: Quarter.Q1
                });
                break;
            case "2":
                props.onChange({
                    year: props.year,
                    quarter: Quarter.Q2
                });
                break;
            case "3":
                props.onChange({
                    year: props.year,
                    quarter: Quarter.Q3
                });
                break;
            case "4":
                props.onChange({
                    year: props.year,
                    quarter: Quarter.Q4
                });
                break;
        }
    }

    const handleYearChanged = (value: string) => {
        props.onChange({
            year: parseInt(value),
            quarter: props.quarter
        });
    }

    return (
        <>
            <Form.Group as={Col}>
                <Form.Label>Kvartal</Form.Label>
                <Form.Select value={props.quarter} onChange={e => handleQuarterChanged(e.target.value)}>
                    <option value={Quarter.Q1}>Q1</option>
                    <option value={Quarter.Q2}>Q2</option>
                    <option value={Quarter.Q3}>Q3</option>
                    <option value={Quarter.Q4}>Q4</option>
                </Form.Select>
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>År</Form.Label>
                <Form.Select value={props.year} onChange={e => handleYearChanged(e.target.value)}>
                    <option>{new Date().getFullYear() - 1}</option>
                    <option>{new Date().getFullYear()}</option>
                    <option>{new Date().getFullYear() + 1}</option>
                </Form.Select>
            </Form.Group>
        </>
    )
}

export default QuarterPicker;