import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '..';
import { TsKundeSearchFilter } from '../TsKunderApi/types';

const baseUrl = getBaseUrl() + "/TsKunderExport";

const TsKunderExportApi = {
  exportToExcel: async (
    filter: TsKundeSearchFilter,
    type: string,
    onlyKeepTjenestegruppeId?: string,
    onlyExportActive?: boolean): Promise<any> => {

    let aditionalQueryParameters = '';
    if(filter.overriddenOmsetningOnly){
      aditionalQueryParameters += '&overrideOmsetningOnly=true';
    }
    if(filter.withoutAbonnement){
      aditionalQueryParameters += '&withoutAbonnement=true';
    }
    if(filter.withoutFakturaAdresser){
      aditionalQueryParameters += '&withoutFakturaAdresser=true';
    }
    if(filter.withoutKontaktpersoner){
      aditionalQueryParameters += '&withoutKontaktpersoner=true';
    }
    if(filter.aktivtAbonnementTjenesteId){
      aditionalQueryParameters += `&aktivtAbonnementTjenesteId=${filter.aktivtAbonnementTjenesteId}&onlyActiveAbonnement=true`;
    }    
    if(filter.aktivtAbonnementTjenestegruppeId){
      aditionalQueryParameters += `&aktivtAbonnementTjenestegruppeId=${filter.aktivtAbonnementTjenestegruppeId}&onlyActiveAbonnement=true`;
    }    
    if(filter.aktivAvtaleTjenesteGruppeId){
      aditionalQueryParameters += `&aktivAvtaleTjenesteGruppeId=${filter.aktivAvtaleTjenesteGruppeId}`;
    }
    if(onlyKeepTjenestegruppeId){
      aditionalQueryParameters += `&onlyKeepTjenestegruppeId=${onlyKeepTjenestegruppeId}`;
    }        
    if(onlyExportActive){
      aditionalQueryParameters += `&excludeInactive=${onlyExportActive}`;
    }

    let response: AxiosResponse<any>;
    switch(type){
      case "kontaktperson":
        response = await axios.get(`${baseUrl}/exporttoexcelkontaktperson?q=${filter.query}&statusFilter=${filter.statusOption}${aditionalQueryParameters}`, { responseType: 'arraybuffer' });
        break;
      case "abonnement":
        response = await axios.get(`${baseUrl}/exporttoexcelabonnement?q=${filter.query}&statusFilter=${filter.statusOption}${aditionalQueryParameters}`, { responseType: 'arraybuffer' });
        break;
      case "avtale":
        response = await axios.get(`${baseUrl}/exporttoexcelavtale?q=${filter.query}&statusFilter=${filter.statusOption}${aditionalQueryParameters}`, { responseType: 'arraybuffer' });
        break;
      default:
        response = await axios.get(`${baseUrl}/exporttoexcel?q=${filter.query}&statusFilter=${filter.statusOption}${aditionalQueryParameters}`, { responseType: 'arraybuffer' });
        break;
    }
    return response.data;
  },
}

export default TsKunderExportApi;