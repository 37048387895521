import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import React, { useState } from "react";
import { Row, Col, Button, Modal, Spinner } from "react-bootstrap";

interface Props {
  onHandleExport: () => Promise<void>;
  exportCount: number;
}

const Export: React.FC<Props> = props => {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);

  const exportClicked = async () => {
    setIsLoadingExport(true);
    await props.onHandleExport();
    setIsLoadingExport(false);
    setShowExportModal(false);
  }

  return (
    <Row>
      <Col>
        <Button variant="outline-primary" onClick={() => setShowExportModal(true)} disabled={isLoadingExport}>
          {
            isLoadingExport ?
              <span>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                {' '}Eksporterer...
              </span> :
              <>
                <IcebergIcon icon='export' />
                {' '}Eksporter
              </>
          }
        </Button>
      </Col>

      <Modal show={showExportModal} onHide={() => setShowExportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eksporter søkeresultat til Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span>
              Last ned søkeresultat som excel-fil (.xlsx).
              {
                props.exportCount > 100 &&
                <>
                  <br />
                  <strong> NB: antall brukere valgt for eksport er over 100. Dette kan ta lenger tid.</strong>
                </>
              }
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowExportModal(false)}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button variant="primary" onClick={exportClicked} disabled={isLoadingExport}>
            <IcebergIcon icon="export" color="#FFF" /> Last ned
            {
              isLoadingExport &&
              <span style={{ paddingRight: '.3em' }}>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              </span>
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  )
}

export default Export;