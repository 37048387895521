export class Client {
  constructor(
    objectId: string,
    applicationId: string,
    name: string,
    dateCreated: Date,
    secrets: string[],
    tsKunde: TsKunde
  ) {
    this.objectId = objectId;
    this.applicationId = applicationId;
    this.name = name;
    this.dateCreated = dateCreated;
    this.secrets = secrets;
    this.tsKunde = tsKunde;
  }

  objectId: string;
  applicationId: string;
  name: string;
  dateCreated: Date;
  secrets: string[] = [];
  tsKunde: TsKunde;

  public static parse = (data: any) => {
    return new Client(
      data.objectId,
      data.applicationId,
      data.name,
      new Date(data.dateCreated),
      data.secrets.map((o: any) => o.value),
      new TsKunde(data.tsKunde.tsKundeId, data.tsKunde.tsKundeNavn)
    );
  };
}

export class TsKunde {
  constructor(tsKundeId: string, tsKundeNavn: string) {
    this.tsKundeId = tsKundeId;
    this.tsKundeNavn = tsKundeNavn;
  }
  tsKundeId: string;
  tsKundeNavn: string;
}

export class ApiError {
  constructor(status: number, statusText: string) {
    this.status = status;
    this.statusText = statusText;
  }
  status: number;
  statusText: string;
}
