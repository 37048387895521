import axios from "axios";
import { Tjenestegruppe } from "./types";
import { getBaseUrl } from "..";

const baseUrl = getBaseUrl() + "/TjenesteGrupper";

const TjenestegruppeApi = {
  getTjenestegrupper: async (): Promise<Tjenestegruppe[]> => {
    const response = await axios.get(baseUrl);
    return response.data;
  },
  getTjenestegruppeById: async (id: string): Promise<Tjenestegruppe> => {
    const response = await axios.get(`${baseUrl}/${id}`);
    return response.data;
  }
}

export default TjenestegruppeApi;