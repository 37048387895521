import { IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import { useState } from "react";
import { OverlayTrigger, Popover, Spinner, Table } from "react-bootstrap";
import FakturagrunnlagApi from "services/AdminportalenApi/FakturagrunnlagApi";
import useToaster from "utils/hooks/useToaster";

interface Props {
    tjenesteGruppeId: string;
    periode: string;
    onDeletePeriod: () => void;
}

const ActionMenu = (props: Props) => {
    const toaster = useToaster();
    const [show, setShow] = useState(false);

    const [isDownloading, setIsDownloading] = useState(false);

    const downloadAsCsv = async () => {
        setIsDownloading(true);
        try {
            await FakturagrunnlagApi.exportAsCsv(props.tjenesteGruppeId, props.periode);
            toaster.success('Ok', 'Nedlastet');
        } catch (e) {
            toaster.error('Feil!', `Klarte ikke å laste ned. ${e.message}`);
        }
        setIsDownloading(false);
    };

    const handleDeletePeriod = () => {
        props.onDeletePeriod();
    };

    return (
        <OverlayTrigger trigger="click" key={'left'} placement={'left'} rootClose show={show} onToggle={setShow}
            overlay={
                <Popover id={`popover-positioned-${'left'}`}>
                    <Popover.Body key="fdg">
                        <Table borderless className="action-context-menu">
                            <tbody>
                                <tr>
                                    <td>
                                        <IconButton disabled={isDownloading} style={{ width: "100%", border: "0" }} icon="import" variant="outline-primary" onClick={downloadAsCsv}>
                                            Last ned som CSV
                                            {isDownloading && <Spinner animation="border" size="sm" style={{ marginRight: "0.5rem" }} />}
                                        </IconButton>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <IconButton style={{ width: "100%", border: "0" }} className="col" icon="trash" variant="outline-primary" onClick={handleDeletePeriod}>
                                            Slett periode
                                        </IconButton>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Popover.Body>
                </Popover>
            }>
            <button style={{ border: 'none', background: 'transparent', padding: 0 }}>
                <IcebergIcon icon="ellipsis" />
            </button>
        </OverlayTrigger>
    );
};

export default ActionMenu;