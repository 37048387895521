import React, { useState } from 'react'
import { Form, Modal, Spinner, Table } from 'react-bootstrap';
import TjenestePriserApi from 'services/AdminportalenApi/TjenestePriserApi';
import { CreateTjenestePrisCommand, PrisIntervallItem } from 'services/AdminportalenApi/TjenestePriserApi/types';
import DatePicker from 'components/DatePicker';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import cx from 'classnames';
import Style from './index.module.css';
import OmsetningVolumMatrix, { PriceModelMatrice } from '../OmsetningVolumMatrix';
import { IconButton } from '@tradesolution/iceberg-ui-react';

interface Props {
  tjenesteId: string;
  onUpdated: () => void;
  show: boolean;
  setShow: (show: boolean) => void;
}

const CreateTjenestePris = (props: Props) => {
  const toaster = useToaster();

  const initialValues: CreateTjenestePrisCommand = {
    tjenesteId: props.tjenesteId,
    pris: 0.0,
    description: '',
    validFromDate: new Date(),
    prisModelType: 0,
    prisIntervaller: []
  };

  const validate = (values: CreateTjenestePrisCommand) => {
    let errors: any = {};
    if (values.pris === -1) {
      errors.pris = 'Pris kan ikke være negativ';
    }

    if (values.validFromDate === null) {
      errors.validFromDate = "Må velges en dato. Datofeltet kan ikke være null";
    }

    // var s = new Set(values.prisIntervaller.map(x => x.antall));
    // if (s.size < values.prisIntervaller.length) {
    //   errors.prisIntervaller = "To eller flere rader har samme Antall";
    // }
    return errors;
  };

  const onSubmit = async (command: CreateTjenestePrisCommand) => {
    try {
      await TjenestePriserApi.createTjenestePris(command);
      toaster.success('Suksess', 'Tjeneste pris opprettet!');
      handleClose();
      resetForm(initialValues);
      props.onUpdated();
    }
    catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  };


  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => {
    resetForm(initialValues);
    props.setShow(false);
  };

  const handleAddPrisintervall = () => {
    const oppdatertePrisIntervall = [...values.prisIntervaller, { antall: 0, pris: 0.0 }]
    handleChange('prisIntervaller', oppdatertePrisIntervall);
  };

  const handleDeletePrisIntervall = (prisIntervall: PrisIntervallItem) => {
    const oppdatertePrisIntervall = values.prisIntervaller.filter(x => x !== prisIntervall);
    handleChange('prisIntervaller', oppdatertePrisIntervall);
  };

  const handleUpdateAntall = (antall: number, index: number) => {
    const oppdatertePrisAntall = [...values.prisIntervaller];
    oppdatertePrisAntall[index].antall = antall;
    handleChange('prisIntervaller', oppdatertePrisAntall);
  };

  const handleUpdatePris = (pris: number, index: number) => {
    const oppdatertePris = [...values.prisIntervaller];
    oppdatertePris[index].pris = pris;
    handleChange('prisIntervaller', oppdatertePris);
  };

  const handlePriceModelMatriceUpdated = (priceModelMatrice: PriceModelMatrice) => {
    const flattened = priceModelMatrice.getPrisIntervall();
    handleChange('prisIntervaller', flattened);
  };

  return (
    <>
      <Modal centered show={props.show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Opprett ny pris</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>

            <Form.Group className='mb-3'>
              <Form.Label>NOK</Form.Label>
              <Form.Control
                value={values.pris}
                step=".01"
                min={0}
                type='number'
                onChange={e => handleChange('pris', parseFloat(e.target.value))}
                placeholder='Pris'
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Gyldig fra og med</Form.Label>
              <DatePicker
                onChange={date => handleChange('validFromDate', date)}
                selected={values.validFromDate} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Kommentar</Form.Label>
              <Form.Control
                value={values.description}
                type="text"
                onChange={e => handleChange('description', e.target.value)}
                placeholder="Kommentar" />
            </Form.Group>

            {/* mål: da man velger enten volumbasert eller omsetningsbasert 
            kan man se på opprett knapp for prisintervall eller skjul denne knappen */}
            <Form.Group className="mb-3">
              <Form.Label>Pris/rabatt modell</Form.Label>
              <Form.Select aria-label='PrismodellSelect'
                style={{ marginBottom: 8, cursor: values.prisModelType === 0 ? 'pointer' : 'not-allowed' }}
                value={values.prisModelType}
                onChange={e => {
                  handleChange('prisModelType', parseInt(e.target.value));
                }}
                disabled={values.prisModelType !== 0}>
                <option value="0">Ingen</option>
                <option value="1">Volumbasert</option>
                <option value="2">Omsetningsbasert</option>
                <option value="3">Volum og omsetningsbasert</option>
              </Form.Select>

              {values.prisModelType === 3 &&
                <OmsetningVolumMatrix onPriceModelUpdated={handlePriceModelMatriceUpdated} />
              }

              {(values.prisModelType === 1 || values.prisModelType === 2) && <div>
                <Table responsive className={cx(Style.table, [Style.withHelpText])}>
                  <thead>
                    <tr>
                      <th style={{ padding: 0 }} className={Style.alignTableHeader}>{values.prisModelType === 1 ? "Volum" : "Omsetning"} (Fra og med)</th>
                      <th style={{ padding: 0 }} className={Style.alignTableHeader}>Pris</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className={Style.tbody}>
                    {values.prisIntervaller.map((prisIntervall, index) =>
                    (<tr>
                      <td>
                        <Form.Control
                          type='number'
                          min={0}
                          value={prisIntervall.antall}
                          onChange={e => handleUpdateAntall(parseInt(e.target.value), index)} />
                      </td>
                      <td>
                        <Form.Control
                          type='number'
                          step=".01"
                          min={0}
                          value={prisIntervall.pris}
                          onChange={e => handleUpdatePris(parseFloat(e.target.value), index)} />
                      </td>
                      <td width={30}>
                        <IconButton
                          variant='outline-danger'
                          icon='trash'
                          className='col'
                          onClick={() => handleDeletePrisIntervall(prisIntervall)} >
                        </IconButton>
                      </td>
                    </tr>)
                    )}
                  </tbody>
                </Table>
                {errors?.prisIntervaller && touched?.prisIntervaller && <div className={Style.invalidFeedback}>{errors?.prisIntervaller}</div>}
                <IconButton
                  variant='outline-primary'
                  className='col'
                  icon='plus'
                  onClick={handleAddPrisintervall}
                >
                  prisintervall
                </IconButton>

              </div>}
            </Form.Group>


          </Modal.Body>

          <Modal.Footer>
            <IconButton
              className='col'
              variant="outline-primary"
              icon="close"
              onClick={handleClose}
            >
              Avbryt
            </IconButton>

            <IconButton
              className='col'
              variant="primary"
              type='submit'
              icon="disk"
              disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
            >
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              }  Lagre
            </IconButton>


          </Modal.Footer>

        </Form>
      </Modal>
    </>
  );
}

export default CreateTjenestePris;