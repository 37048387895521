import { useState } from 'react'
import { Table } from 'react-bootstrap';
import EditProdukteierGLN from './components/EditProdukteierGLN';
import CreateProdukteierGLN from './components/CreateProdukteierGLN';
import { ProdukteierGln } from 'services/AdminportalenApi/KundeKortApi/types';
import PopoverMenu from 'components/PopoverMenu';
import ProdukteierGlnApi from 'services/AdminportalenApi/ProdukteierGlnApi';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  produkteiergln: ProdukteierGln[];
  tsKundeId: string;
  kundeNavn: string;
  isNedlagt: boolean;
  onUpdated: () => void;
}


const ProdukteierGLN = (props: Props) => {
  const toaster = useToaster();

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [selectedGln, setSelectedGln] = useState<ProdukteierGln>();

  const handleEdit = (gln: ProdukteierGln) => {
    setShowEditModal(true);
    setSelectedGln(gln);
  }

  const handleDelete = async (id: string) => {
    try {
      await ProdukteierGlnApi.deleteProdukteierGln(id);
      toaster.success('Suksess', 'ProdukteierGLN slettet!');
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  return (
    <>
      <div className={Style.wrapper}>
        <Table responsive className={Style.table} hover>
          <thead>
            <tr>
              <th>Produkteier GLN</th>
              <th style={{ cursor: props.isNedlagt ? 'default' : 'pointer' }}>
                {!props.isNedlagt && <IcebergIcon icon='plus' title='Ny' bold onClick={() => setShowCreateModal(true)} />}
              </th>
            </tr>
          </thead>
          <tbody className={Style.tbody} style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
            {props.produkteiergln.map(gln => (
              <tr key={gln.produkteierGlnId}>
                <td>{gln.gln} {gln.glnNavn && (' - ' + gln.glnNavn)}</td>
                <td className={Style.tdPopoverMenu}>
                  <PopoverMenu
                    onDelete={() => handleDelete(gln.produkteierGlnId)}
                    onEdit={() => handleEdit(gln)}
                    confirmDelete={true}
                    onlyView={props.isNedlagt} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {showEditModal && <EditProdukteierGLN show={showEditModal} setShow={setShowEditModal} gln={selectedGln} onUpdated={props.onUpdated} onlyView={props.isNedlagt} />}
      <CreateProdukteierGLN tsKundeId={props.tsKundeId} kundeNavn={props.kundeNavn} onUpdated={props.onUpdated} show={showCreateModal} setShow={setShowCreateModal} />
    </>
  )
}

export default ProdukteierGLN