import { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Table,
    InputGroup,
    Form,
    OverlayTrigger,
    Popover,
    Button,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller/dist/InfiniteScroll";
import querystring from "query-string";
import NyBrukerModal from "./components/NyBrukerModal";
import Filters from "./components/Filters";
import Export from "./components/Export";
import EditBruker from "./components/EditBruker";
import EditBrukerTilganger from "./components/EditBrukerTilganger";
import BrukerTilgangerDropdown from "./components/BrukerTilgangerDropdown";
import BulkEndringerModal from "./components/BulkEndringerModal";
import BrukereApi from "services/AdminportalenApi/BrukereApi";
import {
    BrukerWithTilgangerDto,
    BrukerSearchFilter,
    BrukerTilgangCommand,
} from "services/AdminportalenApi/BrukereApi/types";
import useDebounce from "utils/hooks/useDebounce";
import useIsMountedRef from "utils/hooks/useIsMountedRef";
import useToaster from "utils/hooks/useToaster";
import HttpErrorHelper from "utils/HttpErrorHelper";
import Style from "./index.module.css";
import { IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import ResetPasswordModal from "./components/ResetPasswordModal";
import IcebergBadge from "components/IcebergBadge";
import PopoverMenu from "components/PopoverMenu";
import ReinviteBrukerModal from "./components/ReinviteBrukerModal";
import LogInHistoryModal from "./components/LogInHistory";
import CommonLoader from "components/CommonLoader";
import PermissionsApi from "services/AdminportalenApi/PermissionsApi";
import { ValueDisplaynameDto } from "services/AdminportalenApi/PermissionsApi/types";
import PermissionsChangeLogModal from "./components/PermissionsChangeLogModal";
import TsKunderApi from "services/AdminportalenApi/TsKunderApi";


const Brukere = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const toaster = useToaster();

    const [brukere, setBrukere] = useState<BrukerWithTilgangerDto[]>([]);
    const [selectedBruker, setSelectedBruker] =
        useState<BrukerWithTilgangerDto>();
    const [showNyBrukerModal, setShowNyBrukerModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showEditTilgangerModal, setShowEditTilgangerModal] = useState<boolean>(false);
    const [showReinviteBrukerModal, setShowReinviteBrukerModal] = useState<boolean>(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState<boolean>(false);
    const [showLoginHistoryModal, setShowLoginHistoryModal] = useState<boolean>(false);
    const [showPermissionsChangeLogModal, setShowPermissionsChangeLogModal] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [initielleTilganger, setInitielleTilganger] = useState<BrukerTilgangCommand[]>([]);

    // parse querystring to filter
    const querystringToFilter = (): BrukerSearchFilter => {
        let parsedFilter = null;

        if (location.search && location.search.length > 0) {
            parsedFilter = querystring.parse(
                location.search.substring(1, location.search.length),
                { parseNumbers: true, parseBooleans: true, arrayFormat: "bracket" }
            );
        }

        let tsKundeId = parsedFilter?.tsKundeId?.toString() ?? null;
        let onBehalfOfTsKundeId =
            parsedFilter?.onBehalfOfTsKundeId?.toString() ?? null;
        let role = parsedFilter?.role ? decodeURIComponent(parsedFilter?.role?.toString()) : null;
        let accessTo = parsedFilter?.accessTo ? decodeURIComponent(parsedFilter?.accessTo?.toString()) : null;
        let hasNoAccessesString = parsedFilter?.hasNoAccesses?.toString();
        let passiveOnlyString = parsedFilter?.passiveOnly?.toString();

        const filter: BrukerSearchFilter = {
            query: parsedFilter?.query?.toString() || "",
            statusFilter: parsedFilter?.selectedStatusFilter?.toString() || "Alle",
            orderBy: parsedFilter?.selectedOrderBy?.toString() || "TsKundeNavnAsc",
            tsKundeId,
            onBehalfOfTsKundeId,
            role,
            accessTo,
            hasNoAccesses: hasNoAccessesString === "true",
            passiveOnly: passiveOnlyString === "true"
        };
        return filter;
    };

    const [filter, setFilter] = useState<BrukerSearchFilter>(
        querystringToFilter()
    );

    const [selectedBrukerIds, setSelectedBrukerIds] = useState<string[]>([]);
    const [checkAll, setCheckAll] = useState<boolean>(false);

    const isMountedRef = useIsMountedRef();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const totalCount = useRef(0);
    const [queryText, setQueryText] = useState<string>("");
    const [nextPageIsLoading, setNextPageIsLoading] = useState(false);

    useDebounce(() => setFilter({ ...filter, query: queryText }), 300, [
        queryText,
    ]);

    const loadBrukere = async (p: number) => {
        setIsLoading(true);
        if (isMountedRef.current) {
            //Trim tekst før man gjør API kall
            const trimmedFilter = {...filter, query: filter.query.trim()};
            const promise = BrukereApi.getBrukereCount(trimmedFilter);
            const result = await BrukereApi.getBrukereWithPermissions(trimmedFilter, p);
            totalCount.current = await promise;
            setIsLoading(false);
            // if page > 1, just append to list
            if (p > 1) {
                setBrukere((prev) => [...prev, ...result]);
            } else {
                setBrukere(result);
            }

            setNextPageIsLoading(false);
        }
    };

    useEffect(() => {
        if (page > 1) {
            loadBrukere(page);
        }
    }, [page]);

    const handleOrderByChanged = (value: string) => {
        let val:
            | "TsKundeNavnAsc"
            | "TsKundeNavnDesc"
            | "BrukerNavnAsc"
            | "BrukerNavnDesc"
            | "TilgangNavnAsc"
            | "TilgangNavnDesc"
            | "Nyeste"
            | "SistEndret" = "TsKundeNavnAsc";

        if (value === "TsKundeNavnDesc") {
            val = "TsKundeNavnDesc";
        } else if (value === "BrukerNavnAsc") {
            val = "BrukerNavnAsc";
        } else if (value === "BrukerNavnDesc") {
            val = "BrukerNavnDesc";
        } else if (value === "TilgangNavnAsc") {
            val = "TilgangNavnAsc";
        } else if (value === "TilgangNavnDesc") {
            val = "TilgangNavnDesc";
        } else if (value === "Nyeste") {
            val = "Nyeste";
        } else if (value === "SistEndret") {
            val = "SistEndret";
        }

        setFilter((prev) => ({ ...prev, orderBy: val }));
    };

    const handleFilterStatusOptionsChanged = (value: "Alle" | "Aktive" | "Deaktiverte" | null) => {
        if (value === null) {

        } else {
            setFilter((prev) => ({ ...prev, statusFilter: value }));
        }
    };

    const handleFilterStringValueChanged = (prop: string, value: string, name?: string) => {
        if (prop === "tsKundeId") {
            setFilter((prev) => ({ ...prev, tsKundeId: value }));
        } else if (prop === "onBehalfOfTsKundeId") {
            setFilter((prev) => ({ ...prev, onBehalfOfTsKundeId: value }));
        } else if (prop === "role") {
            setFilter((prev) => ({ ...prev, role: value }));
        } else if (prop === "accessTo") {
            setFilter((prev) => ({ ...prev, accessTo: value }));
        }
    };

    const handleFilterBooleanValuesChanged = (prop: string, value: boolean) => {
        switch (prop) {
            case "hasNoAccesses":
                setFilter((prev) => ({ ...prev, hasNoAccesses: value }));
                break;
            case "passiveOnly":
                setFilter((prev) => ({ ...prev, passiveOnly: value }));
                break;
        }
    };

    const handleLoadMore = () => {
        if (
            !isLoading &&
            brukere.length < totalCount.current &&
            isMountedRef.current &&
            !nextPageIsLoading
        ) {
            setNextPageIsLoading(true);
            setPage((prev) => prev + 1);
        }
    };

    const handleEditBruker = (bruker: BrukerWithTilgangerDto) => {
        setSelectedBruker((prev) => bruker);
        setShowEditModal(true);
    };

    // for new user 
    const handleAddBruker = () => {
        setSelectedBruker(undefined);
        setInitielleTilganger([]);
        setShowNyBrukerModal(true);
    };
    // for copying user tilganger
    const handleAddBrukerwithTilganger = (bruker: BrukerWithTilgangerDto) => {
        setSelectedBruker(bruker);
        setInitielleTilganger(mapBrukerWithTilgangerToBrukerTilgangCommands(bruker));
        setShowNyBrukerModal(true);
    };

    const handleEditBrukerTilganger = (bruker: BrukerWithTilgangerDto) => {
        setSelectedBruker((prev) => bruker);
        setShowEditTilgangerModal(true);
    };

    const handleReinviteBruker = (bruker: BrukerWithTilgangerDto) => {
        setSelectedBruker((prev) => bruker);
        setShowReinviteBrukerModal(true);
    };

    const handlePasswordReset = (bruker: BrukerWithTilgangerDto) => {
        setSelectedBruker((prev) => bruker);
        setShowResetPasswordModal(true);
    };

    const handleDeleteBruker = async (brukerId: string) => {
        try {
            await BrukereApi.deleteBruker(brukerId);
            toaster.success("Suksess", "Bruker slettet!");
            loadBrukere(page);
        } catch (err) {
            toaster.error("Noe gikk galt.", HttpErrorHelper.handleErrorMessage(err));
        }
    };

    const handleToggle = async (brukerId: string) => {
        try {
            await BrukereApi.toggleStatus(brukerId);
            toaster.success(
                "Suksess",
                (brukere.find((x) => x.brukerId === brukerId)?.isEnabled
                    ? "Bruker deaktivert."
                    : "Bruker reaktivert.")
            );
            loadBrukere(page);
        } catch (err) {
            toaster.error("Noe gikk galt.", HttpErrorHelper.handleErrorMessage(err));
        }
    };

    const handleShowLoginHistory = (bruker: BrukerWithTilgangerDto) => {
        setSelectedBruker((prev) => bruker);
        setShowLoginHistoryModal(true);
    }

    const handleShowPermissionsChangeLog = (bruker: BrukerWithTilgangerDto) => {
        setSelectedBruker((prev) => bruker);
        setShowPermissionsChangeLogModal(true);
    }

    const handleExportBrukere = async () => {
        await BrukereApi.exportToExcel(filter).then((response) => {
            const url = URL.createObjectURL(
                new Blob([response], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Brukere.xlsx");
            document.body.appendChild(link);
            link.click();
        });
    };

    const handleCheckAll = (on: boolean) => {
        setCheckAll(on);
        if (on) {
            setSelectedBrukerIds(brukere.map((x) => x.brukerId));
        } else {
            setSelectedBrukerIds([]);
        }
    };

    const ExternPopover = (externalTenantName: string) => {
        return (
            <Popover id="popover-menu" className={Style.popover}>
                <Popover.Body>
                    <p>Ekstern bruker: {externalTenantName}</p>
                </Popover.Body>
            </Popover>
        );
    };

    const filterToQuerystring = (filter: BrukerSearchFilter) => {
        let qs = "";
        if (filter.statusFilter) {
            qs = `${qs}selectedStatusFilter=${filter.statusFilter}`;
        }

        if (filter.orderBy) {
            qs = `${qs}&selectedOrderBy=${filter.orderBy}`;
        }

        if (filter.query) {
            setQueryText(filter.query);
            qs = `${qs}&query=${filter.query}`;
        }

        if (filter.tsKundeId) {
            qs = `${qs}&tsKundeId=${filter.tsKundeId}`;
        }

        if (filter.onBehalfOfTsKundeId) {
            qs = `${qs}&onBehalfOfTsKundeId=${filter.onBehalfOfTsKundeId}`;
        }

        if (filter.accessTo) {
            qs = `${qs}&accessTo=${encodeURIComponent(filter.accessTo)}`;
        }

        if (filter.role) {
            qs = `${qs}&role=${encodeURIComponent(filter.role)}`;
        }

        if (filter.hasNoAccesses) {
            qs = `${qs}&hasNoAccesses=${filter.hasNoAccesses}`;
        }

        if (filter.passiveOnly) {
            qs = `${qs}&passiveOnly=${filter.passiveOnly}`;
        }

        return qs;
    };

    // whenever filter changes, update the querystring
    const writeFilterToQuerystring = () => {
        if (isMountedRef.current) {
            const qs = filterToQuerystring(filter);
            navigate({
                pathname: "/Brukere",
                search: "?" + qs,
            });
        }
    };

    useEffect(() => {
        // write filter to querystring
        writeFilterToQuerystring();

        // load brukere based on new filter
        loadBrukere(1);

        // reset page to 1 when filter changes
        setPage(1);
    }, [filter]);

    // clear søketekst skal kun gjøre det, ikke resete verdier i filter
    const clearSearch = () => {
        setQueryText("");
    };

    // for filter component with new design
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [tsKundeNavn, setTsKundeNavn] = useState<string | null>(null);
    const [onBehalfOfTsKundeNavn, setOnBehalfOfTsKundeNavn] = useState<string | null>(null);
    const [accessToName, setAccessToName] = useState<string | null>(null);
    const [roleToName, setRoleToName] = useState<string | null>(null);

    useEffect(() => {
        if (filter.role) {
            PermissionsApi.getRoles()
                .then(response => {
                    const selectedRole = response.find(r => r.value === filter.role);
                    setRoleToName(selectedRole ? selectedRole.displayName : null);
                })
                .catch(error => {
                    console.error('Error fetching role display name:', error);
                    setRoleToName(null);
                });
        } else {
            setRoleToName(null);
        }
    }, [filter.role]);
    useEffect(() => {
        if (filter.accessTo) {
            PermissionsApi.getAccesses()
                .then(response => {
                    const selectedAccessTo = response.find(a => a.value === filter.accessTo);
                    setAccessToName(selectedAccessTo ? selectedAccessTo.displayName : null);
                })
                .catch(error => {
                    console.error('Error fetching accessTo display name:', error);
                    setAccessToName(null);
                });
        } else {
            setAccessToName(null);
        }
    }, [filter.accessTo]);


    useEffect(() => {
        if (filter.tsKundeId) {
            TsKunderApi.getById(filter.tsKundeId)
                .then(response => {
                    setTsKundeNavn(response.tsKundeNavn);
                })
                .catch(error => {
                    console.error('Error fetching tsKundeNavn:', error);
                    setTsKundeNavn(null);
                });
        } else {
            setTsKundeNavn(null);
        }
    }, [filter.tsKundeId]);

    useEffect(() => {
        if (filter.onBehalfOfTsKundeId) {
            TsKunderApi.getById(filter.onBehalfOfTsKundeId)
                .then(response => {
                    setOnBehalfOfTsKundeNavn(response.tsKundeNavn);
                })
                .catch(error => {
                    console.error('Error fetching tsKundeNavn:', error);
                    setOnBehalfOfTsKundeNavn(null);
                });
        } else {
            setOnBehalfOfTsKundeNavn(null);
        }
    }, [filter.onBehalfOfTsKundeId]);


    const [activeFilters, setActiveFilters] = useState<{ label: string, removeAction: () => void }[]>([]);
    useEffect(() => {
        let newFilters: { label: string, removeAction: () => void }[] = [];
        if (filter.statusFilter) {
            newFilters.push({ label: `Status: ${filter.statusFilter}`, removeAction: () => handleFilterStatusOptionsChanged(null) });
        }
        if (tsKundeNavn) {
            newFilters.push({ label: `Ansatt hos : ${tsKundeNavn}`, removeAction: () => handleFilterStringValueChanged('tsKundeId', null) });
        }
        if (onBehalfOfTsKundeNavn) {
            newFilters.push({ label: `På vegne av : ${onBehalfOfTsKundeNavn}`, removeAction: () => handleFilterStringValueChanged('onBehalfOfTsKundeId', null) });
        }
        if (accessToName) {
            newFilters.push({ label: `Tilgang til : ${accessToName}`, removeAction: () => handleFilterStringValueChanged('accessTo', null) });
        }
        if (roleToName) {
            newFilters.push({ label: `Rolle : ${roleToName}`, removeAction: () => handleFilterStringValueChanged('role', null) });
        }
        if (filter.hasNoAccesses) {
            newFilters.push({ label: 'Uten tilganger', removeAction: () => handleFilterBooleanValuesChanged('hasNoAccesses', null) });
        }
        if (filter.passiveOnly) {
            newFilters.push({ label: 'Lenge siden siste innlogging (9 mnd++)', removeAction: () => handleFilterBooleanValuesChanged('passiveOnly', null) });
        }

        setActiveFilters(newFilters);
    }, [filter, tsKundeNavn, onBehalfOfTsKundeNavn, accessToName, roleToName]);

    const removeFilter = (filter: string) => {
        const filterObject = activeFilters.find(x => x.label === filter);
        if (filterObject) {
            filterObject.removeAction();
        }
        setActiveFilters(prev => prev.filter(x => x.label !== filter));
    };

    const handleNullstill = () => {
        handleFilterStatusOptionsChanged('Alle');
        handleFilterStringValueChanged('tsKundeId', null);
        handleFilterStringValueChanged('onBehalfOfTsKundeId', null);
        handleFilterStringValueChanged('role', null);
        handleFilterStringValueChanged('accessTo', null);
        handleFilterBooleanValuesChanged('hasNoAccesses', null);
        handleFilterBooleanValuesChanged('passiveOnly', null);
    };
    const generateRandomNumber = (min: number, max: number) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    const mapBrukerWithTilgangerToBrukerTilgangCommands = (bruker: BrukerWithTilgangerDto): BrukerTilgangCommand[] => {
        console.log('bruker', bruker)
        return bruker.tilganger.flatMap(tilgang =>
            tilgang.apps.flatMap(app =>
                app.accesses.map(access => ({
                    id: access.id,
                    tsKundeId: tilgang.tsKundeId,
                    role: access.role,
                    accessTo: access.accessTo,
                    key: generateRandomNumber(0, 100000),
                    isInitial: true
                }))
            )
        );
    };

    return (
        <>
            <div
                className="container-fluid"
                style={{ marginTop: "20px", marginLeft: "5px", marginRight: "5px" }}
            >
                <Row style={{ marginTop: "0.5em" }}>
                    <Col>
                        <h2 className={Style.mainHeader}>Brukere</h2>
                    </Col>
                    <Col>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                columnGap: "10px",
                            }}
                        >
                            <BulkEndringerModal brukerIds={selectedBrukerIds} />
                            <Export
                                onHandleExport={handleExportBrukere}
                                exportCount={totalCount.current}
                            />
                            <IconButton variant="primary" icon="plus" onClick={handleAddBruker}>
                                Ny Bruker
                            </IconButton>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Søk etter bruker"
                                value={queryText}
                                onChange={(e) => setQueryText(e.target.value)}
                                style={{
                                    borderRight: "hidden",
                                    borderRadius: "24px 0px 0px 24px",
                                }}
                            />
                            <InputGroup.Text
                                style={{
                                    borderLeft: "hidden",
                                    borderRadius: "0px 24px 24px 0px",
                                }}
                            >
                                {filter.query.length > 0 ? (
                                    <IcebergIcon
                                        icon="close"
                                        cursor="pointer"
                                        onClick={clearSearch}
                                    />
                                ) : (
                                    <IcebergIcon icon="search" />
                                )}
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>

                    <Col>
                        <IconButton
                            variant='outline-primary'
                            icon="filter"
                            onClick={() => setShowFilter(!showFilter)}
                        >
                            Filter
                        </IconButton>
                        <Filters
                            selectedFilter={filter}
                            onFilterStatusChanged={handleFilterStatusOptionsChanged}
                            onFilterStringValuesChanged={handleFilterStringValueChanged}
                            onFilterBooleanValuesChanged={handleFilterBooleanValuesChanged}
                            show={showFilter}
                            onClose={() => setShowFilter(false)}
                        />
                    </Col>

                    <Col>
                        <span style={{ float: "right", padding: "8px", color: "#68778D" }}>
                            Viser {brukere.length} av {totalCount.current} Brukere
                        </span>
                    </Col>

                    <Col>
                        <Form.Select
                            aria-label="OrderBySelect"
                            onChange={(e) => handleOrderByChanged(e.target.value)}
                            value={filter.orderBy}
                            style={{ borderRadius: "24px" }}
                        >
                            <option value="TsKundeNavnAsc">TsKunde navn - stigende</option>
                            <option value="TsKundeNavnDesc">TsKunde navn - synkende</option>
                            <option value="BrukerNavnAsc">Brukernavn - stigende</option>
                            <option value="BrukerNavnDesc">Brukernavn - synkende</option>
                            <option value="TilgangNavnAsc">Tilganger navn - stigende</option>
                            <option value="TilgangNavnDesc">Tilganger navn - synkende</option>
                            <option value="Nyeste">Nyeste</option>
                            <option value="SistEndret">Sist endret</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            activeFilters.length > 0 &&
                            !(activeFilters.length === 1 && filter.statusFilter === 'Alle') && (
                                <div
                                    onClick={handleNullstill}
                                    style={{ cursor: 'pointer', display: 'inline-flex', verticalAlign: 'middle' }}
                                >
                                    <IcebergBadge variant="primary" style={{ verticalAlign: 'middle' }}>
                                        <span style={{ verticalAlign: 'middle' }}>Nullstill</span>
                                        <Button
                                            size="sm"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleNullstill();
                                            }}
                                            style={{
                                                marginLeft: '4px',
                                                padding: '0',
                                                verticalAlign: 'middle',
                                                paddingTop: '2px',
                                                paddingBottom: '2px',
                                                backgroundColor: 'transparent',
                                                borderColor: 'transparent',
                                                color: 'inherit'
                                            }}
                                        >
                                            x
                                        </Button>
                                    </IcebergBadge>
                                </div>
                            )}
                        {activeFilters.map((filter, index) => (
                            <div
                                key={index}
                                onClick={() => removeFilter(filter.label)}
                                style={{ cursor: 'pointer', display: 'inline-flex', verticalAlign: 'middle', margin: '2px' }}
                            >
                                <IcebergBadge variant="light" style={{ verticalAlign: 'middle' }}>
                                    <span style={{ verticalAlign: 'middle' }}>
                                        {filter.label}
                                    </span>
                                    <Button
                                        size="sm"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeFilter(filter.label);
                                        }}
                                        style={{
                                            marginLeft: '4px',
                                            padding: '0',
                                            verticalAlign: 'middle',
                                            paddingTop: '2px',
                                            paddingBottom: '2px',
                                            backgroundColor: 'transparent',
                                            borderColor: 'transparent',
                                            color: 'inherit'
                                        }}
                                    >
                                        x
                                    </Button>
                                </IcebergBadge>
                            </div>
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(isLoading && !nextPageIsLoading) ? (
                            <Row>
                                <Col>
                                    <CommonLoader text="Henter bruker..." color="#003b6e" />
                                </Col>
                            </Row>
                        ) : (
                            <>
                                {
                                    (brukere.length <= 0) && !nextPageIsLoading && (
                                        <div>
                                            <h3>Ingen treff. Husk å konsentrere deg før du prøver igjen. Gjerne uten åpenbare skrivefeil denne gangen</h3>
                                            <div>
                                                <iframe title="jhufg" src="https://giphy.com/embed/43mfUYlHAsGNW" width="480" height="204" allowFullScreen></iframe>
                                            </div>
                                        </div>
                                    )
                                }
                                {brukere.length > 0 && (
                                    <Row>
                                        <Col>
                                            <InfiniteScroll
                                                datalength={brukere.length}
                                                pageStart={1}
                                                loadMore={handleLoadMore}
                                                hasMore={totalCount.current > brukere.length}
                                                threshold={50}
                                                initialLoad={false}
                                                loader={
                                                    <div key={0}>
                                                        <CommonLoader text="Henter flere..." color="#003b6e" />
                                                    </div>
                                                }
                                            >
                                                <Table
                                                    responsive
                                                    hover
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Form.Check
                                                                    checked={checkAll}
                                                                    onChange={() => handleCheckAll(!checkAll)}
                                                                />
                                                            </th>
                                                            <th>Navn</th>
                                                            <th>Kontaktinfo</th>
                                                            <th>Status</th>
                                                            <th>Tilganger</th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {brukere.map((bruker) => {
                                                            return (
                                                                <tr key={bruker.brukerId}>
                                                                    <td className="fitContent">
                                                                        <Form.Check
                                                                            checked={selectedBrukerIds.includes(
                                                                                bruker.brukerId
                                                                            )}
                                                                            onChange={(e) => {
                                                                                if (
                                                                                    selectedBrukerIds.includes(
                                                                                        bruker.brukerId
                                                                                    )
                                                                                ) {
                                                                                    setSelectedBrukerIds(
                                                                                        selectedBrukerIds.filter(
                                                                                            (x) => x !== bruker.brukerId
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    setSelectedBrukerIds(
                                                                                        selectedBrukerIds.concat(
                                                                                            bruker.brukerId
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <p className={Style.brukerNavn}>
                                                                                {bruker.givenName + " " + bruker.surname}
                                                                                {bruker.externalTenantName &&
                                                                                    bruker.externalTenantName.toLowerCase() !==
                                                                                    "trades.no" && (
                                                                                        <>
                                                                                            {" "}
                                                                                            <OverlayTrigger
                                                                                                rootClose
                                                                                                placement="bottom"
                                                                                                trigger={["hover", "focus"]}
                                                                                                overlay={ExternPopover(
                                                                                                    bruker.externalTenantName
                                                                                                )}
                                                                                            >
                                                                                                <span>
                                                                                                    <IcebergIcon icon="handshake" />
                                                                                                </span>
                                                                                            </OverlayTrigger>
                                                                                        </>
                                                                                    )}
                                                                            </p>
                                                                            <p className={Style.tsKunde}>
                                                                                {bruker.tsKundeNavn +
                                                                                    (bruker.jobTitle
                                                                                        ? " - " +
                                                                                        bruker.jobTitle.toUpperCase()
                                                                                        : "")}
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <p className={Style.brukerNavn}>
                                                                            {bruker.email}
                                                                        </p>
                                                                        <p className={Style.tsKunde}>
                                                                            {bruker.mobile}
                                                                        </p>
                                                                    </td>
                                                                    <td>
                                                                        {bruker.isEnabled ? (
                                                                            <IcebergBadge variant="primary">
                                                                                Aktiv
                                                                            </IcebergBadge>
                                                                        ) : (
                                                                            <IcebergBadge variant="dark">
                                                                                Deaktivert
                                                                            </IcebergBadge>
                                                                        )}
                                                                    </td>
                                                                    <td >
                                                                        <BrukerTilgangerDropdown bruker={bruker} />
                                                                    </td>
                                                                    <td className="fitContent">
                                                                        <IconButton size="sm" variant='outline-primary' icon="key" onClick={() => handleEditBrukerTilganger(bruker)}>
                                                                            Tilganger
                                                                        </IconButton>
                                                                    </td>
                                                                    <td className="fitContent">
                                                                        {bruker.email.includes("@trades.no") && (
                                                                            <PopoverMenu
                                                                                bruker={bruker}
                                                                                onEdit={() => handleEditBruker(bruker)}
                                                                                onDelete={() =>
                                                                                    handleDeleteBruker(bruker.brukerId)
                                                                                }
                                                                                confirmDelete
                                                                                customOptions={[
                                                                                    {
                                                                                        title: "Reset passord",
                                                                                        type: "edit",
                                                                                        action: () =>
                                                                                            handlePasswordReset(bruker),
                                                                                    },
                                                                                    {
                                                                                        title: "Se innlogginger",
                                                                                        type: "edit",
                                                                                        action: () =>
                                                                                            handleShowLoginHistory(bruker),
                                                                                    },
                                                                                    {
                                                                                        title: "Se tilgangsendringer",
                                                                                        type: "edit",
                                                                                        action: () =>
                                                                                            handleShowPermissionsChangeLog(bruker),
                                                                                    },
                                                                                ]}
                                                                                onToggle={() => handleToggle(bruker.brukerId)}
                                                                                onCopyTilganger={() => handleAddBrukerwithTilganger(bruker)}
                                                                            />
                                                                        )}
                                                                        {!bruker.email.includes("@trades.no") && (
                                                                            <PopoverMenu
                                                                                bruker={bruker}
                                                                                onEdit={() => handleEditBruker(bruker)}
                                                                                onDelete={() =>
                                                                                    handleDeleteBruker(bruker.brukerId)
                                                                                }
                                                                                confirmDelete
                                                                                customOptions={[
                                                                                    {
                                                                                        title: "Reinviter",
                                                                                        type: "edit",
                                                                                        action: () =>
                                                                                            handleReinviteBruker(bruker),
                                                                                    },
                                                                                    {
                                                                                        title: "Se innlogginger",
                                                                                        type: "edit",
                                                                                        action: () =>
                                                                                            handleShowLoginHistory(bruker),
                                                                                    },
                                                                                    {
                                                                                        title: "Se tilgangsendringer",
                                                                                        type: "edit",
                                                                                        action: () =>
                                                                                            handleShowPermissionsChangeLog(bruker),
                                                                                    },
                                                                                ]}
                                                                                onToggle={() => handleToggle(bruker.brukerId)}
                                                                                onCopyTilganger={() => handleAddBrukerwithTilganger(bruker)}
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </InfiniteScroll>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </div>
            {showNyBrukerModal && (
                <NyBrukerModal
                    onClosed={() => setShowNyBrukerModal(false)}
                    onUpdated={(email) =>
                        setFilter({
                            query: email,
                            statusFilter: "Alle",
                            orderBy: "TsKundeNavnAsc",
                        })
                    }
                    initialTilganger={initielleTilganger}
                    tsKundeId={selectedBruker?.tsKundeId}
                />
            )}
            {showEditModal && (
                <EditBruker
                    bruker={selectedBruker}
                    show={showEditModal}
                    setShow={setShowEditModal}
                    onUpdated={() => loadBrukere(page)}
                />
            )}
            {showEditTilgangerModal && (
                <EditBrukerTilganger
                    bruker={selectedBruker}
                    show={showEditTilgangerModal}
                    setShow={setShowEditTilgangerModal}
                    onUpdated={() => loadBrukere(page)}
                />
            )}
            {showReinviteBrukerModal && (
                <ReinviteBrukerModal
                    bruker={selectedBruker}
                    show={showReinviteBrukerModal}
                    setShow={setShowReinviteBrukerModal}
                    onUpdated={() => loadBrukere(page)}
                />
            )}
            {showResetPasswordModal && (
                <ResetPasswordModal
                    bruker={selectedBruker}
                    show={showResetPasswordModal}
                    setShow={setShowResetPasswordModal}
                />
            )}
            {showLoginHistoryModal && (
                <LogInHistoryModal
                    bruker={selectedBruker}
                    show={showLoginHistoryModal}
                    setShow={setShowLoginHistoryModal}
                />
            )}
            {showPermissionsChangeLogModal && (
                <PermissionsChangeLogModal
                    bruker={selectedBruker}
                    show={showPermissionsChangeLogModal}
                    setShow={setShowPermissionsChangeLogModal}
                />
            )}
        </>
    );
};

export default Brukere;