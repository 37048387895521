import { useEffect, useState, useRef } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller/dist/InfiniteScroll';
import querystring from 'query-string';
import Export from "./Export";
import Filters from "./Filters";
import NyTsKunde from "./NyTsKunde";
import TsKundeRow from "./TsKundeRow";
import TsKunderApi from "services/AdminportalenApi/TsKunderApi";
import { TsKundeSearchFilter, TsKundeOverviewDto } from "services/AdminportalenApi/TsKunderApi/types";
import TsKunderExportApi from "services/AdminportalenApi/TsKunderExportApi";
import TjenestegruppeApi from "services/AdminportalenApi/TjenestegruppeApi";
import { Tjenestegruppe } from "services/AdminportalenApi/TjenestegruppeApi/types";
import useIsMountedRef from "utils/hooks/useIsMountedRef";
import useDebounce from 'utils/hooks/useDebounce';
import Style from './index.module.css';
import { IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import useLocalStorage from "utils/hooks/useLocalStorage";
import IcebergBadge from "components/IcebergBadge";
import CommonLoader from "components/CommonLoader";
import TjenesterApi from "services/AdminportalenApi/TjenesterApi";

const KundeSok = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [tsKunder, setTsKunder] = useState<TsKundeOverviewDto[]>([]);
    const [page, setPage] = useState<number>(1);
    const [aktivFilterFromLocalStorage, setAktivFilterFromLocalStorage] = useLocalStorage<string>('aktivFilter', 'Aktive');
    // parse querystring to filter
    const querystringToFilter = (): TsKundeSearchFilter => {
        let parsedFilter = null;

        if (location.search && location.search.length > 0) {
            parsedFilter = querystring.parse(
                location.search.substring(1, location.search.length),
                { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' },
            );
        }

        let overriddenOmsetningOnlyString = parsedFilter?.overriddenOmsetningOnly?.toString();
        let withoutAbonnementString = parsedFilter?.withoutAbonnement?.toString();
        let withoutAvtalerString = parsedFilter?.withoutAvtaler?.toString();
        let withoutFakturaAdresserString = parsedFilter?.withoutFakturaAdresser?.toString();
        let withoutKontaktpersonerString = parsedFilter?.withoutKontaktpersoner?.toString();

        const filter: TsKundeSearchFilter = {
            query: parsedFilter?.query?.toString() || '',
            statusOption: parsedFilter?.selectedStatusOption?.toString() || aktivFilterFromLocalStorage,
            orderBy: parsedFilter?.selectedOrderBy?.toString() || 'TsKundeNavnAsc',
            overriddenOmsetningOnly: overriddenOmsetningOnlyString === 'true',
            withoutAbonnement: withoutAbonnementString === 'true',
            withoutAvtaler: withoutAvtalerString === 'true',
            withoutFakturaAdresser: withoutFakturaAdresserString === 'true',
            withoutKontaktpersoner: withoutKontaktpersonerString === 'true',
            aktivtAbonnementTjenestegruppeId: parsedFilter?.aktivtAbonnementTjenestegruppeId?.toString() || null,
            aktivtAbonnementTjenesteId: parsedFilter?.aktivtAbonnementTjenesteId?.toString() || null,
            aktivAvtaleTjenesteGruppeId: parsedFilter?.aktivAvtaleTjenesteGruppeId?.toString() || null,
            harDagligLederUtenEpost: parsedFilter?.harDagligLederUtenEpost?.toString() === 'true' || null,
            manglerDagligLeder: parsedFilter?.manglerDagligLeder?.toString() === 'true' || null
        };

        return filter;
    }

    const [filter, setFilter] = useState<TsKundeSearchFilter>(querystringToFilter());

    const [selectedTjenestegruppeNavn, setSelectedTjenestegruppeNavn] = useState<string>('');
    const [selectedTjenesteNavn, setSelectedTjenesteNavn] = useState<string>('');
    const [selectedAktivAvtaleTjenestegruppeNavn, setSelectedAktivAvtaleTjenestegruppeNavn] = useState<string>('');
    const [tjenestegrupper, setTjenestegrupper] = useState<Tjenestegruppe[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextPageIsLoading, setNextPageIsLoading] = useState(false);

    const totalCount = useRef(0);

    const [queryText, setQueryText] = useState<string>("");

    useDebounce(() => setFilter({ ...filter, query: queryText }), 500, [queryText]);

    const isMountedRef = useIsMountedRef();

    const loadTsKunder = async (p: number) => {
        setIsLoading(true);
        if (isMountedRef.current) {
            var promise = TsKunderApi.getTsKunderCount(filter);
            const result = await TsKunderApi.getOverviewList(filter, p);
            totalCount.current = await promise;
            setIsLoading(false);
            // if page > 1 just append to list
            if (p > 1) {
                setTsKunder(prev => [...prev, ...result]);
            } else {
                setTsKunder(result);
            }

            setNextPageIsLoading(false);
        }
    }

    useEffect(() => {
        if (page > 1) {
            loadTsKunder(page);
        }
    }, [page]);

    const handleLoadMore = () => {
        if (!isLoading && (tsKunder.length < totalCount.current) && isMountedRef.current && !nextPageIsLoading) {
            setNextPageIsLoading(true);
            setPage(prev => prev + 1);
        }
    };

    const handleExportTsKunder = async (type: string, onlyKeepTjenestegruppeId?: string, onlyExportActive?: boolean) => {
        await TsKunderExportApi.exportToExcel(filter, type, onlyKeepTjenestegruppeId, onlyExportActive).then(response => {
            const url = URL.createObjectURL(
                new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }),
            );
            const link = document.createElement('a');
            link.href = url;
            let name = 'TsKunder';
            if (type === 'kontaktperson')
                name = 'Kontaktpersoner';
            else if (type === 'abonnement')
                name = 'Abonnementer';
            else if (type === 'avtale')
                name = 'Avtaler';
            link.setAttribute('download', `${name}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
    }

    const handleOrderByChanged = (value: string) => {
        let val: 'TsKundeNavnAsc' | 'TsKundeNavnDesc' | 'Nyeste' | 'SistEndret' = 'TsKundeNavnAsc'
        if (value === 'TsKundeNavnDesc') {
            val = 'TsKundeNavnDesc';
        } else if (value === 'Nyeste') {
            val = 'Nyeste';
        } else if (value === 'SistEndret') {
            val = 'SistEndret';
        }
        setFilter(prev => ({ ...prev, orderBy: val }));
    }

    const handleFilterBooleanValuesChanged = (prop: string, value: boolean) => {

        switch (prop) {
            case 'overriddenOmsetningOnly':
                setFilter(prev => ({ ...prev, overriddenOmsetningOnly: value }))
                break;
            case 'withoutAbonnement':
                setFilter(prev => ({ ...prev, withoutAbonnement: value }))
                break;
            case 'withoutAvtaler':
                setFilter(prev => ({ ...prev, withoutAvtaler: value }))
                break;
            case 'withoutFakturaAdresser':
                setFilter(prev => ({ ...prev, withoutFakturaAdresser: value }))
                break;
            case 'withoutKontaktpersoner':
                setFilter(prev => ({ ...prev, withoutKontaktpersoner: value }))
                break;
            case 'harDagligLederUtenEpost':
                setFilter(prev => ({ ...prev, harDagligLederUtenEpost: value }))
                break;
            case 'manglerDagligLeder':
                setFilter(prev => ({ ...prev, manglerDagligLeder: value }))
                break;
        }
    }

    const handleFilterStringValuesChanged = (prop: string, value: string) => {

        switch (prop) {
            case 'aktivtAbonnementTjenestegruppeId':
                setFilter(prev => ({ ...prev, aktivtAbonnementTjenestegruppeId: value }))
                break;
            case 'aktivtAbonnementTjenesteId':
                setFilter(prev => ({ ...prev, aktivtAbonnementTjenesteId: value }))
                break;
            case 'aktivAvtaleTjenesteGruppeId':
                setFilter(prev => ({ ...prev, aktivAvtaleTjenesteGruppeId: value }))
                break;
        }
    }

    const handleFilterStatusOptionsChanged = (value: 'Alle' | 'Aktive' | 'Nedlagte' | null) => {
        if (value === null) {

        } else {
            setAktivFilterFromLocalStorage(value);
            setFilter(prev => ({ ...prev, statusOption: value }))
        }
    }

    const loadTjenestegrupper = async () => {
        let res = await TjenestegruppeApi.getTjenestegrupper();
        setTjenestegrupper(res);
    };

    useEffect(() => {
        loadTjenestegrupper();
    }, []);

    useEffect(() => {
        if (filter.aktivtAbonnementTjenestegruppeId) {
            setSelectedTjenestegruppeNavn(tjenestegrupper.find(x => x.tjenesteGruppeId === filter.aktivtAbonnementTjenestegruppeId)?.tjenesteGruppeNavn || '')
        } else {
            setSelectedTjenestegruppeNavn('');
        }
    }, [filter.aktivtAbonnementTjenestegruppeId, tjenestegrupper]);

    const onTjenesteNavnSelected = (navn: string) => {
        setSelectedTjenesteNavn(navn);
    };

    const loadTjeneste = async (id: string) => {
        const result = await TjenesterApi.getById(id);
        setSelectedTjenesteNavn(result.tjenesteNavn);
    };

    useEffect(() => {
        if (filter.aktivtAbonnementTjenesteId) {
            loadTjeneste(filter.aktivtAbonnementTjenesteId);
        }
    }, [filter.aktivtAbonnementTjenesteId]);

    useEffect(() => {
        if (filter.aktivAvtaleTjenesteGruppeId) {
            setSelectedAktivAvtaleTjenestegruppeNavn(tjenestegrupper.find(x => x.tjenesteGruppeId === filter.aktivAvtaleTjenesteGruppeId)?.tjenesteGruppeNavn || '')
        } else {
            setSelectedAktivAvtaleTjenestegruppeNavn('');
        }
    }, [filter.aktivAvtaleTjenesteGruppeId, tjenestegrupper]);

    const filterToQuerystring = (filter: TsKundeSearchFilter) => {
        let qs = '';

        if (filter.statusOption) {
            qs = `${qs}selectedStatusOption=${filter.statusOption}`;
        }

        if (filter.orderBy) {
            qs = `${qs}&selectedOrderBy=${filter.orderBy}`;
        }

        if (filter.query) {
            setQueryText(filter.query);
            qs = `${qs}&query=${filter.query}`;
        }

        if (filter.overriddenOmsetningOnly) {
            qs = `${qs}&overriddenOmsetningOnly=${filter.overriddenOmsetningOnly}`;
        }

        if (filter.withoutAbonnement) {
            qs = `${qs}&withoutAbonnement=${filter.withoutAbonnement}`;
        }

        if (filter.withoutAvtaler) {
            qs = `${qs}&withoutAvtaler=${filter.withoutAvtaler}`;
        }

        if (filter.withoutFakturaAdresser) {
            qs = `${qs}&withoutFakturaAdresser=${filter.withoutFakturaAdresser}`;
        }

        if (filter.withoutKontaktpersoner) {
            qs = `${qs}&withoutKontaktpersoner=${filter.withoutKontaktpersoner}`;
        }

        if (filter.aktivtAbonnementTjenestegruppeId) {
            qs = `${qs}&aktivtAbonnementTjenestegruppeId=${filter.aktivtAbonnementTjenestegruppeId}`;
        }

        if (filter.aktivtAbonnementTjenesteId) {
            qs = `${qs}&aktivtAbonnementTjenesteId=${filter.aktivtAbonnementTjenesteId}`;
        }

        if (filter.aktivAvtaleTjenesteGruppeId) {
            qs = `${qs}&aktivAvtaleTjenesteGruppeId=${filter.aktivAvtaleTjenesteGruppeId}`;
        }

        if (filter.harDagligLederUtenEpost) {
            qs = `${qs}&harDagligLederUtenEpost=${filter.harDagligLederUtenEpost}`;
        }
        if (filter.manglerDagligLeder) {
            qs = `${qs}&manglerDagligLeder=${filter.manglerDagligLeder}`;
        }

        return qs;
    }

    // Whenever filter changes, update the querystring
    const writeFilterToQuerystring = () => {
        if (isMountedRef.current) {
            const qs = filterToQuerystring(filter);

            navigate({
                pathname: '/TsKunder',
                search: '?' + qs
            })
        }
    }

    useEffect(() => {
        // write filter to querystring
        writeFilterToQuerystring();

        // load tsKunder based on new filter
        loadTsKunder(1);

        // reset page to 1 when filter changes
        setPage(1);

    }, [filter]);

    const clearSearch = () => {
        setQueryText('');
    }
    // for filter component with new design
    const [showFilter, setShowFilter] = useState(false);
    const [activeFilters, setActiveFilters] = useState<{ label: string, removeAction: () => void }[]>([]);
    useEffect(() => {
        let newFilters: { label: string, removeAction: () => void }[] = [];
        if (selectedTjenestegruppeNavn) {
            newFilters.push({
                label: `Abonnent: ${selectedTjenestegruppeNavn}`,
                removeAction: () => {
                    setSelectedTjenestegruppeNavn('');
                    handleFilterStringValuesChanged('aktivtAbonnementTjenestegruppeId', null);
                }
            });
        }

        if (selectedTjenesteNavn) {
            newFilters.push({
                label: `Abonnent: ${selectedTjenesteNavn}`,
                removeAction: () => {
                    setSelectedTjenesteNavn('');
                    handleFilterStringValuesChanged('aktivtAbonnementTjenesteId', null);
                }
            });
        }

        if (selectedAktivAvtaleTjenestegruppeNavn) {
            newFilters.push({
                label: `Avtale: ${selectedAktivAvtaleTjenestegruppeNavn}`,
                removeAction: () => {
                    setSelectedAktivAvtaleTjenestegruppeNavn('');
                    handleFilterStringValuesChanged('aktivAvtaleTjenesteGruppeId', null);
                }
            });
        }
        if (filter.statusOption) {
            newFilters.push({ label: `Status: ${filter.statusOption}`, removeAction: () => handleFilterStatusOptionsChanged(null) });
        }
        if (filter.overriddenOmsetningOnly) {
            newFilters.push({ label: `Med overstyrt omsetning`, removeAction: () => handleFilterBooleanValuesChanged('overriddenOmsetningOnly', false) });
        }
        if (filter.withoutAbonnement) {
            newFilters.push({ label: `Uten abonnement`, removeAction: () => handleFilterBooleanValuesChanged('withoutAbonnement', false) });
        }
        if (filter.withoutAvtaler) {
            newFilters.push({ label: `Uten avtaler`, removeAction: () => handleFilterBooleanValuesChanged('withoutAvtaler', false) });
        }
        if (filter.withoutFakturaAdresser) {
            newFilters.push({ label: `Uten fakturaadresser`, removeAction: () => handleFilterBooleanValuesChanged('withoutFakturaAdresser', false) });
        }
        if (filter.withoutKontaktpersoner) {
            newFilters.push({ label: `Uten kontaktpersoner`, removeAction: () => handleFilterBooleanValuesChanged('withoutKontaktpersoner', false) });
        }
        if (filter.harDagligLederUtenEpost) {
            newFilters.push({ label: `Har daglig leder uten epost`, removeAction: () => handleFilterBooleanValuesChanged('harDagligLederUtenEpost', false) });
        }
        if (filter.manglerDagligLeder) {
            newFilters.push({ label: `Mangler daglig leder`, removeAction: () => handleFilterBooleanValuesChanged('manglerDagligLeder', false) });
        }
        setActiveFilters(newFilters);
    }, [filter, selectedTjenestegruppeNavn, selectedTjenesteNavn, selectedAktivAvtaleTjenestegruppeNavn]);

    const removeFilter = (filter: string) => {
        const filterObject = activeFilters.find(x => x.label === filter);
        if (filterObject) {
            filterObject.removeAction();
        }
        setActiveFilters(prev => prev.filter(x => x.label !== filter));
    }

    const handleNullstill = () => {
        handleFilterStatusOptionsChanged('Aktive');
        handleFilterStringValuesChanged('aktivtAbonnementTjenestegruppeId', null);
        handleFilterStringValuesChanged('aktivtAbonnementTjenesteId', null);
        handleFilterStringValuesChanged('aktivAvtaleTjenesteGruppeId', null);
        handleFilterBooleanValuesChanged('overriddenOmsetningOnly', false);
        handleFilterBooleanValuesChanged('withoutAbonnement', false);
        handleFilterBooleanValuesChanged('withoutAvtaler', false);
        handleFilterBooleanValuesChanged('withoutFakturaAdresser', false);
        handleFilterBooleanValuesChanged('withoutKontaktpersoner', false);
        handleFilterBooleanValuesChanged('harDagligLederUtenEpost', false);
        handleFilterBooleanValuesChanged('manglerDagligLeder', false);
    };

    return (
        <div
            className="container-fluid"
            style={{ marginTop: "20px", marginLeft: "5px", marginRight: "5px" }}
        >
            <Row style={{ marginTop: '0.5em' }}>
                <Col>
                    <h2 className={Style.mainHeader}>TsKunder</h2>
                </Col>
                <Col>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Export
                            onHandleExport={handleExportTsKunder}
                            exportCount={totalCount.current} />
                        <NyTsKunde />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Søk etter kunder"
                            value={queryText}
                            onChange={e => setQueryText(e.target.value)}
                            style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
                            autoFocus />
                        <InputGroup.Text style={{ borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
                            {filter.query.length > 0 ?
                                <IcebergIcon icon='close' cursor='pointer'
                                    onClick={clearSearch} />
                                : <IcebergIcon icon='search' />}
                        </InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                    <IconButton
                        variant="outline-primary"
                        icon="filter"
                        onClick={() => setShowFilter(!showFilter)}
                    >
                        Filter
                    </IconButton>

                    <Filters
                        selectedFilter={filter}
                        tjenesteNavnSelected={(navn: string) => onTjenesteNavnSelected(navn)}
                        selectedAktivAvtaleTjenestegruppeNavn={selectedAktivAvtaleTjenestegruppeNavn}
                        onFilterBooleanValuesChanged={handleFilterBooleanValuesChanged}
                        onFilterStringValuesChanged={handleFilterStringValuesChanged}
                        onFilterStatusChanged={handleFilterStatusOptionsChanged}
                        show={showFilter}
                        onClose={() => setShowFilter(false)}
                    />
                </Col>
                <Col>
                    <span style={{ marginTop: '5px', color: '#68778D', float: 'right' }}>Viser {tsKunder.length} av {totalCount.current} TsKunder</span>
                </Col>

                <Col>
                    <Form.Select aria-label="OrderBySelect" onChange={e => handleOrderByChanged(e.target.value)} value={filter.orderBy} style={{ borderRadius: '24px' }}>
                        <option value="TsKundeNavnAsc">Navn - stigende</option>
                        <option value="TsKundeNavnDesc">Navn - synkende</option>
                        <option value="Nyeste">Nyeste</option>
                        <option value="SistEndret">Sist endret</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        activeFilters.length > 0 &&
                        !(activeFilters.length === 1 && filter.statusOption === 'Aktive') && (
                            <div
                                onClick={handleNullstill}
                                style={{ cursor: 'pointer', display: 'inline-flex', verticalAlign: 'middle' }}
                            >
                                <IcebergBadge variant="primary" style={{ verticalAlign: 'middle' }}>
                                    <span style={{ verticalAlign: 'middle' }}>Nullstill</span>
                                    <Button
                                        size="sm"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleNullstill();
                                        }}
                                        style={{
                                            marginLeft: '4px',
                                            padding: '0',
                                            verticalAlign: 'middle',
                                            paddingTop: '2px',
                                            paddingBottom: '2px',
                                            backgroundColor: 'transparent',
                                            borderColor: 'transparent',
                                            color: 'inherit'
                                        }}
                                    >
                                        x
                                    </Button>
                                </IcebergBadge>
                            </div>
                        )}
                    {activeFilters.map((filter, index) => (
                        <div
                            key={index}
                            onClick={() => removeFilter(filter.label)}
                            style={{ cursor: 'pointer', display: 'inline-flex', verticalAlign: 'middle', margin: '2px' }}
                        >
                            <IcebergBadge variant="light" style={{ verticalAlign: 'middle' }}>
                                <span style={{ verticalAlign: 'middle' }}>
                                    {filter.label}
                                </span>
                                <Button
                                    size="sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeFilter(filter.label);
                                    }}
                                    style={{
                                        marginLeft: '4px',
                                        padding: '0',
                                        verticalAlign: 'middle',
                                        paddingTop: '2px',
                                        paddingBottom: '2px',
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                        color: 'inherit'
                                    }}
                                >
                                    x
                                </Button>
                            </IcebergBadge>
                        </div>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col>
                    {(isLoading && !nextPageIsLoading) ? (
                        <Row>
                            <Col>
                                <CommonLoader text="Henter kunder..." color="#003b6e" />
                            </Col>
                        </Row>
                    ) : (
                        <>
                            {
                                (tsKunder.length <= 0) && !nextPageIsLoading && (
                                    <div>
                                        <h3>Ingen treff. Husk å konsentrere deg før du prøver igjen. Gjerne uten åpenbare skrivefeil denne gangen</h3>
                                        <div>
                                            <iframe title="jhufg" src="https://giphy.com/embed/Vfie0DJryAde8" width="384" height="480" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                )
                            }
                            {tsKunder.length > 0 && (
                                <Row>
                                    <Col>
                                        <InfiniteScroll
                                            datalength={tsKunder.length}
                                            pageStart={1}
                                            loadMore={handleLoadMore}
                                            hasMore={totalCount.current > tsKunder.length}
                                            threshold={50}
                                            initialLoad={false}
                                            loader={
                                                <div key={0}>
                                                    <CommonLoader text="Henter flere..." color="#003b6e" />
                                                </div>
                                            }
                                        >
                                            <Table responsive style={{ borderCollapse: 'collapse', borderTop: 'none' }} hover>
                                                <thead>
                                                    <tr>
                                                        <th>Navn</th>
                                                        <th>Aktive abonnement</th>
                                                        <th>Aktive avtaler</th>
                                                        <th>Organisasjonsnummer</th>
                                                        <th>Omsetning (NOK)</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody className={Style.tableBody} style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
                                                    {
                                                        tsKunder.map(t =>
                                                            <TsKundeRow key={t.tsKundeId} kunde={t} onSelected={() => navigate(`/TsKunder/${t.tsKundeId}/kundeKort`)} />
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </InfiniteScroll>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </div >
    );
}

export default KundeSok;
