import axios from "axios";
import { CreatePONummerCommand, UpdatePONummerCommand, PONummer } from "./types";

import { getBaseUrl } from "..";

const baseUrl = getBaseUrl() + "/PONummer";

const PONummerApi = {
  getPONummer: (ponummerId: string): Promise<PONummer> => 
    axios.get(`${baseUrl}/${ponummerId}`).then(response => response.data),

  createPONummer: (command: CreatePONummerCommand) =>
    axios.post(baseUrl, command).then(response=> response.data),

  updatePONummer: (command: UpdatePONummerCommand) => 
    axios.put(baseUrl, command).then(response => response.data),

  deletePONummerRow: (poNummerId: string, tjenesteGruppeId: string) => 
    axios.delete(`${baseUrl}/${poNummerId}/row/${tjenesteGruppeId}`),

  deletePONummer: (poNummerId: string) =>
    axios.delete(`${baseUrl}/${poNummerId}`)
}

export default PONummerApi;