import { IconButton } from "@tradesolution/iceberg-ui-react";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { PrisIntervallItem } from "services/AdminportalenApi/TjenestePriserApi/types";
import formatAsCurrency from "utils/formatAsCurrency";
import Style from './index.module.scss';

export class PriceModelMatrice {

    constructor(omsetningsGrenser: number[], volumGrenser: number[], priser: string[][]) {
        this.omsetningsGrenser = omsetningsGrenser;
        this.volumGrenser = volumGrenser;
        this.priser = priser;
    }
    priser: string[][];
    omsetningsGrenser: number[] = [];
    volumGrenser: number[] = [];

    getPrisIntervall(): PrisIntervallItem[] {
        return this.omsetningsGrenser.map((omsetningsGrense, x) => {
            return this.volumGrenser.map((volumGrense, y) => {
                const price = parseFloat(this.priser[y][x]);
                return {
                    antall: volumGrense,
                    omsetning: omsetningsGrense,
                    pris: isNaN(price) ? 0 : price
                };
            });
        }).flat();
    }

    addOmsetningsGrense(omsetningsGrense: number) {
        // finnes omsetningsGrense fra før?
        if (this.omsetningsGrenser.indexOf(omsetningsGrense) > -1) return;

        const index = this.omsetningsGrenser.findIndex(x => x < omsetningsGrense);
        if (index === -1) {
            // this is the last omsetnings
            this.omsetningsGrenser.push(omsetningsGrense);
            this.priser.forEach(x => x.push('0'));
        } else {
            this.omsetningsGrenser.splice(index, 0, omsetningsGrense);
            this.priser.forEach(x => x.splice(index, 0, '0'));
        }
    }

    removeOmsetningsGrense(omsetningsGrense: number) {
        const index = this.omsetningsGrenser.findIndex(x => x == omsetningsGrense);
        if (index > -1) {
            this.omsetningsGrenser.splice(index, 1);
            this.priser.forEach(x => x.splice(index, 1));
        }
    }

    addVolumGrense(volumGrense: number) {
        // finnes volumgrense fra før?
        if (this.volumGrenser.indexOf(volumGrense) > -1) return;

        const index = this.volumGrenser.findIndex(x => x > volumGrense);

        if (index === -1) {
            // this is the highest volumgrense
            this.volumGrenser.push(volumGrense);
            this.priser.push(this.omsetningsGrenser.map(x => '0'));
        } else {
            this.volumGrenser.splice(index, 0, volumGrense);
            this.priser.splice(index, 0, this.omsetningsGrenser.map(x => '0'));
        }
    }

    removeVolumGrense(volumGrense: number) {
        const index = this.volumGrenser.findIndex(x => x == volumGrense);
        if (index > -1) {
            this.volumGrenser.splice(index, 1);
            this.priser.splice(index, 1);
        }
    }

    updatePrice(x: number, y: number, price: string) {
        this.priser[y][x] = price;
    }
}

interface Props {
    prisIntervall?: PrisIntervallItem[];
    onPriceModelUpdated: (priceModel: PriceModelMatrice) => void;
}

const OmsetningVolumMatrix = (props: Props) => {
    const defaultOmsetningsGrenser = Array.from(new Set(props.prisIntervall?.map(x => x.omsetning).sort((x, y) => y - x))) || [3000000, 2000000, 1000000];
    const [omsetningsGrenser, setOmsetningsGrenser] = useState<number[]>(defaultOmsetningsGrenser);

    const defaultVolumGrenser = Array.from(new Set(props.prisIntervall?.map(x => x.antall).sort((x, y) => x - y))) || [1, 100, 200, 500, 1000];
    const [volumSteg, setVolumSteg] = useState<number[]>(defaultVolumGrenser);

    const [newOmsetningsGrense, setNewOmsetningsGrense] = useState<number | undefined>();
    const [newStep, setNewStep] = useState<number | undefined>();

    const [validOmsetning, setValidOmsetning] = useState<boolean>(true);
    const [validVolum, setValidVolum] = useState<boolean>(true);

    const getDefaultMatrice = (): string[][] => {
        if (props.prisIntervall) {
            return defaultVolumGrenser.map(o => defaultOmsetningsGrenser.map(v => props.prisIntervall.find(x => x.antall === o && x.omsetning === v)?.pris.toString() || '0'));
        }
        return volumSteg.map(o => omsetningsGrenser.map(v => '0'));
    }

    const [priceModel, setPriceModel] = useState<PriceModelMatrice>(new PriceModelMatrice(omsetningsGrenser, volumSteg, getDefaultMatrice()));

    const addNewOmsetning = () => {
        priceModel.addOmsetningsGrense(newOmsetningsGrense);
        setPriceModel(new PriceModelMatrice(priceModel.omsetningsGrenser, priceModel.volumGrenser, priceModel.priser));
    };

    const addNewStep = () => {
        priceModel.addVolumGrense(newStep);
        setPriceModel(new PriceModelMatrice(priceModel.omsetningsGrenser, priceModel.volumGrenser, priceModel.priser));
    };

    const updateNewOmsetningsGrense = (e: any) => {
        if (e.target.value) {
            const parsedValue = parseInt(e.target.value);
            setNewOmsetningsGrense(parsedValue);
            if (parsedValue < 0) {
                setValidOmsetning(false);
            }
            else {
                setValidOmsetning(true);
            }
        } else {
            setNewOmsetningsGrense(undefined);
            setValidOmsetning(true);
        }
    };

    const updateNewStep = (e: any) => {
        if (e.target.value) {
            const parsedValue = parseInt(e.target.value);
            setNewStep(parsedValue);
            if (parsedValue <= 0) {
                setValidVolum(false);
            } else {
                setValidVolum(true);
            }
        } else {
            setNewStep(undefined);
            setValidVolum(true);
        }
    };

    const removeStep = (step: number) => {
        priceModel.removeVolumGrense(step);
        setPriceModel(new PriceModelMatrice(priceModel.omsetningsGrenser, priceModel.volumGrenser, priceModel.priser));
    };

    const removeOmsetningsGrense = (o: number) => {
        priceModel.removeOmsetningsGrense(o);
        setPriceModel(new PriceModelMatrice(priceModel.omsetningsGrenser, priceModel.volumGrenser, priceModel.priser));
    };

    const updatePrice = (x: number, y: number, e: any) => {
        priceModel.updatePrice(x, y, e.target.value);
        setPriceModel(new PriceModelMatrice(priceModel.omsetningsGrenser, priceModel.volumGrenser, priceModel.priser));
    };

    useEffect(() => {
        props.onPriceModelUpdated(priceModel);
    }, [priceModel]);

    return (
        <Card style={{ marginTop: "1rem" }}>
            <Card.Body>
                <div className="table-responsive">
                    <Table size="sm" className={Style.matrixTable}>
                        <thead>
                            <tr>
                                <th></th>
                                {priceModel.omsetningsGrenser.map(o =>
                                    <th key={o}>{`${formatAsCurrency(o)}`} <IconButton icon="trash" size="sm" title="Fjern" variant="outline-default" onClick={() => removeOmsetningsGrense(o)} /></th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {priceModel.volumGrenser.map((v, y) => <tr key={`volum-${v}`}>
                                <td>{v} <IconButton icon="trash" size="sm" title="Fjern" variant="outline-default" onClick={() => removeStep(v)} /></td>
                                {priceModel.omsetningsGrenser.map((o, x) =>
                                    <td key={`volum-${v}-omsetning-${o}`}><Form.Control size="sm" value={priceModel.priser[y][x]} onChange={e => updatePrice(x, y, e)} /></td>
                                )}
                            </tr>)}
                        </tbody>
                    </Table>
                </div>
                <Row>
                    <Col>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                size="sm"
                                value={newStep}
                                onChange={updateNewStep}
                                placeholder="Fra og med volum. 1 er minsteverdi"
                                isInvalid={!validVolum}
                            />
                            <Button disabled={!newStep || !validVolum} size="sm" variant="outline-primary" onClick={addNewStep}>+ volum</Button>
                            <Form.Control.Feedback type="invalid">
                                Volum må være større eller lik 1
                            </Form.Control.Feedback>
                        </InputGroup>

                    </Col>
                    <Col>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                size="sm"
                                value={newOmsetningsGrense}
                                isInvalid={!validOmsetning}
                                onChange={updateNewOmsetningsGrense}
                                placeholder="Fra og med omsetning"
                            />
                            <Button disabled={!newOmsetningsGrense || !validOmsetning} size="sm" variant="outline-primary" onClick={addNewOmsetning}>+ omsetning</Button>
                            <Form.Control.Feedback type="invalid">
                                Omsetning må være større enn 0
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                </Row>
            </Card.Body>
        </Card>

    );
};

export default OmsetningVolumMatrix;