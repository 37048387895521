import { IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import AccessToSelect from "components/AccessToSelect";
import RoleSelect from "components/RoleSelect";
import { Table } from "react-bootstrap";
import { Permission } from "services/AdminportalenApi/PermissionsApi/types";

interface Props {
    existingPermissions: Permission[];
    addedPermissions: Permission[];
    onExistingUpdated: (permissions: Permission[]) => void;
    onAddedUpdated: (permissions: Permission[]) => void;
}

const TilgangerTable = (props: Props) => {
    const handleAccessToUpdated = (index: number, value: string) => {
        const newTilganger = [...props.addedPermissions];
        newTilganger[index].accessTo = value;
        props.onAddedUpdated(newTilganger);
    }

    const handleRoleUpdated = (index: number, value: string) => {
        const newTilganger = [...props.addedPermissions];
        newTilganger[index].role = value;
        props.onAddedUpdated(newTilganger);
    }

    const handleAddTilgangClicked = () => {
        props.onAddedUpdated([...props.addedPermissions, {} as Permission]);
    }

    const handleRemoveAddedTilgangClicked = (index: number) => {
        const newTilganger = [...props.addedPermissions];
        newTilganger.splice(index, 1);
        props.onAddedUpdated(newTilganger);
    };

    const handleRemoveExistingTilgangClicked = (index: number) => {
        const newTilganger = [...props.existingPermissions];
        newTilganger.splice(index, 1);
        props.onExistingUpdated(newTilganger);
    };

    return (
        <>
        <IconButton  style={{ float: 'right' }} icon='plus' variant='outline-primary' onClick={handleAddTilgangClicked} />
        <Table size="sm" borderless hover>
            <thead>
                <tr>
                    <th>Tilgang til</th>
                    <th>Rolle</th>
                    <th className="fitContent"> 
                    </th>
                </tr>
            </thead>
            <tbody style={{ border: "0" }}>
                {props.existingPermissions.map((t, i) =>
                    <tr key={t.id}>
                        <td>
                            {t.accessToDisplayName || ''}
                        </td>
                        <td>
                            {t.roleDisplayName || ''}
                        </td>
                        <td className="fitContent p-0">
                            <IconButton
                                size="sm"
                                variant="outline-danger"
                                title="Fjern tilgang"
                                icon="trash"
                                onClick={() => handleRemoveExistingTilgangClicked(i)} />
                        </td>
                    </tr>)}
                {props.addedPermissions.map((t, i) =>
                    <tr key={i}>
                        <td>
                            <AccessToSelect
                                size="sm"
                                selected={t.accessTo || ''}
                                onChange={e => handleAccessToUpdated(i, e)}
                                disabled={t.id?.length > 0} />
                        </td>
                        <td>
                            <RoleSelect
                                size="sm"
                                selected={t.role || ''}
                                onChange={e => handleRoleUpdated(i, e)}
                                disabled={t.id?.length > 0} />
                        </td>
                        <td className="fitContent  p-0">
                            <IconButton
                                size="sm"
                                variant="outline-danger"
                                title="Fjern tilgang"
                                icon="trash"
                                onClick={() => handleRemoveAddedTilgangClicked(i)} />
                        </td>
                    </tr>)}
            </tbody>
        </Table>
        </>        
    );
}

export default TilgangerTable;