import { Col, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DatePeriod } from "./DatePeriod";

interface Props {
    year: number;
    month: number;
    onChange: (period: DatePeriod) => void;
}

const MonthPeriodPicker = (props: Props) => {

    const handleYearChanged = (value: string) => {
        const year = parseInt(value);
        props.onChange({
            year: year,
            month: props.month
        });
    }

    const handleMonthChanged = (value: string) => {
        const month = parseInt(value);
        props.onChange({
            year: props.year,
            month: month
        });
    }

    return (
        <>
            <Form.Group as={Col}>
                <Form.Label>Måned</Form.Label>
                <Form.Select value={props.month} onChange={e => handleMonthChanged(e.target.value)}>
                    <option value={0}>Januar</option>
                    <option value={1}>Februar</option>
                    <option value={2}>Mars</option>
                    <option value={3}>April</option>
                    <option value={4}>Mai</option>
                    <option value={5}>Juni</option>
                    <option value={6}>Juli</option>
                    <option value={7}>August</option>
                    <option value={8}>September</option>
                    <option value={9}>Oktober</option>
                    <option value={10}>November</option>
                    <option value={11}>Desember</option>
                </Form.Select>
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>År</Form.Label>
                <Form.Select value={props.year} onChange={e => handleYearChanged(e.target.value)}>
                    <option>{new Date().getFullYear() - 1}</option>
                    <option>{new Date().getFullYear()}</option>
                    <option>{new Date().getFullYear() + 1}</option>
                </Form.Select>
            </Form.Group>
        </>
    )
}

export default MonthPeriodPicker;