import React, { useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import PopoverMenu from 'components/PopoverMenu';

import DetailsModal from './components/DetailsModal';
import EditDetailsModal from './components/EditDetailsModal';
import EditExternalGroupAccessesModal from './components/EditExternalGroupAccessesModal';
import CreateExternalAdGroup from './components/CreateExternalAdGroup';

import ExternalAdSourcesApi from 'services/AdminportalenApi/ExternalAzureAdSourcesApi';
import { ExternalAzureAdSourceDto } from 'services/AdminportalenApi/ExternalAzureAdSourcesApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

const EksterneAdGrupper: React.FC = () => {

  const isMountedRef = useIsMountedRef(); 

  const toaster = useToaster();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [externalAdGroups, setExternalAdGroups] = useState<ExternalAzureAdSourceDto[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<ExternalAzureAdSourceDto>();
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showEditDetailsModal, setShowEditDetailsModal] = useState<boolean>(false);
  const [showExternalAccessesEditModal, setShowExternalAccessesEditModal] = useState<boolean>(false);

  const loadExternalGroups = async () => {

    setIsLoading(prev => true);
    if(isMountedRef.current){

      const result = await ExternalAdSourcesApi.getExternalAdSources();   
      setExternalAdGroups(result);

    }
    setIsLoading(false);
  }

  const handleDeleteGroup = async(id: string) => {
    try {
      await ExternalAdSourcesApi.deleteExternalSource(id)
      toaster.success('Suksess', 'Ekstern Ad gruppe fjernet!');
      loadExternalGroups()
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  useEffect(() => {
    loadExternalGroups();
  }, [])

  return (
    <>
      <div className={Style.container}>
        <Row style={{marginTop: '0.5em'}}>            
          <Col>
            <h2 className={Style.mainHeader}>Eksterne Ad grupper</h2>
          </Col>
          <Col>
            <div style={{display: 'flex', justifyContent: 'end'}}>
              <CreateExternalAdGroup onUpdated={() => loadExternalGroups()} />
            </div> 
          </Col>
        </Row>

        <Row>
          <Col>
            {
              isLoading && (externalAdGroups.length <= 0) && 
              <Row>
                <Col>
                    <CommonLoader text="Henter eksterne ad grupper..." color="#003b6e" />
                </Col>
              </Row>
            }
            {
              !isLoading && (externalAdGroups.length <= 0) && 
              <div>
                <h3>Ingen treff</h3>
              </div>
            }
            {
              externalAdGroups.length > 0 &&
              <Row>
                <Col>
                  <Table responsive style={{borderCollapse: 'collapse', borderTop: 'none'}} hover>
                    <thead style={{color: '#68778D', fontWeight: 'normal'}}>
                      <tr>
                        <th>Kunde</th>
                        <th>Beskrivelse</th>
                        <th>Gruppenavn</th>
                        <th>Tilganger</th>
                        <th>Medlemmer</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className={Style.tableBody} style={{borderTop: 'none', border: '1px solid #DCDCDC'}}>
                      {
                        externalAdGroups.sort((a, b) => a.tsKundeName.localeCompare(b.tsKundeName)).map(externalAdGroup => 
                          {
                            return (
                            <tr key={externalAdGroup.tenantId + " - " + externalAdGroup.groupObjectId}>
                              <td>{externalAdGroup.tsKundeName}</td>
                              <td>{externalAdGroup.name}</td>
                              <td>{externalAdGroup.groupName}</td>
                              <td>{externalAdGroup.permissions?.length ?? 0} tilganger</td>
                              <td>{externalAdGroup.membersOnLastCheck?.length ?? 0} medlemmer</td>  
                              <td width={35}>
                                <PopoverMenu 
                                  onEdit={() => {
                                    setSelectedGroup(externalAdGroup);
                                    setShowEditDetailsModal(true);
                                  }}
                                  onDelete={() => handleDeleteGroup(externalAdGroup.id)}
                                  confirmDelete
                                  customOptions={[
                                    {
                                      title: 'Vis detaljer',
                                      type: 'view',
                                      action: () => {
                                        setSelectedGroup(externalAdGroup);
                                        setShowDetailsModal(true);
                                      }
                                    }, 
                                    {
                                      title: 'Endre tilganger',
                                      type: 'edit',
                                      action: () => {
                                        setSelectedGroup(externalAdGroup);
                                        setShowExternalAccessesEditModal(true);
                                      }
                                    }
                                  ]}/>
                              </td>  
                            </tr>
                            )
                          }
                        )
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </div>

      {showDetailsModal && <DetailsModal show={showDetailsModal} setShow={setShowDetailsModal} externalGroup={selectedGroup} />}
      {showEditDetailsModal && <EditDetailsModal show={showEditDetailsModal} setShow={setShowEditDetailsModal} externalGroup={selectedGroup} onUpdated={() => loadExternalGroups()} />}
      {showExternalAccessesEditModal && <EditExternalGroupAccessesModal show={showExternalAccessesEditModal} setShow={setShowExternalAccessesEditModal} externalGroup={selectedGroup} onUpdated={() => loadExternalGroups()} />}
    </>
  );
}

export default EksterneAdGrupper;