import { Col, Row } from "react-bootstrap";
import { getAppIcon } from "components/IcebergIcon/utils";
import { BrukerWithTilgangerDto, TilgangDto } from "services/AdminportalenApi/BrukereApi/types";
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";

interface Props {
  bruker: BrukerWithTilgangerDto;
}

const BrukerTilgangerDropdown = (props: Props) => {

  const getDistinctAppNames = (tilgang: TilgangDto[]): string[] => {
    const appNames: string[] = [];
    tilgang.flatMap(o => o.apps).forEach(app => {
      if (!appNames.includes(app.appName)) {
        appNames.push(app.appName);
      }
    });
    return appNames;
  };

  return (
    <div>
      <Row>
        <Col>
          {props.bruker.tilganger.length > 0
            ? props.bruker.tilganger.length > 1
              ? ` ${props.bruker.tilganger.length} kunder`
              : props.bruker.tilganger[0].tsKundeNavn
            : ""}
        </Col>
        <Col>
          <span>
            {props.bruker.accessCount > 0 &&
              getDistinctAppNames(props.bruker.tilganger)
                .sort((a, b) => a.localeCompare(b))
                .map((app) => (
                  <IcebergIcon
                    key={Math.random()}
                    icon={getAppIcon(app)}
                    title={app}
                  />
                ))
            }
          </span>
        </Col>
        <Col>
          {props.bruker.roleCount > 0
            ? props.bruker.roleCount > 1
              ? props.bruker.roleCount + " roller"
              : props.bruker.accessCount > 1
                ? "1 rolle"
                : props.bruker.tilganger[0].apps[0].accesses[0].roleDisplayName
            : ""}
        </Col>
      </Row>
    </div>
  );
};

export default BrukerTilgangerDropdown;