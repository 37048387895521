import { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import PermissionsApi from "services/AdminportalenApi/PermissionsApi";
import { ValueDisplaynameDto } from "services/AdminportalenApi/PermissionsApi/types";

interface Props {
  selected: string | undefined;
  onChange: (accessTo: string) => void;
  disabled?: boolean;
  size?: "sm" | "lg";
  style?: React.CSSProperties;
}

const AccessToSelect = (props: Props) => {
  const [accessTos, setAccessTos] = useState<ValueDisplaynameDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadAccessTos = async () => {
    setLoading(true);
    const res = await PermissionsApi.getAccesses();
    setAccessTos(res);
    setLoading(false);
  };

  const handleChange = (value: string | undefined) => {
    props.onChange(value);
  }

  useEffect(() => {
    loadAccessTos();
  }, []);

  { loading && <Spinner animation="border" /> }

  return (
    <>
      <Form.Select
        size={props.size}
        aria-label="AccessToSelect"
        style={{ ...props.style, maxWidth: props.style?.width ? undefined : '250px' }}
        onChange={e => handleChange(e.target.value)} value={props.selected ?? ""} disabled={props.disabled}>
        <option value="">Velg tilgang</option>
        {
          accessTos.map(x =>
            <option key={x.value} value={x.value}>{x.displayName}</option>
          )
        }
      </Form.Select>
      {props.selected && accessTos.map(o => o.value).indexOf(props.selected) === -1 && <Form.Text className="error-text">
        {props.selected} er ikke en gyldig tilgang
      </Form.Text>}
    </>
  );
};

export default AccessToSelect;