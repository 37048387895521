
import { IconButton } from '@tradesolution/iceberg-ui-react';
import TjenesegruppeSelect from 'components/TjenestegruppeSelect';
import { useState, useEffect } from 'react'
import { Alert, Form, Modal, Spinner } from 'react-bootstrap';
import FakturaSpesifiseringApi from 'services/AdminportalenApi/FakturaSpesifiseringApi';
import { EpostSentStatus, SendableFakturagrunnlagViewModel, SendSpesifiseringCommand, SendSpesifiseringCommandResponse } from 'services/AdminportalenApi/FakturaSpesifiseringApi/types';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';


interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}


const SendFakturadetaljer = (props: Props) => {
  const toaster = useToaster();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedTjenesteGruppeId, setSelectedTjenesteGruppeId] = useState<string | undefined>();
  const [selectedPeriode, setSelectedPeriode] = useState<SendableFakturagrunnlagViewModel | undefined>();
  const [periodeInfo, setPeriodeInfo] = useState<SendableFakturagrunnlagViewModel[] | undefined>();
  const [sendSpesifiseringResponse, setSendSpesifiseringResponse] = useState<SendSpesifiseringCommandResponse | undefined>();

  const loadFaktureringsperiode = async () => {
    setIsLoading(true);
    const response = await FakturaSpesifiseringApi.getSendableFakturagrunnlag(selectedTjenesteGruppeId);
    setPeriodeInfo(response);
    setIsLoading(false);
  }

  useEffect(() => {
    if (selectedTjenesteGruppeId) {
      loadFaktureringsperiode();
    }
  }, [selectedTjenesteGruppeId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const command: SendSpesifiseringCommand = {
      periode: selectedPeriode.periode,
      tjenesteGruppeId: selectedTjenesteGruppeId,
      eksportHodeId: selectedPeriode.eksportHodeId
    };

    try {
      const response = await FakturaSpesifiseringApi.sendSpesifisering(command);
      setSendSpesifiseringResponse(response);
      toaster.success('Suksess', 'Fakturaspesifisering er sendt');;
    }
    catch (err) {
      toaster.error('Noe gikk galt', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const handlePeriodeSelected = (periode: string) => {
    const selected = periodeInfo?.find(p => p.periode === periode);
    setSelectedPeriode(selected);
  };

  const handleClose = () => {
    props.setShow(false);
  }

  const formatEpostStatuser = (epostStatus: EpostSentStatus) => {
    return <>
      <span>{epostStatus.email}</span><br />
      {epostStatus.sentOk ? "Sendt ok" : "Sending feilet: " + epostStatus.errorMessage}
      <hr />
    </>;
  }

  return (
    <>
      <IconButton icon='send'
        variant='primary'
        onClick={() => props.setShow(true)} >
        Send fakturadetaljer
      </IconButton>

      <Modal centered show={props.show} onHide={handleClose}>
        <Modal.Header closeButton><Modal.Title>Send fakturadetaljer</Modal.Title> </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>

            <Form.Group className='mb-3'>
              <Form.Label>Tjenestegruppe</Form.Label>
              <TjenesegruppeSelect selected={selectedTjenesteGruppeId} onChange={(tjenesteGruppeId: string) => setSelectedTjenesteGruppeId(tjenesteGruppeId)} />
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Dato sendt til Pogo</Form.Label>
              <Form.Select value={selectedPeriode?.periode} onChange={e => handlePeriodeSelected(e.target.value)}>
                <option value=''>Velg periode</option>
                {periodeInfo?.map((periode, index) =>
                  <option key={index} value={periode.periode}>{periode.exportDateForDisplay}</option>)}
              </Form.Select>
            </Form.Group>

            {sendSpesifiseringResponse && sendSpesifiseringResponse.antallMottakereSomSkalMotta === 0 && sendSpesifiseringResponse.antallMottakereSomTidligereHarMottatt === 0 &&
              <Alert variant='warning'>
                Fant ingen mottakere for valgt periode
              </Alert>
            }

            {sendSpesifiseringResponse && (sendSpesifiseringResponse.antallMottakereSomSkalMotta > 0 || sendSpesifiseringResponse.antallMottakereSomTidligereHarMottatt > 0) &&
              <Alert variant='info'>
                <p>{`${sendSpesifiseringResponse.antallMottakereSomSkalMotta} mottakere skal motta fakturadetaljer på epost`} </p>
                <p>

                  {sendSpesifiseringResponse.epostStatuser.map(element => formatEpostStatuser(element))}

                </p>
                {sendSpesifiseringResponse.antallMottakereSomTidligereHarMottatt > 0 && <p>{`${sendSpesifiseringResponse.antallMottakereSomTidligereHarMottatt} mottakere har mottatt faktura epost tidligere`} </p>}
              </Alert>
            }

          </Modal.Body>

          <Modal.Footer>
            <IconButton
              icon='close'
              variant='outline-primary'
              className='col'
              onClick={handleClose}
            >
              Avbryt
            </IconButton>

            <IconButton
              variant='primary'
              className='col'
              type='submit'
              icon='send'
              disabled={!(selectedPeriode && selectedTjenesteGruppeId)}
            >
              {
                isLoading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              } Send
            </IconButton>


          </Modal.Footer>

        </Form>
      </Modal>
    </>
  );

};

export default SendFakturadetaljer;