import React, { useEffect } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import useForm from 'utils/hooks/useForm';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import { CreateSearchWordCommand } from 'services/AdminportalenApi/SearchWordsApi/types';
import SearchWordsApi from 'services/AdminportalenApi/SearchWordsApi';

interface Props {
  tsKundeId: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onUpdated: () => void;
}

const CreateSearchWord = (props: Props) => {
  const toaster = useToaster();

  useEffect(() => {
    handleChange('tsKundeId', props.tsKundeId);
  }, [props.tsKundeId]);

  const initialValues: CreateSearchWordCommand = {
    tsKundeId: props.tsKundeId,
    searchWord: '',
  }

  const validate = (values: CreateSearchWordCommand) => {
    let errors: any = {};    
    if (values.searchWord.length < 3) errors.searchWord = 'For få tegn. Må ha minimum 3 tegn';    
    return errors;
  }
  
  const onSubmit = async (values: CreateSearchWordCommand) => {
    try {
      await SearchWordsApi.create(values);
      toaster.success('Suksess', 'Søkeord opprettet!');
      handleClose();
      resetForm(undefined);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, loading, touched, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => props.setShow(false);

  return (
    <Modal
      centered
      show={props.show}
      onHide={handleClose}
      dialogClassName="modal-10w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">Nytt søkeord</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Søkeord</Form.Label>
            <Form.Control
              placeholder='Skriv inn søkeord'
              value={values.searchWord}
              onChange={e => handleChange('searchWord', e.target.value)}
              isInvalid={touched?.searchWord && errors?.searchWord} />
              <Form.Control.Feedback type="invalid">
              {errors.searchWord} 
            </Form.Control.Feedback>            
          </Form.Group>          
        </Modal.Body>
        <Modal.Footer>
          <Button className="col" variant="outline-primary" onClick={handleClose}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button variant="primary" className="col" type="submit" disabled={loading || Object.keys(touched).length === 0}>
            <IcebergIcon icon='disk' size='lg' /> Lagre
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default CreateSearchWord;