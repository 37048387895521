import { useState, useEffect } from 'react';
import { Row, Col, Button, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import FakturaSpesifiseringApi, { mapObjectToQueryString } from 'services/AdminportalenApi/FakturaSpesifiseringApi';
import { SendeloggViewModel, SendeloggViewSearchFilter } from 'services/AdminportalenApi/FakturaSpesifiseringApi/types';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import querystring from 'query-string';
import Style from './index.module.css';
import TjenestegruppeSelect from 'components/TjenestegruppeSelect';
import { format } from 'date-fns';
import { TsKundeSearchDto } from 'services/AdminportalenApi/TsKunderApi/types';
import TsKundeTypeahead from 'components/TsKundeTypeahead';
import SendFakturadetaljer from './components/MottakereTable/SendFakturadetaljer';
import CommonLoader from 'components/CommonLoader';

const FakturaSpesifisering = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [utsendelseslogg, setUtsendelseslogg] = useState<SendeloggViewModel[]>([]);

  // sjekk om det finnes filter info i url
  const getFilterFromQueryString = (): SendeloggViewSearchFilter => {
    let parsedFilter: SendeloggViewSearchFilter = {};
    if (location.search && location.search.length > 0) {
      parsedFilter = querystring.parse(
        location.search.substring(1, location.search.length),
        { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' }
      );
    }
    return parsedFilter;
  }

  const [filter, setFilter] = useState<SendeloggViewSearchFilter>(getFilterFromQueryString());

  const [showSendeFakturadetaljer, setShowSendeFakturadetaljer] = useState<boolean>(false);
  const isMountedRef = useIsMountedRef();
  const [selectedTsKunde, setSelectedTsKunde] = useState<TsKundeSearchDto>();

  const goToMottakerPage = () => {
    navigate('/FakturaSpesifisering/Mottakere');
  }

  const loadUtsendelseslogg = async (take?: number, tsKundeId?: string, tjenesteGruppeId?: string) => {
    setIsLoading(true);
    const result = await FakturaSpesifiseringApi.getSendeLogg(take, tsKundeId, tjenesteGruppeId);
    setUtsendelseslogg(result);
    setIsLoading(false);
  };


  useEffect(() => {
    if (isMountedRef.current) {
      const queryString = mapObjectToQueryString(filter);
      navigate(`/FakturaSpesifisering${queryString}`)
    }
  }, [filter]);

  useEffect(() => {
    loadUtsendelseslogg(100, filter.tsKundeId, filter.tjenesteGruppeId);
  }, [filter]);

  useEffect(() => {
    if (selectedTsKunde) {
      setFilter({ ...filter, tsKundeId: selectedTsKunde?.tsKundeId });
    }
    else {
      setFilter({ ...filter, tsKundeId: undefined });
    }
  }, [selectedTsKunde?.tsKundeId]);

  useEffect(() => {
    if (filter.tjenesteGruppeId) {
      setFilter({ ...filter, tjenesteGruppeId: filter?.tjenesteGruppeId });
    }
  }, [filter?.tjenesteGruppeId]);

  return (
    <div className={Style.container}>
      <Row style={{ marginTop: '0.5em' }}>
        <Col xs='8'>
          <h2 className={Style.mainHeader}>Utsendelse av fakturadetaljer</h2>
        </Col>
        <Col className='text-end'>
          <Button className='me-2' onClick={() => goToMottakerPage()}
            variant='outline-primary'
          >
            Vis mottakere
          </Button>

          <SendFakturadetaljer
            show={showSendeFakturadetaljer}
            setShow={setShowSendeFakturadetaljer}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TsKundeTypeahead selectedTsKundeId={selectedTsKunde?.tsKundeId} onHandleChange={e => setSelectedTsKunde(e)} />
        </Col>

        <Col>
        </Col>

        <Col>
          <TjenestegruppeSelect selected={filter.tjenesteGruppeId || ''} onChange={e => setFilter(prev => ({ ...prev, tjenesteGruppeId: e ?? null }))} />
        </Col>

      </Row>

      <Row>
        <Col>
          {isLoading && (utsendelseslogg.length <= 0) &&
            <Row>
              <Col>
                <CommonLoader text="Henter utsendelseslogg..." color="#003b6e" />
              </Col>
            </Row>
          }
          {!isLoading && (utsendelseslogg.length <= 0) && (
            <div>
              <h3>Ingen treff</h3>
            </div>
          )}

          {(utsendelseslogg.length > 0) && (
            <Row className={Style.wrapper}>
              <Table responsive hover>
                <thead className={Style.thead}>
                  <tr>
                    <th>Eksportert til POGO</th>
                    <th>TsKunde</th>
                    <th>Tjenestegruppe</th>
                    <th>Sendt til</th>
                    <th>Tidspunkt</th>
                  </tr>
                </thead>
                <tbody>
                  {utsendelseslogg.map((u, index) => (
                    <tr key={index}>
                      <td>{u.eksportertTilPogo ? format(u.eksportertTilPogo, "dd.MM.yy") : u.periode}</td>
                      <td>{u.tsKundeNavn}</td>
                      <td>{u.tjenesteGruppeNavn}</td>
                      <td>{u.emails.join(", ")}</td>
                      <td>{format(u.sent, "dd.MM.yy HH:mm")} </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default FakturaSpesifisering;                      