import { addMonths } from "date-fns";
import { Quarter } from "./QuarterPeriodPicker";

export const DatePeriodHelper = {
    getCurrentMonth: (): { year: number; month: number; } => {
        const today = new Date();
        return {
            year: today.getFullYear(),
            month: today.getMonth()
        };
    },
    getPreviousMonth: (): { year: number; month: number; } => {
        const previousMonth = addMonths(new Date(), -1);
        return {
            year: previousMonth.getFullYear(),
            month: previousMonth.getMonth()
        };
    },
    dateToQuarter: (date: Date): { year: number; quarter: Quarter; } => {
        const getQuarter = () => {
            switch (date.getMonth()) {
                case 0:
                case 1:
                case 2:
                    return Quarter.Q1;
                case 3:
                case 4:
                case 5:
                    return Quarter.Q2;
                case 6:
                case 7:
                case 8:
                    return Quarter.Q3;
                case 9:
                case 10:
                case 11:
                    return Quarter.Q4;

            }
        };
        return {
            year: date.getFullYear(),
            quarter: getQuarter()
        };
    },
    quarterToDate: (quarter: Quarter, year: number): Date => {
        switch (quarter) {
            case Quarter.Q1:
                return new Date(year, 0, 1);
            case Quarter.Q2:
                return new Date(year, 3, 1);
            case Quarter.Q3:
                return new Date(year, 6, 1);
            case Quarter.Q4:
                return new Date(year, 9, 1);
        }
    },
    getCurrentQuarter: (): { year: number; quarter: Quarter; } => {
        const today = new Date();
        return DatePeriodHelper.dateToQuarter(today);
    },
    getPreviousQuarter: (): { year: number; quarter: Quarter; } => {
        const threeMonthsAgo = addMonths(new Date(), -3);
        return DatePeriodHelper.dateToQuarter(threeMonthsAgo);
    }
};
