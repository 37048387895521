import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { UpdateKontaktpersonCommand } from 'services/AdminportalenApi/KontaktpersonerApi/types';
import { Kontaktperson } from 'services/AdminportalenApi/KundeKortApi/types';
import KontaktpersonerApi from 'services/AdminportalenApi/KontaktpersonerApi';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import useForm from 'utils/hooks/useForm';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import EmailsApi from 'services/AdminportalenApi/EmailsApi';
import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import nb from 'react-phone-number-input/locale/nb.json'
import { E164Number } from 'libphonenumber-js/types.cjs';
interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  tsKundeId: string;
  kontaktperson: Kontaktperson;
  onUpdated: () => void;
  onlyView: boolean;
}

const EditKontaktperson: React.FC<Props> = props => {
  const toaster = useToaster();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);

  const initialValues: UpdateKontaktpersonCommand = {
    tsKundeId: props.tsKundeId,
    kontaktpersonId: props.kontaktperson.kontaktpersonId,
    navn: props.kontaktperson.navn,
    email: props.kontaktperson.email,
    typeKontaktperson: props.kontaktperson.typeKontaktperson,
    mobile: props.kontaktperson.mobile,
    comment: props.kontaktperson.comment
  }

  const validate = (values: UpdateKontaktpersonCommand) => {
    let errors: any = {};
    if (!values.navn) errors.navn = 'Navn må fylles ut';
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = 'Ugyldig email';
    if (values.mobile && values.mobile.length > 12) errors.mobile = 'Mobilnummeret må bestå av max 12 tegn'
    return errors;
  }

  
  const onSubmit = async (values: UpdateKontaktpersonCommand) => {
    try {
      await KontaktpersonerApi.updateKontaktperson(values);
      toaster.success('Suksess', 'Kontaktperson oppdatert!');
      props.setShow(false)
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

    const verifyEmail = async (email: string) => {
      if (email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        const verifyResult = await EmailsApi.verify(email);
        setIsEmailValid(verifyResult.canBeUsed);
      }
    };

  const { values, errors, loading, touched, handleChange, handleSubmit } = useForm(initialValues, validate, onSubmit);

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.setShow(false)}
      dialogClassName="modal-10w"
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">Kontaktperson</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Navn</Form.Label>
            <Form.Control placeholder='Skriv inn navn' value={values.navn} onChange={e => handleChange('navn', e.target.value)} isInvalid={errors?.navn} readOnly={props.onlyView} />
            <Form.Control.Feedback type="invalid">
              {errors?.navn}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>E-post</Form.Label>
            <Form.Control
              placeholder='E-post'
              value={values.email}
              onChange={e => handleChange('email', e.target.value)}
              onBlur={e => verifyEmail(e.target.value)}
              isInvalid={errors?.email || !isEmailValid}
              readOnly={props.onlyView} />
            <Form.Control.Feedback type="invalid">
              {errors?.email}
            </Form.Control.Feedback>
            {!isEmailValid && (
              <div className='invalid-feedback'>
                <span>Det finnes ingen aktiv e-post konto med denne adressen. Har det sneket seg inn en skrivefeil? Kanskje noen har sluttet i jobben?</span>
              </div>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Tittel</Form.Label>
            <Form.Control placeholder='f.eks. produktansvarlig/salgssjef osv' value={values.typeKontaktperson} onChange={e => handleChange('typeKontaktperson', e.target.value)} isInvalid={errors?.typeKontaktperson} readOnly={props.onlyView} />
            <Form.Control.Feedback type="invalid">
              {errors?.typeKontaktperson}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Mobil</Form.Label>
            <PhoneInputWithCountrySelect
              labels={nb}
              countryOptionsOrder={['NO', 'SE', 'DK']}
              defaultCountry='NO'
              className='form-control'
              value={values.mobile}
              onChange={(num: E164Number) => handleChange('mobile', num)}
              readOnly={props.onlyView}
              isInvalid={errors?.mobile}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.mobile}
            </Form.Control.Feedback>
          </Form.Group>


          <Form.Group className="mb-3">
            <Form.Label>Kommentarer</Form.Label>
            <Form.Control as="textarea" value={values.comment} onChange={e => handleChange('comment', e.target.value)} readOnly={props.onlyView} />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          {
            props.onlyView ?
              <Button variant="primary" onClick={() => props.setShow(false)}>
                Lukk
              </Button> :
              <>

                <Button className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
                  <IcebergIcon size='lg' icon='close' /> Avbryt
                </Button>
                <Button variant="primary" className="col" type='submit' disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}>
                  <span>
                    <IcebergIcon icon='disk' size='lg' />  Lagre
                    {
                      loading &&
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    }
                  </span>
                </Button>
              </>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EditKontaktperson;