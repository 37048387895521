import { FaktureringsType } from "./FaktureringsTypePicker";
import { Quarter } from "./QuarterPeriodPicker";
import { DatePeriodHelper } from "./DatePeriodHelper";
import { TjenestegruppeDto } from "services/AdminportalenApi/FakturagrunnlagApi/types";
import { DatePeriod } from "./DatePeriod";
import { addDays, addHours, addMonths } from "date-fns";


export class PeriodeVelgerData {
    faktureringsType: FaktureringsType;
    faktureringsInterval: number;
    defaultValues: {
        monthData: {
            month: number;
            year: number;
        };
        quarterData: {
            quarter: Quarter;
            year: number;
        };
        year: number;
    };

    /* static getPeriod(faktureringsType: FaktureringsType, faktureringsInterval: number): DatePeriod {
        switch (faktureringsInterval) {
            case 1:
                if (faktureringsType === FaktureringsType.Etterskuddsvis) {
                    const previousMonth = DatePeriodHelper.getPreviousMonth();
                    const fraOgMedDateMonth = new Date(previousMonth.year, previousMonth.month, 1);
                    return {
                        fraOgMed: addHours(fraOgMedDateMonth, 3), // første dag i mnd, justert for norsk tid
                        tilOgMed: addHours(addDays(addMonths(fraOgMedDateMonth, 1), -1), 3), // siste dag i mnd, justert for norsk tid
                    }
                }
                const currentMonth = DatePeriodHelper.getCurrentMonth();
                const fraOgMedDateMonth = new Date(currentMonth.year, currentMonth.month, 1);
                return {
                    fraOgMed: addHours(fraOgMedDateMonth, 3), // første dag i mnd, justert for norsk tid
                    tilOgMed: addHours(addDays(addMonths(fraOgMedDateMonth, 1), -1), 3), // siste dag i mnd, justert for norsk tid
                }
            case 3:
                if (faktureringsType === FaktureringsType.Etterskuddsvis) {
                    const previousQuarter = DatePeriodHelper.getPreviousQuarter();
                    const fraOgMedDateQuarter = DatePeriodHelper.quarterToDate(previousQuarter.quarter, previousQuarter.year);
                    return {
                        fraOgMed: addHours(fraOgMedDateQuarter, 3), // første dag i kvartalet, justert for norsk tid
                        tilOgMed: addHours(addDays(addMonths(fraOgMedDateQuarter, 1), -1), 3), // siste dag i kvartalet, justert for norsk tid
                    }
                }
                const currentQuarter = DatePeriodHelper.getCurrentQuarter();
                const fraOgMedDateQuarter = DatePeriodHelper.quarterToDate(currentQuarter.quarter, currentQuarter.year);
                return {
                    fraOgMed: addHours(fraOgMedDateQuarter, 3), // første dag i mnd, justert for norsk tid
                    tilOgMed: addHours(addDays(addMonths(fraOgMedDateQuarter, 1), -1), 3), // siste dag i mnd, justert for norsk tid
                }
            case 12:
                if (faktureringsType === FaktureringsType.Etterskuddsvis) {
                    return {
                        fraOgMed: addHours(new Date(new Date().getFullYear() - 1, 1, 1), 3), // første dag forrige år justert for norsk tid
                        tilOgMed: addHours(addDays(new Date(new Date().getFullYear(), 1, 1), -1), 3), // siste dag forrige år justert for norsk tid
                    }
                }
                return {
                    fraOgMed: addHours(new Date(new Date().getFullYear(), 1, 1), 3), // første dag inneværende år justert for norsk tid
                    tilOgMed: addHours(addDays(new Date(new Date().getFullYear() + 1, 1, 1), -1), 3), // siste inneværende år justert for norsk tid
                }
        }
    }
 */
    static create(tjenester: TjenestegruppeDto[]): PeriodeVelgerData {
        const getFaktureringsType = (tjenester: TjenestegruppeDto[]): FaktureringsType => {
            if (tjenester.find(o => o.faktureresForskuddsvis) && tjenester.find(o => !o.faktureresForskuddsvis)) {
                return FaktureringsType.Begge;
            }
            if (tjenester.find(o => o.faktureresForskuddsvis)) {
                return FaktureringsType.Forskuddsvis;
            }
            return FaktureringsType.Etterskuddsvis;
        };

        const getFakturaInterval = (tjenester: TjenestegruppeDto[]): number => {
            const interval = tjenester.map(o => o.faktureringsIntervall);
            return Math.min(...interval);
        };

        const getDefaultValuesQuarter = (faktureringsType: FaktureringsType) => {
            if (faktureringsType == FaktureringsType.Etterskuddsvis) {
                return DatePeriodHelper.getPreviousQuarter();
            }
            return DatePeriodHelper.getCurrentQuarter();
        };

        const getDefaultValuesMonth = (faktureringsType: FaktureringsType) => {
            if (faktureringsType == FaktureringsType.Etterskuddsvis) {
                return DatePeriodHelper.getPreviousMonth();
            }
            return DatePeriodHelper.getCurrentMonth();
        };

        const getDefaultYear = (faktureringsType: FaktureringsType) => {
            return faktureringsType === FaktureringsType.Etterskuddsvis ? new Date().getFullYear() - 1 : new Date().getFullYear();
        };

        const faktureringsType = getFaktureringsType(tjenester);

        return {
            faktureringsType: faktureringsType,
            faktureringsInterval: getFakturaInterval(tjenester),
            defaultValues: {
                monthData: getDefaultValuesMonth(faktureringsType),
                quarterData: getDefaultValuesQuarter(faktureringsType),
                year: getDefaultYear(faktureringsType)
            }
        };
    }
}

