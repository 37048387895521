import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import Style from './index.module.css';
import { useEffect, useState } from 'react';
import CitadelReportService, { IReport } from 'services/CitadelApi';
import { useParams } from 'react-router-dom';

declare global {
  interface Window {
    tsKundeId: string;
  }
}

const KundeRapport = () => {
  const [pbiReport, setPbiReport] = useState<IReport>();
  const id: string = '940b8e30-2bd5-4507-b4f4-90f32f92fff3';
  const { tsKundeId }: any = useParams();
  const utcHour = new Date().getUTCHours();

  const embedConfig = {
    type: 'report',
    id: pbiReport?.report.id,
    embedUrl: pbiReport?.report.embedUrl,
    accessToken: pbiReport?.accessToken,
    tokenType: models.TokenType.Embed,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      background: models.BackgroundType.Default,      
    }
  }

  const eventHandlers = new Map([
    ['loaded', function () { console.log('Report loaded'); }],
    ['rendered', function () { console.log('Report rendered'); }],
    ['error', function (event: any) { console.log(event.detail); }]
  ]);

  useEffect(() => {    
    const getReport = async (tsKundeId: string) => {      
      const reportService = new CitadelReportService();
      const res: IReport = await reportService.getByIdAndTsKundeId(id, tsKundeId);
      if (res) {
        setPbiReport(res);
      }
    }

    if (tsKundeId) {
      getReport(tsKundeId.toUpperCase());
    }
  }, [tsKundeId]);

  return (
    <div className={Style.container}>      
      {(utcHour < 4) || (utcHour >= 21) ? (
        <div className={Style.unavailableMsgContainer}>
          <h4 className={Style.unavailableMsg}>
            Rapporter er tilgjengelige mellom kl. 06:00 og kl. 22:00. Ta
            kontakt med <a href="mailto: support@tradesolution.no">support@tradesolution.no</a> hvis det er ønskelig å utvide
            tidsrommet.
          </h4>
        </div>
      ) : (
        <div className={Style.powerBiReportContainer}>          
          {pbiReport && (
            <PowerBIEmbed
              embedConfig={embedConfig}
              eventHandlers={eventHandlers}
              cssClassName={Style.powerBiEmbed}
              getEmbeddedComponent={(embeddedReport) => {
                return embeddedReport;
              }}
            />
          )}
        </div >
      )}
    </div>
  );
}

export default KundeRapport;