import { useState, useEffect } from 'react';
import { Form, Modal, InputGroup, Alert, Spinner, Row } from 'react-bootstrap';
import ConfirmButton from 'components/ConfirmButton';
import { updateTsKundeCommand } from 'services/AdminportalenApi/TsKunderApi/types';
import { KundeKortOverviewDto } from 'services/AdminportalenApi/KundeKortApi/types';
import TsKunderApi from 'services/AdminportalenApi/TsKunderApi';
import BrregApi from 'services/AdminportalenApi/BrregApi';
import { EnhetDto } from 'services/AdminportalenApi/BrregApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import Style from './index.module.css';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import useCopyToClipboard from 'utils/hooks/useCopyToClipboard';
import EmailsApi from 'services/AdminportalenApi/EmailsApi';
import cx from 'classnames';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
  kunde: KundeKortOverviewDto;
  onUpdate: () => void;
}

const Rediger = (props: Props) => {
  const [value, copy] = useCopyToClipboard();
  const [brregResult, setBrregResult] = useState<EnhetDto>();
  const [isInactive, setIsInactive] = useState<boolean>(props.kunde.inaktivDato ? true : false);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const toaster = useToaster();

  const initialValues: updateTsKundeCommand = {
    tsKundeId: props.kunde.tsKundeId,
    tsKundeNavn: props.kunde.tsKundeNavn,
    isForeignCompany: props.kunde.isForeignCompany,
    organisasjonsnummer: props.kunde.organisasjonsnummer,
    omsetning: props.kunde.omsetning ?? 0,
    overrideOmsetning: props.kunde.overrideOmsetning,
    foretaksnavn: props.kunde.foretaksnavn,
    betalingsbetingelser: props.kunde.betalingsbetingelser,
    firmaEpost: props.kunde.firmaEpost,
    inaktivDato: props.kunde.inaktivDato,
    organisasjonsform: props.kunde.organisasjonsform,
  }

  const validate = (values: updateTsKundeCommand) => {
    let errors: any = {};
    if (!values.foretaksnavn) errors.foretaksnavn = 'Må fylles ut';
    if (!values.organisasjonsnummer) errors.organisasjonsnummer = 'Må fylles ut';
    if (!values.tsKundeNavn) errors.tsKundeNavn = 'Må fylles ut';
    if (values.omsetning.toString().length < 1) errors.omsetning = 'Må fylles ut';
    if (values.betalingsbetingelser < 30) errors.betalingsbetingelser = 'Må være 30 eller større';
    if (values.firmaEpost && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.firmaEpost)) errors.firmaEpost = 'Ugyldig email';
    else if (!isNumeric(values.omsetning.toString())) errors.omsetning = 'Omsetning kan ikke inneholde bokstaver.';
    return errors;
  }

  const isNumeric = (value: string) => {
    return /^\d+$/.test(value);
  }

  const onSubmit = async (command: updateTsKundeCommand) => {
    try {
      await TsKunderApi.editTsKundeOverview(command);
      toaster.success('Suksess', 'Kunde oppdatert!');
      props.setShow(false)
      props.onUpdate();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }
  const handleSearchEmail = async () => {
    if (!values.firmaEpost || errors.firmaEpost) return;
    try {
      setIsSearching(true);
      const verifyResult = await EmailsApi.verify(values.firmaEpost);
      setIsSearching(false);
      if (!verifyResult.canBeUsed) {
        toaster.error('Ugyldig e-post', 'Det finnes ingen aktiv e-post med denne adressen. Prøv igjen uten skrivefeil :)');
      } 
    } catch (err) {
      toaster.error('Ugyldig e-post', HttpErrorHelper.handleErrorMessage(err));
    }
  };


  const { values, loading, errors, touched, handleChange, handleSubmit, updateValues } = useForm(initialValues, validate, onSubmit);

  const [isDeactivating, setIsDeactivating] = useState(false);

  const handleDeactivate = async () => {
    try {
      setIsDeactivating(true);
      await TsKunderApi.deactivateTsKunde(props.kunde.tsKundeId);
      setIsDeactivating(false);
      toaster.success('Suksess', 'Kunde deaktivert!');
      props.setShow(false)
      props.onUpdate();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const handleBrregSearch = async () => {
    if (errors.organisasjonsnummer) return;
    var result = await BrregApi.getByOrganisasjonsnummer(values.organisasjonsnummer);
    if (result?.length > 0) {
      setBrregResult(result[0]);
    }
  }

  // reset form when kunde changes
  useEffect(() => {
    updateValues({
      tsKundeId: props.kunde.tsKundeId,
      tsKundeNavn: props.kunde.tsKundeNavn,
      isForeignCompany: props.kunde.isForeignCompany,
      organisasjonsnummer: props.kunde.organisasjonsnummer,
      omsetning: props.kunde.omsetning ?? 0,
      overrideOmsetning: props.kunde.overrideOmsetning,
      foretaksnavn: props.kunde.foretaksnavn,
      betalingsbetingelser: props.kunde.betalingsbetingelser,
      firmaEpost: props.kunde.firmaEpost,
      inaktivDato: props.kunde.inaktivDato,
      organisasjonsform: props.kunde.organisasjonsform,
    })
  }, [props.kunde]);

  useEffect(() => {
    handleBrregSearch();
  }, [values.organisasjonsnummer]);

  useEffect(() => {
    if (isInactive)
      values.inaktivDato = props.kunde.inaktivDato === null ? new Date() : props.kunde.inaktivDato;
    else
      values.inaktivDato = null;
  }, [isInactive]);

  return (
    <>
      <IconButton icon='edit' variant="outline-primary" onClick={() => props.setShow(true)}>
        Endre
      </IconButton>

      <Modal
        centered show={props.show} onHide={() => props.setShow(false)} dialogClassName="modal-10w">
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">{values.tsKundeNavn}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Label>TsKundeId</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control disabled placeholder={values.tsKundeId} />
              <IconButton variant="outline-primary" title="Kopier til utklippstavle" icon="copy-to-clipboard" onClick={() => copy(values.tsKundeId)} />
            </InputGroup>

            <Form.Group className="mb-3">
              <Form.Label>TsKundenavn</Form.Label>
              <Form.Control
                value={values.tsKundeNavn}
                onChange={e => handleChange('tsKundeNavn', e.target.value)}
                isInvalid={errors.tsKundeNavn} />
              <Form.Control.Feedback type="invalid">
                {errors.tsKundeNavn}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Organisasjonsnummer</Form.Label>
              <Form.Control
                value={values.organisasjonsnummer}
                onChange={e => handleChange('organisasjonsnummer', e.target.value.trim())}
                isInvalid={errors.organisasjonsnummer} />
              <Form.Control.Feedback type="invalid">
                {errors.organisasjonsnummer}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Organisasjonsform</Form.Label>
              <Form.Control
                value={values.organisasjonsform}
                onChange={e => handleChange('organisasjonsform', e.target.value.trim().toUpperCase())}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Foretaksnavn</Form.Label>
              <Form.Control
                value={values.foretaksnavn}
                onChange={e => handleChange('foretaksnavn', e.target.value.trim())}
                isInvalid={errors.navn} />
              <Form.Control.Feedback type="invalid">
                {errors.foretaksnavn}
              </Form.Control.Feedback>
              {
                brregResult && !brregResult.finnesIkke && values.foretaksnavn !== brregResult.name &&
                <Alert variant='warning' className={Style.brregWarning}>
                  <IcebergIcon icon='alert' size="lg" />
                  {' '}
                  Navn i Brreg: {brregResult.name}.
                  {' '}
                  <a target="_blank" href="#" onClick={e => { e.preventDefault(); handleChange('foretaksnavn', brregResult.name) }}>Klikk for å bytte.</a>
                </Alert>
              }
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check aria-label="option 1" label='Utenlandsk selskap?'
                checked={values.isForeignCompany}
                onChange={e => handleChange('isForeignCompany', e.target.checked)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Firma e-post</Form.Label>
              <InputGroup>
                <Form.Control placeholder='Skriv inn firma e-post f.eks. post@firma.no'
                  value={values.firmaEpost}
                  onChange={e => handleChange('firmaEpost', e.target.value)}
                  onBlur={handleSearchEmail}
                  isInvalid={errors?.firmaEpost && touched?.firmaEpost} />
                {isSearching
                  ? <InputGroup.Text className={cx(Style.spinnerContainer, { [Style.invalid]: (errors?.firmaEpost) && touched?.firmaEpost })}>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  </InputGroup.Text>
                  : <InputGroup.Text className={cx(Style.spinnerContainer, { [Style.invalid]: (errors?.firmaEpost) && touched?.firmaEpost })}>
                    <IcebergIcon icon='email' />
                  </InputGroup.Text>}
                <Form.Control.Feedback type="invalid">
                  {errors?.firmaEpost}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Antall dager til fakturaforfall</Form.Label>
              <Form.Control placeholder='30 dager eller høyere'
                value={values.betalingsbetingelser}
                onChange={e => handleChange('betalingsbetingelser', e.target.value)}
                isInvalid={errors.betalingsbetingelser}
              />
              <Form.Control.Feedback type="invalid">
                {errors.betalingsbetingelser}
              </Form.Control.Feedback>
            </Form.Group>

            <div style={{ marginTop: '25px' }}>
              <Form.Group className="mb-3">
                <Form.Label>Omsetning (NOK)</Form.Label>
                <Form.Control
                  value={values.omsetning?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                  onChange={e => handleChange('omsetning', Number(e.target.value.replace(/\s/g, '')))}
                  isInvalid={errors.omsetning} />
                <Form.Control.Feedback type="invalid">
                  {errors.omsetning}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check aria-label="option 3" label='Ikke oppdater omsetning automatisk'
                  checked={values.overrideOmsetning}
                  onChange={e => handleChange('overrideOmsetning', e.target.checked)}
                />
              </Form.Group>
            </div>

            <div style={{ marginTop: '25px' }}>
              <Form.Group className="mb-3">
                <Form.Check aria-label="option 1" label='Er kunden inaktiv?'
                  checked={isInactive}
                  onChange={e => setIsInactive(e.target.checked)}
                />
              </Form.Group>
            </div>

            {props.kunde.sistEndret && (
              <Form.Group>
                <Form.Label>
                  Sist endret {props.kunde.sistEndret}
                </Form.Label>
              </Form.Group>
            )}
          </Modal.Body>

          <Modal.Footer>
            <IconButton icon="disk" className='col-md-12' style={{ flex: "0 20 auto" }} variant="primary" type='submit' disabled={loading || Object.keys(errors).length > 0}>
              Lagre
            </IconButton>

            <ConfirmButton
              variant='outline-danger'
              className='col'
              disabled={isDeactivating || props.kunde.nedlagtDato !== null}
              onClick={() => handleDeactivate()}>
              Marker som konkurs
            </ConfirmButton>

            <IconButton icon='close' className='col' variant="outline-primary" onClick={() => props.setShow(false)}>
              Avbryt
            </IconButton>

          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default Rediger;