import axios from "axios";
import { getBaseUrl } from "..";
import { CreateMottakerCommand, MottakerSearchFilter, MottakerViewModel, SendableFakturagrunnlagViewModel, SendeloggViewModel, SendeloggViewSearchFilter, SendSpesifiseringCommand, SendSpesifiseringCommandResponse, SendSpesifiseringToTsKundeCommand } from "./types";

const baseUrl = getBaseUrl() + '/FakturaSpesifisering';

export const mapObjectToQueryString = (queryObject: object): string => {
  const queryParamString: string[] = Object.keys(queryObject)
    .filter(key => queryObject[key])
    .map(key => `${key}=${queryObject[key]}`);
  if (!queryParamString.length) {
    return '';
  }
  return `?${queryParamString.join('&')}`;
}

const FakturaSpesifiseringApi = {

  /*Utsendelseslogg*/
  getSendeLogg: async (take?: number, tsKundeId?: string, tjenesteGruppeId?: string): Promise<SendeloggViewModel[]> => {
    const queryParams = {
      take, tsKundeId, tjenesteGruppeId
    };

    const response = await axios.get(`${baseUrl}/Sendelogg${mapObjectToQueryString(queryParams)}`);
    return response.data;
  },


  getSendableFakturagrunnlag: async (tjenesteGruppeId: string): Promise<SendableFakturagrunnlagViewModel[]> => {
    const response = await axios.get(`${baseUrl}/GetSendable/${tjenesteGruppeId}`);
    return response.data;
  },

  sendSpesifisering: async (command: SendSpesifiseringCommand): Promise<SendSpesifiseringCommandResponse> => {
    const response = await axios.post(`${baseUrl}/SendSpesifisering`, command);
    return response.data;
  },

  /*Mottakere */
  getMottakere: async (): Promise<MottakerViewModel[]> => {
    const response = await axios.get(`${baseUrl}/Mottakere`);
    return response.data;
  },

  createMottaker: async (command: CreateMottakerCommand): Promise<MottakerViewModel> => {
    const response = await axios.post(`${baseUrl}/Mottakere`, command);
    return response.data;
  },

  sendSpesifiseringToTsKunde: async (command: SendSpesifiseringToTsKundeCommand) => {
    const response = await axios.post(`${baseUrl}/Mottakere/SendSpesifiseringToTsKunde`, command);
    return response.data;
  },

  deleteMottaker: async (mottakerId: string) =>
    await axios.delete(`${baseUrl}/Mottakere/${mottakerId}`),


  getSendableFakturagrunnlagByTsKunde: async (tjenesteGruppeId: string, tsKundeId: string): Promise<SendableFakturagrunnlagViewModel[]> => {
    const response = await axios.get(`${baseUrl}/Mottakere/GetSendableByTsKunde/${tjenesteGruppeId}/${tsKundeId}`);
    return response.data;
  },



}

export default FakturaSpesifiseringApi;