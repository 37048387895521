import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";

interface Props {
  isInvalid: boolean;
  onSelected: (title: string) => void;
}

const KontaktpersonTypeAhead = (props: Props) => {
  const options: Option[] = [
    { label: "Daglig leder" },
    { label: "Grunndataansvarlig" },
    { label: "Fakturakontakt" },
    { label: "Bildeansvarlig" },
    { label: "Salgssjef" },
    { label: "Fotoansvarlig" },
    { label: "Regnskap" }
  ];

  const handleChanged = (e: Option[]) => {
    if (e.length > 0) {
      props.onSelected(
        (e[0] as { label: string }).label
      );
    }
  }

  return (
    <Typeahead
      id="kontaktpersonTypeahead"
      onChange={e => handleChanged(e)}
      options={options}
      allowNew={true}
      isInvalid={props.isInvalid}
    />
  );
};

export default KontaktpersonTypeAhead;
