import axios from 'axios';

export interface IReport {
  accessToken: string;
  report: {
    datasetId: string;
    embedUrl: string;
    id: string;
    name: string;
    webUrl: string;
  };
}

export default class CitadelReportService {
  getResource() {
    return 'https://citadelapi.tradesolution.no/api';
  }

  getByIdAndTsKundeId = (reportId: string, tsKundeId: string) => {
    const url = `${this.getResource()}/EmbededReports/${reportId}?tsKundeId=${tsKundeId}`;
    const request = axios.get(url).then((response: any) => response.data);
    return request;
  };

  getById = (reportId: string) => {
    const url = `${this.getResource()}/EmbededReports/${reportId}`;
    const request = axios.get(url).then((response: any) => response.data);
    return request;
  };
}