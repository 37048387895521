import { useState, useEffect, useRef } from 'react'
import TjenestegruppeSelect from 'components/TjenestegruppeSelect';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import FakturaSpesifiseringApi, { mapObjectToQueryString } from 'services/AdminportalenApi/FakturaSpesifiseringApi';
import { MottakerViewModel, MottakerViewSearchFilter } from 'services/AdminportalenApi/FakturaSpesifiseringApi/types';
import Style from './index.module.css';
import CreateMottaker from './CreateMottaker';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import querystring from 'query-string';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { useLocation, useNavigate } from 'react-router-dom';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import ConfirmButton from 'components/ConfirmButton';
import SendeSpesifikkKunde from 'pages/FakturaSpesifisering/SendeSpesifikkKunde';
import CommonLoader from 'components/CommonLoader';


const MottakereTable = () => {
  const toaster = useToaster();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mottakere, setMottakere] = useState<MottakerViewModel[]>([]);
  const [showAddMottaker, setShowAddMottaker] = useState<boolean>(false);
  const [showFakturadetaljer, setShowFakturadetaljer] = useState<boolean>(false);
  const [selectedMottaker, setSelectedMottaker] = useState<MottakerViewModel>();
  const [filter, setFilter] = useState<MottakerViewSearchFilter>({
    query: '',
  });
  const isMountedRef = useIsMountedRef();
  const firstRender = useRef<boolean>(true);

  const loadMottakere = async () => {
    setIsLoading(true);
    const result = await FakturaSpesifiseringApi.getMottakere();
    setMottakere(result);
    setIsLoading(false);
  };

  const handleFilter = () => {
    let parsedFilter = null;
    if (location.search && location.search.length > 0) {
      parsedFilter = querystring.parse(
        location.search.substring(1, location.search.length),
        { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' }
      );
    }
    let tjenestegruppeId = parsedFilter?.tjenestegruppeId?.toString() ?? null;
    setFilter(prev => ({
      ...prev,
      query: parsedFilter?.query?.toString() || '',
      tjenestegruppeId,

    }))
  }
  useEffect(() => {
    window.addEventListener("beforeunload", e => { handleFilter(); });
    return () => {
      window.removeEventListener("beforeunload", e => handleFilter());
    };
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      handleFilter();
      firstRender.current = false;
    }
  }, []);

  useEffect(() => {
    if (isMountedRef.current && !firstRender.current) {
      navigate(`/FakturaSpesifisering/Mottakere${mapObjectToQueryString(filter)}`)
    }
  }, [filter]);


  useEffect(() => {
    loadMottakere();
  }, []);

  const deleteMottaker = async (id: string) => {
    try {
      await FakturaSpesifiseringApi.deleteMottaker(id);
      toaster.success('Suksess', 'Mottaker slettet');
      loadMottakere();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }

  };

  const goToLoggPage = () => {
    navigate('/FakturaSpesifisering');
  }

  const filteredMottakere = mottakere.filter(x =>
    !filter.query || filter.query === '' ?
      x :
      x.tsKundeNavn.toLowerCase().includes(filter.query.toLowerCase())
  ).filter(x => !filter.tjenestegruppeId ?
    x :
    x.tjenesteGruppeId === filter.tjenestegruppeId
  );

  console.log(filteredMottakere);

  return (
    <div className={Style.container}>
      <Row style={{ marginTop: '0.5em' }}>
        <Col xs='8'>
          <h2 className={Style.mainHeader}>Mottakere av fakturadetaljer</h2>
        </Col>
        <Col className='text-end'>
          <Button
            className='me-2'
            onClick={() => goToLoggPage()}
            variant='outline-primary'>
            Vis logg
          </Button>
          <CreateMottaker
            show={showAddMottaker}
            setShow={setShowAddMottaker}
            onUpdated={loadMottakere} />
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <InputGroup className='mb-3'>
            <Form.Control placeholder='søk etter kunde'
              type='text'
              value={filter.query}
              style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
              onChange={(e) => setFilter({ ...filter, query: e.target.value })}
            />

            <InputGroup.Text style={{ background: 'white', borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
              {filter.query.length > 0 ?
                <IcebergIcon icon='close' cursor='pointer'
                  onClick={() => setFilter(prev => ({ ...prev, query: '' }))} />
                : <IcebergIcon icon='search' />}
            </InputGroup.Text>
          </InputGroup>
        </Col>

        <Col>
          <span style={{ marginTop: '5px', color: '#68778D', float: 'right' }}> Viser {filteredMottakere.length} av {mottakere.length} mottakere</span>
        </Col>

        <Col>
          <TjenestegruppeSelect selected={filter.tjenestegruppeId} onChange={e => setFilter(prev => ({ ...prev, tjenestegruppeId: e ?? null }))} />
        </Col>

      </Row>

      <Row>
        <Col>
          {isLoading && (mottakere.length <= 0) &&
            <Row>
              <Col>
                <CommonLoader text="Henter mottakere..." color="#003b6e" />
              </Col>
            </Row>
          }
          {!isLoading && (mottakere.length <= 0) && (
            <div>
              <h3>Ingen treff</h3>
            </div>
          )}

          {(mottakere.length > 0) && (
            <Row className={Style.wrapper}>
              <Table responsive hover>
                <thead className={Style.thead}>
                  <tr>
                    <th>TsKunde</th>
                    <th>Tjenestegruppe</th>
                    <th>Epost</th>
                    <th>Send til spesifikk kunde</th>
                    <th>Slett mottaker</th>
                  </tr>
                </thead>
                <tbody className={Style.tbody} style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
                  {filteredMottakere.map((mottaker, index) => (
                    <tr key={index}>
                      <td>{mottaker.tsKundeNavn}</td>
                      <td>{mottaker.tjenesteGruppeNavn}</td>
                      <td>{mottaker.emails.join(", ")} </td>
                      <td>
                        <IconButton
                          icon='send'
                          variant='outline-primary'
                          onClick={() => {
                            setSelectedMottaker(prev => mottaker)
                            setShowFakturadetaljer(true)
                          }}>
                        </IconButton>
                      </td>
                      <td>
                        <ConfirmButton variant='outline-danger' onClick={() => deleteMottaker(mottaker.id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>

          )}
        </Col>
      </Row>
      {
        showFakturadetaljer &&
        <SendeSpesifikkKunde
          show={showFakturadetaljer}
          setShow={setShowFakturadetaljer}
          mottaker={selectedMottaker} />
      }
    </div>
  )
}

export default MottakereTable;