import IcebergBadge from "components/IcebergBadge";
import { Button, Badge } from "react-bootstrap";
import { TsKundeOverviewDto } from "services/AdminportalenApi/TsKunderApi/types";

interface Props {
  kunde: TsKundeOverviewDto;
  onSelected: (kundeId: string) => void;
}

const TsKundeRow = (props: Props) => {

  return (
    <tr>
      <td style={{ cursor: 'pointer' }} onClick={() => props.onSelected(props.kunde.tsKundeId)}>{props.kunde.tsKundeNavn}</td>
      <td>{props.kunde.oversiktTekstForAktiveAbonnement}</td>
      <td>{props.kunde.oversiktTekstForAktiveAvtaler}</td>
      <td>{props.kunde.organisasjonsnummer}</td>
      <td className="fitContent">{props.kunde.omsetning?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</td>
      <td>      
        {props.kunde.nedlagtDato ?
         <IcebergBadge variant="warning">Konkurs</IcebergBadge> : 
          props.kunde.inaktivDato ?
            <IcebergBadge variant="dark">Inaktiv</IcebergBadge> : 
            <IcebergBadge variant="primary">Aktiv</IcebergBadge>}
      </td>
      <td className="fitContent"><Button onClick={() => props.onSelected(props.kunde.tsKundeId)} variant='outline-primary' size="sm">Vis detaljer</Button></td>
    </tr>
  )
}

export default TsKundeRow;