import { useState } from 'react'
import { Table } from 'react-bootstrap';
import { format } from 'date-fns';
import cx from 'classnames';
import PopoverMenu from 'components/PopoverMenu';
import { getTjenestegruppeIcon } from 'components/IcebergIcon/utils';
import CreateLogg from './components/CreateLogg';
import KundeLoggApi from 'services/AdminportalenApi/KundeLoggApi';
import { KundeLoggDto } from 'services/AdminportalenApi/KundeLoggApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import Style from './index.module.css';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import IcebergBadge from 'components/IcebergBadge';

interface Props {
  tsKundeId: string;
  kundeLogger: KundeLoggDto[];
  isNedlagt: boolean;
  onUpdated: () => void;
  filteredTjenestegrupper: string[];
}

const Logg = (props: Props) => {
  const toaster = useToaster();

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const handleDelete = async (id: string) => {
    try {
      await KundeLoggApi.deleteKundeLogg(id);
      toaster.success('Suksess', 'Logg slettet!');
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const filteredLogger = props.kundeLogger.filter(x => (props.filteredTjenestegrupper.length > 0 && x.tjenesteGruppeId) ? props.filteredTjenestegrupper.includes(x.tjenesteGruppeId) : x);

  return (
    <>
      <div className={Style.wrapper}>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Logg</th>
              <th>Melding</th>
              <th>Mottatt/Fra oss</th>
              <th>Opprettet av</th>
              <th>Tidspunkt</th>
              <th>Tjenestegrupper</th>
              <th style={{ cursor: props.isNedlagt ? 'default' : 'pointer' }}>
                {!props.isNedlagt && <IcebergIcon icon='plus' title='Ny' bold onClick={() => setShowCreateModal(true)} />}
              </th>
            </tr>
          </thead>
          <tbody>
            {
              filteredLogger.map(kundeLogg => (
                <tr key={kundeLogg.id}>
                  <td>{kundeLogg.subject}</td>
                  <td>{kundeLogg.message}</td>
                  <td>
                    {
                      kundeLogg.incoming ?
                        <IcebergBadge variant='secondary'>Fra oss</IcebergBadge> :
                        <IcebergBadge variant='primary'>Mottatt</IcebergBadge>
                    }
                  </td>
                  <td>{kundeLogg.opprettetAv}</td>
                  <td>{format(new Date(kundeLogg.timeStamp), 'dd.MM.yyyy HH:mm')}</td>
                  <td className="tjenesteGruppeCol">
                    {
                      kundeLogg.tjenesteGruppeId &&
                      <>
                        <IcebergIcon icon={getTjenestegruppeIcon(kundeLogg.tjenestegruppeNavn)} />
                        {' ' + kundeLogg.tjenestegruppeNavn}
                      </>
                    }
                  </td>
                  <td className="fitContent">
                    <PopoverMenu
                      onDelete={() => handleDelete(kundeLogg.id)}
                      confirmDelete={true}
                      onlyView={props.isNedlagt} />
                  </td>

                </tr>
              ))}
          </tbody>
        </Table>
        {props.filteredTjenestegrupper.length > 0 && <span className={Style.helpText}>Viser {filteredLogger.length} av {props.kundeLogger.length} logger</span>}
      </div>
      <CreateLogg
        show={showCreateModal}
        setShow={setShowCreateModal}
        onUpdated={props.onUpdated}
        tsKundeId={props.tsKundeId} />
    </>
  )
}

export default Logg;