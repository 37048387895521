import { Modal, Row, Col, Table } from 'react-bootstrap';
import { BrukerWithTilgangerDto, SignInDto } from 'services/AdminportalenApi/BrukereApi/types';
import { IconButton } from '@tradesolution/iceberg-ui-react';
import BrukereApi from 'services/AdminportalenApi/BrukereApi';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

interface Props {
  bruker: BrukerWithTilgangerDto;
  show: boolean;
  setShow: (value: boolean) => void;
}

const LogInHistoryModal = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loginHistory, setLoginHistory] = useState<SignInDto[]>([]);


  const loadHistory = async () => {
    setIsLoading(true);
    let history = await BrukereApi.getLogIns(props.bruker.objectId);
    setLoginHistory(history);
    setIsLoading(false);
  };

  useEffect(() => {
    loadHistory();
  }, [props.bruker])


  return (
    <Modal size="xl" centered show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Innloggingshistorikk for {props.bruker.givenName} {props.bruker.surname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <Row>
            <Col>
              <CommonLoader text="Henter nyeste innlogginger..." color="#003b6e" />
            </Col>
          </Row>
        )}
        {!isLoading && (
          <Row>
            <Col>
              {loginHistory.length === 0 && (
                <span>Ingen innlogginger registrert</span>
              )}
              {loginHistory.length > 0 && (
                <Table responsive style={{ borderCollapse: 'collapse', borderTop: 'none' }} hover>
                  <thead>
                    <tr>
                      <th>Tidspunkt</th>
                      <th>Applikasjon</th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
                    {
                      loginHistory.map((t, index) =>
                        <tr key={index}>
                          <td>{format(new Date(t.createdDateTime), 'dd.MM.yyyy HH:mm')}</td>
                          <td>{t.appDisplayName}</td>
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        )}

      </Modal.Body>
      <Modal.Footer>
        <IconButton icon="close" className='col' variant="outline-primary" onClick={() => props.setShow(false)}>
          Lukk
        </IconButton>
      </Modal.Footer>
    </Modal>
  )
}

export default LogInHistoryModal;