import { IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import TjenestegruppeSelect from "components/TjenestegruppeSelect";
import { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Spinner, Form } from "react-bootstrap";
import Style from './index.module.css';

interface Props {
  onHandleExport: (type: string, onlyKeepTjenestegruppeId?: string, onlyExportActive?: boolean) => Promise<void>;
  exportCount: number;
}

const Export = (props: Props) => {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<string>('');  
  const [exportOnlyActive, setExportOnlyActive] = useState<boolean>(true);
  const [onlyKeepTjenestegruppeId, setOnlyKeepTjenestegruppeId] = useState<string>(null);

  const exportClicked = async () => {
    setIsLoadingExport(true);
    await props.onHandleExport(
      selectedData,
      onlyKeepTjenestegruppeId,
      exportOnlyActive
    );
    setIsLoadingExport(false);
    setShowExportModal(false);
  }

  return (
    <>
      <Row style={{ paddingRight: '10px' }}>
        <Col>
          <IconButton icon="export" variant="outline-primary" onClick={() => setShowExportModal(true)} disabled={isLoadingExport}>
            Eksporter
          </IconButton>
        </Col>
      </Row>

      <Modal show={showExportModal} onHide={() => setShowExportModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eksporter søkeresultat til Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>          
          <div>                        
            {
              props.exportCount > 100 &&
              <>
                <br />
                <strong> NB: antall kunder valgt for eksport er over 100. Dette kan ta lenger tid</strong>
              </>
            }            
          </div>
          <Form>       
          <Form.Group className="mb-3">
            <Form.Label>Velg data</Form.Label>
            <Form.Select as="select" onChange={e => setSelectedData(e.target.value)} value={selectedData}>
              <option value="default">Kunde og fakturainfo</option>
              <option value="kontaktperson">Kontaktpersoner</option>
              <option value="abonnement">Abonnementer</option>
              <option value="avtale">Avtaler</option>
            </Form.Select>
          </Form.Group>          
          <Form.Group className="mb-3">
            <Form.Label>Kun eksporter rader knyttet til en tjenestegruppe?</Form.Label>
            <TjenestegruppeSelect selected={onlyKeepTjenestegruppeId ?? ''} onChange={e => setOnlyKeepTjenestegruppeId(e)} />
          </Form.Group>
          {
            (selectedData === "abonnement" || selectedData === "avtale") &&            
              <Form.Group className="mb-3">
                <Form.Check 
                label={selectedData === "abonnement" ? 'Bare abonnement uten til og med dato' : 'Bare avtaler uten til og med dato'} 
                className={Style.checkbox}
                checked={exportOnlyActive}
                onChange={e => setExportOnlyActive(e.target.checked)} />                
              </Form.Group> 
          }          
          </Form>
          
        </Modal.Body>
        <Modal.Footer>
          <Button className="col" variant="outline-primary" onClick={() => setShowExportModal(false)}>
            <IcebergIcon icon="close" size="lg" /> Avbryt
          </Button>

          <Button className="col" variant="primary" onClick={exportClicked} disabled={isLoadingExport}>
            {
              isLoadingExport &&
              <span style={{ paddingRight: '.3em' }}>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              </span>
            }
            <IcebergIcon icon="export" /> Last ned
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Export;