import React from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap';
import FakturafritakApi from 'services/AdminportalenApi/FakturafritakApi';
import { CreateFakturafritakCommand } from 'services/AdminportalenApi/FakturafritakApi/types';
import TjenesteTypeahead from 'components/TjenesteTypeahead';
import TsKundeTypeahead from 'components/TsKundeTypeahead';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import Style from './index.module.css';
import { IconButton } from '@tradesolution/iceberg-ui-react';



interface Props {
  show: boolean;
  onUpdated: () => void;
  setShow: (show: boolean) => void;
}


const CreateFakturafritak = (props: Props) => {
  const toaster = useToaster();


  const initialValues: CreateFakturafritakCommand = {
    tsKundeId: '',
    tjenesteId: '',
    begrunnelse: '',
  };

  const validate = (values: CreateFakturafritakCommand) => {
    let errors: any = {};
    if (!values.tsKundeId) {
      errors.tsKundeId = 'TsKunde må angis';
    }

    if (!values.tjenesteId) {
      errors.tjenesteId = 'Tjeneste må angis';
    }

    return errors;
  };

  const onSubmit = async (command: CreateFakturafritakCommand) => {
    try {
      var create = await FakturafritakApi.createFakturafritak(command);
      toaster.success('Suksess', 'Tjeneste opprettet');
      handleClose();
      resetForm(undefined);
      props.onUpdated()
    }
    catch (err) {
      toaster.error('Noe gikk galt', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => {
    props.setShow(false);
  }

  return (
    <>
      <IconButton
        icon='plus'
        variant='primary'
        className='col'
        onClick={() => props.setShow(true)}
      >
        Fakturafritak
      </IconButton>


      <Modal centered show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>  <Modal.Title>Opprett nytt fakturafritak</Modal.Title> </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>

            <Form.Group className='mb-3'>
              <Form.Label>TsKunde</Form.Label>
              <TsKundeTypeahead onHandleChange={e => handleChange('tsKundeId', e?.tsKundeId)} />
              {errors?.tsKundeId && touched?.tsKundeId && <div className={Style.invalidFeedback}>{errors?.tsKundeId}</div>}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Tjeneste</Form.Label>
              <TjenesteTypeahead onHandleChange={e => handleChange('tjenesteId', e?.tjenesteId)} />
              {errors?.tjenesteId && touched?.tjenesteId && <div className={Style.invalidFeedback}>{errors?.tjenesteId}</div>}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Begrunnelse</Form.Label>
              <Form.Control
                value={values.begrunnelse}
                onChange={e => handleChange('begrunnelse', e.target.value)}
                type='text' />
            </Form.Group>

          </Modal.Body>

          <Modal.Footer>
            <IconButton
              icon='close'
              variant='outline-primary'
              className='col'
              onClick={handleClose}
            >
              Avbryt
            </IconButton>

            <IconButton
              variant='primary'
              className='col'
              type='submit'
              icon='disk'
              disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
            >
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              } Lagre
            </IconButton>
          </Modal.Footer>
        </Form>
      </Modal>

    </>
  );

};


export default CreateFakturafritak;