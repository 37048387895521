import { Col, Row } from 'react-bootstrap';
import Style from './index.module.css';
interface Props {
}

const Home = () => {
  return (
    <div className={Style.container}>
      <Row>
        <Col>
          <h1 className={Style.mainHeader}>{window.location.href.includes('amin') ? "Aminportalen" : "Adminportalen"}</h1>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center' }}>         
          {window.location.href.includes('amin') ? <img src="https://tradesolution.no/wp-content/uploads/Amin.jpg" alt="amin" style={{ width: '450px' }} /> :
            <iframe src="https://giphy.com/embed/duNowzaVje6Di3hnOu" width="480" height="480" allowFullScreen></iframe>}
        </Col>
        <h4 style={{ textAlign: 'center' }}>Endelig arbeid igjen</h4>
      </Row>
    </div >
  );
}

export default Home;
