import Sidebar, { SubLink, NavLink } from ".";

const SidebarWrapper = () => {
    const faktureringSubLinks: SubLink[] = [
        { label: 'Fakturagrunnlag', link: '/Fakturagrunnlag' },
        { label: 'Fakturafritak', link: '/Fakturafritak' },
        { label: 'Utsendelse', link: '/FakturaSpesifisering' },
        { label: 'Prismodeller', link: '/Prismodeller' }
    ];

    const tilgangsstyringSubLinks: SubLink[] = [
        { label: 'API tilganger', link: '/ApiTilganger' },
        { label: 'Eksterne Ad grupper', link: '/Tilgangsstyring/EksterneAdGrupper' },
        { label: 'Tilganger og roller', link: '/Tilgangsstyring/TilgangerOgRoller' },
        { label: 'Tilgangssøknader', link: '/Tilgangsstyring/Tilgangssoknader' }
    ];
    
    const rapporterSubLinks: SubLink[] = [
        { label: 'Faktureringshistorikk', link: '/Rapporter/0e0f8df2-bb73-48ea-acb5-56e2498bf7c9' },
    ];

    const navLinks: NavLink[] = [
        { label: 'TsKunder', link: '/TsKunder', icon: 'company' },
        { label: 'Brukere', link: '/Brukere', icon: 'users' },
        { label: 'Fakturering', link: '#', subLinks: faktureringSubLinks, icon: 'bill' },
        { label: 'Tjenester', link: '/Tjenester', icon: 'cards' },
        { label: 'Tilgangsstyring', link: '#', subLinks: tilgangsstyringSubLinks, icon: 'adminportal' },        
        { label: 'Rapporter', link: '#', subLinks: rapporterSubLinks, icon: 'document' },
    ];

    return <Sidebar navLinks={navLinks} />;
};


export default SidebarWrapper;