import axios from "axios";
import { getBaseUrl } from '..';
import { CreateSearchWordCommand } from "./types";

const baseUrl = getBaseUrl() + '/SearchWords';

const SearchWordsApi = {
    create: (payload: CreateSearchWordCommand) =>
        axios.post(`${baseUrl}`, payload).then(response => response.data),

    delete: async (tsKundeId: string, searchWord: string) => {
        await axios.delete(`${baseUrl}/${tsKundeId}/${searchWord}`);
    }
}

export default SearchWordsApi;