import React from 'react';
import { Row, Col } from 'react-bootstrap';

import TilgangerTable from './components/TilgangerTable';
import RolleTable from './components/RolleTable';

import Style from './index.module.css';

const TilgangerOgRoller: React.FC = () => {

  return (
    <div className={Style.container}>
      <Row style={{marginTop: '0.5em'}}>
        <Col md={8}>
          <h2 className={Style.mainHeader}>Tilganger</h2>
          <TilgangerTable />
        </Col>
        <Col>
          <h2 className={Style.mainHeader}>Roller</h2>
          <RolleTable />
        </Col>
      </Row>
    </div>
  )
}

export default TilgangerOgRoller;