import { useState, useEffect, useRef } from 'react';

import TsKundeTypeahead from 'components/TsKundeTypeahead';

import AccessToSelect from 'components/AccessToSelect';
import RoleSelect from 'components/RoleSelect';

import TsKunderApi from 'services/AdminportalenApi/TsKunderApi';
import { TsKundeSearchDto } from 'services/AdminportalenApi/TsKunderApi/types';
import { BrukerTilgangCommand } from 'services/AdminportalenApi/BrukereApi/types';
import { IconButton } from '@tradesolution/iceberg-ui-react';

interface Props {
  permission: BrukerTilgangCommand;
  onUpdateAccessTo: (value: string) => void;
  onUpdateRole: (value: string) => void;
  onUpdateTsKundeId: (value: string) => void;
  onDeleteRow: () => void;
  selectedTsKundeId: string;
}

const TilgangRow = (props: Props) => {
  const firstRender = useRef(true);

  const [selectedTsKunde, setSelectedTsKunde] = useState<TsKundeSearchDto>();

  useEffect(() => {
    if (!firstRender.current) {
      if (selectedTsKunde) {
        props.onUpdateTsKundeId(selectedTsKunde.tsKundeId);
      } else {
        props.onUpdateTsKundeId(null);
      }
    }
  }, [selectedTsKunde])

  useEffect(() => {
    if (firstRender.current) {
      if (props.selectedTsKundeId) {
        TsKunderApi.getById(props.selectedTsKundeId).then(response => setSelectedTsKunde(response));
      }
      firstRender.current = false;
    }
  }, [props.selectedTsKundeId, props.permission])

  return (
    <tr>
      <td>
        <TsKundeTypeahead
          size='sm'
          selectedTsKundeId={selectedTsKunde?.tsKundeId}
          onHandleChange={e => setSelectedTsKunde(e)}
          disabled={props.permission.id?.length > 0} />
      </td>
      <td>
        <AccessToSelect
          size='sm'
          selected={props.permission.accessTo || ''}
          onChange={a => props.onUpdateAccessTo(a)}
          disabled={props.permission.id?.length > 0} />
      </td>
      <td>
        <RoleSelect
          size='sm'
          selected={props.permission.role || ''}
          onChange={r => props.onUpdateRole(r)}
          disabled={props.permission.id?.length > 0} />
      </td>
      <td className='fitContent'>
        <IconButton icon='trash' variant='outline-danger' onClick={props.onDeleteRow} />
      </td>
    </tr>
  )
};

export default TilgangRow;