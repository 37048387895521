import axios from "axios";
import PagedResult from "services/PagedResult";
import { getBaseUrl } from "..";
import { AdApplication, CreateApplicationCommand, TokenReponse, UpdateApplicationCommand } from "./types";

const baseUrl = getBaseUrl() + '/AdApplications';

const AdApplicationsApi = {
    get: async (filter?: any, page?: number): Promise<AdApplication[]> => {
        filter.page = page;
        const url = `${baseUrl}?${new URLSearchParams(filter).toString()}`;
        const response = await axios.get(url);
        return response.data;
    },
    getApplicationsCount: async (filter?: any): Promise<number> => {
        const url = `${baseUrl}/Count?${new URLSearchParams(filter).toString()}`;
        const response = await axios.get(url);
        return response.data;
    },
    create: async (command: CreateApplicationCommand): Promise<AdApplication | string> => {
        const response = await axios.post(baseUrl, command);
        return response.data;
    },
    update: async (command: UpdateApplicationCommand): Promise<AdApplication | string> => {
        const response = await axios.put(`${baseUrl}`, command);
        return response.data;
    },
    getAccessToken: async (clientId: string): Promise<TokenReponse> => {
        const response = await axios.get(`${baseUrl}/${clientId}/accesstoken`);
        return response.data;
    },
    getClientSecret: async (clientId: string): Promise<string> => {
        const response = await axios.get(`${baseUrl}/${clientId}/clientsecret`);
        return response.data;
    },
    toogleStatus: async (applicationId: string): Promise<string> => {
        const response = await axios.put(`${baseUrl}/${applicationId}/ToogleStatus`);
        return response.data;
    }
};

export default AdApplicationsApi;