import { IconButton } from '@tradesolution/iceberg-ui-react';
import TjenesegruppeSelect from 'components/TjenestegruppeSelect';
import TsKundeTypeahead from 'components/TsKundeTypeahead';
import { Form, Modal, Spinner } from 'react-bootstrap';
import FakturaSpesifiseringApi from 'services/AdminportalenApi/FakturaSpesifiseringApi';
import { CreateMottakerCommand } from 'services/AdminportalenApi/FakturaSpesifiseringApi/types';
import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import Style from './index.module.css';

interface Props {
  show: boolean;
  onUpdated: () => void;
  setShow: (show: boolean) => void;
}


const CreateMottaker = (props: Props) => {
  const toaster = useToaster();

  const initialValues: CreateMottakerCommand = {
    tsKundeId: '',
    tjenesteGruppeId: '',
    emails: [],
  };


  const validate = (values: CreateMottakerCommand) => {
    let errors: any = {};
    if (!values.tsKundeId) {
      errors.tsKundeId = 'TsKunde må angis';
    }

    if (values.tjenesteGruppeId.length === null || values.tjenesteGruppeId === '') {
      errors.tjenesteGruppeId = 'TjenesteGruppe må angis';
    }
    return errors;
  };


  const onSubmit = async (command: CreateMottakerCommand) => {
    try {
      await FakturaSpesifiseringApi.createMottaker(command);
      toaster.success('Suksess', 'Mottaker opprettet');
      handleClose();
      resetForm(undefined);
      props.onUpdated()
    }
    catch (err) {
      toaster.error('Noe gikk galt', HttpErrorHelper.handleErrorMessage(err));
    }
  };

  const { values, errors, touched, loading, handleChange, handleSubmit, resetForm } = useForm(initialValues, validate, onSubmit);

  const handleClose = () => {
    props.setShow(false);
  }

  const convertTextToEmailArray = (text: string): string[] => {
    return text.split(',').map((email: string) => email.trim());
  }

  const updateEmails = (value: string): void => {
    const emailList = convertTextToEmailArray(value);
    handleChange("emails", emailList);
  };

  return (
    <>
      <IconButton
        variant="primary"
        icon='plus'
        onClick={() => props.setShow(true)}
      >
        Mottaker
      </IconButton>

      <Modal centered show={props.show} onHide={handleClose} >
        <Modal.Header closeButton> <Modal.Title>Opprett ny mottaker </Modal.Title>  </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>

            <Form.Group className='mb-3'>
              <Form.Label>TsKunde</Form.Label>
              <TsKundeTypeahead onHandleChange={e => handleChange('tsKundeId', e?.tsKundeId)} />
            </Form.Group>


            <Form.Group className="mb-3">
              <Form.Label>Tjenestegruppe</Form.Label>
              <TjenesegruppeSelect selected={values.tjenesteGruppeId} onChange={e => handleChange('tjenesteGruppeId', e)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>E-post</Form.Label>
              <Form.Control
                value={values.emails}
                onChange={e => updateEmails(e.target.value)}
              />
              <Form.Text muted>Adminportal foreslår fakturakontakt automatisk.
                For flere adresser bruk komma som skilletegn. Eks: test@testface.com,boaty@mcboatface.com</Form.Text>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <IconButton
              icon='close'
              variant='outline-primary'
              className='col'
              onClick={handleClose}
            >
              Avbryt
            </IconButton>

            <IconButton
              variant='primary'
              className='col'
              type='submit'
              icon='disk'
              disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
            >
              {
                loading &&
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              } Lagre
            </IconButton>

          </Modal.Footer>
        </Form>
      </Modal>

    </>

  );
};


export default CreateMottaker;




