import { IcebergIcon, IconName } from "@tradesolution/iceberg-ui-react";
import { Variant } from "react-bootstrap/esm/types";
import Style from './index.module.scss';

interface Props {
    icon: IconName;
    variant?: Variant;
    title?: string;
}

const IconWrapper = (props: Props) => {
    const getIconWrapperColor = (variant?: string) => {
        switch (variant) {
            case "success":
                return "#CCEEC9";
            case "danger":
                return "#FAC1C0";
            case "warning":
                return "#FCD7AD";
            default:
                return "#BFE0FF";
        }
    };

    return (
        <span title={props.title} className={Style.iconWrapper} style={{ backgroundColor: getIconWrapperColor(props.variant) }}>
            <IcebergIcon icon={props.icon} />
        </span>);
};

export default IconWrapper;