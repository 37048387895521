import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import TjenestegruppeApi from "services/AdminportalenApi/TjenestegruppeApi";
import { Tjenestegruppe } from "services/AdminportalenApi/TjenestegruppeApi/types";

interface Props {
  selected: string;
  onChange: (tjenestegruppeId: string) => void;
  className?: string;
}

const TjenesegruppeSelect: React.FC<Props> = props => {
  const [tjenestegrupper, setTjenestegrupper] = useState<Tjenestegruppe[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadTjenestegrupper = async () => {
    setLoading(true);
    let res = await TjenestegruppeApi.getTjenestegrupper();
    setTjenestegrupper(res);
    setLoading(false);
  };

  const handleChange = (value: string) => {
    if(value){
      props.onChange(value);
    } else {
      props.onChange(null);
    }
  }

  useEffect(() => {
    loadTjenestegrupper();
  }, []);
  
  {loading && <Spinner animation="border" />}

  return (
    <Form.Select 
      className={props.className} aria-label="TjenestegruppeSelect" 
      onChange={e => handleChange(e.target.value)} 
      value={props.selected}
      style={{borderRadius: "24px"}}
      >                  
      <option value="">Velg tjenestegruppe</option>
      {
        tjenestegrupper.map(x => 
          <option key={x.tjenesteGruppeId} value={x.tjenesteGruppeId}>{x.tjenesteGruppeNavn}</option>
        )
      }
    </Form.Select>
  );
};

export default TjenesegruppeSelect;