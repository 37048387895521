import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import React from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

import ExternalAdSourcesApi from 'services/AdminportalenApi/ExternalAzureAdSourcesApi';
import { ExternalAzureAdSourceDto, UpdateExternalGroupCommand } from "services/AdminportalenApi/ExternalAzureAdSourcesApi/types";

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';


interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  externalGroup: ExternalAzureAdSourceDto;
  onUpdated: () => void;
}

const EditDetailsModal: React.FC<Props> = props => {

  const toaster = useToaster();

  const initialVals: UpdateExternalGroupCommand = {
    id: props.externalGroup.id,
    name: props.externalGroup.name,
    description: props.externalGroup.description
  }

  const validate = (values: UpdateExternalGroupCommand) => {
    let errors: any = {};

    if (!values.name) {
      errors.name = 'Navn kan ikke være tomt';
    }

    return errors;
  }

  const onSubmit = async (command: UpdateExternalGroupCommand) => {
    try {
      await ExternalAdSourcesApi.updateExternalSource(command)
      toaster.success('Suksess', 'Ekstern Ad gruppe oppdatert!');
      props.setShow(false);
      props.onUpdated();
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.handleErrorMessage(err));
    }
  }

  const { values, errors, touched, loading, handleChange, handleSubmit } = useForm(initialVals, validate, onSubmit);

  return (
    <Modal
      centered
      show={props.show}
      onHide={() => props.setShow(false)}
      aria-labelledby="example-custom-modal-styling-title">
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">{props.externalGroup.name}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Navn</Form.Label>
            <Form.Control
              value={values.name}
              onChange={e => handleChange('name', e.target.value)}
              isInvalid={touched?.name && errors?.name} />
            <Form.Control.Feedback type='invalid'>
              {errors?.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Beskrivelse</Form.Label>
            <Form.Control
              value={values.description}
              onChange={e => handleChange('description', e.target.value)}
              maxLength={80} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>

          <Button className="col" variant="outline-primary" onClick={() => props.setShow(false)}>
            <IcebergIcon size='lg' icon='close' /> Avbryt
          </Button>
          <Button
            variant="primary"
            className="col"
            disabled={loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0}
            type="submit">
            <IcebergIcon icon='disk' size='lg' /> Lagre
            {
              loading &&
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            }
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

export default EditDetailsModal;