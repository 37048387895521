import axios from "axios";
import { CreateTjenestePrisCommand, TjenestePrismedIntervallDto, UpdateTjenestePrisCommand } from "./types";
import { getBaseUrl } from "..";


const baseUrl = getBaseUrl() + '/TjenestePriser';

const TjenestePriserApi = {
  getTjenestePris: async (tjenestePrisId: string): Promise<TjenestePrismedIntervallDto> => {
    const url = `${baseUrl}/${tjenestePrisId}`;
    const response = await axios.get(url);
    return response.data;
  },

  createTjenestePris: async (command: CreateTjenestePrisCommand): Promise<TjenestePrismedIntervallDto> => {
    const response = await axios.post(`${baseUrl}`, command);
    return response.data;
  },

  updateTjenestePris: async (tjenestePrisId: string, command: UpdateTjenestePrisCommand): Promise<TjenestePrismedIntervallDto> => {
    const response = await axios.put(`${baseUrl}/${tjenestePrisId}`, command);
    return response.data;
  },

  deleteTjenestePris: async (tjenestePrisId: string) => 
    await axios.delete(`${baseUrl}/${tjenestePrisId}`)
  

};

export default TjenestePriserApi;