export default class HttpErrorHelper {

  /**
   * function handleErrorMessage
   * 
   * Used to handle and correctly interpret errors, mainly through try/catch
   * @param msg received error object
   * @returns array of errors
   */
  static handleErrorMessage = (msg: any): string | { key: string; errors: string[] }[] => {
    let arr: { key: string; errors: string[] }[] = [];

    if(msg && msg.response){
      if (msg.response.status === 400) {
        // errors returned from commandValidator
        if (msg.response.data.errors.length > 0) {
          for (const [key, value] of Object.entries(msg.response.data.errors)) {
            if (key !== 'DomainValidations') {
              if (Array.isArray(value)) {
                arr.push({ key: key, errors: value });
              } else if(value instanceof String) {
                arr.push({ key: key, errors: [value.toString()] });
              }
            }
          }
        }
        else // errors returned from commandhandler
        {
          for (const [key, value] of Object.entries(msg.response.data.errors)) {
            if (Array.isArray(value)) {
              arr.push({ key: '', errors: value });
            } else if(value instanceof String){
              arr.push({ key: '', errors: [value.toString()] });
            }
          }
        }
      } else if(msg.response.status === 500){
        if(msg.response.data && msg.response.data.message)
          arr.push({ key: '', errors: [msg.response.data.message] });
      }
    } else {
      arr = [{ key: 'Serverfeil', errors: [msg.response.statusText] }];
    }

    return arr;
  };
}
