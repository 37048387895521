import { Col, Form } from "react-bootstrap"

export enum FaktureringsType {
    Forskuddsvis, Etterskuddsvis, Begge
}

interface Props {
    onChange: (faktureringsType: FaktureringsType) => void;
}

const FaktureringsTypePicker = (props: Props) => {

    const handleSelectedChanged = (value: string) => {
        if (value === "0") {
            props.onChange(FaktureringsType.Forskuddsvis);
        } else {
            props.onChange(FaktureringsType.Etterskuddsvis);
        }
    };

    return (
        <Form.Group as={Col}>
            <Form.Label>Type</Form.Label>
            <Form.Select onChange={e => handleSelectedChanged(e.target.value)}>
                <option value={FaktureringsType.Forskuddsvis}>Forskuddsvis</option>
                <option value={FaktureringsType.Etterskuddsvis}>Etterskuddsvis</option>
            </Form.Select>
        </Form.Group>
    );
}

export default FaktureringsTypePicker;